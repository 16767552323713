import React, { useContext, useEffect, useState } from "react";
import styles from "./PatientRegistration.module.css";
import Grid from "@material-ui/core/Grid";
import Container from "@mui/material/Container";
import { Formik, Form, ErrorMessage } from "formik";
import * as yup from "yup";
import { createYupSchema } from "../../../../FormControls/Validation/yupSchemaCreator";
import LinearProgress from "@material-ui/core/LinearProgress";
import MuiPhoneNumber from "material-ui-phone-number";
import Checkbox from "../../../../FormControls/CheckBoxInput/CheckBox";
import Input from "../../../../FormControls/TextInput/Input";
import inputStyles from "../../../../FormControls/TextInput/Input.module.css";
import RadioButton from "../../../../FormControls/RadioButtonInput/RadioButton";
import DateInput from "../../../../FormControls/TextInput/DateInput";
import InputErrorMessage from "../../../../../Components/FormControls/InputError/InputErrorMessage.js";
import PatientRegistrationHttp from "../../../../API/PatientRegistration/PatientRegistrationHttp";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile, getSelectedProjectIdAndLocationId } from "../../../../../Reducer/selectors/DashboardSelector";
import Box from '@mui/material/Box';
import { getAddSubject } from "../../../../../Actions";
import { getAddSubjectSelector } from "../../../../../Reducer/selectors/PatientSelector";

export const PatientRegistrationForm = (props) => {
  // State to manage progress bar
  const [isSubmitted, setIsSubmitted] = useState(false);

  const diagnosesOptions = [
    { key: "COPD", value: "COPD" },
    { key: "CHF", value: "CHF" },
    { key: "Hypertension", value: "Hypertension" },
    { key: "Asthma", value: "Asthma" },
    { key: "Diabetes", value: "Diabetes" },
    { key: "CKD", value: "CKD" },
    { key: "COVID-19", value: "COVID-19" },
    { key: "Others", value: "Others" },
  ];

  const genderOptions = [
    { key: "M", value: "Male" },
    { key: "F", value: "Female" },
    { key: "N/A", value: "Prefer not to say" },
  ];

  const programsOptions = [
    { key: "CCM", value: "CCM" },
    { key: "RPM", value: "RPM" },
    { key: "ADB", value: "ADB" },
    { key: "Other", value: "Other" },
  ];

  const consentOptions = [
    { key: "True", value: "Consented" },
    { key: "False", value: "Not Consented" },
    { key: "N/A", value: "N/A" },
  ];

  const initialValues = {
    firstName: "",
    lastName: "",
    phone: "",
    emailId: "",
    dob: "",
    gender: "",
    ehrId: "",
    diagnoses: "",
    programs: "",
    consent: "",
  };

  const projAndLoc = useSelector(getSelectedProjectIdAndLocationId);
  const addSubjectResponse = useSelector(getAddSubjectSelector);
  
  const projectId = projAndLoc.selectedProjectId;
  const locationId = projAndLoc.selectedLocationId;

  const profile = useSelector(getUserProfile);

  // const [userEmail] = useState(profile.email);
  const [firstName] = useState(profile.firstName);
  const [lastName] = useState(profile.lastName);
  
  let username =firstName + " " + lastName;

  console.log("USERNAME IS: ", username);
  console.log("PROJ & LOC: ", projectId, locationId);
  const dispatch = useDispatch();

  const validationSchema = yup.object({
    firstName: yup
      .string('First Name')
      .required('First Name is required'),
    lastName: yup
      .string('Last Name')
      .required('Last Name is required'),
    dob: yup
      .date('Date Of Birth')
      .required('Date of Birth is required'),
    ehrId: yup
      .string().required('Ehr ID is required'),
    emailId: yup
      .string().email('Enter a valid email'),
  });

  const formSubmitHandler = (values, actions) => {
    console.log("SUMBISSION", values);
    console.log("ACTIONS", actions);

    let data = {
      consent: values.consent,
      diagnoses: values.diagnoses,
      dob: values.dob,
      ehrId: values.ehrId,
      firstName: values.firstName,
      lastName: values.lastName,
      gender: values.gender,
      middleName: values.middleName,
      phoneNumber: values.phoneNumber,
      programs: values.programs,
      emailId: values.emailId,
      registeredBy: username,
      projectId: projectId,
      locationId: locationId,
    };

    setIsSubmitted(true);
    // PatientRegistrationHttp(data).then((res) => {
    //   console.log("I got the  response of registration", res.id);

    //   // Send updated patient Id to parent component exc function
    //   if (res.success === true) {
    //     localStorage.setItem("PGUID", res.id);
    //     localStorage.setItem("subjectId", res.subjectId);
    //   }
    // });

    dispatch(getAddSubject(data));
    
    setTimeout(() => {
      props.onHandleNext();
      setIsSubmitted(false);
    }, 2000);
  };

  const handleReset = (resetForm) => {
    resetForm();
  };
  
  useEffect(() => {
    console.log('1..1..',addSubjectResponse)
    localStorage.setItem("PGUID", addSubjectResponse.id);
    localStorage.setItem("subjectId", addSubjectResponse.subjectId);
  },[addSubjectResponse])

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={formSubmitHandler}
      >
        {(formik) => (
          <Form>
            <div className={styles.card}>
              <Container maxWidth="sm">
                <Grid container>
                  <Grid item xs={12} sm={12} md={6}>
                    {/* FIRST NAME */}

                    <Input name="firstName" label="First Name" placeholder="John"  required="true" />
                  </Grid>

                  {/* LAST NAME */}

                  <Grid>
                    <Input name="lastName" label="Last Name" placeholder="Doe" required="true" />
                  </Grid>

                  <Grid item xs={12} sm={12} md={6}>
                    <Input name="emailId" label="Email ID" placeholder="jdoe@mail.com" />
                  </Grid>

                  <Grid>
                    <div>
                      <label htmlFor="phone" className={inputStyles.labelDivStyles}>
                        <div className={inputStyles.labelStyles}>Phone Number</div>
                      </label>
                      <MuiPhoneNumber
                        name="phone"
                        defaultCountry={"us"}
                        disableAreaCodes={"true"}
                        value={formik.values.phone}
                        placeholder="(702) 123-4567"
                        InputProps={{
                          disableUnderline: true,
                          className: styles.phoneStyle,
                        }}
                        //error={formik.touched.phonr && Boolean(props.formik.errors.phone)}
                        //helperText={formik.touched.phone && props.formik.errors.phone}
                        onChange={(value) => formik.setFieldValue("phone", value)}
                        onlyCountries={["us"]}
                        autoFormat={"false"}
                      />
                      <ErrorMessage name="phone" component={InputErrorMessage} />
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={12} md={6}>
                    <DateInput name="dob" label="Date of Birth" required="true"/>
                  </Grid>

                  {/* EHR ID */}

                  <Grid /*  style={{ marginTop: "17px" }} */>
                    <Input name="ehrId" label="EHR ID" placeholder="122" required="true"/>
                  </Grid>
                  {/* SELECT GENDER */}

                  <Grid item xs={12}>
                    <RadioButton name="gender" label="Select gender" options={genderOptions} />
                  </Grid>

                  {/* DIAGNOSES */}

                  <Grid item xs={12}>
                    <Checkbox name="diagnoses" label="Diagnoses" options={diagnosesOptions} />
                  </Grid>

                  {/*  PROGRAMS */}

                  <Grid item xs={12}>
                    <Checkbox name="programs" label="Programs" options={programsOptions} />
                  </Grid>

                  {/* CONSENT */}

                  <Grid item xs={12}>
                    <RadioButton name="consent" label="Consent Status" options={consentOptions} />
                  </Grid>
                </Grid>
              </Container>

              <div className={styles.buttonDiv}>
                {isSubmitted && (
                  <div style={{ marginTop: `20px`, marginBottom: `20px` }}>
                    <Box sx={{ width: "100%" }}>
                      <LinearProgress />
                    </Box>
                  </div>
                )}
                <button className={styles.nextButton} onClick={handleReset.bind(null, formik.resetForm)}
              type="button">Cancel</button>
                <button type="submit" className={styles.submitButton}>
                  Submit
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};
