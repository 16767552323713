import { createAction } from "@reduxjs/toolkit";
import { utilsConstants } from "../Constants/UtilConstants";

export const setShowLoader = createAction(
  utilsConstants.SET_SHOW_LOADER,
  (payload) => ({ payload })
);

export const setCurrentPatientGUID = createAction(
  utilsConstants.SET_CURRENT_PATIENT_GUID,
  (payload) => ({ payload })
);

export const setCurrentPatientSubjectID = createAction(
  utilsConstants.SET_CURRENT_PATIENT_SUBJECTID,
  (payload) => ({ payload })
);

export const setAllPatientsList = createAction(
  utilsConstants.SET_ALL_PATIENTS_LIST,
  (payload) => ({ payload })
);

export const setShowPatientProfilePageLoader = createAction(
  utilsConstants.SET_SHOW_PATIENT_PROFILE_PAGE_LOADER,
  (payload) => ({ payload })
);

export const setShowPatientTimerDrawerLoader = createAction(
  utilsConstants.SET_SHOW_PATIENT_TIMER_DRAWER_LOADER,
  (payload) => ({ payload })
);

export const setShowPatientGraphsLoader = createAction(
  utilsConstants.SET_SHOW_PATIENT_GRAPHS_LOADER,
  (payload) => ({ payload })
);

export const setShowPatientVitalsLoader = createAction(
  utilsConstants.SET_SHOW_PATIENT_VITALS_LOADER,
  (payload) => ({ payload })
);

export const setShowPatientNotesLoader = createAction(
  utilsConstants.SET_SHOW_PATIENT_NOTES_LOADER,
  (payload) => ({ payload })
);

export const setShowPatientAidiLoader = createAction(
  utilsConstants.SET_SHOW_PATIENT_AIDI_LOADER,
  (payload) => ({ payload })
);

export const setShowAidiValidationLoader = createAction(
  utilsConstants.SET_SHOW_AIDI_VALIDATION_LOADER,
  (payload) => ({ payload })
);

export const setShowAssignMlLoader = createAction(
  utilsConstants.SET_SHOW_ASSIGN_ML_LOADER,
  (payload) => ({ payload })
);

export const setShowChooseMlLoader = createAction(
  utilsConstants.SET_SHOW_CHOOSE_ML_LOADER,
  (payload) => ({ payload })
);

export const setPatientMainTab = createAction(
  utilsConstants.SET_PATIENT_MAINTAB,
  (payload) => ({ payload })
);

export const setSpo2BrExpand = createAction(
  utilsConstants.SET_SPO2_BR_EXPAND,
  (payload) => ({ payload })
);

export const setHrHrvExpand = createAction(
  utilsConstants.SET_HR_HRV_EXPAND,
  (payload) => ({ payload })
);

export const setBpExpand = createAction(
  utilsConstants.SET_BP_EXPAND,
  (payload) => ({ payload })
);

export const setTempPrExpand = createAction(
  utilsConstants.SET_TEMP_PR_EXPAND,
  (payload) => ({ payload })
);

export const setFevPefExpand = createAction(
  utilsConstants.SET_FEV_PEF_EXPAND,
  (payload) => ({ payload })
);

export const setGWPExpand = createAction(
  utilsConstants.SET_G_W_P_EXPAND,
  (payload) => ({ payload })
);

export const setPatientListLoader = createAction(
  utilsConstants.SET_PATIENT_LIST_LOADER,
  (payload) => ({ payload })
);

export const setPatientProfileLoaderAB = createAction(
  utilsConstants.SET_PATIENT_PROFILE_LOADER,
  (payload) => ({ payload })
);

export const setSelectedUnitFilters = createAction(
  utilsConstants.SET_SELECTED_UNIT_FILTERS,
  (payload) => ({ payload })
);

export const setSelectedFloorFilters = createAction(
  utilsConstants.SET_SELECTED_FLOOR_FILTERS,
  (payload) => ({ payload })
);
