import React, { useContext, useState, useEffect } from "react";

import styles from "./PatientsList.module.css";
import Grid from "@material-ui/core/Grid";
import PatientAccordion from "../PatientAccordion/PatientAccordion";
import NDPatientAccordion from "../PatientAccordion/NDPatientAccordion";
import { UserContext } from "../../../../Store/UserContext";
import { getAccRenderInfo } from "../../../../API/data";
import CircularProgress from '@mui/material/CircularProgress';
import Header from "../../Header/Header";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getLocationName, getProjectName, getUserProfile } from "../../../../../Reducer/selectors/DashboardSelector";
import { setMLVitalsWrapperId, setRulesWrapperId, setSelectedPageName } from "../../../../../Actions";
import { globalConstants } from "../../../../../Constants/GlobalConstants";
import NAHeader from "../../Header/NAHeader";
import { getSelectedMlIdSelector } from "../../../../../Reducer/selectors/DashboardSelector";
import HubIcon from '@mui/icons-material/Hub';
import CloseIcon from '@mui/icons-material/Close';
import { setSelectedMlId } from "../../../../../Actions";
import Modal from "@material-ui/core/Modal";
import { RiCloseCircleFill } from "react-icons/ri";
import IconButton from "@material-ui/core/IconButton";
import UnselectML from "../../DeviceLog/MouthLabLogs/MLActions/UnselectML";
import Backdrop from '@mui/material/Backdrop';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import PostUnassignMouthLabFromSubject from "../../../../API/PostUnassignMouthLabFromSubject/PostUnassignMouthLabFromSubject";
import MLImg from '../../../../../Assets/Images/Aidar-Hand-Device-Sketch-01.png';

export default function PatientsList(props) {
  const userContext = useContext(UserContext);
  const profile = useSelector(getUserProfile);

  const [userEmail] = useState(profile.email);
  const [firstName] = useState(profile.firstName);
  const [lastName] = useState(profile.lastName);

  const { projectId, locationId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const projectName = useSelector(getProjectName);
  const locationName = useSelector(getLocationName);

  const selectedML = useSelector(getSelectedMlIdSelector);

  const [showDialog, setShowDialog] = useState(false);

  // useEffect(() => {
  //   console.log('new date...', new Date().toString())
  //   dispatch(setMLVitalsWrapperId(0));
  //   dispatch(setRulesWrapperId(0));

  //   dispatch(setSelectedPageName('patientslist'));
  // }, []);

  useEffect(() => {
    if (projectId && locationId) {
      userContext.setCurPlSelections({
        projectId,
        locationId,
      });
    }
  }, [projectId, locationId]);

  const indicatorSize = 80;

  if (!userEmail || !projectId || !locationId) {
    return (

      <CircularProgress thickness={5.0} style={{ color: `#4E253A` }} />
    );
  }
  const userName = firstName + " " + lastName;
  const accs = getAccRenderInfo(); //TODO #YI# Refactor this dummy data

  const renderNDAccordions = accs.map((acc) => (
    <NDPatientAccordion
      key={acc.accID}
      accId={acc.accID}
      /* label={acc.accLabel} */
      // label={'All Patients'}
      type={acc.accType}
      priorityId={acc.priorityID}
      risk={acc.priority}
      email={userEmail}
      projectId={projectId}
      locationId={locationId}
    />
  ));

  let today = new Date();
  var hour = today.getHours();

  const unpairML = (event) => {
    event.preventDefault();
    setShowDialog(true);
    // PostUnassignMouthLabFromSubject({ deviceId: selectedML }).then((res) => {
    // });
    // navigate(`/deviceAssignments`);
  };

  function closeDialogMenu(event, reason) {
    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      // Set 'open' to false, however you would do that with your particular code.
      setShowDialog(false);
    }
  };

  const handleSubmit = () => {
    PostUnassignMouthLabFromSubject({ deviceId: selectedML }).then((res) => {
    });
    dispatch(setSelectedMlId(""));
    navigate(`/deviceAssignments`);
  };


  return (
    <div className={styles.accContainer}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer - 1 }}
        open={showDialog}
      ></Backdrop>
      <main></main>
      <NAHeader userEmail={profile.email} />

      <div className={styles.selectedMLDiv}>
        <div className={styles.MLIdDiv}>
          <div className={styles.hubIconDiv}>
            <HubIcon style={{ height: '16px', width: '16px', color: "#ffffff" }}></HubIcon>
          </div>
          ML {selectedML}
        </div>
        <div className={styles.unpairButton} onClick={unpairML}>
          Change
          <div className={styles.hubIconDiv}>
            <CloseIcon style={{ height: '16px', width: '16px', color: "#ffffff" }}></CloseIcon>
          </div>
        </div>
      </div>
      <Grid container>
        <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
          <div>{renderNDAccordions}</div>
        </Grid>
      </Grid>
      {/* MouthLab removal from subject */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={styles.modal}
        open={showDialog}
        onClose={(event, reason) => closeDialogMenu(event, reason)}
        hideBackdrop={true}
      >
        <div className={styles.paperUnassignML}>
          <div className={styles.menuTitle}>Change MouthLab</div>

          <div className={styles.userQuestionMessage}> Are you sure you want to change the selected MouthLab device? </div>

          <div className={styles.mouthLabImgDiv}>
            <img src={MLImg} loading="lazy" className={styles.mlImg} />
          </div>

          <div className={styles.buttonContainer}>
            <button type="button" onClick={closeDialogMenu} className={styles.cancelButton}>
              Cancel
            </button>
            <button type="submit" onClick={handleSubmit} className={styles.submitButton}>
              Confirm
            </button>
          </div>

        </div>
      </Modal>

      {/* MouthLab removal from subject */}
      {/* <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={styles.modal}
        open={showDialog}
        onClose={(event, reason) => closeDialogMenu(event, reason)}
        hideBackdrop={true}
      >
        <div className={styles.paperUnassignML}>
          <div className={styles.menuTitle}>
            <div className={styles.titleText}>Unselect MouthLab</div>
            <div>
              <IconButton aria-label="delete">
                <RiCloseCircleFill size={29} onClick={closeDialogMenu} style={{ color: "#4E253A" }} />
              </IconButton>
            </div>
          </div>

          <div className={styles.patientListDiv}>
            <Grid container>
              <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
                <UnselectML currentDeviceId={selectedML} closeModal={closeDialogMenu} />
              </Grid>
            </Grid>
          </div>
        </div>
      </Modal> */}
    </div>
  );
}
