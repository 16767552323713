import React from "react";
import styles from "./PatientsPrograms.module.css";
import PatientStatusWrapper from "./PatientStatus/PatientStatusWrapper";

export default function PatientsProgram() {
  return (
    <div className={styles.wrapperCard}>
      <div className={styles.devicesListContainer}>
        <PatientStatusWrapper />
      </div>
    </div>
  );
}
