import React from "react";
import QuickViewVitals from "../QuickViewVitals/QuickViewVitals";
import { BsExclamationCircle } from "react-icons/bs";
import Grid from "@material-ui/core/Grid";
import styles from "../QuickViewVitals/QuickViewVitals.module.css";
import { useSelector } from "react-redux";
import { getUserRole } from "../../../../../Reducer/selectors/DashboardSelector";
import { globalConstants } from "../../../../../Constants/GlobalConstants";
import QuickViewVitalsPH from "../QuickViewVitalsPhysicianView/QuickViewVitalsPH";

export default function QuickViewWrapper(props) {
  console.log(
    "=======================================",
    props.vitals
  );

  const isVitals = (props.vitals.length > 0) ? true : false;
  const isRecordId = isVitals ? props.vitals[0].recordId : false;
  const userRole = useSelector(getUserRole);

  console.log("isRecordId: ", isRecordId);

  const patientVitalsTable = props.vitals.map((allVitals) => (
    <QuickViewVitals
      key={allVitals.recordId}
      mlId={allVitals.deviceId}
      timestamp={allVitals.timestamp}
      temp={allVitals.temperature.value}
      spo2={allVitals.spo2.value}
      breathingRate={allVitals.breathingRate.value}
      pulseRate={allVitals.pulseRate.value}
      heartRate={allVitals.heartRate.value}
      hrv={allVitals.hrv.value}
      sbp={allVitals.sbp.value}
      dbp={allVitals.dbp.value}
      fvc={allVitals.fvc.value}
      fev1={allVitals.fev1.value}
      pef={allVitals.pef.value}
      tempRisk={allVitals.temperature.risk}
      spo2Risk={allVitals.spo2.risk}
      breathingRateRisk={allVitals.breathingRate.risk}
      pulseRateRisk={allVitals.pulseRate.risk}
      heartRateRisk={allVitals.heartRate.risk}
      hrvRisk={allVitals.hrv.risk}
      sbpRisk={allVitals.sbp.risk}
      dbpRisk={allVitals.dbp.risk}
      fvcRisk={allVitals.fvc.risk}
      fev1Risk={allVitals.fev1.risk}
      pefRisk={allVitals.pef.risk}
      recordId={allVitals.recordId}
    />
  ));

  const patientVitalsTablePH = props.vitals.map((allVitals) => (
    <QuickViewVitalsPH
      key={allVitals.recordId}
      mlId={allVitals.deviceId}
      timestamp={allVitals.timestamp}
      temp={allVitals.temperature.value}
      spo2={allVitals.spo2.value}
      breathingRate={allVitals.breathingRate.value}
      pulseRate={allVitals.pulseRate.value}
      heartRate={allVitals.heartRate.value}
      hrv={allVitals.hrv.value}
      sbp={allVitals.sbp.value}
      dbp={allVitals.dbp.value}
      fvc={allVitals.fvc.value}
      fev1={allVitals.fev1.value}
      pef={allVitals.pef.value}
      tempRisk={allVitals.temperature.risk}
      spo2Risk={allVitals.spo2.risk}
      breathingRateRisk={allVitals.breathingRate.risk}
      pulseRateRisk={allVitals.pulseRate.risk}
      heartRateRisk={allVitals.heartRate.risk}
      hrvRisk={allVitals.hrv.risk}
      sbpRisk={allVitals.sbp.risk}
      dbpRisk={allVitals.dbp.risk}
      fvcRisk={allVitals.fvc.risk}
      fev1Risk={allVitals.fev1.risk}
      pefRisk={allVitals.pef.risk}
      recordId={allVitals.recordId}
    />
  ));


  if (userRole === globalConstants.USER_ROLE_PHYSICIAN) {
    return (
      <div style={{ backgroundColor: `#F3E8EE`, height: `100%` }}>
        {isRecordId ? patientVitalsTablePH : (
          <Grid
            container
            spacing={4}
            // justify="center"
            style={{ padding: 20 }}
          >
            <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
              <div className={styles.cardHighRisk}>
                <div className={styles.iconDivStyles}>
                  <BsExclamationCircle
                    className={styles.highRiskIcon}
                    size={50}
                  />
                </div>
                <div className={styles.countDivStyles}>
                  <div className={styles.patientCountHighRisk}>
                    No measurements found
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        )}
      </div>
    );
  } else {
    return (
      <div style={{ backgroundColor: `#F3E8EE`, height: `100%` }}>
        {isRecordId ? patientVitalsTable : (
          <Grid
            container
            spacing={4}
            // justify="center"
            style={{ padding: 20 }}
          >
            <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
              <div className={styles.cardHighRisk}>
                <div className={styles.iconDivStyles}>
                  <BsExclamationCircle
                    className={styles.highRiskIcon}
                    size={50}
                  />
                </div>
                <div className={styles.countDivStyles}>
                  <div className={styles.patientCountHighRisk}>
                    No measurements found
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        )}
      </div>
    );
  }
}
