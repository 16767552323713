import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./QuickViewVitalsPH.module.css";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

//Icons Import
import { BsExclamationCircle } from "react-icons/bs";
import { ReactComponent as TempIcon } from "./../../../../../Assets/icons/01-Temp.svg";
import { ReactComponent as BPIcon } from "./../../../../../Assets/icons/02-BP.svg";
import { ReactComponent as RRIcon } from "./../../../../../Assets/icons/03-RR.svg";
import { ReactComponent as PRIcon } from "./../../../../../Assets/icons/04-PR.svg";
import { ReactComponent as HRIcon } from "./../../../../../Assets/icons/05-HR.svg";
import { ReactComponent as SPOIcon } from "./../../../../../Assets/icons/06-SpO2.svg";
import { ReactComponent as HRVIcon } from "./../../../../../Assets/icons/07-HRV.svg";
import { ReactComponent as PEFIcon } from "./../../../../../Assets/icons/08-PEF.svg";
import { ReactComponent as FEVIcon } from "./../../../../../Assets/icons/09-FEV1.svg";

const useStyles = makeStyles({
    highRiskCard: {
        height: 80,
        background: "#FFFF",
        borderColor: "#283593",
        color: "#283593",
    },

    cardContentStyleHigh: {
        color: "#283593",
        textAlign: "right",
    },

    textFont: {
        fontSize: 15,
        textTransform: "uppercase",
    },
});

export default function QuickViewVitalsPH(props) {
    const classes = useStyles();

    console.log("Object Data", props);
    dayjs.extend(utc);
    dayjs.extend(timezone);

    const timeZoneFromDayjs = dayjs.tz.guess();

    return (
        <div className={styles.backColor}>
            <Grid
                container
                spacing={2}
                // justify="center"
                style={{ padding: 20 }}
            >
                <Grid item xs={6} xl={6} lg={6} md={6} sm={6}>
                    <div className={styles.cardHighRisk}>
                        <div className={styles.iconDivStyles}>
                            <TempIcon className={styles.highRiskIcon} size={35} />
                        </div>
                        <div className={styles.countDivStyles}>
                            <div className={styles.patientCountHighRisk}>{props.temp}</div>
                            <div className={styles.patientCatagoryHighRisk}>Temp (F)</div>
                        </div>
                    </div>
                </Grid>

                <Grid item xs={6} xl={6} lg={6} md={6} sm={6}>
                    <div className={styles.cardHighRisk}>
                        <div className={styles.iconDivStyles}>
                            <BPIcon className={styles.highRiskIcon} size={35} />
                        </div>
                        <div className={styles.countDivStyles}>
                            <div className={styles.patientCountHighRisk}>
                                {props.sbp + "/" + props.dbp}
                            </div>
                            <div className={styles.patientCatagoryHighRisk}>BP (mmHg)</div>
                        </div>
                    </div>
                </Grid>

                <Grid item xs={6} xl={6} lg={6} md={6} sm={6}>
                    <div className={styles.cardHighRisk}>
                        <div className={styles.iconDivStyles}>
                            <RRIcon className={styles.highRiskIcon} size={40} />
                        </div>
                        <div className={styles.countDivStyles}>
                            <div className={styles.patientCountHighRisk}>
                                {props.breathingRate}
                            </div>
                            <div className={styles.patientCatagoryHighRisk}>
                                RR (br/min)
                            </div>
                        </div>
                    </div>
                </Grid>

                <Grid item xs={6} xl={6} lg={6} md={6} sm={6}>
                    <div className={styles.cardHighRisk}>
                        <div className={styles.iconDivStyles}>
                            <PRIcon className={styles.highRiskIcon} size={40} />
                        </div>
                        <div className={styles.countDivStyles}>
                            <div className={styles.patientCountHighRisk}>
                                {props.pulseRate}
                            </div>
                            <div className={styles.patientCatagoryHighRisk}>PR (bpm)</div>
                        </div>
                    </div>
                </Grid>

                <Grid item xs={6} xl={6} lg={6} md={6} sm={6}>
                    <div className={styles.cardHighRisk}>
                        <div className={styles.iconDivStyles}>
                            <HRIcon className={styles.highRiskIcon} size={40} />
                        </div>
                        <div className={styles.countDivStyles}>
                            <div className={styles.patientCountHighRisk}>
                                {props.heartRate}
                            </div>
                            <div className={styles.patientCatagoryHighRisk}>HR (bpm)</div>
                        </div>
                    </div>
                </Grid>

                <Grid item xs={6} xl={6} lg={6} md={6} sm={6}>
                    <div className={styles.cardHighRisk}>
                        <div className={styles.iconDivStyles}>
                            <SPOIcon className={styles.highRiskIcon} size={40} />
                        </div>
                        <div className={styles.countDivStyles}>
                            <div className={styles.patientCountHighRisk}>{props.spo2}</div>
                            <div className={styles.patientCatagoryHighRisk}>SpO2 (%)</div>
                        </div>
                    </div>
                </Grid>

                <Grid item xs={6} xl={6} lg={6} md={6} sm={6}>
                    <div className={styles.cardHighRisk}>
                        <div className={styles.iconDivStyles}>
                            <HRVIcon className={styles.highRiskIcon} size={40} />
                        </div>
                        <div className={styles.countDivStyles}>
                            <div className={styles.patientCountHighRisk}>{props.hrv}</div>
                            <div className={styles.patientCatagoryHighRisk}>HRV (ms)</div>
                        </div>
                    </div>
                </Grid>

                <Grid item xs={6} xl={6} lg={6} md={6} sm={6}>
                    <div className={styles.cardHighRisk}>
                        <div className={styles.iconDivStyles}>
                            <PEFIcon className={styles.highRiskIcon} size={40} />
                        </div>
                        <div className={styles.countDivStyles}>
                            <div className={styles.patientCountHighRisk}>{props.pef}</div>
                            <div className={styles.patientCatagoryHighRisk}>
                                PEF (L/sec)
                            </div>
                        </div>
                    </div>
                </Grid>

                <Grid item xs={6} xl={6} lg={6} md={6} sm={6}>
                    <div className={styles.cardHighRisk}>
                        <div className={styles.iconDivStyles}>
                            <FEVIcon className={styles.highRiskIcon} size={40} />
                        </div>
                        <div className={styles.countDivStyles}>
                            <div className={styles.patientCountHighRisk}>{props.fev1}</div>
                            <div className={styles.patientCatagoryHighRisk}>FEV1 (L)</div>
                        </div>
                    </div>
                </Grid>
                <div className={styles.timeStampStyles}>
                    <div>{dayjs.utc(props.timestamp).tz(timeZoneFromDayjs).format('YYYY-MM-DD HH:mm')}</div>
                    <div style={{color: "#4E253A"}}>Most Recent Measurement</div>
                </div>
                {/*
           <Grid item xs={4} xl={4} lg={4} md={4} sm={4}>
            <div className={styles.cardHighRisk}>
              <div className={styles.iconDivStyles}>
                <RiTempHotLine className={styles.highRiskIcon} size={50} />
              </div>
              <div className={styles.countDivStyles}>
                <div className={styles.patientCountHighRisk}>{props.fvc}</div>
                <div className={styles.patientCatagoryHighRisk}>FVC (L)</div>
              </div>
            </div>
          </Grid> 
*/}
                {/* 
        <Grid item xs={4} xl={4} lg={4} md={4} sm={4}>
          <div className={styles.cardHighRisk}>
            <div className={styles.iconDivStyles}>
              <RiLungsLine className={styles.highRiskIcon} size={50} />
            </div>
            <div className={styles.countDivStyles}>
              <div className={styles.patientCountHighRisk}>
                {props.patientVitals.fev1_fvc}
              </div>
              <div className={styles.patientCatagoryHighRisk}>FEV1/FVC (%)</div>
            </div>
          </div>
        </Grid>
*/}
            </Grid>
        </div>
    );
}