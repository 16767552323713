import React, { useContext, useEffect, useState } from "react";
import styles from "./DeviceAssignment.module.css";
import { Formik, Form, FieldArray } from "formik";
import * as yup from "yup";
import AssignDeviceWithUsagePlan from "../../../../API/AssignDeviceWithUsagePlan/AssignDeviceWithUsagePlan";
import { Field, ErrorMessage } from "formik";
import InputErrorMessage from "../../../../FormControls/InputError/InputErrorMessage.js";
import { getAddSubjectSelector } from "../../../../../Reducer/selectors/PatientSelector";
import { useDispatch, useSelector } from "react-redux";
import ChooseDevices from "../../../HomePage/ChooseDevices/ChooseDevices";
import { getUserProfile } from "../../../../../Reducer/selectors/DashboardSelector";
import { getMouthLabLogs, setProjectIdAndLocationId } from "../../../../../Actions";

export default function DeviceAssignment(props) {
  // state to manage response msg
  const [responseMsg, setResponseMsg] = useState("");
  const [showResMsg, setShowResMsg] = useState(false);
  const [resMsgType, setResMsgType] = useState(false);

  // Patient GUID fetched from local storage to perform assignment operation
  //const patientGUID = localStorage.getItem("PGUID");
  const addSubjectResponse = useSelector(getAddSubjectSelector);
  const subjId = addSubjectResponse && addSubjectResponse.subjectId;
  console.log("subjectId= ", subjId);
  const userProfile = useSelector(getUserProfile);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   console.log('2..',userProfile.role);
  // },[]);

  useEffect(() => {
    console.log('1..',userProfile.role);
    if(userProfile.role === 'Physician'){
      dispatch(
        setProjectIdAndLocationId({
          projectId: userProfile.projects[0].id,
          locationId: userProfile.locations[0].id,
        }))
      dispatch(getMouthLabLogs({projectId: userProfile.projects[0].id, locationId: userProfile.locations[0].id}))  
    }
  },[userProfile])

  const deviceOptions = [
    { key: "MouthLab", value: "1" },
    /*    { key: "Omron BP Cuff", value: "2" },
    { key: "Glucometer", value: "3" }, */
  ];

  
  const initialValues = {
    devices: [
      {
        subjectId: subjId,
        //name: "",
        deviceId: "",
        //paramsUnits: "",
        usage: "",
      },
    ],
  };
  // const validationSchema = yup.object({});

  const validationSchema = yup.object().shape({
    devices: yup
      .array()
      .of(
        yup.object().shape({
          usage: yup.string().required("Required"), // these constraints take precedence
        })
      )
      .required("Required"), // these constraints are shown if and only if inner constraints are satisfied
  });

  let items = props.availableDevices.map(function (x) {
    return { key: x, value: x };
  });

  const handleSubmit = (values) => {
    console.log("this is values", values);
    AssignDeviceWithUsagePlan(values.devices[0]).then((res) => {
      console.log("Mouthlab Assignment response", res);
      setResponseMsg(res.response.data);
      console.log("res.status = ", res.response.status);
      setShowResMsg(true);
      setTimeout(() => {
        setShowResMsg(false);
      }, 1500);
      if (res.response.status === 200) {
        setResMsgType(true);
        setTimeout(() => {
          setResponseMsg("");
          setShowResMsg(false);
          setResMsgType(false);
          props.onHandleNext();
        }, 1500);
      }
    });
  };
  return (
    <div className={styles.wrapperDiv}>
      <div className={styles.card}>
        {/* DeviceAssignment */}
        <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
          {({ values }) => (
            <Form>
              <FieldArray name="devices">
                {({ insert, remove, push }) => (
                  <div>
                    {values.devices.length > 0 &&
                      values.devices.map((device, index) => (
                        <div className={styles.deviceFormDiv} key={index}>
                          <div className={styles.itemCellContainer}>
                            <div className={styles.itemCellHeader}>Device Name</div>
                            <div className={styles.textStyle}>
                              MouthLab
                            </div>
                          </div>
                          <div className={styles.itemCellContainer}>
                            <label htmlFor={`devices.${index}.deviceId`}>
                              <div className={styles.itemCellHeader}>Device ID</div>
                            </label>
                            <Field as="select" id={`devices.${index}.deviceId`} name={`devices.${index}.deviceId`} className={styles.selectDropdown}>
                              <option key={""} value={""}>
                                Select
                              </option>
                              {items &&
                                items.map((option) => {
                                  return (
                                    <option key={option.value} value={option.value} className={styles.dropDownItems}>
                                      {option.key}
                                    </option>
                                  );
                                })}
                            </Field>
                            <ErrorMessage name={`devices.${index}.deviceId`} component={InputErrorMessage} />
                          </div>
                          <div className={styles.itemCellContainer}>
                            <label htmlFor={`devices.${index}.usage`}>
                              <div className={styles.itemCellHeader}>Usage per day</div>
                            </label>
                            <Field id={`devices.${index}.usage`} name={`devices.${index}.usage`} className={styles.usageStyle} type="number" />
                            <ErrorMessage name={`devices.${index}.usage`} component={InputErrorMessage} />
                          </div>
                          <div className={styles.itemCellContainer}>
                            <div className={styles.assignButtonContainer}>
                            <button type="submit" className={styles.assignButton}>
                              Assign Device
                            </button>
                            </div>
                          </div>
                          {/* 
                          <Grid container>
                            <Grid item xs={12} xl={10} lg={10} md={12} sm={12}>
                              <div style={{ display: `flex` }}>
                                <Grid container>
                                  <Grid item xs={6} lg={3} md={4}>
                                    <div>
                                      <label htmlFor={`devices.${index}.deviceId`}>
                                        <div className={styles.itemCellHeader}>Device ID</div>
                                      </label>
                                      <Field
                                        as="select"
                                        id={`devices.${index}.deviceId`}
                                        name={`devices.${index}.deviceId`}
                                        className={selectStyles.selectDropdown}
                                      >
                                        <option key={""} value={""}>
                                          Select
                                        </option>
                                        {items &&
                                          items.map((option) => {
                                            return (
                                              <option key={option.value} value={option.value} className={styles.dropDownItems}>
                                                {option.key}
                                              </option>
                                            );
                                          })}
                                      </Field>
                                      <ErrorMessage name={`devices.${index}.deviceId`} component={InputErrorMessage} />
                                    </div>
                                  </Grid>
                                  <Grid item xs={6} lg={3} md={4}>
                                    <div>
                                      <label htmlFor={`devices.${index}.usage`}>
                                        <div className={styles.itemCellHeader}>Usage per day</div>
                                      </label>
                                      <Field
                                        id={`devices.${index}.usage`}
                                        name={`devices.${index}.usage`}
                                        className={selectStyles.selectDropdown}
                                        type="number"
                                      />
                                      <ErrorMessage name={`devices.${index}.usage`} component={InputErrorMessage} />
                                    </div>
                                  </Grid>

                                  <Grid item xs={6} lg={3} md={4}>
                                    <div className={styles.buttonDiv}>
                                      <button type="submit" className={`${styles["baseButtonStyles"]} ${styles.submitButton}`}>
                                        Assign Device
                                      </button>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                            </Grid>
                          </Grid>
 */}
                        </div>
                      ))}
                  </div>
                )}
              </FieldArray>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
