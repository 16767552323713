import React, { useState, useEffect } from "react";
import styles from "./NDTableHeader.module.css";
import Grid from "@material-ui/core/Grid";
import { FaSort } from "react-icons/fa";
import NDTableBody from "../TableBody/NDTableBody";
import Input from '@material-ui/core/Input';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Popper from "@mui/material/Popper";
import PopupState, { bindToggle, bindPopper } from "material-ui-popup-state";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import { RiCloseCircleFill } from "react-icons/ri";
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import { Select } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { getPccPatientListPageNumber, getPccPatientListPageSize } from "../../../../../Reducer/selectors/NursingDashboardSelector";
import { searchPccPatient, setPCCPatientListPageNumber, setPCCPatientListPageSize } from "../../../../../Actions";
import { getPCCPatientsList } from "../../../../../Actions";
import { setPatientListLoader, setSelectedFloorFilters, setSelectedUnitFilters, setShowLoader } from "../../../../../Actions/UtilActions";
import { getPageLoader, getPatientListLoader, getSelectedFloorFilters, getSelectedUnitFilters } from "../../../../../Reducer/selectors/UtilSelector";
import { CircularProgress } from "@material-ui/core";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function NDTableHeader(props) {
    console.log('props: ', props);
    const [patientListSorting, setPatientListSorting] = useState([]);
    const [sortDirection, setSortDirection] = useState(false);
    const [searched, setSearched] = useState("");

    const [unitFilters, setUnitFilters] = useState(["All"]);
    const [floorFilters, setFloorFilters] = useState(["All"]);

    const selectedUnitFilters = useSelector(getSelectedUnitFilters);
    const selectedFloorFilters = useSelector(getSelectedFloorFilters);

    const dispatch = useDispatch();
    const pccPageNumber = useSelector(getPccPatientListPageNumber);
    const patientListLoader = useSelector(getPatientListLoader);

    const pccPageSize = useSelector(getPccPatientListPageSize);

    useEffect(() => {
        dispatch(setPatientListLoader(true));
        const payload = { 'projectId': props.projectId, 'locationId': props.locationId, 'page': pccPageNumber.toString(), 'pageSize': pccPageSize ? pccPageSize.toString() : "20" };
        dispatch(getPCCPatientsList(payload));
    }, [pccPageNumber, pccPageSize]);

    const handleStringSorting = (key) => {
        setSortDirection((prevSortDirection) => !prevSortDirection);
        let direction = sortDirection;
        let sortableItem = [...patientListSorting];
        sortableItem.sort((a, b) => {
            if (direction === false) {
                return a[key]?.localeCompare(b[key]);
            } else {
                return b[key]?.localeCompare(a[key]);
            }
        });
        setPCCFilters(sortableItem);
        return setPatientListSorting(sortableItem);
    };

    const handleNumberSorting = (key) => {
        setSortDirection((prevSortDirection) => !prevSortDirection);
        let direction = sortDirection;
        let sortableItem = [...patientListSorting];
        sortableItem.sort((a, b) => {
            if (direction === false) {
                if (a[key] === '') {
                    return (-b[key]);
                } else if (b[key] === '') {
                    return (a[key]);
                } else {
                    return a[key] - b[key];
                }
            } else {
                return b[key] - a[key];
            }
        });
        return setPatientListSorting(sortableItem);
    };

    const calculateDailyAdherence = (lastTss) => {
        if (!lastTss || lastTss.length === 0) {
            return [false, false, false];
        }

        let adh1 = false;
        let adh2 = false;
        let adh3 = false;

        const currentDate = new Date();
        const currentHour = currentDate.getHours();
        const currentDayOfMonth = currentDate.getDate();
        const currentMonth = currentDate.getMonth(); // Be careful! January is 0, not 1
        const currentYear = currentDate.getFullYear();

        if (currentHour < 6) {
            const prev6AM = new Date(currentYear, currentMonth, currentDayOfMonth - 1, 6, 0, 0);
            const prev2PM = new Date(currentYear, currentMonth, currentDayOfMonth - 1, 14, 0, 0);
            const prev10PM = new Date(currentYear, currentMonth, currentDayOfMonth - 1, 22, 0, 0);
            const curr6AM = new Date(currentYear, currentMonth, currentDayOfMonth, 6, 0, 0);

            let i = 0;
            for (i = 0; i < lastTss.length; i++) {
                let t = new Date(lastTss[i]);
                if (t >= prev6AM && t < prev2PM) {
                    adh1 = true;
                } else if (t >= prev2PM && t < prev10PM) {
                    adh2 = true;
                } else if (t >= prev10PM && t < curr6AM) {
                    adh3 = true;
                }
            }

            return [adh1, adh2, adh3];
        } else {
            const curr6AM = new Date(currentYear, currentMonth, currentDayOfMonth, 6, 0, 0);
            const curr2PM = new Date(currentYear, currentMonth, currentDayOfMonth, 14, 0, 0);
            const curr10PM = new Date(currentYear, currentMonth, currentDayOfMonth, 22, 0, 0);
            const next6AM = new Date(currentYear, currentMonth, currentDayOfMonth + 1, 6, 0, 0);

            let i = 0;
            for (i = 0; i < lastTss.length; i++) {
                let t = new Date(lastTss[i]);
                if (t >= curr6AM && t < curr2PM) {
                    adh1 = true;
                } else if (t >= curr2PM && t < curr10PM) {
                    adh2 = true;
                } else if (t >= curr10PM && t < next6AM) {
                    adh3 = true;
                }
            }

            return [adh1, adh2, adh3];
        }
    };

    useEffect(() => {
        const patientListCopy = props.patientList.map((x) => x);
        const patientListCopy1 = applySelectedFilters(patientListCopy);
        setPatientListSorting(patientListCopy1);
        setPCCFilters(patientListCopy);
    }, [props.patientList, selectedUnitFilters, selectedFloorFilters]);

    const patientTableBody = patientListSorting.map((pat) => {
        const adhValues = calculateDailyAdherence(pat.lastTss);
        return (<NDTableBody
            patientID={pat.patientId}
            fullName={pat.firstName + " " + pat.lastName}
            elapsedHours={pat.elapsedHours}
            adh1={adhValues[0]}
            adh2={adhValues[1]}
            adh3={adhValues[2]}
            patientList={props.patientList}
            room={pat.roomDesc}
            medicalRecordNumber={pat.medicalRecordNumber}
            unitId={pat.unitId}
            dob={pat.birthdate}
        />)
    });

    const applySelectedFilters = (patientListCopy) => {
        console.log('applySelectedFilters selectedUnitFilters: ', selectedUnitFilters);
        console.log('applySelectedFilters selectedFloorFilters: ', selectedFloorFilters);
        let filteredList = patientListCopy.filter((patient) => {
            if (selectedUnitFilters.includes("All") || selectedFloorFilters.includes("All")) {
                return true;
            } else if (selectedUnitFilters.includes(patient.unitId ? patient.unitId.toString() : "None") || selectedFloorFilters.includes(patient.floorId ? patient.floorId.toString() : "None")) {
                return true;
            } else if (selectedUnitFilters.length === 0 && selectedFloorFilters.length === 0) {
                return true;
            }
        });

        console.log('applySelectedFilters filteredList: ', filteredList);
        return filteredList;
    }

    const requestSearch = (event) => {
        setSearched(event.target.value);
        let searchVal = searched;

        if (!searchVal || searchVal.length === 0) {
            const patientListCopy = props.patientList.map((x) => x);
            setPatientListSorting(patientListCopy);
            setPCCFilters(patientListCopy);
        } else {
            let filteredPatients = [];
            const patientListCopy = props.patientList.map((x) => x);
            filteredPatients = patientListCopy.filter((row) => {
                let fullName = row.firstName + " " + row.lastName;
                return (
                    /* (row.patientId && row.patientId.toString().toLowerCase().includes(searchVal.split(' ').join('').toLowerCase())) || */
                    (fullName && fullName.toString().toLowerCase().includes(searchVal.toLowerCase())) ||
                    (row.medicalRecordNumber && row.medicalRecordNumber.toString().toLowerCase().includes(searchVal)) ||
                    (row.roomDesc && row.roomDesc.toString().toLowerCase().includes(searchVal)));
            });
            setPatientListSorting(filteredPatients);
            setPCCFilters(filteredPatients);
        }
    };

    const handleCancleSearch = () => {
        setSearched("");
        const patientListCopy = props.patientList.map((x) => x);
        setPatientListSorting(patientListCopy);
        setPCCFilters(patientListCopy);
    }

    const setPCCFilters = (pccPatientsList) => {
        let floFilters = ["All"];
        let uniFilters = ["All"];

        pccPatientsList.map(patient => {

            console.log("patient: ", patient);

            if (!floFilters.includes(patient.floorId ? patient.floorId.toString() : "None")) {
                floFilters.push(patient.floorId ? patient.floorId.toString() : "None");
            }

            if (!uniFilters.includes(patient.unitId ? patient.unitId.toString() : "None")) {
                uniFilters.push(patient.unitId ? patient.unitId.toString() : "None");
            }
        });

        setFloorFilters(floFilters);
        setUnitFilters(uniFilters);
        //dispatch(setShowLoader(false));
    };

    const RenderUnitsFilters = (props) => {
        return (
            <FormGroup>
                {props.filterList && props.filterList.map(filter =>
                    <FormControlLabel className={selectedUnitFilters.includes(filter.toString()) ? styles.filtersDivTextSelected1 : styles.filtersDivText1} control={<Radio size="small" sx={{ color: "#F14C00", '&.Mui-checked': { color: "#F14C00" } }} name={filter.toString()} checked={selectedUnitFilters.includes(filter.toString())} onClick={(event) => addToUnitFilter(event)} />} label={filter.toString()} />)}
            </FormGroup>
        );
    };

    const RenderFloorsFilters = (props) => {
        return (
            <FormGroup>
                {props.filterList && props.filterList.map(filter =>
                    <FormControlLabel className={selectedFloorFilters.includes(filter.toString()) ? styles.filtersDivTextSelected : styles.filtersDivText} control={<Radio size="small" sx={{ color: "#AA346F", '&.Mui-checked': { color: "#AA346F" } }} name={filter.toString()} checked={selectedFloorFilters.includes(filter.toString())} onClick={(event) => addToFloorFilter(event)} />} label={filter.toString()} />)}
            </FormGroup>
        );
    };

    const addToUnitFilter = (event) => {
        if (!selectedUnitFilters.includes(event.target.name)) {
            dispatch(setSelectedUnitFilters([event.target.name]));
            console.log("Selected Unit Filter: ", selectedUnitFilters)
        } else if (selectedUnitFilters.includes(event.target.name) && !event.target.checked) {
            const newState = selectedUnitFilters.filter((d) => d !== event.target.name);
            console.log("NEW STATE: ", newState);
            dispatch(setSelectedUnitFilters(newState));
        }
    }

    const addToFloorFilter = (event) => {
        if (!selectedFloorFilters.includes(event.target.name)) {
            dispatch(setSelectedFloorFilters([event.target.name]));
        } else if (selectedFloorFilters.includes(event.target.name) && !event.target.checked) {
            const newState = selectedFloorFilters.filter((d) => d !== event.target.name);
            console.log("NEW STATE: ", newState);
            dispatch(setSelectedFloorFilters(newState));
        }
    }

    const clearUnitFilters = () => {
        dispatch(setSelectedUnitFilters([]));
    }

    const clearFloorFilters = () => {
        dispatch(setSelectedFloorFilters([]));
    }

    const FilterPanel = (props) => {
        return (
            <div className={styles.wrapper}>
                <RenderFloorsFilters filterList={floorFilters}></RenderFloorsFilters>
            </div>
        );
    }

    const FilterPanel1 = (props) => {
        return (
            <div className={styles.wrapper}>
                <RenderUnitsFilters filterList={unitFilters}></RenderUnitsFilters>
            </div>
        );
    }

    const goToNextPage = () => {
        dispatch(setShowLoader(true));
        dispatch(setPCCPatientListPageNumber(pccPageNumber + 1));
        const payload = { 'projectId': props.projectId, 'locationId': props.locationId, 'page': pccPageNumber.toString(), 'pageSize': pccPageSize ? pccPageSize.toString() : "20" };
        dispatch(getPCCPatientsList(payload));
    }

    const goToPrevPage = () => {
        dispatch(setShowLoader(true));
        dispatch(setPCCPatientListPageNumber(pccPageNumber - 1));
        const payload = { 'projectId': props.projectId, 'locationId': props.locationId, 'page': pccPageNumber.toString(), 'pageSize': pccPageSize ? pccPageSize.toString() : "20" };
        dispatch(getPCCPatientsList(payload));
    }

    const handleSelectPageSize = (event) => {
        dispatch(setPCCPatientListPageSize(event.target.value));
        dispatch(setShowLoader(true));
        const payload = { 'projectId': props.projectId, 'locationId': props.locationId, 'page': pccPageNumber.toString(), 'pageSize': pccPageSize ? pccPageSize.toString() : "20" };
        dispatch(getPCCPatientsList(payload));
    };

    const searchPccPatientUsingName = () => {
        console.log('1...')
        dispatch(searchPccPatient(searched));
    };

    const resetSearchPccPatient = () => {
        // setSearched('');
        const payload = { 'projectId': props.projectId, 'locationId': props.locationId, 'page': pccPageNumber.toString(), 'pageSize': pccPageSize ? pccPageSize.toString() : "20" };
        dispatch(getPCCPatientsList(payload));
        handleCancleSearch();
    };

    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #dadde9',
        },
    }));

    return (
        <div>
            <div>
                <div className={styles.topDiv}>
                    <Input
                        className={styles.searchBar}
                        type="text"
                        placeholder="Search"
                        value={searched}
                        onChange={(event) => setSearched(event.target.value)}
                        onKeyUp={(event) => requestSearch(event)}
                        endAdornment={searched ?
                            <><InputAdornment position="start">
                                <IconButton
                                    className={styles.cancelButWrapper}
                                >
                                    <CloseIcon onClick={() => resetSearchPccPatient()} style={{ color: "#4E253A", height: '15px', width: '15px' }}></CloseIcon>
                                </IconButton>
                            </InputAdornment> </> :
                            <></>
                        }
                        startAdornment={
                            <InputAdornment position="end">
                                {<IconButton
                                    sx={{ color: "#4E253A" }}
                                >
                                    {<SearchIcon style={{ color: "#4E253A" }}></SearchIcon>}
                                </IconButton>}
                            </InputAdornment>
                        }
                        disableUnderline="true"
                        style={{
                            fontFamily: "GTWalsheimProRegular"
                        }}
                    />
                    <PopupState variant="popper" popupId="demo-popup-popper" placement="bottom-start">
                        {(popupState) => (
                            <div className={selectedFloorFilters[0] ? styles.filterDivSelected : styles.filterDiv} {...bindToggle(popupState)}>
                                <Radio size="small" sx={{ color: "#AA346F", '&.Mui-checked': { color: "#AA346F" } }} checked={true}></Radio>
                                <div className={styles.filterDivLabel} >{selectedFloorFilters[0] ? selectedFloorFilters[0] + ' Floor' : 'Floor'}</div>
                                <div>
                                    {selectedFloorFilters[0] ? (<CloseIcon onClick={() => clearFloorFilters()} style={{ marginTop: '6px', color: "#4E253A", height: "20px", width: "20px" }}></CloseIcon>) : (<ExpandMoreIcon style={{ marginTop: '6px', color: "#4E253A", height: "20px", width: "20px" }}></ExpandMoreIcon>)}
                                </div>
                                <Popper {...bindPopper(popupState)} transition>
                                    {({ TransitionProps }) => (
                                        <Fade {...TransitionProps} timeout={350}>
                                            <Paper className={styles.filterBackdrop}>
                                                <FilterPanel popupState={popupState} />
                                            </Paper>
                                        </Fade>
                                    )}
                                </Popper>
                            </div>
                        )}
                    </PopupState>


                    <PopupState variant="popper" popupId="demo-popup-popper" placement="bottom-start">
                        {(popupState) => (
                            <div className={selectedUnitFilters[0] ? styles.filterDivSelected1 : styles.filterDiv} {...bindToggle(popupState)}>
                                <Radio size="small" sx={{ color: "#F14C00", '&.Mui-checked': { color: "#F14C00" } }} checked={true}></Radio>
                                <div className={styles.filterDivLabel} >{selectedUnitFilters[0] ? selectedUnitFilters[0] : 'Unit'}</div>
                                <div>
                                    {selectedUnitFilters[0] ? (<CloseIcon onClick={() => clearUnitFilters()} style={{ marginTop: '6px', color: "#4E253A", height: "20px", width: "20px" }}></CloseIcon>) : (<ExpandMoreIcon style={{ marginTop: '6px', color: "#4E253A", height: "20px", width: "20px" }}></ExpandMoreIcon>)}
                                </div>
                                <Popper {...bindPopper(popupState)} transition>
                                    {({ TransitionProps }) => (
                                        <Fade {...TransitionProps} timeout={350}>
                                            <Paper className={styles.filterBackdrop}>
                                                <FilterPanel1 popupState={popupState} />
                                            </Paper>
                                        </Fade>
                                    )}
                                </Popper>
                            </div>
                        )}
                    </PopupState>

                </div>
                <p></p>
                <Grid container>
                    <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
                        <table className={styles.responsive}>
                            <thead>
                                <tr>
                                    <th className={styles.nameHeader} onClick={() => handleStringSorting("firstName")}>
                                        <div className={styles.headingContainer1}>
                                            Full Name
                                            <FaSort className={styles.iconsStyleSort} />
                                        </div>
                                    </th>
                                    <th className={styles.nameHeader}>
                                        <div className={styles.headingContainer1}>
                                            DOB
                                            <HtmlTooltip
                                                title={
                                                    <React.Fragment>
                                                        YYYY-MM-DD
                                                    </React.Fragment>
                                                }
                                            >
                                                <InfoOutlinedIcon className={styles.iconsStyleSort} />
                                            </HtmlTooltip>
                                        </div>
                                    </th>
                                    <th className={styles.patientIDHeader} onClick={() => handleNumberSorting("medicalRecordNumber")}>
                                        <div className={styles.headingContainer}>
                                            MRN ID
                                            <FaSort className={styles.iconsStyleSort} />
                                        </div>
                                    </th>
                                    <th className={styles.patientIDHeader} onClick={() => handleStringSorting("unitId")}>
                                        <div className={styles.headingContainer}>
                                            Unit
                                            <FaSort className={styles.iconsStyleSort} />
                                        </div>
                                    </th>
                                    <th className={styles.patientIDHeader} onClick={() => handleNumberSorting("roomDesc")}>
                                        <div className={styles.headingContainer}>
                                            Room
                                            <FaSort className={styles.iconsStyleSort} />
                                        </div>
                                    </th>
                                    <th className={styles.dailyMeasurementsHeader}>
                                        <div className={styles.headingContainer}>
                                            Adherence
                                        </div>
                                    </th>
                                    <th className={styles.elapsedTimeHeader} onClick={() => handleNumberSorting("elapsedHours")}>
                                        <div className={styles.headingContainer2}>
                                            Elapsed Hrs.
                                            <FaSort className={styles.iconsStyleSort} />
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                        </table>
                        <Grid className={styles.patientListContainer} item xs={12} xl={12} lg={12} md={12} sm={12} style={{ maxHeight: '60vh', overflowY: 'scroll' }}>
                            {patientListLoader ? (<div className={styles.circularProgress}><CircularProgress thickness={5.0} /></div>) : patientTableBody}
                        </Grid>
                    </Grid>
                    {/*<div className={styles.bottomNavDiv}>
                        <div className={styles.navButttonDiv}>
                            <button disabled={pccPageNumber === 1} className={pccPageNumber === 1 ? styles.navButtonsDisable : styles.navButtons} onClick={() => goToPrevPage()}>
                                Previous
                            </button>
                        </div>
                        <div className={styles.pageSizeNumberDiv}>
                            <div className={styles.pageNumberDiv}>
                                <InputLabel style={{ fontSize: "12px", fontFamily: "GTWalsheimProRegular" }}>Page Size:</InputLabel>
                            </div>
                            <div className={styles.pageSizeDiv}>
                                <Select
                                    value={pccPageSize}
                                    onChange={(event) => handleSelectPageSize(event)}
                                >
                                    <MenuItem value="10">10</MenuItem>
                                    <MenuItem value="20">20</MenuItem>
                                    <MenuItem value="50">50</MenuItem>
                                    <MenuItem value="100">100</MenuItem>
                                    <MenuItem value="200">200</MenuItem>
                                </Select>
                            </div>
                            <div className={styles.pageNumberDiv}>
                                <InputLabel style={{ fontSize: "12px", fontFamily: "GTWalsheimProRegular" }}>Page:</InputLabel>
                            </div>
                            <div className={styles.pageNumberDiv}>
                                {pccPageNumber}
                            </div>
                        </div>
                        <div className={styles.navButttonDiv}>
                            <button disabled={props.paging && !props.paging.hasMore} className={props.paging && !props.paging.hasMore ? styles.navButtonsDisable : styles.navButtons} onClick={() => goToNextPage()}>
                                Next
                            </button>
                        </div>
                                    </div>*/}
                </Grid>
            </div>
        </div>
    );
}
