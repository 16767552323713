import React, { useContext } from "react";
import styles from "./Baseline.module.css";
import { Formik, Form } from "formik";
import * as yup from "yup";

import Grid from "@material-ui/core/Grid";
import FormikControl from "../../../../FormControls/FormikControl";

// Dummy Data Function
import { getBaselineFormTemplate } from "../../../../API/data";

// Context Import
import { PatientContext } from "../../../../Store/PatientDataProvider";

// API imports
import PostSubjectBaseline from "../../../../API/PostSubjectBaseline/PostSubjectBaseline";
import { useSelector } from "react-redux";
import { getAddSubjectSelector } from "../../../../../Reducer/selectors/PatientSelector";

export default function Baseline(props) {
  // Fetching dummy template from data
  // let formTemplate = getBaselineFormTemplate();
  // console.log("this is baseline template", formTemplate);

  // let { temperature } = formTemplate;
  // console.log("destructing temp from tempaltae ", temperature);
  

  // Fetching Patient GUID from local Storage
  const addSubjectResponse = useSelector(getAddSubjectSelector);
  const patientGUID = addSubjectResponse && addSubjectResponse.id;
  let ctxSubjectId = addSubjectResponse && addSubjectResponse.subjectId;

  console.log("this is subject id from the context", ctxSubjectId);

  console.log("BASELINE", props);

  const selectOptions = [
    { key: "1", value: "1" },
    { key: "2", value: "2" },
    { key: "3", value: "3" },
  ];

  const initialValues = {
    // tempNormalMin: "",
    // tempNormalMax: "",
    // tempHighMin: "",
    // tempHighMax: "",
    // tempWeight: "",
    patientGuid: patientGUID,
    temperature: {
      normalMin: "",
      normalMax: "",
      highMin: "",
      highMax: "",
      weight: "",
    },
    sbp: {
      normalMin: "",
      normalMax: "",
      highMin: "",
      highMax: "",
      weight: "",
    },
    dbp: {
      normalMin: "",
      normalMax: "",
      highMin: "",
      highMax: "",
      weight: "",
    },
    pulseRate: {
      normalMin: "",
      normalMax: "",
      highMin: "",
      highMax: "",
      weight: "",
    },
    spo2: {
      normalMin: "",
      normalMax: "",
      highMin: "",
      highMax: "",
      weight: "",
    },
    heartRate: {
      normalMin: "",
      normalMax: "",
      highMin: "",
      highMax: "",
      weight: "",
    },
    hrv: {
      normalMin: "",
      normalMax: "",
      highMin: "",
      highMax: "",
      weight: "",
    },

    respirationRate: {
      normalMin: "",
      normalMax: "",
      highMin: "",
      highMax: "",
      weight: "",
    },
    fev1: {
      normalMin: "",
      normalMax: "",
      highMin: "",
      highMax: "",
      weight: "",
    },
    pef: {
      normalMin: "",
      normalMax: "",
      highMin: "",
      highMax: "",
      weight: "",
    },
  };

  const validationSchema = yup.object({});

  const handleSubmit = (values) => {
    console.log("Baseline Form", values);
    PostSubjectBaseline(values).then((res) => {
      console.log("I got the response of setting baseline", res);
    });
    props.onHandleNext();
  };

  return (
    <div className={styles.wrapperDiv}>
      <div className={styles.card}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <Form>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  className={styles.ruleContainer}
                >
                  <Grid container>
                    <Grid item xs={12} xl={2} lg={2} md={2} sm={12}>
                      <div className={styles.vitalLabelContainer}>
                        <div className={styles.vitalsLabel}>Temperature</div>
                      </div>
                    </Grid>
                    <Grid item xs={12} xl={10} lg={10} md={10} sm={12}>
                      <div style={{ display: `flex` }}>
                        <Grid container>
                          <Grid item xs={12} xl={4} lg={4} md={6} sm={12}>
                            <div className={styles.normalRangeIpContainer}>
                              <div className={styles.inputWrapper}>
                                <FormikControl
                                  control="smallIp"
                                  type="text"
                                  label="Normal Min"
                                  name="temperature.normalMin"
                                  // placeholder="e.g., John"
                                />
                                <FormikControl
                                  control="smallIp"
                                  type="text"
                                  label="Normal Max"
                                  name="temperature.normalMax"
                                  // placeholder="e.g., John"
                                />
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={12} xl={4} lg={4} md={6} sm={12}>
                            <div className={styles.highRangeIpContainer}>
                              <div className={styles.inputWrapper}>
                                <FormikControl
                                  control="smallIp"
                                  type="text"
                                  label="High Min"
                                  name="temperature.highMin"
                                  // placeholder="e.g., John"
                                />
                                <FormikControl
                                  control="smallIp"
                                  type="text"
                                  label="High Max"
                                  name="temperature.highMax"
                                  // placeholder="e.g., John"
                                />
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={12} xl={4} lg={4} md={6} sm={12}>
                            <div className={styles.weightIpContainer}>
                              <div className={styles.weightWrapper}>
                                <FormikControl
                                  value="tempWeight"
                                  control="select"
                                  label="Weight "
                                  name="temperature.weight"
                                  options={selectOptions}
                                  //onChange={handleChange}
                                />
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={12}
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  className={styles.ruleContainer}
                >
                  <Grid container>
                    <Grid item xs={12} xl={2} lg={2} md={2} sm={12}>
                      <div className={styles.vitalLabelContainer}>
                        <div className={styles.vitalsLabel}>Systolic BP</div>
                      </div>
                    </Grid>
                    <Grid item xs={12} xl={10} lg={10} md={10} sm={12}>
                      <div style={{ display: `flex` }}>
                        <Grid container>
                          <Grid item xs={12} xl={4} lg={4} md={6} sm={12}>
                            <div className={styles.normalRangeIpContainer}>
                              <div className={styles.inputWrapper}>
                                <FormikControl
                                  control="smallIp"
                                  type="text"
                                  label="Normal Min"
                                  name="sbp.normalMin"
                                  // placeholder="e.g., John"
                                />
                                <FormikControl
                                  control="smallIp"
                                  type="text"
                                  label="Normal Max"
                                  name="sbp.normalMax"
                                  // placeholder="e.g., John"
                                />
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={12} xl={4} lg={4} md={6} sm={12}>
                            <div className={styles.highRangeIpContainer}>
                              <div className={styles.inputWrapper}>
                                <FormikControl
                                  control="smallIp"
                                  type="text"
                                  label="High Min"
                                  name="sbp.highMin"
                                  // placeholder="e.g., John"
                                />
                                <FormikControl
                                  control="smallIp"
                                  type="text"
                                  label="High Max"
                                  name="sbp.highMax"
                                  // placeholder="e.g., John"
                                />
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={12} xl={4} lg={4} md={6} sm={12}>
                            <div className={styles.weightIpContainer}>
                              <div className={styles.weightWrapper}>
                                <FormikControl
                                  value="tempWeight"
                                  control="select"
                                  label="Weight "
                                  name="sbp.weight"
                                  options={selectOptions}
                                  //onChange={handleChange}
                                />
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={12}
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  className={styles.ruleContainer}
                >
                  <Grid container>
                    <Grid item xs={12} xl={2} lg={2} md={2} sm={12}>
                      <div className={styles.vitalLabelContainer}>
                        <div className={styles.vitalsLabel}>Diastolic BP</div>
                      </div>
                    </Grid>
                    <Grid item xs={12} xl={10} lg={10} md={10} sm={12}>
                      <div style={{ display: `flex` }}>
                        <Grid container>
                          <Grid item xs={12} xl={4} lg={4} md={6} sm={12}>
                            <div className={styles.normalRangeIpContainer}>
                              <div className={styles.inputWrapper}>
                                <FormikControl
                                  control="smallIp"
                                  type="text"
                                  label="Normal Min"
                                  name="dbp.normalMin"
                                  // placeholder="e.g., John"
                                />
                                <FormikControl
                                  control="smallIp"
                                  type="text"
                                  label="Normal Max"
                                  name="dbp.normalMax"
                                  // placeholder="e.g., John"
                                />
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={12} xl={4} lg={4} md={6} sm={12}>
                            <div className={styles.highRangeIpContainer}>
                              <div className={styles.inputWrapper}>
                                <FormikControl
                                  control="smallIp"
                                  type="text"
                                  label="High Min"
                                  name="dbp.highMin"
                                  // placeholder="e.g., John"
                                />
                                <FormikControl
                                  control="smallIp"
                                  type="text"
                                  label="High Max"
                                  name="dbp.highMax"
                                  // placeholder="e.g., John"
                                />
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={12} xl={4} lg={4} md={6} sm={12}>
                            <div className={styles.weightIpContainer}>
                              <div className={styles.weightWrapper}>
                                <FormikControl
                                  value="tempWeight"
                                  control="select"
                                  label="Weight "
                                  name="dbp.weight"
                                  options={selectOptions}
                                  //onChange={handleChange}
                                />
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>

                {/* Pulse Rate */}
                <Grid
                  item
                  xs={12}
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  className={styles.ruleContainer}
                >
                  <Grid container>
                    <Grid item xs={12} xl={2} lg={2} md={2} sm={12}>
                      <div className={styles.vitalLabelContainer}>
                        <div className={styles.vitalsLabel}>Pulse Rate</div>
                      </div>
                    </Grid>
                    <Grid item xs={12} xl={10} lg={10} md={10} sm={12}>
                      <div style={{ display: `flex` }}>
                        <Grid container>
                          <Grid item xs={12} xl={4} lg={4} md={6} sm={12}>
                            <div className={styles.normalRangeIpContainer}>
                              <div className={styles.inputWrapper}>
                                <FormikControl
                                  control="smallIp"
                                  type="text"
                                  label="Normal Min"
                                  name="pulseRate.normalMin"
                                  // placeholder="e.g., John"
                                />
                                <FormikControl
                                  control="smallIp"
                                  type="text"
                                  label="Normal Max"
                                  name="pulseRate.normalMax"
                                  // placeholder="e.g., John"
                                />
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={12} xl={4} lg={4} md={6} sm={12}>
                            <div className={styles.highRangeIpContainer}>
                              <div className={styles.inputWrapper}>
                                <FormikControl
                                  control="smallIp"
                                  type="text"
                                  label="High Min"
                                  name="pulseRate.highMin"
                                  // placeholder="e.g., John"
                                />
                                <FormikControl
                                  control="smallIp"
                                  type="text"
                                  label="High Max"
                                  name="pulseRate.highMax"
                                  // placeholder="e.g., John"
                                />
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={12} xl={4} lg={4} md={6} sm={12}>
                            <div className={styles.weightIpContainer}>
                              <div className={styles.weightWrapper}>
                                <FormikControl
                                  value="tempWeight"
                                  control="select"
                                  label="Weight "
                                  name="pulseRate.weight"
                                  options={selectOptions}
                                  //onChange={handleChange}
                                />
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>

                {/* SPO2 */}
                <Grid
                  item
                  xs={12}
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  className={styles.ruleContainer}
                >
                  <Grid container>
                    <Grid item xs={12} xl={2} lg={2} md={2} sm={12}>
                      <div className={styles.vitalLabelContainer}>
                        <div className={styles.vitalsLabel}>Spo2</div>
                      </div>
                    </Grid>
                    <Grid item xs={12} xl={10} lg={10} md={10} sm={12}>
                      <div style={{ display: `flex` }}>
                        <Grid container>
                          <Grid item xs={12} xl={4} lg={4} md={6} sm={12}>
                            <div className={styles.normalRangeIpContainer}>
                              <div className={styles.inputWrapper}>
                                <FormikControl
                                  control="smallIp"
                                  type="text"
                                  label="Normal Min"
                                  name="spo2.normalMin"
                                  // placeholder="e.g., John"
                                />
                                <FormikControl
                                  control="smallIp"
                                  type="text"
                                  label="Normal Max"
                                  name="spo2.normalMax"
                                  // placeholder="e.g., John"
                                />
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={12} xl={4} lg={4} md={6} sm={12}>
                            <div className={styles.highRangeIpContainer}>
                              <div className={styles.inputWrapper}>
                                <FormikControl
                                  control="smallIp"
                                  type="text"
                                  label="High Min"
                                  name="spo2.highMin"
                                  // placeholder="e.g., John"
                                />
                                <FormikControl
                                  control="smallIp"
                                  type="text"
                                  label="High Max"
                                  name="spo2.highMax"
                                  // placeholder="e.g., John"
                                />
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={12} xl={4} lg={4} md={6} sm={12}>
                            <div className={styles.weightIpContainer}>
                              <div className={styles.weightWrapper}>
                                <FormikControl
                                  value="tempWeight"
                                  control="select"
                                  label="Weight "
                                  name="spo2.weight"
                                  options={selectOptions}
                                  //onChange={handleChange}
                                />
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>

                {/* Heart Rate */}
                <Grid
                  item
                  xs={12}
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  className={styles.ruleContainer}
                >
                  <Grid container>
                    <Grid item xs={12} xl={2} lg={2} md={2} sm={12}>
                      <div className={styles.vitalLabelContainer}>
                        <div className={styles.vitalsLabel}>Heart Rate</div>
                      </div>
                    </Grid>
                    <Grid item xs={12} xl={10} lg={10} md={10} sm={12}>
                      <div style={{ display: `flex` }}>
                        <Grid container>
                          <Grid item xs={12} xl={4} lg={4} md={6} sm={12}>
                            <div className={styles.normalRangeIpContainer}>
                              <div className={styles.inputWrapper}>
                                <FormikControl
                                  control="smallIp"
                                  type="text"
                                  label="Normal Min"
                                  name="heartRate.normalMin"
                                  // placeholder="e.g., John"
                                />
                                <FormikControl
                                  control="smallIp"
                                  type="text"
                                  label="Normal Max"
                                  name="heartRate.normalMax"
                                  // placeholder="e.g., John"
                                />
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={12} xl={4} lg={4} md={6} sm={12}>
                            <div className={styles.highRangeIpContainer}>
                              <div className={styles.inputWrapper}>
                                <FormikControl
                                  control="smallIp"
                                  type="text"
                                  label="High Min"
                                  name="heartRate.highMin"
                                  // placeholder="e.g., John"
                                />
                                <FormikControl
                                  control="smallIp"
                                  type="text"
                                  label="High Max"
                                  name="heartRate.highMax"
                                  // placeholder="e.g., John"
                                />
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={12} xl={4} lg={4} md={6} sm={12}>
                            <div className={styles.weightIpContainer}>
                              <div className={styles.weightWrapper}>
                                <FormikControl
                                  value="tempWeight"
                                  control="select"
                                  label="Weight "
                                  name="heartRate.weight"
                                  options={selectOptions}
                                  //onChange={handleChange}
                                />
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>

                {/* HRV */}
                <Grid
                  item
                  xs={12}
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  className={styles.ruleContainer}
                >
                  <Grid container>
                    <Grid item xs={12} xl={2} lg={2} md={2} sm={12}>
                      <div className={styles.vitalLabelContainer}>
                        <div className={styles.vitalsLabel}>HRV</div>
                      </div>
                    </Grid>
                    <Grid item xs={12} xl={10} lg={10} md={10} sm={12}>
                      <div style={{ display: `flex` }}>
                        <Grid container>
                          <Grid item xs={12} xl={4} lg={4} md={6} sm={12}>
                            <div className={styles.normalRangeIpContainer}>
                              <div className={styles.inputWrapper}>
                                <FormikControl
                                  control="smallIp"
                                  type="text"
                                  label="Normal Min"
                                  name="hrv.normalMin"
                                  // placeholder="e.g., John"
                                />
                                <FormikControl
                                  control="smallIp"
                                  type="text"
                                  label="Normal Max"
                                  name="hrv.normalMax"
                                  // placeholder="e.g., John"
                                />
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={12} xl={4} lg={4} md={6} sm={12}>
                            <div className={styles.highRangeIpContainer}>
                              <div className={styles.inputWrapper}>
                                <FormikControl
                                  control="smallIp"
                                  type="text"
                                  label="High Min"
                                  name="hrv.highMin"
                                  // placeholder="e.g., John"
                                />
                                <FormikControl
                                  control="smallIp"
                                  type="text"
                                  label="High Max"
                                  name="hrv.highMax"
                                  // placeholder="e.g., John"
                                />
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={12} xl={4} lg={4} md={6} sm={12}>
                            <div className={styles.weightIpContainer}>
                              <div className={styles.weightWrapper}>
                                <FormikControl
                                  value="tempWeight"
                                  control="select"
                                  label="Weight "
                                  name="hrv.weight"
                                  options={selectOptions}
                                  //onChange={handleChange}
                                />
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>

                {/* RR */}
                <Grid
                  item
                  xs={12}
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  className={styles.ruleContainer}
                >
                  <Grid container>
                    <Grid item xs={12} xl={2} lg={2} md={2} sm={12}>
                      <div className={styles.vitalLabelContainer}>
                        <div className={styles.vitalsLabel}>RR</div>
                      </div>
                    </Grid>
                    <Grid item xs={12} xl={10} lg={10} md={10} sm={12}>
                      <div style={{ display: `flex` }}>
                        <Grid container>
                          <Grid item xs={12} xl={4} lg={4} md={6} sm={12}>
                            <div className={styles.normalRangeIpContainer}>
                              <div className={styles.inputWrapper}>
                                <FormikControl
                                  control="smallIp"
                                  type="text"
                                  label="Normal Min"
                                  name="respirationRate.normalMin"
                                  // placeholder="e.g., John"
                                />
                                <FormikControl
                                  control="smallIp"
                                  type="text"
                                  label="Normal Max"
                                  name="respirationRate.normalMax"
                                  // placeholder="e.g., John"
                                />
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={12} xl={4} lg={4} md={6} sm={12}>
                            <div className={styles.highRangeIpContainer}>
                              <div className={styles.inputWrapper}>
                                <FormikControl
                                  control="smallIp"
                                  type="text"
                                  label="High Min"
                                  name="respirationRate.highMin"
                                  // placeholder="e.g., John"
                                />
                                <FormikControl
                                  control="smallIp"
                                  type="text"
                                  label="High Max"
                                  name="respirationRate.highMax"
                                  // placeholder="e.g., John"
                                />
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={12} xl={4} lg={4} md={6} sm={12}>
                            <div className={styles.weightIpContainer}>
                              <div className={styles.weightWrapper}>
                                <FormikControl
                                  value="tempWeight"
                                  control="select"
                                  label="Weight "
                                  name="respirationRate.weight"
                                  options={selectOptions}
                                  //onChange={handleChange}
                                />
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>

                {/* FEV1 */}
                <Grid
                  item
                  xs={12}
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  className={styles.ruleContainer}
                >
                  <Grid container>
                    <Grid item xs={12} xl={2} lg={2} md={2} sm={12}>
                      <div className={styles.vitalLabelContainer}>
                        <div className={styles.vitalsLabel}>FEV1</div>
                      </div>
                    </Grid>
                    <Grid item xs={12} xl={10} lg={10} md={10} sm={12}>
                      <div style={{ display: `flex` }}>
                        <Grid container>
                          <Grid item xs={12} xl={4} lg={4} md={6} sm={12}>
                            <div className={styles.normalRangeIpContainer}>
                              <div className={styles.inputWrapper}>
                                <FormikControl
                                  control="smallIp"
                                  type="text"
                                  label="Normal Min"
                                  name="fev1.normalMin"
                                  // placeholder="e.g., John"
                                />
                                <FormikControl
                                  control="smallIp"
                                  type="text"
                                  label="Normal Max"
                                  name="fev1.normalMax"
                                  // placeholder="e.g., John"
                                />
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={12} xl={4} lg={4} md={6} sm={12}>
                            <div className={styles.highRangeIpContainer}>
                              <div className={styles.inputWrapper}>
                                <FormikControl
                                  control="smallIp"
                                  type="text"
                                  label="High Min"
                                  name="fev1.highMin"
                                  // placeholder="e.g., John"
                                />
                                <FormikControl
                                  control="smallIp"
                                  type="text"
                                  label="High Max"
                                  name="fev1.highMax"
                                  // placeholder="e.g., John"
                                />
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={12} xl={4} lg={4} md={6} sm={12}>
                            <div className={styles.weightIpContainer}>
                              <div className={styles.weightWrapper}>
                                <FormikControl
                                  value="tempWeight"
                                  control="select"
                                  label="Weight "
                                  name="fev1.weight"
                                  options={selectOptions}
                                  //onChange={handleChange}
                                />
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>

                {/* PEF */}
                <Grid
                  item
                  xs={12}
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  className={styles.ruleContainer}
                >
                  <Grid container>
                    <Grid item xs={12} xl={2} lg={2} md={2} sm={12}>
                      <div className={styles.vitalLabelContainer}>
                        <div className={styles.vitalsLabel}>PEF</div>
                      </div>
                    </Grid>
                    <Grid item xs={12} xl={10} lg={10} md={10} sm={12}>
                      <div style={{ display: `flex` }}>
                        <Grid container>
                          <Grid item xs={12} xl={4} lg={4} md={6} sm={12}>
                            <div className={styles.normalRangeIpContainer}>
                              <div className={styles.inputWrapper}>
                                <FormikControl
                                  control="smallIp"
                                  type="text"
                                  label="Normal Min"
                                  name="pef.normalMin"
                                  // placeholder="e.g., John"
                                />
                                <FormikControl
                                  control="smallIp"
                                  type="text"
                                  label="Normal Max"
                                  name="pef.normalMax"
                                  // placeholder="e.g., John"
                                />
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={12} xl={4} lg={4} md={6} sm={12}>
                            <div className={styles.highRangeIpContainer}>
                              <div className={styles.inputWrapper}>
                                <FormikControl
                                  control="smallIp"
                                  type="text"
                                  label="High Min"
                                  name="pef.highMin"
                                  // placeholder="e.g., John"
                                />
                                <FormikControl
                                  control="smallIp"
                                  type="text"
                                  label="High Max"
                                  name="pef.highMax"
                                  // placeholder="e.g., John"
                                />
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={12} xl={4} lg={4} md={6} sm={12}>
                            <div className={styles.weightIpContainer}>
                              <div className={styles.weightWrapper}>
                                <FormikControl
                                  value="tempWeight"
                                  control="select"
                                  label="Weight "
                                  name="pef.weight"
                                  options={selectOptions}
                                  //onChange={handleChange}
                                />
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>

                {/* djkhasdkdhjkashdkjsahjd */}
              </Grid>

              <div className={styles.buttonDiv}>
                <button type="submit" className={styles.submitButton}>
                  Submit
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
