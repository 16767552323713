import axios from "axios";
import domainCF from "../domainCF.js";

export default function SetPatientStatus(status_data) {
  var token = sessionStorage.getItem("IdToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const SET_PATIENT_STATUS = `${domainCF}/api/setPatientStatus`;
  return axios
    .post(SET_PATIENT_STATUS, status_data, { headers })
    .then(function (response) {
      console.log("This is Patient Status response...", response.data);
      return response.data;
    });
}
