import { put, takeLatest } from "redux-saga/effects";
import * as nursingDashboardActions from "../Actions/NursingDashboardActions";
import Transport from "../Transport/AidarTransport";
import { setPatientListLoader, setPatientProfileLoaderAB, setShowLoader } from "../Actions/UtilActions";
import domainCF from "../Components/API/domainCF";

function* getPCCPatientListSaga(action) {
    var token = sessionStorage.getItem("IdToken");
    const headers = {
        Authorization: `Bearer ${token}`,
    };
//    const GET_PCC_PATIENTS_BY_PROJECT_AND_LOCATION = `${domainCF}/api/getPccPatientList?projectId=${action.payload.projectId}&locationId=${action.payload.locationId}&page=${action.payload.page}&pageSize=${action.payload.pageSize}`;
    const GET_PCC_PATIENTS_BY_PROJECT_AND_LOCATION = `${domainCF}/api/getAllPatientsView?projectId=${action.payload.projectId}&locationId=${action.payload.locationId}&page=${action.payload.page}&pageSize=${action.payload.pageSize}`;
    console.log(" GET PCC PATIENTS URL: ", GET_PCC_PATIENTS_BY_PROJECT_AND_LOCATION);
    try {
        // const response = yield axios.post(POST_USER_INFO_FROM_EMAIL, action.payload, { headers });
        const response = yield Transport.genericOperation(GET_PCC_PATIENTS_BY_PROJECT_AND_LOCATION, headers, {}, "GET");
        console.log(" GET PCC PATIENTS RESPONSE: ", response);

        if (!response) {
            yield put(nursingDashboardActions.getPCCPatientsListFailure());
        } else {
            yield put(nursingDashboardActions.getPCCPatientsListSuccess(response));
        }

        console.log("TURNING THE LOADER OFF");
        yield put(setPatientListLoader(false));
    } catch (e) {
        yield put(setShowLoader(false));
        console.error("getChildInfoSaga Saga ERROR ", e);
        throw e;
    }
}

function* getPCCUserProfileSaga(action) {
    var token = sessionStorage.getItem("IdToken");
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    const GET_PCC_USER_PROFILE = `${domainCF}/api/getPccPatientProfile?patientId=${action.payload.patientId}&projectId=${action.payload.projectId}&locationId=${action.payload.locationId}`;
    console.log(" GET PCC USER PROFILE URL: ", GET_PCC_USER_PROFILE);
    try {
        // const response = yield axios.post(POST_USER_INFO_FROM_EMAIL, action.payload, { headers });
        const response = yield Transport.genericOperation(GET_PCC_USER_PROFILE, headers, {}, "GET");
        console.log("GET PCC USER PROFILE RESPONSE: ", response);

        if (!response) {
            yield put(nursingDashboardActions.getPCCUserProfileFailure());
        } else {
            yield put(nursingDashboardActions.getPCCUserProfileSuccess(response));
        }
        yield put(setPatientProfileLoaderAB(false));
    } catch (e) {
        yield put(setPatientProfileLoaderAB(false));
        console.error("getChildInfoSaga Saga ERROR ", e);
        throw e;
    }
}

function* updateExtraMeasurementParamsSaga(action) {
    var token = sessionStorage.getItem("IdToken");
    const headers = {
        Authorization: `Bearer ${token}`,
    };

    const UPDATE_EXTRA_MEASUREMENT_PARAMS = `${domainCF}/api/updateSubjectHealthRecordWithExtraParams?recordId=${action.payload.recordId}`;

    try {
        const response = yield Transport.genericOperation(UPDATE_EXTRA_MEASUREMENT_PARAMS, headers, action.payload, "POST");

        if (!response) {
            yield put(nursingDashboardActions.updateExtraMeasurementParamsFailure());
        } else {
            console.log("updateExtraMeasurementParamsSaga response: ", response);
            yield put(nursingDashboardActions.updateExtraMeasurementParamsSuccess(response));
        }
    } catch (e) {
        console.error("updateExtraMeasurementParamsSaga ERROR ", e);
        throw e;
    }
}

function* uploadManualEntrySaga(action) {
    var token = sessionStorage.getItem("IdToken");
    const headers = {
        Authorization: `Bearer ${token}`,
    };

    const UPLOAD_MANUAL_ENTRY = `${domainCF}/api/addSubjectHealthRecord`;

    try {
        const response = yield Transport.genericOperation(UPLOAD_MANUAL_ENTRY, headers, action.payload, "POST");

        if (!response) {
            yield put(nursingDashboardActions.uploadManualEntryFailure());
        } else {
            console.log("uploadManualEntrySaga response: ", response);
            yield put(nursingDashboardActions.uploadManualEntrySuccess(response));
            yield put(nursingDashboardActions.setUploadManualEntryStatus(true));
        }
    } catch (e) {
        console.error("uploadManualEntrySaga ERROR ", e);
        throw e;
    }
}

function* setLatestMeasurementSaga(action) {
    yield put(setShowLoader(false));
}

function* searchPccPatient(action) {
    var token = sessionStorage.getItem("IdToken");
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    const SEARCH_PCC_PATIENT = `${domainCF}/api/searchPccPatient?searchQuery=${action.payload}`;
    console.log(" SEARCH PCC PATIENT: ", SEARCH_PCC_PATIENT);
    try {
        // const response = yield axios.post(POST_USER_INFO_FROM_EMAIL, action.payload, { headers });
        const response = yield Transport.genericOperation(SEARCH_PCC_PATIENT, headers, {}, "GET");
        console.log(" GET PCC PATIENTS RESPONSE: ", response);

        if (!response) {
            yield put(nursingDashboardActions.searchPccPatient);
        } else {
            yield put(nursingDashboardActions.searchPccPatientSuccess(response));
        }
        yield put(setShowLoader(false));
    } catch (e) {
        yield put(setShowLoader(false));
        console.error("searchPccPatient Saga ERROR ", e);
        throw e;
    }
}

function* getSubjectHasMlSaga(action) {
    var token = sessionStorage.getItem("IdToken");
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    const GET_SUBJECT_HAS_ML_URL = `${domainCF}/api/isSubjectHasMLDevice?subjectId=${action.payload.subjectId}`;
    console.log(" GET_SUBJECT_HAS_ML_URL: ", GET_SUBJECT_HAS_ML_URL);
    try {
        // const response = yield axios.post(POST_USER_INFO_FROM_EMAIL, action.payload, { headers });
        const response = yield Transport.genericOperation(GET_SUBJECT_HAS_ML_URL, headers, {}, "GET");
        console.log("GET_SUBJECT_HAS_ML_URL RESPONSE: ", response);

        if (!response) {
            yield put(nursingDashboardActions.getSubjectHasMlFailure());
        } else {
            yield put(nursingDashboardActions.getSubjectHasMlSuccess(response));
        }
    } catch (e) {
        console.error("getSubjectHasMlSaga Saga ERROR ", e);
        throw e;
    }
}

function* getSubjectIdFromDeviceAssignmentSaga(action) {
    var token = sessionStorage.getItem("IdToken");
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    const GET_SUBJECT_FROM_DEVICE_URL = `${domainCF}/api/getSubjectIdFromDeviceAssignment?deviceId=${action.payload.deviceId}`;
    console.log(" GET_SUBJECT_FROM_DEVICE_URL: ", GET_SUBJECT_FROM_DEVICE_URL);
    try {
        // const response = yield axios.post(POST_USER_INFO_FROM_EMAIL, action.payload, { headers });
        const response = yield Transport.genericOperation(GET_SUBJECT_FROM_DEVICE_URL, headers, {}, "GET");
        console.log("GET_SUBJECT_FROM_DEVICE_URL RESPONSE: ", response);

        if (!response) {
            yield put(nursingDashboardActions.getSubjectHasMlFailure());
        } else {
            yield put(nursingDashboardActions.getSubjectHasMlSuccess(response.subjectId));
        }
    } catch (e) {
        console.error("getSubjectIdFromDeviceAssignmentSaga Saga ERROR ", e);
        throw e;
    }
}

function* updateFacilityInfoSaga(action) {
    var token = sessionStorage.getItem("IdToken");
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    const UPDATE_FACILITY_INFO = `${domainCF}/api/updateFacilityBySubjectId`;

    try {
        const response = yield Transport.genericOperation(UPDATE_FACILITY_INFO, headers, action.payload, "POST");

        if (!response) {
            yield put(nursingDashboardActions.updateFacilityInfoFailure());
        } else {
            console.log("updateFacilityInfoSaga response: ", response);
            yield put(nursingDashboardActions.updateFacilityInfoSuccess(response));
        }
    } catch (e) {
        console.error("updateFacilityInfoSaga ERROR ", e);
        throw e;
    }
}


export default function* NursingDashboardSaga() {
    try {
        yield takeLatest(nursingDashboardActions.getPCCPatientsList, getPCCPatientListSaga);
        yield takeLatest(nursingDashboardActions.getPCCUserProfile, getPCCUserProfileSaga);
        yield takeLatest(nursingDashboardActions.updateExtraMeasurementParams, updateExtraMeasurementParamsSaga);
        yield takeLatest(nursingDashboardActions.uploadManualEntry, uploadManualEntrySaga);
        yield takeLatest(nursingDashboardActions.setLatestMeasurement, setLatestMeasurementSaga);
        yield takeLatest(nursingDashboardActions.searchPccPatient, searchPccPatient);
        yield takeLatest(nursingDashboardActions.getSubjectHasMl, getSubjectHasMlSaga);
        yield takeLatest(nursingDashboardActions.getSubjectIdFromDeviceAssignment, getSubjectIdFromDeviceAssignmentSaga);
        yield takeLatest(nursingDashboardActions.updateFacilityInfo, updateFacilityInfoSaga);

    } catch {
        yield;
    }
}