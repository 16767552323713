import React, { useState, useEffect, useContext, Fragment } from "react";
import styles from "./Registration.module.css";
import PatientRegistration from "./RegistrationForms/PatientRegistration/PatientRegistration";
import DeviceAssignment from "./RegistrationForms/Device/DeviceAssignment";
import BpCalibration from "./RegistrationForms/Calibration/BpCalibration";
import Baseline from "./RegistrationForms/Rules/Baseline";
import { PatientDataProvider } from "../../Store/PatientDataProvider";
import { PatientContext } from "../../Store/PatientDataProvider";
import { UserContext } from "../../Store/UserContext.js";
import GetAvailableDevicesByProjectAndLocation from "../../API/AvailableMouthlabs/GetAvailableDevices";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedProjectIdAndLocationId, getUserProfile } from "../../../Reducer/selectors/DashboardSelector";
import { setSelectedPageName } from "../../../Actions";
import { setCurrentPatientGUID, setCurrentPatientSubjectID } from "../../../Actions/UtilActions";
import { getAddSubjectSelector } from "../../../Reducer/selectors/PatientSelector";
import RegistrationHeader from "./RegistrationHeader"

export default function Registration() {
  let navigate = useNavigate();
  const patientInfo = useContext(PatientContext);
  const userContext = useContext(UserContext);
  const dispatch = useDispatch();

  const userProfile = useSelector(getUserProfile);
  const projectIdAndLocationId = useSelector(getSelectedProjectIdAndLocationId);
  const userEmail = userProfile.email;
  const projectId = projectIdAndLocationId.selectedProjectId;
  const locationId = projectIdAndLocationId.selectedLocationId;

  const [patientGUID, setPatientGUID] = useState();
  const [registrationActiveStep, setRegistrationActiveStep] = useState(0);
  // State to manage all available subject ids
  const [availableMouthLabs, setAvailableMouthLabs] = useState([]);

  const addSubjectResponse = useSelector(getAddSubjectSelector);
  const subjectId = addSubjectResponse && addSubjectResponse.subjectId;
  const guid = addSubjectResponse && addSubjectResponse.id;

  useEffect(() => {
    GetAvailableDevicesByProjectAndLocation(projectId, locationId).then((res) => {
      setAvailableMouthLabs(res.map((x) => x.deviceId));

      dispatch(setSelectedPageName('register'));
    });
  }, []);

  // Form State - state to manage the skipped steps in the process
  const [skipped, setSkipped] = useState(new Set());

  const steps = getSteps();

  const isStepOptional = (step) => {
    // console.log("thses are steps", step);
    let optionalSteps = new Set([1, 2, 3]);
    // console.log("this is new set ", optionalSteps);

    if (optionalSteps.has(step)) {
      return step;
    }

    //return step === 2;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };
  // Functions to set forms state - Previous and Next
  // Previous and Next function are passed down to child components
  // as props and assigned to buttons
  // the callback functions will return value to change the state of the form

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(registrationActiveStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(registrationActiveStep);
    }
    setRegistrationActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setRegistrationActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(registrationActiveStep)) {
      throw new Error("You can not skip this step");
    }

    setRegistrationActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(registrationActiveStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setRegistrationActiveStep(0);
    localStorage.removeItem("PGUID");
  };

  // function to update the patientId in the context received from Post method response
  // Function will be drilled down to the registration component asa a prop
  const handlePatientIdUpdate = (newPatientGUID) => {
    setPatientGUID(newPatientGUID);
    patientInfo.setSubjectGUID(newPatientGUID);
    localStorage.setItem("PGUID", newPatientGUID);
  };

  // following block of code is for registration process stepper
  function getSteps() {
    return [
      <div className={styles.stepTitle}>Registration</div>,
      //<div className={styles.stepTitle}>Devices</div>,
      // <div className={styles.stepTitle}>Calibration</div>,
      // <div className={styles.stepTitle}>Baseline </div>,
    ];
  }

  // Function to get content of the each step
  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <PatientDataProvider>
            <PatientRegistration
              //curStepIndex={registrationActiveStep}
              onHandleBack={handleBack}
              onHandleNext={handleNext}
              onPatientIdUpdate={handlePatientIdUpdate}
              //onSkip={handleSkip}
              //isOptional={isStepOptional}
            />
          </PatientDataProvider>
        );
      case 1:
        return (
          <PatientDataProvider>
            <DeviceAssignment onHandleNext={handleNext} patientGUID={patientGUID} availableDevices={availableMouthLabs} />
          </PatientDataProvider>
        );
      case 2:
        return (
          <PatientDataProvider>
            <BpCalibration />
          </PatientDataProvider>
        );
      case 3:
        return (
          <PatientDataProvider>
            <Baseline onHandleBack={handleBack} onHandleNext={handleNext} />
          </PatientDataProvider>
        );
      default:
        return "Unknown step";
    }
  }

  function handleNewPatientRegistration() {
    dispatch(setCurrentPatientGUID(guid));
    dispatch(setCurrentPatientSubjectID(subjectId));
    patientInfo.setSubjectId(subjectId);
    patientInfo.setSubjectGUID(guid);
    navigate(`/patientDetails/${guid}`);
  }

  return (
    <Fragment>
      <div className={styles.headerDiv}>
        <RegistrationHeader/>
         <div>
          {registrationActiveStep === steps.length ? (
            <div className={styles.wrapperDiv}>
              <div className={styles.card}>
                <div className={styles.stepsCompleteMessage}>
                  Patient Registration Complete! <br />
                  {/* Created subject profile with subjectId ADR - {localStorage.getItem("subjectId")} */}
                </div>
              </div>
              <div className={styles.finalPageButtonWrapper}>
                <button className={styles.navigationButtonsStyles} onClick={handleReset}>
                  Register another patient
                </button>
                {/* <NavLink to={`/patientDetails/${localStorage.getItem("PGUID")}`} className={styles.navlinkStyle}> */}
                <button
                  className={styles.navigationButtonsStyles}
                  // onClick={handleReset}
                  onClick={() => handleNewPatientRegistration()}
                >
                  Go to the patients profile ADR - {subjectId}
                </button>
                {/* </NavLink> */}
              </div>
              {/* <RegComplete /> */}
            </div>
          ) : (
            <div>
              {/* <Typography className={classes.instructions}>{getStepContent(registrationActiveStep)}</Typography> */}
              <div>{getStepContent(registrationActiveStep)}</div>
              <div className={styles.buttonsOuter}>
                <div className={styles.buttonDiv}>
                  {registrationActiveStep !== 0 && (
                    <button
                      className={styles.skipButtonStyle}
                      //disabled={registrationActiveStep === 0}
                      onClick={handleBack}
                    >
                      Back
                    </button>
                  )}
{/*                   {isStepOptional(registrationActiveStep) && (
                    <button className={styles.nextButtonStyle} variant="contained" color="primary" onClick={handleSkip}>
                      {registrationActiveStep === steps.length - 1 ? "Finish" : "Next"}
                    </button>
                  )}
 */}                </div>

                {/* Use this button for testing Registration Page*/}

                {/*  <button variant="contained" color="primary" onClick={handleNext} className={classes.button}>
                  {registrationActiveStep === steps.length - 1 ? "Finish" : "Next"}
                </button> */}
              </div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
}
