import React from "react";
import { Formik, Form } from "formik";
import styles from "./CalibrationForm.module.css";
import Grid from "@material-ui/core/Grid";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import FormikControl from "../../../../../FormControls/FormikControl";
import CalibrationSubmission from "../../../../../API/BpCalibration/CalibrationSubmission";
import { useSelector } from "react-redux";
import { getPCCUserProfileSelector } from "../../../../../../Reducer/selectors/NursingDashboardSelector";
import { useEffect } from "react";
import PostUnassignMouthLabFromSubject from "../../../../../API/PostUnassignMouthLabFromSubject/PostUnassignMouthLabFromSubject";
import { useState } from "react";
import { getSelectedMlIdSelector } from "../../../../../../Reducer/selectors/DashboardSelector";

export default function CalibrationFormND(props) {
  // Patient information from context
  //const patientInfo = useContext(PatientContext);

  //let subjectId = patientInfo.subjectId;

  // Fetching Subject GUID from local storage
  const pccUserProfile = useSelector(getPCCUserProfileSelector);

  const [systolicVal, setSystolicVal] = useState('');
  const [diastolicVal, setDiastolicVal] = useState('');

  const initialValues = {
    timestamp: new Date().toJSON(),
    id: pccUserProfile.guid,
    systolic: systolicVal,
    diastolic: diastolicVal,
  };

  const validationSchema = Yup.object({});

  const handleSubmit = () => {

    const values = {
      timestamp: new Date().toJSON(),
      id: pccUserProfile.guid,
      systolic: systolicVal,
      diastolic: diastolicVal,
    };

    console.log("calibration form submission", values);

    CalibrationSubmission(values);
    props.onNextStep();
  };

  const handleCancel = () => {
    props.onCancel();
  }

  const selectedML = useSelector(getSelectedMlIdSelector);
  const [response, setResponse] = useState({
    statusCode: 0,
    message: "",
  });

  useEffect(() => {
    return () => {
      window.addEventListener("beforeunload", function (e) {

        // let confirmationMessage = "o/";
        // (e || window.event).returnValue = confirmationMessage; //Gecko + IE
        // console.log("logout !");
        // return confirmationMessage; //Webkit, Safari, Chrome

        PostUnassignMouthLabFromSubject({ deviceId: selectedML }).then((res) => {
          setResponse({
            statusCode: res.response ? res.response.status : res.error.response.status,
            message: res.response ? res.response.data : res.error.response.data,
          });
        });
      });
    }
  });

  const handleFocus = (event) => event.target.select();

  return (
    <div className={styles.wrapperDiv}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form>
            <Grid container>
              <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
                {/* <FormikControl
                  className={styles.bpInputStyles}
                  control="input"
                  type="number"
                  label="Systolic"
                  name="systolic"
                // placeholder="e.g., John"
                /> */}
                <div className={styles.labelDiv}>Systolic</div>
                <input
                  type="number"
                  value={systolicVal}
                  onChange={e => setSystolicVal(e.currentTarget.value)}
                  className={styles.bpInputStyles}
                  onFocus={e => handleFocus(e)} />
              </Grid>
              <Grid item xs={12} xl={12} lg={12} md={12} sm={12} >
                {/* <FormikControl
                  control="input"
                  type="number"
                  label="Diastolic"
                  name="diastolic"
                // placeholder="e.g., John"
                /> */}
                <div className={styles.labelDiv}>Diastolic</div>
                <input
                  type="number"
                  value={diastolicVal}
                  onChange={e => setDiastolicVal(e.currentTarget.value)}
                  className={styles.bpInputStyles}
                  onFocus={e => handleFocus(e)} />
              </Grid>
              <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
                {/* <div style={{ textAlign: "center" }}> */}
                <button
                  type="button"
                  onClick={handleCancel}
                  className={styles.stepperButton1}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  // onClick={handleCalibration}
                  className={styles.stepperButton}
                >
                  Calibrate
                </button>
                {/* </div> */}
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
}
