import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ProtectedRoutes from "./ProtectedRoutes";
import Homepage from "../Components/HomepageComponents/HomePage/Homepage";
import Registration from "../Components/HomepageComponents/PatientRegistration/Registration";
import PatientDetails from "../Components/HomepageComponents/PatientDetails/PatientDetails";
import MlVitalsTabs from "../Components/HomepageComponents/PatientDetails/PatientVitals/MlVitalsTabs/MlVitalsTabs";
import Rules from "../Components/HomepageComponents/PatientDetails/Rules/Rules";
import BaselineWrapper from "../Components/HomepageComponents/PatientDetails/Rules/Thresholds/BaselineWrapper/BaselineWrapper";
import BpCalibration from "../Components/HomepageComponents/PatientRegistration/RegistrationForms/Calibration/BpCalibration";
import PatientsList from "../Components/HomepageComponents/HomePage/PatientsPresentation/PatientsList/PatientsList";
import PatientDevices from "../Components/HomepageComponents/PatientDetails/PatientDevices/PatientDevices";
import PatientsProgram from "../Components/HomepageComponents/PatientDetails/Programs/PatientsProgram";
import ProjectLocationCards from "../Components/HomepageComponents/HomePage/ProjectLocationCards/ProjectLocationCards";
import PatientNotes from "../Components/HomepageComponents/PatientDetails/PatientNotes/PatientNotes";
import PCCPatientDetails from "../Components/HomepageComponents/PatientDetails/PCCPatientDetails";
import Login from "../Components/Authentication/Login";
import SignUp from "../Components/Authentication/SignUp";
import MlLogs from "../Components/HomepageComponents/HomePage/DeviceLog/MouthLabLogs/MLLogs/MlLogs";
import ErrorBoundary from "../Components/UI/ErrorBoundaries/ErrorBoundary";
import FormikContainer from "../Components/FormControls/FormikContainer";
import TestSomething from "../Components/HomepageComponents/PatientDetails/PatientVitals/TestSomething";
import { UserContextProvider } from "../Components/Store/UserContext";
import { Account } from "../Components/Store/AuthenticationStore/Account";
import ChooseDevices from "../Components/HomepageComponents/HomePage/ChooseDevices/ChooseDevices";
import Dashboard from "../Components/HomepageComponents/HomePage/Dashboard/Dashboard";
import SubjectQuickView from "../Components/HomepageComponents/HomePage/QuickView/SubjectQuickView";
import PatientAIDIPage from "../Components/HomepageComponents/PatientDetails/PatientAIDI/PatientAIDIPage";
import PatientAIDITabs from "../Components/HomepageComponents/PatientDetails/PatientAIDI/PatientAIDITabs";
import AIDITable from "../Components/HomepageComponents/PatientDetails/PatientAIDI/Table/PatientAIDITable";
import PatientAIDITable from "../Components/HomepageComponents/PatientDetails/PatientAIDI/Table/PatientAIDITable";
import PatientAIDIInfo from "../Components/HomepageComponents/PatientDetails/PatientAIDI/Info/PatientAIDIInfo";
import HelpAndSupport from "../Components/HomepageComponents/HomePage/HelpAndSupport/HelpAndSupport";
import VitalsTableWrapper from "../Components/HomepageComponents/PatientDetails/PatientVitals/MlVitalsTabs/VitalsTableWrapper/VitalsTableWrapper";
import EcgWrapper from "../Components/HomepageComponents/PatientDetails/PatientVitals/MlVitalsTabs/EcgWrapper/EcgWrapper";
import RfmWrapper from "../Components/HomepageComponents/PatientDetails/PatientVitals/MlVitalsTabs/RfmWrapper/RfmWrapper";
import LfGraphWrapper from "../Components/HomepageComponents/PatientDetails/PatientVitals/MlVitalsTabs/LfWrapper/LfGraphWrapper";
import TrendsWrapperUpdated from "../Components/HomepageComponents/PatientDetails/PatientVitals/MlVitalsTabs/CradioVascularTrends/AllTrendsWrapper/TrendsWrapperUpdated";

export default function Main() {
  return (
    <BrowserRouter>
      <UserContextProvider>
        <Account>
          <Routes>
            <Route path="/authentication" element={<Login />}></Route>
            <Route path="/signup" element={<SignUp />}></Route>

            <Route
              path="/"
              element={
                <ErrorBoundary>
                  <ProtectedRoutes>
                    <Homepage />
                  </ProtectedRoutes>
                </ErrorBoundary>
              }
            >

              <Route path="/register" element={<Registration />}></Route>
              <Route path="/subjects/:projectId/:locationId" element={<PatientsList />}></Route>
              <Route path="/projectlocations" element={<ProjectLocationCards />}></Route>
              <Route path="/dashboard" element={<Dashboard />}></Route>
              <Route path="/choosedevices" element={<ChooseDevices />}></Route>
              <Route path="/deviceAssignments" element={<MlLogs />}></Route>
              <Route path="/help" element={<HelpAndSupport />}></Route>
              <Route path="/subjectquickview" element={<SubjectQuickView />}></Route>
              <Route path="/pccPatientDetails/:patientId" element={<PCCPatientDetails style={{ "width": "100vw" }} />}></Route>
              <Route path="/patientDetails/:patientId" element={<PatientDetails style={{ "width": "100vw" }} />}>
                <Route index element={<MlVitalsTabs />}></Route>
                <Route path="overview/:tabId" element={<TestSomething />}></Route>
                <Route path="vitals/:tabId" element={<MlVitalsTabs />}>
                  <Route index element={<VitalsTableWrapper />} />
                  <Route path="vitalsTable" element={<VitalsTableWrapper />} />
                  <Route path="trends" element={<VitalsTableWrapper />} />
                  <Route path="ecg" element={<EcgWrapper />} />
                  <Route path="rfm" element={<RfmWrapper />} />
                  <Route path="lfg" element={<LfGraphWrapper />} />
                </Route>
                <Route path="trends/:tabId" element={<TrendsWrapperUpdated />} />
                <Route path="patientRules/:tabId" element={<Rules />}>
                  <Route index element={<PatientsProgram />} />
                  <Route path="patientDevices" element={<PatientDevices />} />
                  <Route path="patientPrograms" element={<PatientsProgram />} />
                  <Route path="calibration" element={<BpCalibration />} />
                  <Route path="baseline" element={<BaselineWrapper />} />
                </Route>
                <Route path="notes/:tabId" element={<PatientNotes />}></Route>
                <Route path="aidi/:tabId" element={<PatientAIDITabs />}>
                  <Route index element={<PatientAIDIPage />} />
                  <Route path="patientAidiPage" element={<PatientAIDIPage />} />
                  <Route path="patientAidiTable" element={<PatientAIDITable />} />
                  <Route path="patientAidiInfo" element={<PatientAIDIInfo />} />
                </Route>
              </Route>

              {/* testing routes */}
              <Route path="/formstesting" element={<FormikContainer />}></Route>
              <Route path="/baselinetesting" element={<BaselineWrapper />}></Route>

            </Route>
          </Routes>
        </Account>
      </UserContextProvider>
    </BrowserRouter>
  );
}
