import axios from "axios";
import domainCF from "../domainCF.js";

export default async function GetSubjectDevices(subjectId) {
  var token = sessionStorage.getItem("IdToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const GET_SUBJECT_DEVICES = `${domainCF}/api/getSubjectDetails?subjectId=${subjectId}`;

  return axios.get(GET_SUBJECT_DEVICES, { headers }).then(function (response) {
    console.log(`Response from ${GET_SUBJECT_DEVICES}...`, response.data);
    return response.data.subject;
  });
}
