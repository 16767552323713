import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Slider from "@material-ui/core/Slider";
import Button from "@material-ui/core/Button";
const defaultValues = {
  email: "",
  institution: "",
  role: "",
  os: "",
  favoriteNumber: 0,
};
const AddNewUserInfoForm = () => {
  const [formValues, setFormValues] = useState(defaultValues);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };
  const handleSliderChange = (name) => (e, value) => {
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(formValues);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container justifyContent="center" direction="column">
        <Grid item>
          <TextField
            id="email"
            name="email"
            label="Email"
            type="text"
            value={formValues.email}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item >
          <TextField
            id="institution"
            name="institution"
            label="Institution"
            type="text"
            value={formValues.institution}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item>
          <FormControl>
            <FormLabel>Role</FormLabel>
            <RadioGroup
              name="role"
              value={formValues.role}
              onChange={handleInputChange}
              row
            >
              <FormControlLabel
                key="admin"
                value="admin"
                control={<Radio size="small" />}
                label="Project Administrator"
              />
              <FormControlLabel
                key="physician"
                value="physician"
                control={<Radio size="small" />}
                label="Physician"
              />
              <FormControlLabel
                key="cm"
                value="cm"
                control={<Radio size="small" />}
                label="Care Manager"
              />
              <FormControlLabel
                key="dm"
                value="dm"
                control={<Radio size="small" />}
                label="Data Manager"
              />
              <FormControlLabel
                key="sponsor"
                value="sponsor"
                control={<Radio size="small" />}
                label="Sponsor"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Button variant="contained" color="primary" type="submit">
          Submit
        </Button>
      </Grid>
    </form>
  );
};
export default AddNewUserInfoForm;