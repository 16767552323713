import { patientConstants } from "../Constants/PatientConstants";

const initialState = {
    patientsList: [],
    patientVitalsList: [],
    subjectVitalsList: [],
    ecgPlot: [],
    rfmPlot: [],
    lfgPlot: [],
    loader: false,
    mouthLabLogs: [],
    addSubject: [],
    timerStatus: false,
    timerCount: 0,
    timerStarted: false,
    latestBaseline: [],
    sbpPayload: [],
    dbpPayload: [],
    spo2Payload: [],
    brPayload: [],
    hrPayload: [],
    hrvPayload: [],
    tempPayload: [],
    prPayload: [],
    fevPayload: [],
    pefPayload: [],
};

export function patientReducer(state = initialState, action) {
    switch (action.type) {
        case patientConstants.GET_PATIENTS_LIST:
            return {
                ...state,
            };
        case patientConstants.GET_PATIENTS_LIST_SUCCESS:
            return {
                ...state,
                patientsList: action.payload && action.payload.subjects
            };
        case patientConstants.GET_PATIENTS_LIST_FAILURE:
            return {
                ...state,
                patientsList: []
            };
        case patientConstants.GET_PATIENT_VITALS_LIST:
            return {
                ...state,
                loader: true,
            };
        case patientConstants.GET_PATIENT_VITALS_LIST_SUCCESS:
            return {
                ...state,
                patientVitalsList: action.payload,
                loader: false
            };
        case patientConstants.GET_PATIENT_VITALS_LIST_FAILURE:
            return {
                ...state,
                loader: false
            };
        case patientConstants.GET_SUBJECT_VITALS_LIST:
            return {
                ...state,
            };
        case patientConstants.GET_SUBJECT_VITALS_LIST_SUCCESS:
            return {
                ...state,
                subjectVitalsList: action.payload && action.payload.vitals
            };
        case patientConstants.GET_SUBJECT_VITALS_LIST_FAILURE:
            return {
                ...state,
            };
        case patientConstants.GET_ECG_PLOT:
            return {
                ...state,
            };
        case patientConstants.GET_ECG_PLOT_SUCCESS:
            return {
                ...state,
                ecgPlot: action.payload && action.payload.vitals
            };
        case patientConstants.GET_ECG_PLOT_FAILURE:
            return {
                ...state,
            };
        case patientConstants.GET_RFM_PLOT:
            return {
                ...state,
            };
        case patientConstants.GET_RFM_PLOT_SUCCESS:
            return {
                ...state,
                rfmPlot: action.payload && action.payload.vitals
            };
        case patientConstants.GET_RFM_PLOT_FAILURE:
            return {
                ...state,
            };
        case patientConstants.GET_LFG_PLOT:
            return {
                ...state,
            };
        case patientConstants.GET_LFG_PLOT_SUCCESS:
            return {
                ...state,
                lfgPlot: action.payload && action.payload.vitals
            };
        case patientConstants.GET_LFG_PLOT_FAILURE:
            return {
                ...state,
            };
        case patientConstants.GET_MOUTHLAB_LOGS:
            return {
                ...state,
            };
        case patientConstants.GET_MOUTHLAB_LOGS_SUCCESS:
            return {
                ...state,
                mouthLabLogs: action.payload && action.payload.devices
            };
        case patientConstants.GET_MOUTHLAB_LOGS_FAILURE:
            return {
                ...state,
            };
        case patientConstants.GET_ADD_SUBJECT:
            return {
                ...state,
            };
        case patientConstants.GET_ADD_SUBJECT_SUCCESS:
            return {
                ...state,
                addSubject: action.payload
            };
        case patientConstants.GET_ADD_SUBJECT_FAILURE:
            return {
                ...state,
            };
        case patientConstants.SET_TIMER_STATUS:
            return {
                ...state,
                timerStatus: action.payload
            };
        case patientConstants.SET_TIMER_COUNT:
            return {
                ...state,
                timerCount: action.payload
            };
        case patientConstants.PUT_START_TIMER:
            return {
                ...state,
            };
        case patientConstants.PUT_START_TIMER_SUCCESS:
            return {
                ...state,
                timerStarted: action.payload && action.payload.success
            };
        case patientConstants.PUT_START_TIMER_FAILURE:
            return {
                ...state,
                timerStarted: false
            };
        case patientConstants.PUT_END_TIMER:
            return {
                ...state,
            };
        case patientConstants.PUT_END_TIMER_SUCCESS:
            return {
                ...state,
                timerStarted: false
            };
        case patientConstants.PUT_END_TIMER_FAILURE:
            return {
                ...state,
                timerStarted: false
            };
        case patientConstants.PUT_ABORT_END_TIMER:
            return {
                ...state,
            };
        case patientConstants.PUT_ABORT_END_TIMER_SUCCESS:
            return {
                ...state,
                timerStarted: false
            };
        case patientConstants.PUT_ABORT_END_TIMER_FAILURE:
            return {
                ...state,
                timerStarted: false
            };
        case patientConstants.GET_LATEST_BASELINE:
            return {
                ...state,
            };
        case patientConstants.GET_LATEST_BASELINE_SUCCESS:
            return {
                ...state,
                latestBaseline: action.payload.baseline
            };
        case patientConstants.GET_LATEST_BASELINE_FAILURE:
            return {
                ...state,
            };
        case patientConstants.GET_SBP_PAYLOAD:
            return {
                ...state,
            };
        case patientConstants.GET_SBP_PAYLOAD_SUCCESS:
            return {
                ...state,
                sbpPayload: action.payload
            };
        case patientConstants.GET_SBP_PAYLOAD_FAILURE:
            return {
                ...state,
                dbpPayload: []
            };
        case patientConstants.GET_DBP_PAYLOAD:
            return {
                ...state,
            };
        case patientConstants.GET_DBP_PAYLOAD_SUCCESS:
            return {
                ...state,
                dbpPayload: action.payload
            };
        case patientConstants.GET_DBP_PAYLOAD_FAILURE:
            return {
                ...state,
                dbpPayload: []
            };
        case patientConstants.GET_SPO2_PAYLOAD:
            return {
                ...state,
            };
        case patientConstants.GET_SPO2_PAYLOAD_SUCCESS:
            return {
                ...state,
                spo2Payload: action.payload
            };
        case patientConstants.GET_SPO2_PAYLOAD_FAILURE:
            return {
                ...state,
                spo2Payload: []
            };
        case patientConstants.GET_BR_PAYLOAD:
            return {
                ...state,
            };
        case patientConstants.GET_BR_PAYLOAD_SUCCESS:
            return {
                ...state,
                brPayload: action.payload
            };
        case patientConstants.GET_BR_PAYLOAD_FAILURE:
            return {
                ...state,
                brPayload: []
            };
        case patientConstants.GET_HR_PAYLOAD:
            return {
                ...state,
            };
        case patientConstants.GET_HR_PAYLOAD_SUCCESS:
            return {
                ...state,
                hrPayload: action.payload
            };
        case patientConstants.GET_HR_PAYLOAD_FAILURE:
            return {
                ...state,
                hrPayload: []
            };
        case patientConstants.GET_HRV_PAYLOAD:
            return {
                ...state,
            };
        case patientConstants.GET_HRV_PAYLOAD_SUCCESS:
            return {
                ...state,
                hrvPayload: action.payload
            };
        case patientConstants.GET_HRV_PAYLOAD_FAILURE:
            return {
                ...state,
                hrvPayload: []
            };
        case patientConstants.GET_TEMP_PAYLOAD:
            return {
                ...state,
            };
        case patientConstants.GET_TEMP_PAYLOAD_SUCCESS:
            return {
                ...state,
                tempPayload: action.payload
            };
        case patientConstants.GET_TEMP_PAYLOAD_FAILURE:
            return {
                ...state,
                tempPayload: []
            };
        case patientConstants.GET_PR_PAYLOAD:
            return {
                ...state,
            };
        case patientConstants.GET_PR_PAYLOAD_SUCCESS:
            return {
                ...state,
                prPayload: action.payload
            };
        case patientConstants.GET_PR_PAYLOAD_FAILURE:
            return {
                ...state,
                prPayload: []
            };
        case patientConstants.GET_FEV_PAYLOAD:
            return {
                ...state,
            };
        case patientConstants.GET_FEV_PAYLOAD_SUCCESS:
            return {
                ...state,
                fevPayload: action.payload
            };
        case patientConstants.GET_FEV_PAYLOAD_FAILURE:
            return {
                ...state,
                fevPayload: []
            };
        case patientConstants.GET_PEF_PAYLOAD:
            return {
                ...state,
            };
        case patientConstants.GET_PEF_PAYLOAD_SUCCESS:
            return {
                ...state,
                pefPayload: action.payload
            };
        case patientConstants.GET_PEF_PAYLOAD_FAILURE:
            return {
                ...state,
                pefPayload: []
            };
        default:
            return state;
    }
}



