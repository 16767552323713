import 'c3/c3.css';
import c3 from "c3";
import 'c3/c3.min.css';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPrPayloadSelector, getTempPayloadSelector } from '../../../../../../../Reducer/selectors/PatientSelector';

export default function RenderTempPR() {

    const tempPayload = useSelector(getTempPayloadSelector);
    console.log("tempPayload: ", tempPayload);

    let tempColumnPayload = [
        ["daily_avg", 95.7, 95.7, 95.2, 95, 95.2, 95.7, 94.8, 96.1, 96.4, 97.3, 96.1, 94.6, 95.3, 96.4, 94.7, 94.9, 95.8, 96, 95.1],
        ["daily_avg_x", "2023-12-05", "2023-12-06", "2023-12-07", "2023-12-08", "2023-12-09", "2023-12-10", "2023-12-11", "2023-12-12", "2023-12-13", "2023-12-14", "2023-12-15", "2023-12-16", "2023-12-17", "2023-12-18", "2023-12-19", "2023-12-20", "2023-12-21"],
        ["measurement_1", 95.7, 95.7, 95.2, 95, 95.2, 95.7, 94.8, 96.1, 96.4, 97.3, 96.1, 94.6, 95.3, 96.4, 94.7, 94.9, 95.8, 96, 95.1],
        ["measurement_1_x", "2023-12-05", "2023-12-06", "2023-12-07", "2023-12-08", "2023-12-09", "2023-12-10", "2023-12-11", "2023-12-12", "2023-12-13", "2023-12-14", "2023-12-15", "2023-12-16", "2023-12-17", "2023-12-18", "2023-12-19", "2023-12-20", "2023-12-21"],
        ['measurement_2_x'],
        ['measurement_2'],
        ['measurement_3_x'],
        ['measurement_3'],
        ['measurement_4_x'],
        ['measurement_4'],
        ['measurement_5_x'],
        ['measurement_5'],
        ['measurement_6_x'],
        ['measurement_6'],
        ['measurement_7_x'],
        ['measurement_7'],
        ['measurement_8_x'],
        ['measurement_8'],
    ];

    let chart_temp_bindto = "#chart-temp";

    let chart_temp_padding = {
        right: 40,
    };

    let chart_temp_zoom = {
        enabled: false,
    };

    let chart_temp_axis = {
        x: {
            show: false,
            type: 'timeseries',
            tick: {
                rotate: 0,
                fit: false,
                format: "%e %b",
                centered: true,
            }
        },
        y: {
            tick: {
                format: function (d) {
                    return (parseInt(d) == d) ? d : null;
                }
            },
            label: {
                text: 'Temperature',
                position: 'outer middle',
            }
        }
    };

    let chart_temp_tooltip = {
        grouped: false, // Default true
        format: {
            title: function (d) {
                return format(d, "yyyy-MM-dd");
            },
            value: function (value, ratio, id) {
                return value + " %";
            }
        }
    };

    let chart_temp_data = {
        selection: {
            enabled: false,
        },
        xs: {
            daily_avg: 'daily_avg_x',
            measurement_1: 'measurement_1_x',
            measurement_2: 'measurement_2_x',
            measurement_3: 'measurement_3_x',
            measurement_4: 'measurement_4_x',
            measurement_5: 'measurement_5_x',
            measurement_6: 'measurement_6_x',
            measurement_7: 'measurement_7_x',
            measurement_8: 'measurement_8_x',
        },
        columns: tempColumnPayload,
        type: "line",
        types: {
            measurement_1: 'scatter',
            measurement_2: 'scatter',
            measurement_3: 'scatter',
            measurement_4: 'scatter',
            measurement_5: 'scatter',
            measurement_6: 'scatter',
            measurement_7: 'scatter',
            measurement_8: 'scatter',
        },
    };

    let chart_temp_color = {
        pattern: ['#F14C00', '#fa8f5c', '#fa8f5c', '#fa8f5c', '#fa8f5c', '#fa8f5c', '#fa8f5c', '#fa8f5c', '#fa8f5c']
    };

    let chart_temp_size = {
        height: 200,
    };

    let chart_temp_point = {
        show: true,
        r: 3,
        focus: {
            enabled: true,
            r: 4
        },
        select: {
            r: 5
        }
    };

    let chart_temp_legend = {
        show: false
    };

    const regenerateTempGraph = () => {
        tempGraph = c3.generate({
            bindto: chart_temp_bindto,
            padding: chart_temp_padding,
            data: chart_temp_data,
            zoom: chart_temp_zoom,
            axis: chart_temp_axis,
            legend: chart_temp_legend,
            tooltip: chart_temp_tooltip,
            color: chart_temp_color,
            size: chart_temp_size,
            point: chart_temp_point,
        });
    }

    var tempGraph = c3.generate({
        bindto: chart_temp_bindto,
        padding: chart_temp_padding,
        data: chart_temp_data,
        zoom: chart_temp_zoom,
        axis: chart_temp_axis,
        legend: chart_temp_legend,
        tooltip: chart_temp_tooltip,
        color: chart_temp_color,
        size: chart_temp_size,
        point: chart_temp_point,
    });

    useEffect(() => {
        regenerateTempGraph();
    }, []);


    const prPayload = useSelector(getPrPayloadSelector);
    console.log("prPayload: ", prPayload);

    let prColumnPayload = [
        ["daily_avg", 90, 96, 70, 71, 75, 81, 74, 77, 76, 88, 82, 86, 84, 73, 77, 80, 85, 91, 79],
        ["daily_avg_x", "2023-12-05", "2023-12-06", "2023-12-07", "2023-12-08", "2023-12-09", "2023-12-10", "2023-12-11", "2023-12-12", "2023-12-13", "2023-12-14", "2023-12-15", "2023-12-16", "2023-12-17", "2023-12-18", "2023-12-19", "2023-12-20", "2023-12-21"],
        ["measurement_1", 90, 96, 70, 71, 75, 81, 74, 77, 76, 88, 82, 86, 84, 73, 77, 80, 85, 91, 79],
        ["measurement_1_x", "2023-12-05", "2023-12-06", "2023-12-07", "2023-12-08", "2023-12-09", "2023-12-10", "2023-12-11", "2023-12-12", "2023-12-13", "2023-12-14", "2023-12-15", "2023-12-16", "2023-12-17", "2023-12-18", "2023-12-19", "2023-12-20", "2023-12-21"],
        ['measurement_2_x'],
        ['measurement_2'],
        ['measurement_3_x'],
        ['measurement_3'],
        ['measurement_4_x'],
        ['measurement_4'],
        ['measurement_5_x'],
        ['measurement_5'],
        ['measurement_6_x'],
        ['measurement_6'],
        ['measurement_7_x'],
        ['measurement_7'],
        ['measurement_8_x'],
        ['measurement_8'],
    ];

    let chart_pr_bindto = "#chart-pr";

    let chart_pr_padding = {
        right: 40,
    };

    let chart_pr_zoom = {
        enabled: false,
    };

    let chart_pr_axis = {
        x: {
            type: 'timeseries',
            tick: {
                rotate: 0,
                fit: false,
                format: "%e %b",
                centered: true,
            }
        },
        y: {
            tick: {
                format: function (d) {
                    return (parseInt(d) == d) ? d : null;
                }
            },
            label: {
                text: 'Pulse Rate',
                position: 'outer middle',
            }
        }
    };

    let chart_pr_tooltip = {
        grouped: false, // Default true
        format: {
            title: function (d) {
                return format(d, "yyyy-MM-dd");
            },
            value: function (value, ratio, id) {
                return value + " br/min";
            }
        }
    };

    let chart_pr_data = {
        selection: {
            enabled: false,
        },
        xs: {
            daily_avg: 'daily_avg_x',
            measurement_1: 'measurement_1_x',
            measurement_2: 'measurement_2_x',
            measurement_3: 'measurement_3_x',
            measurement_4: 'measurement_4_x',
            measurement_5: 'measurement_5_x',
            measurement_6: 'measurement_6_x',
            measurement_7: 'measurement_7_x',
            measurement_8: 'measurement_8_x',
        },
        columns: prColumnPayload,
        type: "line",
        types: {
            measurement_1: 'scatter',
            measurement_2: 'scatter',
            measurement_3: 'scatter',
            measurement_4: 'scatter',
            measurement_5: 'scatter',
            measurement_6: 'scatter',
            measurement_7: 'scatter',
            measurement_8: 'scatter',
        }
    };

    let chart_pr_color = {
        pattern: ['#694FC0', '#9981f0', '#9981f0', '#9981f0', '#9981f0', '#9981f0', '#9981f0', '#9981f0', '#9981f0']
    };

    let chart_pr_size = {
        height: 200,
    };

    let chart_pr_point = {
        show: true,
        r: 3,
        focus: {
            enabled: true,
            r: 4
        },
        select: {
            r: 5
        }
    };

    let chart_pr_legend = {
        show: false
    };

    const regeneratePRGraph = () => {
        prGraph = c3.generate({
            bindto: chart_pr_bindto,
            padding: chart_pr_padding,
            data: chart_pr_data,
            zoom: chart_pr_zoom,
            axis: chart_pr_axis,
            legend: chart_pr_legend,
            tooltip: chart_pr_tooltip,
            color: chart_pr_color,
            size: chart_pr_size,
            point: chart_pr_point,
        });
    }

    var prGraph = c3.generate({
        bindto: chart_pr_bindto,
        padding: chart_pr_padding,
        data: chart_pr_data,
        zoom: chart_pr_zoom,
        axis: chart_pr_axis,
        legend: chart_pr_legend,
        tooltip: chart_pr_tooltip,
        color: chart_pr_color,
        size: chart_pr_size,
        point: chart_pr_point,
    });

    useEffect(() => {
        regeneratePRGraph();
    }, []);

    return (
        <div style={{ width: "100%" }}>
            <div id='chart-temp' style={{ marginTop: "10px", marginLeft: "10px" }} />
            <div id='chart-pr' style={{ marginLeft: "10px" }} />
        </div>
    );
}