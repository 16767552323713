import 'c3/c3.css';
import c3 from "c3";
import 'c3/c3.min.css';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getHrPayloadSelector, getHrvPayloadSelector } from '../../../../../../../Reducer/selectors/PatientSelector';

export default function RenderHRHRV() {

    const hrPayload = useSelector(getHrPayloadSelector);
    console.log("hrPayload: ", hrPayload);

    let hrColumnPayload = [
        ["daily_avg", 75, 78, 84, 81, 90, 75, 79, 75, 79, 81, 73, 70, 73, 68, 68, 72, 74, 73, 80],
        ["daily_avg_x", "2023-12-05", "2023-12-06", "2023-12-07", "2023-12-08", "2023-12-09", "2023-12-10", "2023-12-11", "2023-12-12", "2023-12-13", "2023-12-14", "2023-12-15", "2023-12-16", "2023-12-17", "2023-12-18", "2023-12-19", "2023-12-20", "2023-12-21"],
        ["measurement_1", 77, 77, 81, 84, 93, 76, 72, 77, 80, 80, 70, 75, 72, 69, 65, 70, 77, 75, 82],
        ["measurement_1_x", "2023-12-05", "2023-12-06", "2023-12-07", "2023-12-08", "2023-12-09", "2023-12-10", "2023-12-11", "2023-12-12", "2023-12-13", "2023-12-14", "2023-12-15", "2023-12-16", "2023-12-17", "2023-12-18", "2023-12-19", "2023-12-20", "2023-12-21"],
        ['measurement_2_x'],
        ['measurement_2'],
        ['measurement_3_x'],
        ['measurement_3'],
        ['measurement_4_x'],
        ['measurement_4'],
        ['measurement_5_x'],
        ['measurement_5'],
        ['measurement_6_x'],
        ['measurement_6'],
        ['measurement_7_x'],
        ['measurement_7'],
        ['measurement_8_x'],
        ['measurement_8'],
    ];

    let chart_hr_bindto = "#chart-hr";

    let chart_hr_padding = {
        right: 40,
    };

    let chart_hr_zoom = {
        enabled: false,
    };

    let chart_hr_axis = {
        x: {
            show: false,
            type: 'timeseries',
            tick: {
                rotate: 0,
                fit: false,
                format: "%e %b",
                centered: true,
            }
        },
        y: {
            tick: {
                format: function (d) {
                    return (parseInt(d) == d) ? d : null;
                }
            },
            label: {
                text: 'Heart Rate',
                position: 'outer middle',
            }
        }
    };

    let chart_hr_tooltip = {
        grouped: false, // Default true
        format: {
            title: function (d) {
                return format(d, "yyyy-MM-dd");
            },
            value: function (value, ratio, id) {
                return value + " bpm";
            }
        }
    };

    let chart_hr_data = {
        selection: {
            enabled: false,
        },
        xs: {
            daily_avg: 'daily_avg_x',
            measurement_1: 'measurement_1_x',
            measurement_2: 'measurement_2_x',
            measurement_3: 'measurement_3_x',
            measurement_4: 'measurement_4_x',
            measurement_5: 'measurement_5_x',
            measurement_6: 'measurement_6_x',
            measurement_7: 'measurement_7_x',
            measurement_8: 'measurement_8_x',
        },
        columns: hrColumnPayload,
        type: "line",
        types: {
            measurement_1: 'scatter',
            measurement_2: 'scatter',
            measurement_3: 'scatter',
            measurement_4: 'scatter',
            measurement_5: 'scatter',
            measurement_6: 'scatter',
            measurement_7: 'scatter',
            measurement_8: 'scatter',
        }
    };

    let chart_hr_color = {
        pattern: ['#F14C00', '#fa8f5c', '#fa8f5c', '#fa8f5c', '#fa8f5c', '#fa8f5c', '#fa8f5c', '#fa8f5c', '#fa8f5c']
    };

    let chart_hr_size = {
        height: 200,
    };

    let chart_hr_point = {
        show: true,
        r: 3,
        focus: {
            enabled: true,
            r: 4
        },
        select: {
            r: 5
        }
    };

    let chart_hr_legend = {
        show: false
    };

    const regenerateHRGraph = () => {
        hrGraph = c3.generate({
            bindto: chart_hr_bindto,
            padding: chart_hr_padding,
            data: chart_hr_data,
            zoom: chart_hr_zoom,
            axis: chart_hr_axis,
            legend: chart_hr_legend,
            tooltip: chart_hr_tooltip,
            color: chart_hr_color,
            size: chart_hr_size,
            point: chart_hr_point,
        });
    }

    var hrGraph = c3.generate({
        bindto: chart_hr_bindto,
        padding: chart_hr_padding,
        data: chart_hr_data,
        zoom: chart_hr_zoom,
        axis: chart_hr_axis,
        legend: chart_hr_legend,
        tooltip: chart_hr_tooltip,
        color: chart_hr_color,
        size: chart_hr_size,
        point: chart_hr_point,
    });

    useEffect(() => {
        regenerateHRGraph();
    }, []);

    const hrvPayload = useSelector(getHrvPayloadSelector);
    console.log("hrvPayload: ", hrvPayload);

    let hrvColumnPayload = [
        ["daily_avg", 49, 78, 81, 10, 24, 80, 25, 40, 46, 58, 48, 14, 77, 43, 42, 37, 14, 23, 30],
        ["daily_avg_x", "2023-12-05", "2023-12-06", "2023-12-07", "2023-12-08", "2023-12-09", "2023-12-10", "2023-12-11", "2023-12-12", "2023-12-13", "2023-12-14", "2023-12-15", "2023-12-16", "2023-12-17", "2023-12-18", "2023-12-19", "2023-12-20", "2023-12-21"],
        ["measurement_1", 55, 72, 84, 14, 26, 82, 22, 36, 44, 54, 54, 16, 79, 40, 40, 39, 18, 20, 32],
        ["measurement_1_x", "2023-12-05", "2023-12-06", "2023-12-07", "2023-12-08", "2023-12-09", "2023-12-10", "2023-12-11", "2023-12-12", "2023-12-13", "2023-12-14", "2023-12-15", "2023-12-16", "2023-12-17", "2023-12-18", "2023-12-19", "2023-12-20", "2023-12-21"],
        ['measurement_2_x'],
        ['measurement_2'],
        ['measurement_3_x'],
        ['measurement_3'],
        ['measurement_4_x'],
        ['measurement_4'],
        ['measurement_5_x'],
        ['measurement_5'],
        ['measurement_6_x'],
        ['measurement_6'],
        ['measurement_7_x'],
        ['measurement_7'],
        ['measurement_8_x'],
        ['measurement_8'],
    ];

    let chart_hrv_bindto = "#chart-hrv";

    let chart_hrv_padding = {
        right: 40,
    };

    let chart_hrv_zoom = {
        enabled: false,
    };

    let chart_hrv_axis = {
        x: {
            type: 'timeseries',
            tick: {
                rotate: 0,
                fit: false,
                format: "%e %b",
                centered: true,
            }
        },
        y: {
            tick: {
                format: function (d) {
                    return (parseInt(d) == d) ? d : null;
                }
            },
            label: {
                text: 'Heart Rate Variability',
                position: 'outer middle',
            }
        }
    };

    let chart_hrv_tooltip = {
        grouped: false, // Default true
        format: {
            title: function (d) {
                return format(d, "yyyy-MM-dd");
            },
            value: function (value, ratio, id) {
                return value + " ms";
            }
        }
    };

    let chart_hrv_data = {
        selection: {
            enabled: false,
        },
        xs: {
            daily_avg: 'daily_avg_x',
            measurement_1: 'measurement_1_x',
            measurement_2: 'measurement_2_x',
            measurement_3: 'measurement_3_x',
            measurement_4: 'measurement_4_x',
            measurement_5: 'measurement_5_x',
            measurement_6: 'measurement_6_x',
            measurement_7: 'measurement_7_x',
            measurement_8: 'measurement_8_x',
        },
        columns: hrvColumnPayload,
        type: "line",
        types: {
            measurement_1: 'scatter',
            measurement_2: 'scatter',
            measurement_3: 'scatter',
            measurement_4: 'scatter',
            measurement_5: 'scatter',
            measurement_6: 'scatter',
            measurement_7: 'scatter',
            measurement_8: 'scatter',
        }
    };

    let chart_hrv_color = {
        pattern: ['#694FC0', '#9981f0', '#9981f0', '#9981f0', '#9981f0', '#9981f0', '#9981f0', '#9981f0', '#9981f0']
    };

    let chart_hrv_size = {
        height: 200,
    };

    let chart_hrv_point = {
        show: true,
        r: 3,
        focus: {
            enabled: true,
            r: 4
        },
        select: {
            r: 5
        }
    };

    let chart_hrv_legend = {
        show: false
    };

    const regenerateHRVGraph = () => {
        hrvGraph = c3.generate({
            bindto: chart_hrv_bindto,
            padding: chart_hrv_padding,
            data: chart_hrv_data,
            zoom: chart_hrv_zoom,
            axis: chart_hrv_axis,
            legend: chart_hrv_legend,
            tooltip: chart_hrv_tooltip,
            color: chart_hrv_color,
            size: chart_hrv_size,
            point: chart_hrv_point,
        });
    }

    var hrvGraph = c3.generate({
        bindto: chart_hrv_bindto,
        padding: chart_hrv_padding,
        data: chart_hrv_data,
        zoom: chart_hrv_zoom,
        axis: chart_hrv_axis,
        legend: chart_hrv_legend,
        tooltip: chart_hrv_tooltip,
        color: chart_hrv_color,
        size: chart_hrv_size,
        point: chart_hrv_point,
    });

    useEffect(() => {
        regenerateHRVGraph();
    }, []);

    return (
        <div style={{ width: "100%" }}>
            <div id='chart-hr' style={{ marginTop: "10px", marginLeft: "10px" }} />
            <div id='chart-hrv' style={{ marginLeft: "10px" }} />
        </div>
    );
}