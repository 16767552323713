import React, {
  useContext,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useRef,
} from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import SegmentIcon from '@mui/icons-material/Segment';

// Components Imports
import QuickViewTabs from "./QuickViewTabs/QuickViewTabs";

// Context Imports
import { PatientContext } from "../../../Store/PatientDataProvider";

const drawerWidth = 600;

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    justifyContent: "flex-start",
    backgroundColor: "#E4DFE9",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
}));

const QuickView = forwardRef((props, ref) => {

  const refOne = useRef(null);
  const classesQV = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    document.addEventListener("keydown", hideOnEscape, true);
    document.addEventListener("click", hideOnClickOutside, true);
  }, []);

  // hide dropdown on ESC press
  const hideOnEscape = (e) => {
    if( e.key === "Escape" ) {
      handleQuickDrawerClose();
    }
  }

   // Hide dropdown on outside click
   const hideOnClickOutside = (e) => {
    if( refOne.current && !refOne.current.contains(e.target) ) {
      handleQuickDrawerClose();
    }
  }

  //function to open the drawer
  const handleQuickDrawerOpen = () => {
    setOpen(true);
  };

  // function to close the drawer
  const handleQuickDrawerClose = () => {
    setOpen(false);
  };

  useImperativeHandle(ref, () => {
    return {
      handleQuickDrawerOpen: handleQuickDrawerOpen,
    };
  });

  // Patient context defined to get access to context information about patient
  const patientInfo = useContext(PatientContext);

  let subjectId = patientInfo.subjectId;
  let subjectGUID = patientInfo.subjectGUID;
  let patientList = patientInfo.patientList;

  return (
    <div className={classesQV.root} ref={refOne}>
      <main></main>
      <Drawer
        className={classesQV.drawer}
        variant="persistent"
        anchor="right"
        open={open}
        classes={{
          paper: classesQV.drawerPaper,
        }}
      >
        <div className={classesQV.drawerHeader}>
          <IconButton onClick={handleQuickDrawerClose}>
            <SegmentIcon></SegmentIcon>
          </IconButton>
        </div>
        <Divider />

        {/* <TabQuickView subjectID={subjectID} studySubjectID={studySubjectID} /> */}

        {open && (
          <QuickViewTabs subjectId={subjectId} subjectGUID={subjectGUID} patientList={patientList}/>
        )}
      </Drawer>
    </div>
  );
});
export default QuickView;