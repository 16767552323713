import { CognitoUserPool } from "amazon-cognito-identity-js";

const poolData = {

  // Old UserPool
  // UserPoolId: "us-west-2_2CJD0jN9F",
  // ClientId: "2j80qbqbch8tnr1409h7562j72",

  // Wakemed  UserPool
  // UserPoolId: "us-west-2_5bnUwUPx8",
  // ClientId: "5p80iu64ksgnpuc0qtt272b4fa",

  // Aidar Connect  UserPool (prod)
  // UserPoolId: "us-west-2_5bnUwUPx8",
  // ClientId: "7pn90sfbdk9iq7jmo6o0j5bii1",

  // Aidar Connect Dev  UserPool
  // UserPoolId: "us-west-2_KUxpNEIoV",
  // ClientId: "7h24cus8o5kva0hj60c4h03skr",

  // Aidar Bridge Dev  UserPool
  // UserPoolId: "us-west-2_fpEFHmanS",
  // ClientId: "57c7nvbvid9ang31me6qjgsjm8",
  // "aws_cognito_identity_pool_id": "us-west-2_KUxpNEIoV",

  // Aidar Bridge Prod UserPool
  UserPoolId: "us-west-2_B68Sl8hGi",
  ClientId: "4eftkg1jorb4s9t18tdnr60rae",
  "aws_cognito_identity_pool_id": "us-west-2_B68Sl8hGi",

};

export default new CognitoUserPool(poolData);
