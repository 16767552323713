import React, { useState, useContext, useEffect, useRef } from "react";
import styles from "./TrendsWrapperUpdated.module.css";
import Grid from "@material-ui/core/Grid";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { useParams } from "react-router-dom";
import { format, addDays } from "date-fns";
import { DateContext } from "../../../../../../Store/DateProvider";
import { useDispatch, useSelector } from "react-redux";
import { getBrPayload, getDbpPayload, getFevPayload, getHrPayload, getHrvPayload, getPefPayload, getPrPayload, getSbpPayload, getSpo2Payload, getSubjectVitalsList, getTempPayload } from "../../../../../../../Actions";
import { getSubjectVitalsListSelector } from "../../../../../../../Reducer/selectors/PatientSelector";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@mui/material/Button";
import BPWrapper from "./BPWrapper";
import Spo2BRWrapper from "./Spo2BRWrapper";
import HRHRVWrapper from "./HRHRVWrapper";
import TempPRWrapper from "./TempPRWrapper";
import FEVPEFWrapper from "./FEVPEFWrapper";
import GWPWrapper from "./GWPWrapper";
import subDays from "date-fns/subDays";
import { setBpExpand, setFevPefExpand, setGWPExpand, setHrHrvExpand, setSpo2BrExpand, setTempPrExpand } from "../../../../../../../Actions/UtilActions";
import { getCurrentPatientSubjectID } from "../../../../../../../Reducer/selectors/UtilSelector";

export default function TrendsWrapperUpdated() {
    console.log("TrendsWrapperUpdated");

    const useStyles = makeStyles((theme) => ({
        tooltip: {
            marginTop: 5,
            font: "12px",
            background: "black",
            fontFamily: "GTWalsheimProRegular",
        },
    }));

    const classes = useStyles();

    const currentSubjectID = useSelector(getCurrentPatientSubjectID);

    // Date context defined to get access to context information
    // const dateInfo = useContext(DateContext);

    // State to manage the Date pickers inputs
    const [dateRangeApplied, setDateRangeApplied] = useState([
        {
            startDate: subDays(new Date(), 30),
            endDate: new Date(),
            key: "selection",
        },
    ]);
    const [dateRangeSelected, setDateRangeSelected] = useState(dateRangeApplied);

    var start_date = format(dateRangeSelected[0].startDate, "yyyy-MM-dd");
    var end_date = format(addDays(dateRangeSelected[0].endDate, 1), "yyyy-MM-dd");

    const [isCalOpen, setIsCalOpen] = useState(false);
    const refOne = useRef(null);
    const dispatch = useDispatch();

    const handleCalChanges = (item) => {
        setDateRangeSelected([item.selection]);
    };

    const handleCalClick = (event) => {
        event.preventDefault();
        if (isCalOpen) {
            setDateRangeSelected(dateRangeApplied);
            setIsCalOpen(false);
        } else {
            setDateRangeApplied(dateRangeSelected);
            setIsCalOpen(true);
        }
    };

    const sleep = ms => new Promise(r => setTimeout(r, ms));

    const fetchPatientCVDetials = () => {
        setDateRangeApplied(dateRangeSelected);
        setIsCalOpen(false);
        fetchAllTrends();
    };

    const fetchAllTrends = () => {
        const payload = {
            subjectId: currentSubjectID,
            startDate: (dateRangeSelected[0].startDate).toISOString().split('T')[0],
            endDate: (dateRangeSelected[0].endDate).toISOString().split('T')[0],
        };
        dispatch(getSbpPayload(payload));
        dispatch(getDbpPayload(payload));
        dispatch(getSpo2Payload(payload));
        dispatch(getBrPayload(payload));
        dispatch(getHrPayload(payload));
        dispatch(getHrvPayload(payload));
        dispatch(getTempPayload(payload));
        dispatch(getPrPayload(payload));
        dispatch(getFevPayload(payload));
        dispatch(getPefPayload(payload));
    }

    const expandAllTrends = () => {
        dispatch(setSpo2BrExpand(true));
        dispatch(setHrHrvExpand(true));
        dispatch(setBpExpand(true));
        dispatch(setTempPrExpand(true));
        dispatch(setFevPefExpand(true));
        dispatch(setGWPExpand(true));
    };

    const collapseAllTrends = () => {
        dispatch(setSpo2BrExpand(false));
        dispatch(setHrHrvExpand(false));
        dispatch(setBpExpand(false));
        dispatch(setTempPrExpand(false));
        dispatch(setFevPefExpand(false));
        dispatch(setGWPExpand(false));
    };

    collapseAllTrends();

    return (
        <div>
            <div className={styles.containerDiv}>
                <div className={styles.datePickerDiv}>
                    <Tooltip title="Choose another date range" classes={{ tooltip: classes.tooltip }}>
                        <CalendarMonthIcon onClick={(event) => handleCalClick(event)}></CalendarMonthIcon>
                    </Tooltip>
                    <div className={styles.dateInputDiv}>
                        <input
                            value={`${format(dateRangeSelected[0].startDate, "MM/dd/yyyy")}  |  ${format(dateRangeSelected[0].endDate, "MM/dd/yyyy")}`}
                            readOnly
                            className={styles.dateInput}
                            onClick={(event) => handleCalClick(event)}
                        />
                    </div>
                    <div className={styles.expColButtons}>
                        <div className={styles.expAllButton} onClick={() => expandAllTrends()}>
                            Expand All
                        </div>
                        <div className={styles.colAllButton} onClick={() => collapseAllTrends()}>
                            Collapse All
                        </div>
                    </div>
                </div>
                {isCalOpen && (
                    <div className={styles.datePickerWrapper} ref={refOne}>
                        <DateRangePicker
                            onChange={(item) => handleCalChanges(item)}
                            editableDateInputs={false}
                            showSelectionPreview={true}
                            moveRangeOnFirstSelection={false}
                            months={2}
                            ranges={dateRangeSelected}
                            direction="horizontal"
                            rangeColors={["#4E253A"]}
                            maxDate={addDays(new Date(), 1)}
                        />
                        <div className={styles.applyButtonDiv}>
                            <div className={styles.applyButton} onClick={() => fetchPatientCVDetials()}> Apply </div>
                            <div className={styles.applyButton} onClick={(event) => handleCalClick(event)}> Cancel </div>
                        </div>
                    </div>
                )}
            </div>
            <Spo2BRWrapper></Spo2BRWrapper>
            <HRHRVWrapper></HRHRVWrapper>
            <BPWrapper></BPWrapper>
            <TempPRWrapper></TempPRWrapper>
            <FEVPEFWrapper></FEVPEFWrapper>
            <GWPWrapper></GWPWrapper>
        </div>
    )
}
