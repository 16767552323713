import 'c3/c3.css';
import c3 from "c3";
import 'c3/c3.min.css';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFevPayloadSelector, getPefPayloadSelector } from '../../../../../../../Reducer/selectors/PatientSelector';
import { getPefPayloadFailure } from '../../../../../../../Actions';

export default function RenderFevPef() {

    const fevPayload = useSelector(getFevPayloadSelector);
    console.log("fevPayload: ", fevPayload);

    let fevColumnPayload = [
        ["daily_avg", 97, 98, 99, 100, 95, 98, 98, 97, 99, 100, 100, 97, 95, 96, 97, 99, 98, 97, 98],
        ["daily_avg_x", "2023-12-05", "2023-12-06", "2023-12-07", "2023-12-08", "2023-12-09", "2023-12-10", "2023-12-11", "2023-12-12", "2023-12-13", "2023-12-14", "2023-12-15", "2023-12-16", "2023-12-17", "2023-12-18", "2023-12-19", "2023-12-20", "2023-12-21"],
        ["measurement_1", 96, 92, 97, 99, 100, 100, 98, 97, 97, 102, 98, 100, 91, 99, 97, 89, 97, 90, 94],
        ["measurement_1_x", "2023-12-05", "2023-12-06", "2023-12-07", "2023-12-08", "2023-12-09", "2023-12-10", "2023-12-11", "2023-12-12", "2023-12-13", "2023-12-14", "2023-12-15", "2023-12-16", "2023-12-17", "2023-12-18", "2023-12-19", "2023-12-20", "2023-12-21"],
        ['measurement_2_x'],
        ['measurement_2'],
        ['measurement_3_x'],
        ['measurement_3'],
        ['measurement_4_x'],
        ['measurement_4'],
        ['measurement_5_x'],
        ['measurement_5'],
        ['measurement_6_x'],
        ['measurement_6'],
        ['measurement_7_x'],
        ['measurement_7'],
        ['measurement_8_x'],
        ['measurement_8'],
    ];

    let chart_fev_bindto = "#chart-fev";

    let chart_fev_padding = {
        right: 40,
    };

    let chart_fev_zoom = {
        enabled: false,
    };

    let chart_fev_axis = {
        x: {
            show: false,
            type: 'timeseries',
            tick: {
                rotate: 0,
                fit: false,
                format: "%e %b",
                centered: true,
            }
        },
        y: {
            tick: {
                format: function (d) {
                    return (parseInt(d) == d) ? d : null;
                }
            },
            label: {
                text: 'Forced Expiratory Volume',
                position: 'outer middle',
            }
        }
    };

    let chart_fev_tooltip = {
        grouped: false, // Default true
        format: {
            title: function (d) {
                return format(d, "yyyy-MM-dd");
            },
            value: function (value, ratio, id) {
                return value + " L";
            }
        }
    };

    let chart_fev_data = {
        selection: {
            enabled: false,
        },
        xs: {
            daily_avg: 'daily_avg_x',
            measurement_1: 'measurement_1_x',
            measurement_2: 'measurement_2_x',
            measurement_3: 'measurement_3_x',
            measurement_4: 'measurement_4_x',
            measurement_5: 'measurement_5_x',
            measurement_6: 'measurement_6_x',
            measurement_7: 'measurement_7_x',
            measurement_8: 'measurement_8_x',
        },
        columns: fevColumnPayload,
        type: "line",
        types: {
            measurement_1: 'scatter',
            measurement_2: 'scatter',
            measurement_3: 'scatter',
            measurement_4: 'scatter',
            measurement_5: 'scatter',
            measurement_6: 'scatter',
            measurement_7: 'scatter',
            measurement_8: 'scatter',
        },
    };

    let chart_fev_color = {
        pattern: ['#F14C00', '#fa8f5c', '#fa8f5c', '#fa8f5c', '#fa8f5c', '#fa8f5c', '#fa8f5c', '#fa8f5c', '#fa8f5c']
    };

    let chart_fev_size = {
        height: 200,
    };

    let chart_fev_point = {
        show: true,
        r: 3,
        focus: {
            enabled: true,
            r: 4
        },
        select: {
            r: 5
        }
    };

    let chart_fev_legend = {
        show: false
    };

    const regenerateFEVGraph = () => {
        fevGraph = c3.generate({
            bindto: chart_fev_bindto,
            padding: chart_fev_padding,
            data: chart_fev_data,
            zoom: chart_fev_zoom,
            axis: chart_fev_axis,
            legend: chart_fev_legend,
            tooltip: chart_fev_tooltip,
            color: chart_fev_color,
            size: chart_fev_size,
            point: chart_fev_point,
        });
    }

    var fevGraph = c3.generate({
        bindto: chart_fev_bindto,
        padding: chart_fev_padding,
        data: chart_fev_data,
        zoom: chart_fev_zoom,
        axis: chart_fev_axis,
        legend: chart_fev_legend,
        tooltip: chart_fev_tooltip,
        color: chart_fev_color,
        size: chart_fev_size,
        point: chart_fev_point,
    });

    useEffect(() => {
        regenerateFEVGraph();
    }, []);

    const pefPayload = useSelector(getPefPayloadSelector);
    console.log("pefPayload: ", pefPayload);

    let pefColumnPayload = [
        ["daily_avg", 19, 13, 14, 15, 15, 18, 14, 17, 16, 18, 16, 16, 14, 13, 17, 16, 15, 15, 19],
        ["daily_avg_x", "2023-12-05", "2023-12-06", "2023-12-07", "2023-12-08", "2023-12-09", "2023-12-10", "2023-12-11", "2023-12-12", "2023-12-13", "2023-12-14", "2023-12-15", "2023-12-16", "2023-12-17", "2023-12-18", "2023-12-19", "2023-12-20", "2023-12-21"],
        ["measurement_1", 14, 15, 12, 17, 18, 15, 15, 19, 18, 16, 14, 15, 13, 12, 19, 14, 16, 17, 17],
        ["measurement_1_x", "2023-12-05", "2023-12-06", "2023-12-07", "2023-12-08", "2023-12-09", "2023-12-10", "2023-12-11", "2023-12-12", "2023-12-13", "2023-12-14", "2023-12-15", "2023-12-16", "2023-12-17", "2023-12-18", "2023-12-19", "2023-12-20", "2023-12-21"],
        ['measurement_2_x'],
        ['measurement_2'],
        ['measurement_3_x'],
        ['measurement_3'],
        ['measurement_4_x'],
        ['measurement_4'],
        ['measurement_5_x'],
        ['measurement_5'],
        ['measurement_6_x'],
        ['measurement_6'],
        ['measurement_7_x'],
        ['measurement_7'],
        ['measurement_8_x'],
        ['measurement_8'],
    ];

    let chart_pef_bindto = "#chart-pef";

    let chart_pef_padding = {
        right: 40,
    };

    let chart_pef_zoom = {
        enabled: false,
    };

    let chart_pef_axis = {
        x: {
            type: 'timeseries',
            tick: {
                rotate: 0,
                fit: false,
                format: "%e %b",
                centered: true,
            }
        },
        y: {
            tick: {
                format: function (d) {
                    return (parseInt(d) == d) ? d : null;
                }
            },
            label: {
                text: 'Peak Expiratory Flow',
                position: 'outer middle',
            }
        }
    };

    let chart_pef_tooltip = {
        grouped: false, // Default true
        format: {
            title: function (d) {
                return format(d, "yyyy-MM-dd");
            },
            value: function (value, ratio, id) {
                return value + " L/s";
            }
        }
    };

    let chart_pef_data = {
        selection: {
            enabled: false,
        },
        xs: {
            daily_avg: 'daily_avg_x',
            measurement_1: 'measurement_1_x',
            measurement_2: 'measurement_2_x',
            measurement_3: 'measurement_3_x',
            measurement_4: 'measurement_4_x',
            measurement_5: 'measurement_5_x',
            measurement_6: 'measurement_6_x',
            measurement_7: 'measurement_7_x',
            measurement_8: 'measurement_8_x',
        },
        columns: pefColumnPayload,
        type: "line",
        types: {
            measurement_1: 'scatter',
            measurement_2: 'scatter',
            measurement_3: 'scatter',
            measurement_4: 'scatter',
            measurement_5: 'scatter',
            measurement_6: 'scatter',
            measurement_7: 'scatter',
            measurement_8: 'scatter',
        },
    };

    let chart_pef_color = {
        pattern: ['#694FC0', '#9981f0', '#9981f0', '#9981f0', '#9981f0', '#9981f0', '#9981f0', '#9981f0', '#9981f0']
    };

    let chart_pef_size = {
        height: 200,
    };

    let chart_pef_point = {
        show: true,
        r: 3,
        focus: {
            enabled: true,
            r: 4
        },
        select: {
            r: 5
        }
    };

    let chart_pef_legend = {
        show: false
    };

    const regeneratePEFGraph = () => {
        pefGraph = c3.generate({
            bindto: chart_pef_bindto,
            padding: chart_pef_padding,
            data: chart_pef_data,
            zoom: chart_pef_zoom,
            axis: chart_pef_axis,
            legend: chart_pef_legend,
            tooltip: chart_pef_tooltip,
            color: chart_pef_color,
            size: chart_pef_size,
            point: chart_pef_point,
        });
    }

    var pefGraph = c3.generate({
        bindto: chart_pef_bindto,
        padding: chart_pef_padding,
        data: chart_pef_data,
        zoom: chart_pef_zoom,
        axis: chart_pef_axis,
        legend: chart_pef_legend,
        tooltip: chart_pef_tooltip,
        color: chart_pef_color,
        size: chart_pef_size,
        point: chart_pef_point,
    });

    useEffect(() => {
        regeneratePEFGraph();
    }, []);

    return (
        <div style={{ width: "100%" }}>
            <div id='chart-fev' style={{ marginTop: "10px", marginLeft: "10px" }} />
            <div id='chart-pef' style={{ marginLeft: "10px" }} />
        </div>
    );
}