import 'c3/c3.css';
import c3 from "c3";
import 'c3/c3.min.css';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from "./RenderAIDIGraphCollapsed.css";
import { getAidiGraphPayloadSelector, getSelectedPatientBRPayload, getSelectedPatientHRPayload } from '../../../../../Reducer/selectors/AIDISelector';
import { format } from 'date-fns';

export default function RenderAIDIGraphCollapsed() {

    const aidiGraphColPayload = useSelector(getAidiGraphPayloadSelector);
    console.log("aidiGraphColPayload: ", aidiGraphColPayload);

    const daily_aidi_dummy = aidiGraphColPayload.daily_aidi ? aidiGraphColPayload.daily_aidi : ["daily_aidi", ];
    console.log("daily_aidi_dummy: ", daily_aidi_dummy);

    const daily_aidi_x = aidiGraphColPayload.daily_aidi_x ? aidiGraphColPayload.daily_aidi_x : ["daily_aidi_x", ];
    const daily_aidi = new Array(daily_aidi_x.length - 1).fill('1');
    daily_aidi.splice(0, 0, 'daily_aidi');
    console.log("daily_aidi: ", daily_aidi);

    const baseline_num = aidiGraphColPayload.baseline_num ? aidiGraphColPayload.baseline_num : ["baseline_num", ];

    const pallete = [ "#C6E8BF", "#FFE299", "#FB9595" ];

    const hrPayload = useSelector(getSelectedPatientHRPayload);
    const daily_hr = hrPayload.daily_avg;

    const brPayload = useSelector(getSelectedPatientBRPayload);
    const daily_br = brPayload.daily_avg;

    let chart_aidi_col_bindto = "#chart-aidi-sum";

    let chart_aidi_col_padding = {
        right: 40,
    };

    let chart_aidi_col_data = {
        selection: {
            enabled: false,
        },
        xs: {
            daily_aidi: 'daily_aidi_x',
        },
        columns: [daily_aidi_x, daily_aidi],
        type: 'bar',
        // labels: {
        //     format: function (v, id, i, j) {
        //         return parseFloat(daily_aidi_dummy[i + 1]);
        //     }
        // },
        colors: {
            daily_aidi: function (d) {
                if (parseFloat(daily_aidi_dummy[d.index + 1])  <= 0.3 ) {
                    return pallete[0];
                }  else if (parseFloat(daily_aidi_dummy[d.index + 1])  <= 0.6 ) {
                    return pallete[1];
                } else if (parseFloat(daily_aidi_dummy[d.index + 1])  > 0.6 ) {
                    return pallete[2];
                }
            }
        },
    };

    let chart_aidi_col_bar = {
        width: 10,
        zerobased: false,
        // or
        //width: 100 // this makes bar width 100px
    };

    let chart_aidi_col_zoom = {
        enabled: false,
        type: 'drag',
        rescale: true,
        //onzoomend: function (d) { correctBRGraphZoom(d); }
    };

    let chart_aidi_col_axis = {
        x: {
            type: 'timeseries',
            tick: {
                fit: false,
                format: "%e %b",
                centered: true,
            }
        },
        y: {
            show: true,
            tick: {
                centered: true,
                values: [],
            },
            max: 1.0,
            min: 0.0,
            padding: { top: 0, bottom: 0 },
            label: {
                 text: 'AIDI',
                 position: 'outer middle',
            }
        }
    };

    let chart_aidi_col_legend = {
        show: false
    };

    let chart_aidi_col_tooltip = {
        grouped: false, // Default true
        format: {
            title: function (d) {
                return format(d, "yyyy-MM-dd");
            },
            value: function (value, ratio, id, index) {
                return "";
            },
            name: function (name, ratio, id, index) {
                return "AIDI: " + daily_aidi_dummy[index + 1] + " | BR: " + daily_br[index + 1] + " | HR: " + daily_hr[index + 1];
            }
        }
    };

    let chart_aidi_col_size = {
        height: 80,
    };

    const regenerateAIDIColGraph = () => {
        aidiColGraph = c3.generate({
            bindto: chart_aidi_col_bindto,
            padding: chart_aidi_col_padding,
            data: chart_aidi_col_data,
            bar: chart_aidi_col_bar,
            zoom: chart_aidi_col_zoom,
            axis: chart_aidi_col_axis,
            legend: chart_aidi_col_legend,
            size: chart_aidi_col_size,
            tooltip: chart_aidi_col_tooltip
        });
    }

    var aidiColGraph = c3.generate({
        bindto: chart_aidi_col_bindto,
        padding: chart_aidi_col_padding,
        data: chart_aidi_col_data,
        bar: chart_aidi_col_bar,
        zoom: chart_aidi_col_zoom,
        axis: chart_aidi_col_axis,
        legend: chart_aidi_col_legend,
        size: chart_aidi_col_size,
        tooltip: chart_aidi_col_tooltip
    });

    useEffect(() => {
        regenerateAIDIColGraph();
    }, []);

    return (
        <div>
            <div id='chart-aidi-sum' style={{ marginTop: "20px", marginLeft: "10px" }} />
        </div>
    );
}