import 'c3/c3.css';
import c3 from "c3";
import 'c3/c3.min.css';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export default function RenderGWP() {

    let gColumnPayload = [
        ["daily_g_avg", 97, 98, 99, 100, 95, 98, 98, 97, 99, 100, 100, 97, 95, 96, 97, 99, 98, 97, 98],
        ["daily_g_avg_x", "2023-12-05", "2023-12-06", "2023-12-07", "2023-12-08", "2023-12-09", "2023-12-10", "2023-12-11", "2023-12-12", "2023-12-13", "2023-12-14", "2023-12-15", "2023-12-16", "2023-12-17", "2023-12-18", "2023-12-19", "2023-12-20", "2023-12-21"],
    ];

    let chart_g_bindto = "#chart-g";

    let chart_g_padding = {
        right: 40,
    };

    let chart_g_zoom = {
        enabled: false,
    };

    let chart_g_axis = {
        x: {
            show: false,
            type: 'timeseries',
            tick: {
                rotate: 0,
                fit: false,
                format: "%e %b",
                centered: true,
            }
        },
        y: {
            tick: {
                format: function (d) {
                    return (parseInt(d) == d) ? d : null;
                }
            },
            label: {
                text: 'Glucose',
                position: 'outer middle',
            }
        }
    };

    let chart_g_tooltip = {
        grouped: false, // Default true
        format: {
            title: function (d) {
                return format(d, "yyyy-MM-dd");
            },
            value: function (value, ratio, id) {
                return value + " mg/dL";
            }
        }
    };

    let chart_g_data = {
        selection: {
            enabled: false,
        },
        xs: {
            daily_g_avg: 'daily_g_avg_x',
        },
        columns: gColumnPayload,
        type: "spline",
        types: {
            daily_g_avg: "spline",
        }
    };

    let chart_g_color = {
        pattern: ['#F14C00', '#fa8f5c', '#fa8f5c', '#fa8f5c', '#fa8f5c', '#fa8f5c', '#fa8f5c', '#fa8f5c', '#fa8f5c']
    };

    let chart_g_size = {
        height: 200,
    };

    let chart_g_point = {
        show: true,
        r: 3,
        focus: {
            enabled: true,
            r: 4
        },
        select: {
            r: 5
        }
    };

    let chart_g_legend = {
        show: false
    };

    const regenerateGGraph = () => {
        gGraph = c3.generate({
            bindto: chart_g_bindto,
            padding: chart_g_padding,
            data: chart_g_data,
            zoom: chart_g_zoom,
            axis: chart_g_axis,
            legend: chart_g_legend,
            tooltip: chart_g_tooltip,
            color: chart_g_color,
            size: chart_g_size,
            point: chart_g_point,
        });
    }

    var gGraph = c3.generate({
        bindto: chart_g_bindto,
        padding: chart_g_padding,
        data: chart_g_data,
        zoom: chart_g_zoom,
        axis: chart_g_axis,
        legend: chart_g_legend,
        tooltip: chart_g_tooltip,
        color: chart_g_color,
        size: chart_g_size,
        point: chart_g_point,
    });

    useEffect(() => {
        regenerateGGraph();
    }, []);


    let wColumnPayload = [
        ["daily_w_avg", 97, 98, 99, 100, 95, 98, 98, 97, 99, 100, 100, 97, 95, 96, 97, 99, 98, 97, 98],
        ["daily_w_avg_x", "2023-12-05", "2023-12-06", "2023-12-07", "2023-12-08", "2023-12-09", "2023-12-10", "2023-12-11", "2023-12-12", "2023-12-13", "2023-12-14", "2023-12-15", "2023-12-16", "2023-12-17", "2023-12-18", "2023-12-19", "2023-12-20", "2023-12-21"],
    ];

    let chart_w_bindto = "#chart-w";

    let chart_w_padding = {
        right: 40,
    };

    let chart_w_zoom = {
        enabled: false,
    };

    let chart_w_axis = {
        x: {
            show: false,
            type: 'timeseries',
            tick: {
                rotate: 0,
                fit: false,
                format: "%e %b",
                centered: true,
            }
        },
        y: {
            tick: {
                format: function (d) {
                    return (parseInt(d) == d) ? d : null;
                }
            },
            label: {
                text: 'Weight',
                position: 'outer middle',
            }
        }
    };

    let chart_w_tooltip = {
        grouped: false, // Default true
        format: {
            title: function (d) {
                return format(d, "yyyy-MM-dd");
            },
            value: function (value, ratio, id) {
                return value + " lbs";
            }
        }
    };

    let chart_w_data = {
        selection: {
            enabled: false,
        },
        xs: {
            daily_w_avg: 'daily_w_avg_x',
        },
        columns: wColumnPayload,
        type: "spline",
        types: {
            daily_w_avg: "spline",
        }
    };

    let chart_w_color = {
        pattern: ['#694FC0', '#9981f0', '#9981f0', '#9981f0', '#9981f0', '#9981f0', '#9981f0', '#9981f0', '#9981f0']
    };

    let chart_w_size = {
        height: 200,
    };

    let chart_w_point = {
        show: true,
        r: 3,
        focus: {
            enabled: true,
            r: 4
        },
        select: {
            r: 5
        }
    };

    let chart_w_legend = {
        show: false
    };

    const regenerateWGraph = () => {
        wGraph = c3.generate({
            bindto: chart_w_bindto,
            padding: chart_w_padding,
            data: chart_w_data,
            zoom: chart_w_zoom,
            axis: chart_w_axis,
            legend: chart_w_legend,
            tooltip: chart_w_tooltip,
            color: chart_w_color,
            size: chart_w_size,
            point: chart_w_point,
        });
    }

    var wGraph = c3.generate({
        bindto: chart_w_bindto,
        padding: chart_w_padding,
        data: chart_w_data,
        zoom: chart_w_zoom,
        axis: chart_w_axis,
        legend: chart_w_legend,
        tooltip: chart_w_tooltip,
        color: chart_w_color,
        size: chart_w_size,
        point: chart_w_point,
    });

    useEffect(() => {
        regenerateWGraph();
    }, []);


    let pColumnPayload = [
        ["daily_p_avg", 19, 13, 14, 15, 15, 18, 14, 17, 16, 18, 16, 16, 14, 13, 17, 16, 15, 15, 19],
        ["daily_p_avg_x", "2023-12-05", "2023-12-06", "2023-12-07", "2023-12-08", "2023-12-09", "2023-12-10", "2023-12-11", "2023-12-12", "2023-12-13", "2023-12-14", "2023-12-15", "2023-12-16", "2023-12-17", "2023-12-18", "2023-12-19", "2023-12-20", "2023-12-21"],
    ];

    let chart_p_bindto = "#chart-p";

    let chart_p_padding = {
        right: 40,
    };

    let chart_p_zoom = {
        enabled: false,
    };

    let chart_p_axis = {
        x: {
            type: 'timeseries',
            tick: {
                rotate: 0,
                fit: false,
                format: "%e %b",
                centered: true,
            }
        },
        y: {
            tick: {
                format: function (d) {
                    return (parseInt(d) == d) ? d : null;
                }
            },
            label: {
                text: 'Pain',
                position: 'outer middle',
            }
        }
    };

    let chart_p_tooltip = {
        grouped: false, // Default true
        format: {
            title: function (d) {
                return format(d, "yyyy-MM-dd");
            },
            value: function (value, ratio, id) {
                return value;
            }
        }
    };

    let chart_p_data = {
        selection: {
            enabled: false,
        },
        xs: {
            daily_p_avg: 'daily_p_avg_x'
        },
        columns: pColumnPayload,
        type: "spline",
        types: {
            daily_p_avg: "spline",
        }
    };

    let chart_p_color = {
        pattern: ['green', '#9981f0', '#9981f0', '#9981f0', '#9981f0', '#9981f0', '#9981f0', '#9981f0', '#9981f0']
    };

    let chart_p_size = {
        height: 200,
    };

    let chart_p_point = {
        show: true,
        r: 3,
        focus: {
            enabled: true,
            r: 4
        },
        select: {
            r: 5
        }
    };

    let chart_p_legend = {
        show: false
    };

    const regeneratePGraph = () => {
        pGraph = c3.generate({
            bindto: chart_p_bindto,
            padding: chart_p_padding,
            data: chart_p_data,
            zoom: chart_p_zoom,
            axis: chart_p_axis,
            legend: chart_p_legend,
            tooltip: chart_p_tooltip,
            color: chart_p_color,
            size: chart_p_size,
            point: chart_p_point,
        });
    }

    var pGraph = c3.generate({
        bindto: chart_p_bindto,
        padding: chart_p_padding,
        data: chart_p_data,
        zoom: chart_p_zoom,
        axis: chart_p_axis,
        legend: chart_p_legend,
        tooltip: chart_p_tooltip,
        color: chart_p_color,
        size: chart_p_size,
        point: chart_p_point,
    });

    useEffect(() => {
        regeneratePGraph();
    }, []);

    return (
        <div style={{ width: "100%" }}>
            <div id='chart-g' style={{ marginTop: "10px", marginLeft: "10px" }} />
            <div id='chart-w' style={{ marginLeft: "10px" }} />
            <div id='chart-p' style={{ marginLeft: "10px" }} />
        </div>
    );
}