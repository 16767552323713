// AIDAR CONNECT
// const domainCF = 'https://d1kstnhfbq78td.cloudfront.net'; // current prod 
// const domainCF = 'https://d37borf687po83.cloudfront.net'; // develop
// const domainCF = 'https://dr9gak5ds199l.cloudfront.net'; // bluetooth
// const domainCF = 'http://localhost:8080'; // localhost
// const domainCF = 'https://d33ctkk299v975.cloudfront.net'; // aidi

// AIDAR BRIDGE
//const domainCF = 'https://d55snwwpr91u2.cloudfront.net'; // develop 
// const domainCF = 'https://d33hqpwr5p1la6.cloudfront.net'; // develop new
// const domainCF = 'https://d34t761av3dme4.cloudfront.net'; // old main
const domainCF = 'https://d1trvzpiua3sln.cloudfront.net'; // new main

export default domainCF;