import React from "react";
import styles from "./BaselineContainer.module.css";
import Grid from "@material-ui/core/Grid";
import { FaEdit } from "react-icons/fa";

// Components Imports
import BaselineRange from "../BaselineRanges/BaselineRange";
export default function BaselineContainer(props) {
  console.log("-----------------------------", props);

  const handleEditBaseline = () => {
    props.onEditing();
  };
  return (
    <div className={styles.rulesContainer}>
      <div className={styles.actionContainer}>
        <div className={styles.editActionDiv} onClick={handleEditBaseline}>
          <FaEdit size={20} />
          <div className={styles.actionName}>Edit</div>
        </div>
      </div>
      <Grid container>
        <Grid item xs={12} xl={3} lg={3} md={3} sm={12}>
          <div className={styles.card}>
            {/* <div className={styles.actionContainer}>
              <div
                className={styles.editActionDiv}
                onClick={handleEditBaseline}
              >
                <FaEdit size={20} />
                <div className={styles.actionName}>Edit</div>
              </div>
            </div> */}
            <div>
              <BaselineRange ranges={props.baseline.temperatureBaseline} />
            </div>
            <div className={styles.titleDiv}>Temperature</div>
          </div>
        </Grid>
        <Grid item xs={12} xl={3} lg={3} md={3} sm={12}>
          <div className={styles.card}>
            {/* <div className={styles.actionContainer}>
              <div
                className={styles.editActionDiv}
                onClick={handleEditBaseline}
              >
                <FaEdit size={20} />
                <div className={styles.actionName}>Edit</div>
              </div>
            </div> */}
            <div>
              <BaselineRange ranges={props.baseline.sbpBaseline} />
            </div>
            <div className={styles.titleDiv}>Systolic BP</div>
          </div>
        </Grid>

        <Grid item xs={12} xl={3} lg={3} md={3} sm={12}>
          <div className={styles.card}>
            {/* <div className={styles.actionContainer}>
              <div
                className={styles.editActionDiv}
                onClick={handleEditBaseline}
              >
                <FaEdit size={20} />
                <div className={styles.actionName}>Edit</div>
              </div>
            </div> */}
            <div>
              <BaselineRange ranges={props.baseline.dbpBaseline} />
            </div>
            <div className={styles.titleDiv}>Diastolic BP</div>
          </div>
        </Grid>

        <Grid item xs={12} xl={3} lg={3} md={3} sm={12}>
          <div className={styles.card}>
            {/* <div className={styles.actionContainer}>
              <div
                className={styles.editActionDiv}
                onClick={handleEditBaseline}
              >
                <FaEdit size={20} />
                <div className={styles.actionName}>Edit</div>
              </div>
            </div> */}
            <div>
              <BaselineRange ranges={props.baseline.pulseRateBaseline} />
            </div>
            <div className={styles.titleDiv}>Pulse Rate</div>
          </div>
        </Grid>
        <Grid item xs={12} xl={3} lg={3} md={3} sm={12}>
          <div className={styles.card}>
            {/* <div className={styles.actionContainer}>
              <div
                className={styles.editActionDiv}
                onClick={handleEditBaseline}
              >
                <FaEdit size={20} />
                <div className={styles.actionName}>Edit</div>
              </div>
            </div> */}
            <div>
              <BaselineRange ranges={props.baseline.spo2Baseline} />
            </div>
            <div className={styles.titleDiv}>Spo2</div>
          </div>
        </Grid>
        <Grid item xs={12} xl={3} lg={3} md={3} sm={12}>
          <div className={styles.card}>
            {/* <div className={styles.actionContainer}>
              <div
                className={styles.editActionDiv}
                onClick={handleEditBaseline}
              >
                <FaEdit size={20} />
                <div className={styles.actionName}>Edit</div>
              </div>
            </div> */}
            <div>
              <BaselineRange ranges={props.baseline.heartRateBaseline} />
            </div>
            <div className={styles.titleDiv}>Heart Rate</div>
          </div>
        </Grid>
        <Grid item xs={12} xl={3} lg={3} md={3} sm={12}>
          <div className={styles.card}>
            {/* <div className={styles.actionContainer}>
              <div
                className={styles.editActionDiv}
                onClick={handleEditBaseline}
              >
                <FaEdit size={20} />
                <div className={styles.actionName}>Edit</div>
              </div>
            </div> */}
            <div>
              <BaselineRange ranges={props.baseline.hrvBaseline} />
            </div>
            <div className={styles.titleDiv}>Heart Rate Variability</div>
          </div>
        </Grid>

        <Grid item xs={12} xl={3} lg={3} md={3} sm={12}>
          <div className={styles.card}>
            {/* <div className={styles.actionContainer}>
              <div
                className={styles.editActionDiv}
                onClick={handleEditBaseline}
              >
                <FaEdit size={20} />
                <div className={styles.actionName}>Edit</div>
              </div>
            </div> */}
            <div>
              <BaselineRange ranges={props.baseline.respirationRateBaseline} />
            </div>
            <div className={styles.titleDiv}>Respiration Rate</div>
          </div>
        </Grid>

        <Grid item xs={12} xl={3} lg={3} md={3} sm={12}>
          <div className={styles.card}>
            {/* <div className={styles.actionContainer}>
              <div
                className={styles.editActionDiv}
                onClick={handleEditBaseline}
              >
                <FaEdit size={20} />
                <div className={styles.actionName}>Edit</div>
              </div>
            </div> */}
            <div>
              <BaselineRange ranges={props.baseline.fev1Baseline} />
            </div>
            <div className={styles.titleDiv}>FEV1</div>
          </div>
        </Grid>

        <Grid item xs={12} xl={3} lg={3} md={3} sm={12}>
          <div className={styles.card}>
            {/* <div className={styles.actionContainer}>
              <div
                className={styles.editActionDiv}
                onClick={handleEditBaseline}
              >
                <FaEdit size={20} />
                <div className={styles.actionName}>Edit</div>
              </div>
            </div> */}
            <div>
              <BaselineRange ranges={props.baseline.pefBaseline} />
            </div>
            <div className={styles.titleDiv}>PEF</div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
