import React, { useState } from "react";
import styles from "./PatientItem.module.css";
import Grid from "@material-ui/core/Grid";
import Modal from "@material-ui/core/Modal";
import { RiCloseCircleFill } from "react-icons/ri";
import IconButton from "@material-ui/core/IconButton";
import { BrowserRouter as Router, useParams } from "react-router-dom";
import PatientStatusSet from "../PatientStatusActions/PatientStatusSet";

export default function PatientItem(props) {
  // Getting Patient GUID from URL
  let currentURLSubject = useParams();

  // Modal Menu Controls
  const [assignmentModal, setAssignmentModal] = useState();

  const [statusKey, setStatusKey] = useState("");
  const openAssignMenu = (key) => {
    setStatusKey(key);
    setAssignmentModal(true);
  };
  const closeAssignMenu = () => {
    setAssignmentModal(false);
  };
  return (
    <div className={styles.statusItemContainer}>
      <div className={styles.itemCellContainer}>
        <div className={styles.itemCellHeader}>
          <button
            type="button"
            className={`${props.currentPatientStatus === "Registered" ? styles.curRegisterButton : styles.registerButton}`}
            onClick={() => {
              openAssignMenu("Registered");
            }}
          >
            Registered
          </button>
        </div>
        {/* <div className={styles.itemCellElement}>MouthLab</div> */}
      </div>

      <div className={styles.itemCellContainer}>
        <div className={styles.itemCellHeader}>
          <button
            type="button"
            className={`${props.currentPatientStatus === "Terminated" ? styles.curTerminateButton : styles.terminateButton}`}
            onClick={() => {
              openAssignMenu("Terminated");
            }}
          >
            Terminated
          </button>
        </div>
        {/* <div className={styles.itemCellElement}>MouthLab</div> */}
      </div>

      <div className={styles.itemCellContainer}>
        <div className={styles.itemCellHeader}>
          <button
            type="button"
            className={`${props.currentPatientStatus === "Active" ? styles.curActiveButton : styles.activeButton}`}
            onClick={() => {
              openAssignMenu("Active");
            }}
          >
            Active
          </button>
        </div>
        {/* <div className={styles.itemCellElement}>MouthLab</div> */}
      </div>

      <div className={styles.itemCellContainer}>
        <div className={styles.itemCellHeader}>
          <button
            type="button"
            className={`${props.currentPatientStatus === "Calibrate" ? styles.curCalibrateButton : styles.calibrateButton}`}
            onClick={() => {
              openAssignMenu("Calibrate");
            }}
          >
            Calibrate
          </button>
        </div>
        {/* <div className={styles.itemCellElement}>MouthLab</div> */}
      </div>

      <div className={styles.itemCellContainer}>
        <div className={styles.itemCellHeader}>
          <button
            type="button"
            //className={styles.completedButton}
            className={`${props.currentPatientStatus === "Completed" ? styles.curComplete : styles.completedButton}`}
            onClick={() => {
              openAssignMenu("Completed");
            }}
          >
            Completed
          </button>
        </div>
        {/* <div className={styles.itemCellElement}>MouthLab</div> */}
      </div>

      {/* Status Menu */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={styles.modal}
        open={assignmentModal}
        onClose={closeAssignMenu}
      >
        <div className={styles.paper}>
          <div className={styles.menuTitle}>
            <div className={styles.titleText}>Status</div>
            <div>
              <IconButton aria-label="delete">
                <RiCloseCircleFill size={25} style={{color:'#4E253A'}} onClick={closeAssignMenu} />
              </IconButton>
            </div>
          </div>

          <div className={styles.patientListDiv}>
            <Grid container>
              <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
                {/* <AssignMouthlab
                  currentSubjectID={curPatientId.patientId}
                  closeModal={closeAssignMenu}
                /> */}

                <PatientStatusSet currentSubjectID={currentURLSubject.patientId} status={statusKey} closeModal={closeAssignMenu} />
              </Grid>
            </Grid>
          </div>
        </div>
      </Modal>
      {/*Status Menu */}
    </div>
  );
}
