import React, { useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, useNavigate } from "react-router";
import { globalConstants } from "../../../../Constants/GlobalConstants";
import { getUserRole } from "../../../../Reducer/selectors/DashboardSelector";
import { setPCCPatientListPageNumber, setPCCPatientListPageSize, setSelectedMlId } from "../../../../Actions";
import { getUserProfile } from "../../../../Reducer/selectors/DashboardSelector.js";
import * as actions from "../../../../Actions";
import { UserContext } from "../../../Store/UserContext.js";

function Dashboard() {
  // const userRole = useSelector(getUserRole);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currUserProfile = useSelector(getUserProfile);
  //  const currUserEmail = currUserProfile.email;
  const userRole = currUserProfile.role;
  //  console.log("email from redux state: ", currUserProfile);
  console.log("user profile from redux state: ", currUserProfile);
  /* useEffect(() => {
        if(userRole === globalConstants.USER_ROLE_NURSE_AID){
            navigate(`/deviceAssignments`, { replace: true }); 
        }else if(userRole === globalConstants.USER_ROLE_PATIENT){
            console.log('patient... ')
            navigate(`/subjectquickview`, { replace: true }); 
        }else {
            navigate(`/projectlocations`, { replace: true }); 
        }
    
     }, []); */

  useEffect(() => {
    dispatch(actions.getUserProfile(currUserProfile.email));
  });


  const { setUserInformation } = useContext(UserContext);

  useEffect(() => {
    if (currUserProfile && currUserProfile.firstName) {
      setUserInformation({
        email: currUserProfile.email,
        firstName: currUserProfile.firstName,
        lastName: currUserProfile.lastName,
        institutionId: currUserProfile.institutionId,
        projects: currUserProfile.projects,
        locations: currUserProfile.locations,
        selectedProjectId: "",
        selectedLocationId: "",
      });
    }
    navigate(`/deviceAssignments`, { replace: true });
  }, []);

  // useEffect(() => {
  //   //let userRole = currUserProfile.role;
  //   console.log("THE ROLE OF THE USER LOGGIN IN: ", userRole);
  //   navigate(`/deviceAssignments`, { replace: true });
  // }, []);
  return <div></div>;
}
export default Dashboard;
