import React, { useState, useContext } from "react";
import styles from "./PatientStatusSet.module.css";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@mui/material/LinearProgress";

// API Imports
import SetPatientStatus from "../../../../../API/SetPatientStatus/SetPatientStatus";

// Context import
import { PatientContext } from "../../../../../Store/PatientDataProvider";

export default function PatientStatusSet(props) {
  let patientCtx = useContext(PatientContext);
  let refreshData = patientCtx.isRefresh;
  const [isSuccess, setIsSuccess] = useState(0);

  const [patientStatusData, setPatientStatusData] = useState({
    patientGuid: props.currentSubjectID,
    status: props.status,
  });

  const handleSubmit = () => {
    SetPatientStatus(patientStatusData).then((res) => {
      setIsSuccess(1);
      console.log("Mouthlab Assignment response", res.operationSuccessful);
      console.log("Respiosne ", res);
      if (res.wasSuccessful === true) {
        setIsSuccess(0);
        props.closeModal();
        patientCtx.setIsRefresh(!refreshData);
      } else {
        setIsSuccess(0);
      }
    });
  };
  return (
    <div>
      <Grid container>
        <Grid container>
          <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
            <div className={styles.radioLabelForm}>
              Change status to {props.status}?
            </div>
          </Grid>
        </Grid>

        {isSuccess === 1 && (
          <div className={styles.progressResponseDiv}>
            <div className={styles.overlayProgressBar}>
              <LinearProgress style={{ color: `#22577a` }} />
              <div className={styles.progressMessage}>
                Processing, please wait.
              </div>
              {/* <button onClick={somefunc}>click me</button> */}
            </div>
          </div>
        )}

        <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
          <div style={{ textAlign: "center" }}>
            <button
              type="button"
              onClick={props.closeModal}
              className={styles.cancelButton}
            >
              Cancel
            </button>
            <button
              type="submit"
              onClick={handleSubmit}
              className={styles.submitButton}
            >
              Confirm
            </button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
