import React, { useContext, useState } from "react";
import styles from "../PatientsPresentation/PatientsList/PatientsList.module.css";
import Grid from "@material-ui/core/Grid";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedPageName, getSelectedProjectIdAndLocationId, getUserProfile } from "../../../../Reducer/selectors/DashboardSelector";
import Avatar from "@material-ui/core/Avatar";
import ProfileIcon from "../../../../Assets/Images/ProfileIcon.png";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useNavigate } from "react-router-dom";
import { setSelectedPageName } from "../../../../Actions";
import HubIcon from '@mui/icons-material/Hub';
import HubOutlinedIcon from '@mui/icons-material/HubOutlined';
import DnsOutlinedIcon from '@mui/icons-material/DnsOutlined';
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined';

export default function Header(props) {
  const userProfile = useSelector(getUserProfile);
  let today = new Date();
  var hour = today.getHours();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const selectedPageName = useSelector(getSelectedPageName);

  const getUserDisplayName = (firstName, lastName) => {
    return firstName.substring(0, 1) + '. ' + lastName;
  };

  const projectAndLocation = useSelector(getSelectedProjectIdAndLocationId);
  const profile = useSelector(getUserProfile);

  const goBackToPatientList = () => {
    dispatch(setSelectedPageName('patientslist'));
    navigate("/subjects/" + projectAndLocation.selectedProjectId + "/" + projectAndLocation.selectedLocationId, {
      state: {
        userEmail: profile.email,
        userFirstName: profile.firstName,
        userLastName: profile.lastName,
      },
    });
  };

  return (
    <div className={styles.accContainer}>
      <Grid container>
        <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
          <div className={styles.displayContainer}>
            {selectedPageName === 'profile' && (<div className={styles.backButtonDiv} onClick={(e) => goBackToPatientList(e)}>
              <div className={styles.backButton}>
                <ChevronLeftIcon className={styles.leftArrow} />
              </div>
              All Patients
            </div>)}
            {selectedPageName === 'logs' && (<div className={styles.pageHeader}>
              <HubOutlinedIcon style={{ color: "#4E253A", height: "25px", width: "25px" }}></HubOutlinedIcon>
              <h3> Device Assignment </h3>
            </div>)}
            {selectedPageName === 'patientslist' && (<div className={styles.pageHeader}>
              <DnsOutlinedIcon style={{ color: "#4E253A", height: "25px", width: "25px" }}></DnsOutlinedIcon>
              <h3> All Patients </h3>
            </div>)}
            {selectedPageName === 'help' && (<div className={styles.pageHeader}>
              <HelpCenterOutlinedIcon style={{ color: "#4E253A", height: "30px", width: "25px" }}></HelpCenterOutlinedIcon>
              <h3> Support </h3>
            </div>)}
            <div className={styles.userGreetings}>
              <div className={styles.userInfoDiv}>
                <div>
                  <Avatar className={styles.avatar2} alt="avatar_patient" src={ProfileIcon}></Avatar>
                </div>
                <div className={styles.displayName}>
                  {userProfile.firstName ? getUserDisplayName(userProfile.firstName, userProfile.lastName) : ''}
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
