import React, { Fragment, useState } from "react";
import styles from "./HelpAndSupport.module.css";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import IntroductionVideo from "./Videos/IntroductionVideo.js";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme) => ({
/*   root: {
    width: "98%",
    padding: `0px 0px 0px 20px`,
  },
 */  contentStyle: {
    display: "block",
    alignItems: "center",
  },
  acStyles: {
    backgroundColor: `#F9F4FA`,
    position: `relative`,
  },
}));

export default function HelpVideosFragment() {
  const classes = useStyles();
  const [selectedVideoButton, setSelectedVideoButton] = useState("introduction");
  const [expand, setExpand] = useState(true);
  const [title, setTitle] = useState("Introduction Video");
  const [videoEmbedId, setVideoEmbedId] = useState("nPODjOJPbbY");

  return (
    <Fragment>
      <div>
        <Accordion expanded={expand} className={classes.acStyles}  style={{ backgroundColor: "#F9F4FA" }} disableGutters elevation={0}>
          <AccordionSummary
            expandIcon={<ExpandCircleDownIcon style={{ color: `#7C596A` }} onClick={() => setExpand(!expand)} />}
            aria-controls="panel2a-content"
          >
            <div className={styles.videoButtonsWrapper}>
              <div
                className={selectedVideoButton === "introduction" ? styles.videoButtonSelected : styles.videoButton}
                onClick={() => {
                    setSelectedVideoButton("introduction");
                    setTitle("Introduction Video");
                    setVideoEmbedId("nPODjOJPbbY");
                }}
              >
                Introduction
              </div>
            </div>
            <div className={styles.videoButtonsWrapper}>
              <div
                className={selectedVideoButton === "aidarconnect" ? styles.videoButtonSelected : styles.videoButton}
                onClick={() => {setSelectedVideoButton("aidarconnect"); setTitle("Aidar Connect Demo"); setVideoEmbedId("B4UKKNhmpn8");}}
              >
                Aidar Connect
              </div>
            </div>
            {/* <div className={styles.videoButtonsWrapper}>
              <div className={selectedVideoButton === "aidi" ? styles.videoButtonSelected : styles.videoButton} 
              onClick={() => {setSelectedVideoButton("aidi"); setTitle("AIDI Demo"); setVideoEmbedId("4dpFmlz2fNs");}}>
                AIDI
              </div>
            </div> */}
            <div className={styles.videoButtonsWrapper}>
              <div
                className={selectedVideoButton === "mouthlab" ? styles.videoButtonSelected : styles.videoButton}
                onClick={() => {setSelectedVideoButton("mouthlab"); setTitle("MouthLab Monitoring System Demo"); setVideoEmbedId("WocPWaYY8LA");}}
              >
                MouthLab
              </div>
            </div>
            <div className={styles.videoButtonsWrapper}>
              <div
                className={selectedVideoButton === "calibration" ? styles.videoButtonSelected : styles.videoButton}
                onClick={() => {setSelectedVideoButton("calibration"); setTitle("Calibration Demo"); setVideoEmbedId("pzGuRkBhk58");}}
              >
                Calibration
              </div>
            </div>
            <div className={styles.videoButtonsWrapper}>
              <div
                className={selectedVideoButton === "registration" ? styles.videoButtonSelected : styles.videoButton}
                onClick={() => {setSelectedVideoButton("registration"); setTitle("Registration Demo"); setVideoEmbedId("Kqqh1cyWaN8");}}
              >
                Registration
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails className={classes.contentStyle}>
            <Divider style={{ background: '#ede9e9' }} />
            <div className={styles.videoTitle}>{title}</div>
            <Divider style={{ background: '#ede9e9' }} />
            <div className={styles.videoWrapper}>
              <IntroductionVideo embedId={videoEmbedId} />
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </Fragment>
  );
}
