import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import VitalsTableWrapper from "./VitalsTableWrapper/VitalsTableWrapper";
import ManualVitalsTableWrapper from "./ManualVitalsTableWrapper/ManualVitalsTableWrapper";
import EcgWrapper from "./EcgWrapper/EcgWrapper";
import RfmWrapper from "./RfmWrapper/RfmWrapper";
import LfGraphWrapper from "./LfWrapper/LfGraphWrapper";
import RTWrapper from "./RespiratoryTrends/RTWrapper/RTWrapper";
import { DateProvider } from "../../../../Store/DateProvider";
import { useDispatch, useSelector } from "react-redux";
import { setMLVitalsWrapperId } from "../../../../../Actions/DashboardActions";
import { getSelectedMlVitalsTab } from "../../../../../Reducer/selectors/DashboardSelector";


const AntTabs = withStyles({
  root: {
    boxShadow: "0px 1px 5px 0px #cccaca",
    alignItems: "right",
    marginRight: `20px`,
    marginLeft: `20px`,
    borderBottomRightRadius: `10px`,
    borderBottomLeftRadius: `10px`,
    fontWeight: "bold",
    backgroundColor: `#E3E3E4`,
  },
  indicator: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: "99%",
      width: "100%",
      backgroundColor: "#4E253A",
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    paddingTop: `10px`,
    color: "#7C596A",
    background: `#e9ecef`,
    minWidth: 72,
    borderRight: "1px solid #cccaca",
    fontSize: `15px`,
    fontWeight: "bold",
    fontFamily: ["GTWalsheimProRegular"].join(","),
    "&:last-child": {
      borderRight: `none`,
    },
    "&:hover": {
      color: "#4E253A",
      //opacity: 1,
    },
    "&:focus": {
      color: "#4E253A",
    },
    //selected tab styles
    "&$selected": {
      color: "#4E253A",
      backgroundColor: "#fff",
      fontWeight: theme.typography.fontWeightBold,
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  demo2: {
    backgroundColor: "#2e1534",
    alignItems: "center",
  },
}));

export default function MlVitalsTabs() {
  console.log("MlVitalsTabs");
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(0);

  const handleChange = (event, newValue) => {
    dispatch(setMLVitalsWrapperId(newValue));
    setSelectedTab(newValue);
  };

  const mlvitalsWrapperId = useSelector(getSelectedMlVitalsTab);

  useEffect(() => {
    if (mlvitalsWrapperId && mlvitalsWrapperId.selectedMlVitalsTab && mlvitalsWrapperId.selectedMlVitalsTab > 0) {
      setSelectedTab(mlvitalsWrapperId.selectedMlVitalsTab);
    }
  }, [selectedTab]);


  // useEffect(() => {
  //   if (mlvitalsWrapperId && mlvitalsWrapperId.selectedMlVitalsTab && mlvitalsWrapperId.selectedMlVitalsTab > 0) {
  //     setSelectedTab(mlvitalsWrapperId.selectedMlVitalsTab);
  //   }
  // }, []);

  return (
    <div className={classes.root}>
      <DateProvider>
        <>
          <div className={classes.demo1}>
            <AntTabs value={selectedTab} onChange={handleChange} aria-label="ant example" variant="fullWidth">
              <AntTab label="Vitals" />
              {/*  <AntTab label="Secondary" /> */}
              <AntTab label="ECG" />
              <AntTab label="RFM" />
              <AntTab label="Exhalation Curve" />
            </AntTabs>
            <Typography className={classes.padding} />

            <div style={{ alignItems: "center", textAlign: "-webkit-center" }}>
              {selectedTab === 0 && (
                //   <DateProvider>
                <VitalsTableWrapper />
                // </DateProvider>
              )}
              {/*               {selectedTab === 1 && (
                <ManualVitalsTableWrapper />
              )} */}
              {selectedTab === 1 && <EcgWrapper />}
              {selectedTab === 2 && <RfmWrapper />}
              {selectedTab === 3 && <LfGraphWrapper />}
              {/* {selectedTab === 6 && <RTWrapper />} */}
              {/* <Outlet /> */}
            </div>
          </div>
        </>
      </DateProvider>
    </div>
  );
}
