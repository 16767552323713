import React, { useEffect, useState } from "react";
import styles from "./PatientDetailsTabs.module.css";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import { NavLink, Outlet, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPatientMainTab } from "../../../../Reducer/selectors/UtilSelector";
import { setPatientMainTab } from "../../../../Actions/UtilActions";

const AntTabs = withStyles({
  root: {
    borderBottom: "1px solid #cccaca",
    boxShadow: "0px 1px 5px 0px #cccaca",
    borderTopRightRadius: `10px`,
    borderTopLeftRadius: `10px`,
    alignItems: "right",
    backgroundColor: `#fff`,
    marginRight: `20px`,
    marginLeft: `20px`,
  },
  indicator: {
    backgroundColor: "#4E253A",
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    // paddingTop: `10px`,
    color: "#4E253A",
    borderTopLeftRadius: `10px`,
    borderTopRightRadius: `10px`,
    // background: `#ffffff`,
    minWidth: 72,
    fontSize: `15px`,
    fontFamily: ["GTWalsheimProRegular"].join(","),
    "&:hover": {
      color: "#4E253A",
      opacity: 1,
    },
    //selected tab text styles
    "&$selected": {
      color: "#fff",
      fontWeight: theme.typography.fontWeightMedium,
      backgroundColor: "#4E253A",
    },
    "&:focus": {
      color: "#fff",
      fontWeight: theme.typography.fontWeightMedium,
      // backgroundColor: "#292F4C",
      // opacity: 1,
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  demo2: {
    backgroundColor: "#2e1534",
    alignItems: "center",
  },
}));

export default function PatientDetailsTabs(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { tabId } = useSelector(getPatientMainTab);
  /* console.log("tabId..", tabId);
  console.log('selectedMainTab...', props.selectedMainTab) */

  /* console.log(
    "Values of the selected tab from managed state changes on click",
    props.selectedMainTab
  ); */
  const handleChange = (event, newValue) => {
    props.onChangeMainTab(newValue);
    dispatch(setPatientMainTab(newValue));
  };

  /* console.log("this is the selected tab for patient Details", props.selectedMainTab); */
  return (
    <div className={classes.root}>
      <>
        <div className={classes.demo1}>
          <AntTabs
            value={props.selectedMainTab}
            onChange={handleChange}
            aria-label="ant example"
          >
            {/* <AntTab
              label="Overview"
              component={NavLink}
              //to={`${selectedTab}`}
              //to={`overview/${selectedTab}`}
              to="overview/1"
            /> */}

            <AntTab
              label="Vitals"
              component={NavLink}
              //to={`${selectedTab}`}
              // to={`vitals/${selectedTab}`}
              to="vitals/2"
            />

            <AntTab
              label="Trends"
              component={NavLink}
              //to={`${selectedTab}`}
              // to={`vitals/${selectedTab}`}
              to="trends/3"
            />

            <AntTab
              label="AIDI"
              component={NavLink}
              // icon={<FiPieChart />}
              //to={`${selectedTab}`}
              // to={`vitals/${selectedTab}`}
              to="aidi/4"
            />

            <AntTab
              //label="Rules"
              label="Actions"
              component={NavLink}
              // icon={<FiPieChart />}
              //to={`${selectedTab}`}
              // to={`vitals/${selectedTab}`}
              to="patientRules/5"
            />

            <AntTab
              label="Notes"
              component={NavLink}
              // icon={<FiPieChart />}
              //to={`${selectedTab}`}
              // to={`vitals/${selectedTab}`}
              to="notes/6"
            />

            {/* <AntTab
              label={
                <span className={classes.textFont}>
                  <span className={classes.tabsIconDivStyles}>
                    <BiPlus
                      size={23}
                      style={{ display: "inline-block", marginTop: `10px` }}
                    />
                  </span>
                </span>
              }
            /> */}
          </AntTabs>
          <Typography className={classes.padding} />

          <div>
            <Outlet />
          </div>
        </div>
      </>
    </div>
  );
}
