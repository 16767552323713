export const nursingDashboardConstants = {
    GET_PCC_PATIENTS_LIST: "GET_PCC_PATIENTS_LIST",
    GET_PCC_PATIENTS_LIST_SUCCESS: "GET_PCC_PATIENTS_LIST_SUCCESS",
    GET_PCC_PATIENTS_LIST_FAILURE: "GET_PCC_PATIENTS_LIST_FAILURE",
    GET_PCC_USER_PROFILE_REQUEST: 'GET_PCC_USER_PROFILE_REQUEST',
    GET_PCC_USER_PROFILE_SUCCESS: 'GET_PCC_USER_PROFILE_SUCCESS',
    GET_PCC_USER_PROFILE_FAILURE: 'GET_PCC_USER_PROFILE_FAILURE',
    SET_PREV_MEASUREMENT: 'SET_PREV_MEASUREMENT',
    SET_LATEST_MEASUREMENT: 'SET_LATEST_MEASUREMENT',
    SET_LATEST_CAL_MEASUREMENT: 'SET_LATEST_CAL_MEASUREMENT',
    UPDATE_EXTRA_MEASUREMENT_PARAMS: 'UPDATE_EXTRA_MEASUREMENT_PARAMS',
    UPDATE_EXTRA_MEASUREMENT_PARAMS_SUCCESS: 'UPDATE_EXTRA_MEASUREMENT_PARAMS_SUCCESS',
    UPDATE_EXTRA_MEASUREMENT_PARAMS_FAILURE: 'UPDATE_EXTRA_MEASUREMENT_PARAMS_FAILURE',
    UPLOAD_MANUAL_ENTRY: 'UPLOAD_MANUAL_ENTRY',
    UPLOAD_MANUAL_ENTRY_SUCCESS: 'UPLOAD_MANUAL_ENTRY_SUCCESS',
    UPLOAD_MANUAL_ENTRY_FAILURE: 'UPLOAD_MANUAL_ENTRY_FAILURE',
    SET_UPLOAD_MANUAL_ENTRY_STATUS: 'SET_UPLOAD_MANUAL_ENTRY_STATUS',
    SET_CALIBRATION_STATUS: 'SET_CALIBRATION_STATUS',
    SET_PCC_PAGE_NUMBER: 'SET_PCC_PAGE_NUMBER',
    SET_PCC_PAGE_SIZE: 'SET_PCC_PAGE_SIZE',

    SEARCH_PCC_PATIENT: 'SEARCH_PCC_PATIENT',
    SEARCH_PCC_PATIENT_SUCCESS: 'SEARCH_PCC_PATIENT_SUCCESS',
    SEARCH_PCC_PATIENT_FAILURE: 'SEARCH_PCC_PATIENT_FAILURE',
    GET_SUBJECT_HAS_ML: 'GET_SUBJECT_HAS_ML',
    GET_SUBJECT_HAS_ML_SUCCESS: 'GET_SUBJECT_HAS_ML_SUCCESS',
    GET_SUBJECT_HAS_ML_FAILURE: 'GET_SUBJECT_HAS_ML_FAILURE',
    GET_SUBJECT_FROM_DEVICE: 'GET_SUBJECT_FROM_DEVICE',
    GET_SUBJECT_FROM_DEVICE_SUCCESS: 'GET_SUBJECT_FROM_DEVICE_SUCCESS',
    GET_SUBJECT_FROM_DEVICE_FAILURE: 'GET_SUBJECT_FROM_DEVICE_FAILURE',
    SET_SUBJECT_FROM_DEVICE: 'SET_SUBJECT_FROM_DEVICE',
    SET_SHOW_QM_DRAWER: 'SET_SHOW_QM_DRAWER',
    SET_SHOW_ME_DRAWER: 'SET_SHOW_ME_DRAWER',
    SET_SHOW_CM_DRAWER: 'SET_SHOW_CM_DRAWER',
    UPDATE_FACILITY_INFO: 'UPDATE_FACILITY_INFO',
    UPDATE_FACILITY_INFO_SUCCESS: 'UPDATE_FACILITY_INFO_SUCCESS',
    UPDATE_FACILITY_INFO_FAILURE: 'UPDATE_FACILITY_INFO_FAILURE',
    SET_FLOOR_EDIT: 'SET_FLOOR_EDIT',
    SET_SHOW_SAVE_CANCEL_FACILITY: 'SET_SHOW_SAVE_CANCEL_FACILITY',
    SET_SHOW_FOOTER_BUTTONS: 'SET_SHOW_FOOTER_BUTTONS',
};