import axios from "axios";
import domainCF from "../domainCF.js";

export default async function CalibrationSubmission(bpCalValues) {
  console.log("Calibration form values passed", bpCalValues);
  var token = sessionStorage.getItem("IdToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const POST_BPCALIBRATION_VALUES = `${domainCF}/api/calibrateBloodPressure`;

  const response = await axios.post(POST_BPCALIBRATION_VALUES, bpCalValues, { headers });
    console.log("THIS IS Add Bp Cal res...", response.data);
    return response.data;
}
