import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { getUserProfile, getSelectedProjectIdAndLocationId } from "../../../../Reducer/selectors/DashboardSelector";
import NAHeader from "../Header/NAHeader";
import HubIcon from '@mui/icons-material/Hub';
import Grid from "@material-ui/core/Grid";
import Modal from "@material-ui/core/Modal";
import styles from "./ChooseDevices.module.css";
import { setProjectIdAndLocationId, getMouthLabLogs, setSelectedPageName, setShowCMDrawer, setShowMEDrawer, setShowQMDrawer, setIsCalibrationOngoing, setPCCPatientListPageNumber, setPCCPatientListPageSize } from "../../../../Actions";
import IconButton from "@material-ui/core/IconButton";
import { RiCloseCircleFill } from "react-icons/ri";
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import MLImg from '../../../../Assets/Images/Aidar-Hand-Device-Sketch-01.png';
import { setSelectedMlId } from "../../../../Actions";
import PostUnassignMouthLabFromSubject from "../../../API/PostUnassignMouthLabFromSubject/PostUnassignMouthLabFromSubject";
import { CircularProgress } from "@material-ui/core";
import { setPatientListLoader, setSelectedFloorFilters, setSelectedUnitFilters, setShowChooseMlLoader } from "../../../../Actions/UtilActions";
import { getChooseMlLoader } from "../../../../Reducer/selectors/UtilSelector";
import TouchAppIcon from '@mui/icons-material/TouchApp';

function ChooseDevices({ mouthLabLogs }) {

    const profile = useSelector(getUserProfile);
    const [mouthLabPatientLogs, setMouthLabPatientLogs] = useState([]);
    const dispatch = useDispatch();
    //const mouthLabLogs = useSelector(getMouthLabLogselector);
    const [showDialog, setShowDialog] = useState(false);
    const [curSubjectId, setCurSubjectId] = useState();
    const [curFirstName, setCurFirstName] = useState();
    const [curLastName, setCurLastName] = useState();
    const [curDeviceId, setCurDeviceId] = useState();
    const [showModalUA, setShowModalUA] = useState(false);
    const [showModal, setShowModal] = useState();
    const [showModalWOML, setShowModalWOML] = useState();
    const [formState, setFormState] = useState(true);
    const [selectedTab, setSelectedTab] = useState(0);
    const [selectedML, setSelectedML] = useState("");
    const navigate = useNavigate();

    const [showUALoader, setShowUALoader] = useState(false);
    const [uaMsg, setUaMsg] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);

    useEffect(() => {
        dispatch(setShowChooseMlLoader(true));
        if (profile.projects && profile.projects.length > 0) {
            dispatch(
                setProjectIdAndLocationId({
                    projectId: profile.projects[0].id,
                    locationId: profile.locations[0].id,
                }));
            dispatch(getMouthLabLogs({ projectId: profile.projects[0].id, locationId: profile.locations[0].id }));
        }
        dispatch(setSelectedPageName('logs'));
    }, [profile]);

    useEffect(() => {
        if (selectedTab === 1) {
            let updateList = mouthLabLogs.filter(log => log.subjectId ? true : false);
            setMouthLabPatientLogs(updateList);
        } else {
            let updateList = mouthLabLogs.filter(log => log.subjectId ? false : true);
            setMouthLabPatientLogs(updateList);
        }
    }, [selectedTab, mouthLabLogs]);

    const openMenu = (subjectId, deviceId) => {
        setSelectedML(deviceId);
        setShowModal(true);
        setCurSubjectId(subjectId);
        setCurDeviceId(deviceId);
    };

    const openWOMLMenu = (event) => {
        event.preventDefault();
        setSelectedML('--');
        setShowModalWOML(true);
    };

    const openMenuUA = (subjectId, deviceId, firstName, lastName) => {
        setShowModalUA(true);
        setCurSubjectId(subjectId);
        setCurFirstName(firstName);
        setCurLastName(lastName)
        setCurDeviceId(deviceId);
    };

    const closeMenu = () => {
        setShowModal(false);
        changeState();
    };

    const closeMenuWOML = () => {
        setShowModalWOML(false);
        changeState();
    };

    const closeMenuUA = () => {
        setShowModalUA(false);
        changeState();
    };

    const changeState = () => {
        setFormState(!formState);
    };

    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const pairTheMl = () => {
        console.log("THE ML SELECTED: ", selectedML);
        dispatch(setPatientListLoader(true));
        dispatch(setSelectedMlId(selectedML));
        dispatch(setSelectedFloorFilters([]));
        dispatch(setSelectedUnitFilters([]));
        dispatch(setPCCPatientListPageNumber(1));
        dispatch(setPCCPatientListPageSize("20"));
        navigate(`/subjects/` + profile.projects[0].id + `/` + profile.locations[0].id);
        dispatch(setSelectedPageName('patientslist'));
        dispatch(setShowCMDrawer(false));
        dispatch(setShowMEDrawer(false));
        dispatch(setShowQMDrawer(false));
        dispatch(setIsCalibrationOngoing(false));
    };

    const pairWOTheMl = () => {
        console.log("skipping ML selection");
        dispatch(setPatientListLoader(true));
        dispatch(setSelectedMlId('--'));
        dispatch(setSelectedFloorFilters([]));
        dispatch(setSelectedUnitFilters([]));
        dispatch(setPCCPatientListPageNumber(1));
        dispatch(setPCCPatientListPageSize("20"));
        navigate(`/subjects/` + profile.projects[0].id + `/` + profile.locations[0].id);
        dispatch(setSelectedPageName('patientslist'));
        dispatch(setShowCMDrawer(false));
        dispatch(setShowMEDrawer(false));
        dispatch(setShowQMDrawer(false));
        dispatch(setIsCalibrationOngoing(false));
    };

    const sleep = ms => new Promise(r => setTimeout(r, ms));

    const unassignTheMl = async () => {
        setIsProcessing(true);
        setShowUALoader(true);
        await sleep(1000);
        PostUnassignMouthLabFromSubject({ deviceId: curDeviceId }).then((res) => {
        });
        await sleep(1000);
        setUaMsg('The device was successfully unassigned!');
        setShowUALoader(false);
        await sleep(2000);
        dispatch(getMouthLabLogs({ projectId: profile.projects[0].id, locationId: profile.locations[0].id }));
        setIsProcessing(false);
        setShowModalUA(false);
    };

    const devicelogs1 = mouthLabPatientLogs.map((logs) => (
        <div onClick={selectedTab === 0 ? () => { openMenu(logs.subjectId, logs.deviceId); } : () => { openMenuUA(logs.subjectId, logs.deviceId, logs.firstName, logs.lastName); }}>
            <Grid item xs={12}>
                <div className={selectedTab === 0 ? styles.cardHighRiskA : styles.cardHighRiskUA}>
                    <div className={styles.iconDivStyles}>
                        {selectedTab === 0 ? <CheckCircleIcon className={styles.highRiskIconA} size={30} /> : <CancelIcon className={styles.highRiskIconUA} size={30} />}
                    </div>
                    <div className={styles.countDivStyles}>
                        <div className={selectedTab === 0 ? styles.patientCountHighRiskA : styles.patientCountHighRiskUA}>{"ML - " + logs.deviceId}</div>
                    </div>
                </div>
            </Grid>
        </div>
    ));


    const AntTab = withStyles((theme) => ({
        root: {
            textTransform: "none",
            paddingTop: `10px`,
            color: "#4E253A",
            background: `#e9ecef`,
            minWidth: "50%",
            borderRight: "0px solid #cccaca",
            fontSize: `16px`,
            fontFamily: ["GTWalsheimProRegular"].join(","),
            fontWeight: theme.typography.fontWeightBold,
            color: "#4E253A",
            backgroundColor: "#FFE0E7",
            "&:last-child": {
                borderRight: `none`,
            },
            "&:hover": {
                color: "#4E253A",
                //opacity: 1,
            },
            "&:focus": {
                color: "#4E253A",
            },
            //selected tab styles
            "&$selected": {
                color: "#fff",
                backgroundColor: "#4E253A",
                fontWeight: theme.typography.fontWeightBold,
            },
            "&$not-selected": {
                color: "#4E253A",
                backgroundColor: "#FFE0E7",
                fontWeight: theme.typography.fontWeightBold,
            },
        },
        selected: {},
    }))((props) => <Tab disableRipple {...props} />);

    const AntTabs = withStyles({
        root: {
            boxShadow: "0px 1px 5px 0px #cccaca",
            alignItems: "right",
            marginRight: `40px`,
            marginLeft: `10%`,
            marginTop: `20px`,
            borderTopRightRadius: `10px`,
            borderTopLeftRadius: `10px`,
            backgroundColor: `#E3E3E4`,
            width: "80%",
        },
        indicator: {
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            backgroundColor: "transparent",
            "& > span": {
                maxWidth: "100%",
                width: "100%",
                backgroundColor: "#4E253A",
            },
        },
    })((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

    const chooseMlLoader = useSelector(getChooseMlLoader);

    if (chooseMlLoader) {
        return (<div className={styles.circularProgress}><CircularProgress color="#4E253A" thickness={5.0} /></div>);
    } else {
        return (
            <>
                <NAHeader userEmail={profile.email} />
                <div>
                    <Grid container spacing={2} className={styles.mlLogWrapper}>
                        <div className={styles.MLInstructions}>
                            <div style={{ fontSize: '21px', fontWeight: 'bold', color: "#4E253A" }}>Please choose from available devices for this session.</div>
                            <div style={{ fontSize: '13px', color: '#4E253A', fontStyle: 'italic', fontWeight: '500' }}>Note: Once selected please avoid changing the device between sessions.</div>
                        </div>

                        <AntTabs value={selectedTab} onChange={handleChange} aria-label="ant example" variant="fullWidth">
                            <AntTab label="Available" />
                            <AntTab label="Unavailable" />
                        </AntTabs>

                        <div className={styles.TableWrapper}>
                            <Grid container className={styles.TableWrapperGrid} spacing={2} style={{ minHeight: '40vh', maxHeight: '40vh', overflowY: 'scroll', padding: "20px" }}>
                                {devicelogs1}
                            </Grid>
                        </div>
                    </Grid>


                    <div className={styles.footerDiv}>
                        {/* <div className={styles.skipMLSelectionDiv} onClick={openWOMLMenu}>
                            Proceed Manually
                        </div> */}
                        <div className={styles.enterManuallyButton} onClick={(event) => openWOMLMenu(event)}>
                            <div className={styles.touchIconWrapper}><TouchAppIcon style={{ height: '30px !important', width: '30px !important', color: "#4E253A" }}></TouchAppIcon></div>
                            <div className={styles.manualLabel}>Proceed Manually</div>
                        </div>
                    </div>


                    {/* MouthLab Assignment */}
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className={styles.modal}
                        open={showModal}
                        onClose={closeMenu}
                    >
                        <div className={styles.paper}>
                            <div className={styles.menuTitle}> MouthLab Selection </div>
                            <div className={styles.deviceSelectionWarning}>
                                <h4 style={{ paddingLeft: '15px', color: '#4E253A', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', }}>Are you sure you would like to select <h2 className={styles.selectedML}>{"ML " + selectedML}</h2>?</h4>
                            </div>
                            <div className={styles.mouthLabImgDiv}>
                                <img src={MLImg} loading="lazy" className={styles.mlImg} />
                            </div>

                            <div className={styles.buttonContainer}>
                                <button onClick={closeMenu} className={styles.cancelButton}>
                                    Cancel
                                </button>
                                <button onClick={pairTheMl} className={styles.submitButton}>
                                    Confirm
                                </button>
                            </div>
                        </div>
                    </Modal>


                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className={styles.modal}
                        open={showModalWOML}
                        onClose={closeMenuWOML}
                    >
                        <div className={styles.paper}>
                            <div className={styles.menuTitle}> Proceed Manually </div>
                            <div className={styles.deviceSelectionWarning}>
                                <h4 style={{ paddingLeft: '15px', paddingRight: '5px', color: '#4E253A', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', }}>Are you sure you would like to proceed without selecting ML?</h4>
                            </div>
                            <div className={styles.mouthLabImgDiv}>
                                <img src={MLImg} loading="lazy" className={styles.mlImg} />
                            </div>

                            <div className={styles.buttonContainer}>
                                <button onClick={closeMenuWOML} className={styles.cancelButton}>
                                    Cancel
                                </button>
                                <button onClick={pairWOTheMl} className={styles.submitButton}>
                                    Confirm
                                </button>
                            </div>
                        </div>
                    </Modal>



                    {/* MouthLab Unassignment */}
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className={styles.modal}
                        open={showModalUA}
                        onClose={closeMenuUA}
                    >
                        <div className={styles.paper}>
                            <div className={styles.menuTitle}> Unassign MouthLab </div>

                            <div className={styles.deviceSelectionWarning1}>
                                <h4 style={{ paddingLeft: '15px', color: '#7C596A', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', }}>Are you sure you would like to unassign {"ML " + curDeviceId} From {curFirstName} {curLastName}?</h4>
                            </div>

                            <div className={styles.mouthLabImgDiv1}>
                                <img src={MLImg} loading="lazy" className={styles.mlImg} />
                            </div>

                            {isProcessing && (showUALoader ? (<>
                                <div className={styles.circularProgress}>
                                    <CircularProgress thickness={5.0} />
                                </div>
                            </>) : (<div className={styles.uaSuccessMsg}>{uaMsg}</div>))}

                            <div className={styles.buttonContainer1}>
                                <button onClick={closeMenuUA} className={styles.cancelButton}>
                                    Cancel
                                </button>
                                <button onClick={unassignTheMl} className={styles.submitButton}>
                                    Confirm
                                </button>
                            </div>
                        </div>
                    </Modal>

                </div>
            </>
        );
    }
} export default ChooseDevices;
