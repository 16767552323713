export function getPageLoader({ utilReducer }) {
    if (
        utilReducer &&
        utilReducer.loader
    )
        return utilReducer.loader;
    else return false;
}

export function getCurrentPatientGUID({ utilReducer }) {
    if (
        utilReducer &&
        utilReducer.currPatientGUID
    )
        return utilReducer.currPatientGUID;
    else return "";
}

export function getCurrentPatientSubjectID({ utilReducer }) {
    if (
        utilReducer &&
        utilReducer.currPatientSubjectID
    )
        return utilReducer.currPatientSubjectID;
    else return "";
}

export function getAllPatientsList({ utilReducer }) {
    if (
        utilReducer &&
        utilReducer.allPatientsList
    )
        return utilReducer.allPatientsList;
    else return [];
}

export function getPatientProfilePageLoader({ utilReducer }) {
    if (
        utilReducer &&
        utilReducer.patientProfilePageLoader
    )
        return utilReducer.patientProfilePageLoader;
    else return false;
}

export function getpatientProfilePageLoaderAB({ utilReducer }) {
    if (
        utilReducer &&
        utilReducer.patientProfilePageLoaderAB
    )
        return utilReducer.patientProfilePageLoaderAB;
    else return false;
}

export function getPatientTimerDrawerLoader({ utilReducer }) {
    if (
        utilReducer &&
        utilReducer.patientTimerDrawerLoader
    )
        return utilReducer.patientTimerDrawerLoader;
    else return false;
}

export function getPatientGraphsLoader({ utilReducer }) {
    if (
        utilReducer &&
        utilReducer.patientGraphsLoader
    )
        return utilReducer.patientGraphsLoader;
    else return false;
}

export function getPatientAidiLoader({ utilReducer }) {
    if (
        utilReducer &&
        utilReducer.patientAidiLoader
    )
        return utilReducer.patientAidiLoader;
    else return false;
}

export function getAidiValidationLoader({ utilReducer }) {
    if (
        utilReducer &&
        utilReducer.aidiValidationLoader
    )
        return utilReducer.aidiValidationLoader;
    else return false;
}

export function getPatientVitalsLoader({ utilReducer }) {
    if (
        utilReducer &&
        utilReducer.patientVitalsLoader
    )
        return utilReducer.patientVitalsLoader;
    else return false;
}

export function getPatientNotesLoader({ utilReducer }) {
    if (
        utilReducer &&
        utilReducer.patientNotesLoader
    )
        return utilReducer.patientNotesLoader;
    else return false;
}

export function getAssignMlLoader({ utilReducer }) {
    if (
        utilReducer &&
        utilReducer.assignMlLoader
    )
        return utilReducer.assignMlLoader;
    else return false;
}

export function getChooseMlLoader({ utilReducer }) {
    if (
        utilReducer &&
        utilReducer.chooseMlLoader
    )
        return utilReducer.chooseMlLoader;
    else return false;
}

export function getPatientMainTab({ utilReducer }) {
    if (
        utilReducer &&
        utilReducer.patientMainTab
    )
        return utilReducer.patientMainTab;
    else return 0;
}

export function getSpo2BrExpand({ utilReducer }) {
    if (
        utilReducer &&
        utilReducer.spo2BrExpand
    )
        return utilReducer.spo2BrExpand;
    else return false;
}

export function getHrHrvExpand({ utilReducer }) {
    if (
        utilReducer &&
        utilReducer.hrHrvExpand
    )
        return utilReducer.hrHrvExpand;
    else return false;
}

export function getBpExpand({ utilReducer }) {
    if (
        utilReducer &&
        utilReducer.bpExpand
    )
        return utilReducer.bpExpand;
    else return false;
}

export function getTempPrExpand({ utilReducer }) {
    if (
        utilReducer &&
        utilReducer.tempPrExpand
    )
        return utilReducer.tempPrExpand;
    else return false;
}

export function getFevPefExpand({ utilReducer }) {
    if (
        utilReducer &&
        utilReducer.fevPefExpand
    )
        return utilReducer.fevPefExpand;
    else return false;
}

export function getGWPExpand({ utilReducer }) {
    if (
        utilReducer &&
        utilReducer.gwpExpand
    )
        return utilReducer.gwpExpand;
    else return false;
}

export function getPatientListLoader({ utilReducer }) {
    if (
        utilReducer &&
        utilReducer.patientListLoader
    )
        return utilReducer.patientListLoader;
    else return false;
}

export function getSelectedFloorFilters({ utilReducer }) {
    if (
        utilReducer &&
        utilReducer.selectedFloorFilters
    )
        return utilReducer.selectedFloorFilters;
    else return [];
}

export function getSelectedUnitFilters({ utilReducer }) {
    if (
        utilReducer &&
        utilReducer.selectedUnitFilters
    )
        return utilReducer.selectedUnitFilters;
    else return [];
}