import React from "react";
import Grid from "@material-ui/core/Grid";
import styles from "./TableHeaderVitals.module.css";
import { useSelector } from "react-redux";
import { getBaselineSelector } from "../../../../../../Reducer/selectors/PatientSelector.js";
import VitalsTable from "./VitalsTable";
import { styled } from '@mui/material/styles';
import { tooltipClasses } from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from "@material-ui/core/Tooltip";

export default function TableHeaderVitals(props) {
  console.log("TableHeaderVitals");

  const baseline = useSelector(getBaselineSelector);
  //console.log("baseline: ", baseline);

  // const HtmlTooltip = styled(({ className, ...props }) => (
  //   <Tooltip {...props} classes={{ popper: className }} />
  // ))(({ theme }) => ({
  //   [`& .${tooltipClasses.tooltip}`]: {
  //     backgroundColor: '#f5f5f9',
  //     color: 'rgba(0, 0, 0, 0.87)',
  //     maxWidth: 220,
  //     fontSize: theme.typography.pxToRem(12),
  //     border: '1px solid #dadde9',
  //   },
  // }));

  const vitalsLength = props.patientVitals.length;

  const patientVitalsTable = props.patientVitals.map((allVitals) => {

    const tempRisk = ((baseline && baseline.tempHigh && (allVitals.temperature.value > baseline.tempHigh)) ||
      (baseline && baseline.tempLow && (allVitals.temperature.value < baseline.tempLow))) ? 1 : 0;
    const spo2Risk = ((baseline && baseline.spo2High && (allVitals.spo2.value > baseline.spo2High)) ||
      (baseline && baseline.spo2Low && (allVitals.spo2.value < baseline.spo2Low))) ? 1 : 0;
    const breathingRateRisk = ((baseline && baseline.breathingRateHigh && (allVitals.breathingRate.value > baseline.breathingRateHigh)) ||
      (baseline && baseline.breathingRateLow && (allVitals.breathingRate.value < baseline.breathingRateLow))) ? 1 : 0;
    const pulseRateRisk = ((baseline && baseline.pulseRateHigh && (allVitals.pulseRate.value > baseline.pulseRateHigh)) ||
      (baseline && baseline.pulseRateLow && (allVitals.pulseRate.value < baseline.pulseRateLow))) ? 1 : 0;
    const heartRateRisk = ((baseline && baseline.heartRateHigh && (allVitals.heartRate.value > baseline.heartRateHigh)) ||
      (baseline && baseline.heartRateLow && (allVitals.heartRate.value < baseline.heartRateLow))) ? 1 : 0;
    const hrvRisk = ((baseline && baseline.hrvHigh && (allVitals.hrv.value > baseline.hrvHigh)) ||
      (baseline && baseline.hrvLow && (allVitals.hrv.value < baseline.hrvLow))) ? 1 : 0;
    const sbpRisk = ((baseline && baseline.sbpHigh && (allVitals.sbp.value > baseline.sbpHigh)) ||
      (baseline && baseline.sbpLow && (allVitals.sbp.value < baseline.sbpLow))) ? 1 : 0;
    const dbpRisk = ((baseline && baseline.dbpHigh && (allVitals.dbp.value > baseline.dbpHigh)) ||
      (baseline && baseline.dbpLow && (allVitals.dbp.value < baseline.dbpLow))) ? 1 : 0;
    // const fvcRisk = ((baseline.fvcHigh && (allVitals.fvc.value > baseline.fvcHigh)) || 
    // (baseline.fvcLow && (allVitals.fvc.value < baseline.fvcLow))) ? 1 : 0;
    const fev1Risk = ((baseline && baseline.fev1High && (allVitals.fev1.value > baseline.fev1High)) ||
      (baseline && baseline.fev1Low && (allVitals.fev1.value < baseline.fev1Low))) ? 1 : 0;
    const pefRisk = ((baseline && baseline.pefHigh && (allVitals.pef.value > baseline.pefHigh)) ||
      (baseline && baseline.pefLow && (allVitals.pef.value < baseline.pefLow))) ? 1 : 0;

    return (<VitalsTable
      key={allVitals.recordId}
      recordId={allVitals.recordId}
      mlId={allVitals.deviceId}
      timestamp={allVitals.timestamp}
      temp={allVitals.temperature.value}
      spo2={allVitals.spo2.value}
      breathingRate={allVitals.breathingRate.value}
      pulseRate={allVitals.pulseRate.value}
      heartRate={allVitals.heartRate.value}
      hrv={allVitals.hrv.value}
      sbp={allVitals.sbp.value}
      dbp={allVitals.dbp.value}
      //   fvc={allVitals.fvc.value}
      fev1={allVitals.fev1.value}
      pef={allVitals.pef.value}
      tempRisk={tempRisk}
      spo2Risk={spo2Risk}
      breathingRateRisk={breathingRateRisk}
      pulseRateRisk={pulseRateRisk}
      heartRateRisk={heartRateRisk}
      hrvRisk={hrvRisk}
      sbpRisk={sbpRisk}
      dbpRisk={dbpRisk}
      //   fvcRisk={fvcRisk}
      fev1Risk={fev1Risk}
      pefRisk={pefRisk}
      hasNotes={allVitals.hasNotes}
    />
    )
  });

  return (
    <div>
      <Grid container>
        <Grid item xs={12} xl={12} lg={12} md={10} sm={12}>
          <div className={styles.responsive}>
            <table className={styles.cardStyle}>
              <thead>
                <tr className={styles.header}>
                  {/* <th className={styles.timeStamp}>Date</th> */}
                  <th className={styles.timeStamp}>Time</th>
                  <th className={styles.mouthLabId}>ID</th>
                  <th className={styles.temperature}>
                    Temp
                    <br />
                    (F)
                  </th>
                  <th className={styles.bp}>
                    BP
                    <br />
                    (mmHg)<span style={{ color: 'red' }}>*</span>
                  </th>
                  <th className={styles.pr}>
                    PR
                    <br />
                    (bpm)
                  </th>
                  <th className={styles.spo2}>
                    SpO2
                    <br />
                    (%)
                  </th>
                  <th className={styles.hr}>
                    HR
                    <br />
                    (bpm)
                  </th>
                  <th className={styles.hrv}>
                    HRV
                    <br />
                    (ms)
                  </th>
                  <th className={styles.rr}>
                    RR
                    <br />
                    (br/min)
                  </th>
                  <th className={styles.fev1}>
                    FEV1
                    <br />
                    (L)
                  </th>
                  <th className={styles.pef}>
                    PEF
                    <br />
                    (L/sec)
                  </th>
                  <th className={styles.notes}>Notes</th>
                  {/* Currently it is not being calculated and hence need not be shown.
                  <th className={styles.fvc}>FVC (L)</th>
                  <th className={styles.fev1fvc}>FEV1/FVC</th>
 */}
                </tr>
              </thead>
            </table>
            {vitalsLength > 0 ? (
              <Grid item xs={12} xl={12} lg={12} md={10} sm={12} style={{ maxHeight: "55vh", overflowY: "scroll" }}>
                {patientVitalsTable}
              </Grid>
            ) : (
              <div className={styles.noVitals}>No data available</div>
            )}
          </div>
        </Grid>
      </Grid>
      <div className={styles.bpTitle}>
            <span style={{ color: 'red' }}>*</span> BP is for investigational use only.
          </div>
    </div>
  );
}
