import axios from "axios";
import domainCF from "../domainCF.js";

export default function GetPatientStatus(patientGuid) {
  var token = sessionStorage.getItem("IdToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const GET_PATIENT_STATUS = `${domainCF}/api/getPatientStatus?patientGuid=${patientGuid}`;

  return axios.get(GET_PATIENT_STATUS, { headers }).then(function (response) {
    console.log("THIS IS Patient Status", response.data.patientStatus);
    return response.data.patientStatus;
  });
}
