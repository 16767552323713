import { put, takeLatest } from "redux-saga/effects";
import * as patientActions from "../Actions/PatientActions";
import * as dashboardActions from "../Actions/DashboardActions";
// import axios from "axios";
import Transport from "../Transport/AidarTransport";
import { setShowChooseMlLoader, setShowLoader, setShowPatientGraphsLoader, setShowPatientVitalsLoader } from "../Actions/UtilActions";
import domainCF from "../Components/API/domainCF";
import { dashboardConstants } from "../Constants";
import { useSelector } from "react-redux";
import { getTimerId } from "../Reducer/selectors/DashboardSelector";
import { getTimerInfo } from "../Actions";

function* getPatientListSaga(action) {
  var token = sessionStorage.getItem("IdToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const GET_SUBJECTS_BY_PROJECT_AND_LOCATION = `${domainCF}/api/getSubjectsByProjectAndLocation?projectId=${action.payload.projectId}&locationId=${action.payload.locationId}`;

  try {
    // const response = yield axios.post(POST_USER_INFO_FROM_EMAIL, action.payload, { headers });
    const response = yield Transport.genericOperation(GET_SUBJECTS_BY_PROJECT_AND_LOCATION, headers, {}, "GET");
    console.log(" GET AIDAR PATIENTS RESPONSE: ", response);
    if (!response) {
      yield put(patientActions.getPatientsListFailure());
    } else {
      yield put(patientActions.getPatientsListSuccess(response));
    }

    yield put(setShowLoader(false));
  } catch (e) {
    yield put(setShowLoader(false));
    console.error("getChildInfoSaga Saga ERROR ", e);
    throw e;
  }
}

function* getPatientVitalsSaga(action) {
  var token = sessionStorage.getItem("IdToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const GET_LATEST_VITALS = `${domainCF}/api/getLatestVitals?patientGuid=${action.payload}`;

  try {
    // const response = yield axios.post(POST_USER_INFO_FROM_EMAIL, action.payload, { headers });
    const response = yield Transport.genericOperation(GET_LATEST_VITALS, headers, {}, "GET");
    console.log("getPatientVitalsList response: ", response.vitals);
    if (!response) {
      yield put(patientActions.getPatientsVitalsFailure());
    } else {
      yield put(patientActions.getPatientsVitalsSuccess(response.vitals));
    }

    yield put(setShowLoader(false));
  } catch (e) {
    yield put(setShowLoader(false));
    console.error("getChildInfoSaga Saga ERROR ", e);
    throw e;
  }
}

function* getSubjectVitalsSaga(action) {
  var token = sessionStorage.getItem("IdToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  //console.log('6...',action.payload)
  const GET_SUBJECT_VITALS = `${domainCF}/api/getAllSubjectVitalsFast?patientGuid=${action.payload.subjectId}&startDate=${action.payload.startDate}&endDate=${action.payload.endDate}`;
  try {
    // const response = yield axios.post(POST_USER_INFO_FROM_EMAIL, action.payload, { headers });
    const response = yield Transport.genericOperation(GET_SUBJECT_VITALS, headers, {}, "GET");

    if (!response) {
      yield put(patientActions.getSubectVitalsListFailure());
    } else {
      yield put(patientActions.getSubjectVitalsListSuccess(response));
    }

    yield put(setShowLoader(false));
    yield put(setShowPatientVitalsLoader(false));
  } catch (e) {
    yield put(setShowLoader(false));
    yield put(setShowPatientVitalsLoader(false));
    console.error("getSubjectVitalsSaga ERROR ", e);
    throw e;
  }
}

function* getEcgPlotSaga(action) {
  var token = sessionStorage.getItem("IdToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const GET_SUBJECT_ECG_PLOT = `${domainCF}/api/getECGPlots?patientGuid=${action.payload.subjectGUID}&startDate=${action.payload.startDate}&endDate=${action.payload.endDate}`;

  try {
    const response = yield Transport.genericOperation(GET_SUBJECT_ECG_PLOT, headers, {}, "GET");

    if (!response) {
      yield put(patientActions.getEcgPlotFailure());
    } else {
      console.log("ECG Plot response ", response);
      yield put(patientActions.getEcgPlotSuccess(response));
    }
    yield put(setShowPatientGraphsLoader(false));
  } catch (e) {
    yield put(setShowPatientGraphsLoader(false));
    console.error("getEcgPlotSaga ERROR ", e);
    throw e;
  }
}

function* getRfmPlotSaga(action) {
  var token = sessionStorage.getItem("IdToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const GET_SUBJECT_RFM_PLOT = `${domainCF}/api/getRFMPlots?patientGuid=${action.payload.subjectGUID}&startDate=${action.payload.startDate}&endDate=${action.payload.endDate}`;

  try {
    const response = yield Transport.genericOperation(GET_SUBJECT_RFM_PLOT, headers, {}, "GET");

    if (!response) {
      yield put(patientActions.getRfmPlotFailure());
    } else {
      console.log("RFM Plot response ", response);
      yield put(patientActions.getRfmPlotSuccess(response));
    }
    yield put(setShowPatientGraphsLoader(false));
  } catch (e) {
    yield put(setShowPatientGraphsLoader(false));
    console.error("getRfmPlotSaga ERROR ", e);
    throw e;
  }
}

function* getLfgPlotSaga(action) {
  var token = sessionStorage.getItem("IdToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const GET_SUBJECT_LFG_PLOT = `${domainCF}/api/getLFPlots?patientGuid=${action.payload.subjectGUID}&startDate=${action.payload.startDate}&endDate=${action.payload.endDate}`;

  try {
    const response = yield Transport.genericOperation(GET_SUBJECT_LFG_PLOT, headers, {}, "GET");

    if (!response) {
      yield put(patientActions.getLfgPlotFailure());
    } else {
      console.log("LFG Plot response ", response);
      yield put(patientActions.getLfgPlotSuccess(response));
    }
    yield put(setShowPatientGraphsLoader(false));
  } catch (e) {
    yield put(setShowPatientGraphsLoader(false));
    console.error("getLfgPlotSaga ERROR ", e);
    throw e;
  }
}

function* getMouthLabLogsSaga(action) {
  var token = sessionStorage.getItem("IdToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const GET_MOUTHLAB_LOGS = `${domainCF}/api/getDevicesByProjectAndLocation?projectId=${action.payload.projectId}&locationId=${action.payload.locationId}`;
  try {
    const response = yield Transport.genericOperation(GET_MOUTHLAB_LOGS, headers, {}, "GET");

    if (!response) {
      yield put(patientActions.getEcgPlotFailure());
    } else {
      console.log("MouthLab Logs response ", response);
      yield put(patientActions.getMouthLabLogSuccess(response));
    }
  } catch (e) {
    yield put(setShowLoader(false));
    console.error("getChildInfoSaga Saga ERROR ", e);
    throw e;
  }
  yield put(setShowChooseMlLoader(false));
}

function* getAddSubjectSaga(action) {
  var token = sessionStorage.getItem("IdToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const ADD_SUBJECT_URL = `${domainCF}/api/addSubject`;

  try {
    const response = yield Transport.genericOperation(ADD_SUBJECT_URL, headers, action.payload, "POST");

    if (!response) {
      yield put(patientActions.getAddSubjectFailure());
    } else {
      console.log("Add Subject response ", response);
      yield put(patientActions.getAddSubjectSuccess(response));
    }
  } catch (e) {
    yield put(setShowLoader(false));
    console.error("getChildInfoSaga Saga ERROR ", e);
    throw e;
  }
}

function* putStartTimerSaga(action) {
  var token = sessionStorage.getItem("IdToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const ADD_TIMER_URL = `${domainCF}/api/addTimer`;

  try {
    const response = yield Transport.genericOperation(ADD_TIMER_URL, headers, action.payload, "POST");

    if (!response) {
      yield put(patientActions.putStartTimerFailure());
    } else {
      console.log("Add Timer response ", response);
      yield put(patientActions.putStartTimerSuccess(response));
      yield put(dashboardActions.setTimerID(response.timerId));
    }
  } catch (e) {
    console.error("putStartTimerSaga Saga ERROR ", e);
    throw e;
  }
}

function* putEndTimerSaga(action) {
  var token = sessionStorage.getItem("IdToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const END_TIMER_URL = `${domainCF}/api/updateTimerWithNote/`;

  const new_payload = {
    "timerId": action.payload.timerId,
    "subjectId": action.payload.subjectId,
    "userId": action.payload.userId,
    "end_time": action.payload.end_time,
    "duration": action.payload.duration,
    "status": action.payload.status,
    "note": action.payload.note,
    "tags": action.payload.tags,
    "createdBy": action.payload.createdBy,
    "createdDateTime": action.payload.createdDateTime,
    "timezoneStr": action.payload.timezoneStr,
  }

  try {
    const response = yield Transport.genericOperation(END_TIMER_URL, headers, new_payload, "POST");

    if (!response) {
      yield put(patientActions.putEndTimerFailure());
    } else {
      console.log("End Timer response ", response);
      yield put(patientActions.putEndTimerSuccess(response));
      yield put(getTimerInfo({ userId: action.payload.userId, subjectGuid: action.payload.subjectGuid }));
    }
  } catch (e) {
    console.error("putEndTimerSaga Saga ERROR ", e);
    throw e;
  }
}

function* putAbortedEndTimerSaga(action) {
  var token = sessionStorage.getItem("IdToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const END_TIMER_URL = `${domainCF}/api/updateTimer/`;

  const new_payload = {
    "timerId": action.payload.timerId,
    "subjectId": action.payload.subjectId,
    "userId": action.payload.userId,
    "end_time": action.payload.end_time,
    "duration": action.payload.duration,
    "status": action.payload.status,
  }

  try {
    const response = yield Transport.genericOperation(END_TIMER_URL, headers, new_payload, "POST");

    if (!response) {
      yield put(patientActions.putAbortedEndTimerFailure());
    } else {
      console.log("End Timer response ", response);
      yield put(patientActions.putAbortedEndTimerSuccess(response));
      //    yield put(getTimerInfo({userId: action.payload.userId,subjectGuid: action.payload.subjectGuid}));
    }
  } catch (e) {
    console.error("putAbortedEndTimerSaga Saga ERROR ", e);
    throw e;
  }
}

function* getLatestBaselineSaga(action) {
  var token = sessionStorage.getItem("IdToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  //console.log('baseline...',action.payload)
  const GET_LATEST_BASELINE = `${domainCF}/api/getLatestBaseline?subjectGuid=${action.payload.subjectGuid}`;
  try {
    const response = yield Transport.genericOperation(GET_LATEST_BASELINE, headers, {}, "GET");

    if (!response) {
      yield put(patientActions.getLatestBaselineFailure());
    } else {
      //console.log("Baseline response ", response);
      yield put(patientActions.getLatestBaselineSuccess(response));
    }

  } catch (e) {
    console.error("getChildInfoSaga Saga ERROR ", e);
    throw e;
  }

}

function* getSbpPayloadSaga(action) {
  var token = sessionStorage.getItem("IdToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const GET_SBP_PAYLOAD_URL = `${domainCF}/getSbpPayloadBySubjectIdWithRangeArray?subjectId=${action.payload.subjectId}&startDate=${action.payload.startDate}&endDate=${action.payload.endDate}`;

  try {
    const response = yield Transport.genericOperation(GET_SBP_PAYLOAD_URL, headers, {}, "GET");
    console.log("getSbpPayload response: ", response);
    if (!response) {
      yield put(patientActions.getSbpPayloadFailure());
    } else {
      yield put(patientActions.getSbpPayloadSuccess(response.trends8Marrays));
    }
  } catch (e) {
    console.error("getSbpPayloadSaga Saga ERROR ", e);
    throw e;
  }
}


function* getDbpPayloadSaga(action) {
  var token = sessionStorage.getItem("IdToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const GET_DBP_PAYLOAD_URL = `${domainCF}/getDbpPayloadBySubjectIdWithRangeArray?subjectId=${action.payload.subjectId}&startDate=${action.payload.startDate}&endDate=${action.payload.endDate}`;

  try {
    const response = yield Transport.genericOperation(GET_DBP_PAYLOAD_URL, headers, {}, "GET");
    console.log("getDbpPayload response: ", response);
    if (!response) {
      yield put(patientActions.getDbpPayloadFailure());
    } else {
      yield put(patientActions.getDbpPayloadSuccess(response.trends8Marrays));
    }
  } catch (e) {
    console.error("getDbpPayloadSaga Saga ERROR ", e);
    throw e;
  }
}


function* getSpo2PayloadSaga(action) {
  var token = sessionStorage.getItem("IdToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const GET_SPO2_PAYLOAD_URL = `${domainCF}/getSpo2PayloadBySubjectIdWithRangeArray?subjectId=${action.payload.subjectId}&startDate=${action.payload.startDate}&endDate=${action.payload.endDate}`;

  try {
    const response = yield Transport.genericOperation(GET_SPO2_PAYLOAD_URL, headers, {}, "GET");
    console.log("getSpo2Payload response: ", response);
    if (!response) {
      yield put(patientActions.getSpo2PayloadFailure());
    } else {
      yield put(patientActions.getSpo2PayloadSuccess(response.trends8Marrays));
    }
  } catch (e) {
    console.error("getSpo2PayloadSaga Saga ERROR ", e);
    throw e;
  }
}


function* getBrPayloadSaga(action) {
  var token = sessionStorage.getItem("IdToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const GET_BR_PAYLOAD_URL = `${domainCF}/getBrPayloadBySubjectIdWithRangeArray?subjectId=${action.payload.subjectId}&startDate=${action.payload.startDate}&endDate=${action.payload.endDate}`;

  try {
    const response = yield Transport.genericOperation(GET_BR_PAYLOAD_URL, headers, {}, "GET");
    console.log("getBrPayload response: ", response);
    if (!response) {
      yield put(patientActions.getBrPayloadFailure());
    } else {
      yield put(patientActions.getBrPayloadSuccess(response.trends8Marrays));
    }
  } catch (e) {
    console.error("getBrPayloadSaga Saga ERROR ", e);
    throw e;
  }
}


function* getHrPayloadSaga(action) {
  var token = sessionStorage.getItem("IdToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const GET_HR_PAYLOAD_URL = `${domainCF}/getHrPayloadBySubjectIdWithRangeArray?subjectId=${action.payload.subjectId}&startDate=${action.payload.startDate}&endDate=${action.payload.endDate}`;

  try {
    const response = yield Transport.genericOperation(GET_HR_PAYLOAD_URL, headers, {}, "GET");
    console.log("getHrPayload response: ", response);
    if (!response) {
      yield put(patientActions.getHrPayloadFailure());
    } else {
      yield put(patientActions.getHrPayloadSuccess(response.trends8Marrays));
    }
  } catch (e) {
    console.error("getHrPayloadSaga Saga ERROR ", e);
    throw e;
  }
}


function* getHrvPayloadSaga(action) {
  var token = sessionStorage.getItem("IdToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const GET_HRV_PAYLOAD_URL = `${domainCF}/getHrvPayloadBySubjectIdWithRangeArray?subjectId=${action.payload.subjectId}&startDate=${action.payload.startDate}&endDate=${action.payload.endDate}`;

  try {
    const response = yield Transport.genericOperation(GET_HRV_PAYLOAD_URL, headers, {}, "GET");
    console.log("getHrvPayload response: ", response);
    if (!response) {
      yield put(patientActions.getHrvPayloadFailure());
    } else {
      yield put(patientActions.getHrvPayloadSuccess(response.trends8Marrays));
    }
  } catch (e) {
    console.error("getHrvPayloadSaga Saga ERROR ", e);
    throw e;
  }
}


function* getTempPayloadSaga(action) {
  var token = sessionStorage.getItem("IdToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const GET_TEMP_PAYLOAD_URL = `${domainCF}/getTempPayloadBySubjectIdWithRangeArray?subjectId=${action.payload.subjectId}&startDate=${action.payload.startDate}&endDate=${action.payload.endDate}`;

  try {
    const response = yield Transport.genericOperation(GET_TEMP_PAYLOAD_URL, headers, {}, "GET");
    console.log("getTempPayload response: ", response);
    if (!response) {
      yield put(patientActions.getTempPayloadFailure());
    } else {
      yield put(patientActions.getTempPayloadSuccess(response.trends8Marrays));
    }
  } catch (e) {
    console.error("getTempPayloadSaga Saga ERROR ", e);
    throw e;
  }
}


function* getPrPayloadSaga(action) {
  var token = sessionStorage.getItem("IdToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const GET_PR_PAYLOAD_URL = `${domainCF}/getPrPayloadBySubjectIdWithRangeArray?subjectId=${action.payload.subjectId}&startDate=${action.payload.startDate}&endDate=${action.payload.endDate}`;

  try {
    const response = yield Transport.genericOperation(GET_PR_PAYLOAD_URL, headers, {}, "GET");
    console.log("getPrPayload response: ", response);
    if (!response) {
      yield put(patientActions.getPrPayloadFailure());
    } else {
      yield put(patientActions.getPrPayloadSuccess(response.trends8Marrays));
    }
  } catch (e) {
    console.error("getPrPayloadSaga Saga ERROR ", e);
    throw e;
  }
}


function* getFevPayloadSaga(action) {
  var token = sessionStorage.getItem("IdToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const GET_FEV_PAYLOAD_URL = `${domainCF}/getFev1PayloadBySubjectIdWithRangeArray?subjectId=${action.payload.subjectId}&startDate=${action.payload.startDate}&endDate=${action.payload.endDate}`;

  try {
    const response = yield Transport.genericOperation(GET_FEV_PAYLOAD_URL, headers, {}, "GET");
    console.log("getFevPayload response: ", response);
    if (!response) {
      yield put(patientActions.getFevPayloadFailure());
    } else {
      yield put(patientActions.getFevPayloadSuccess(response.trends8Marrays));
    }
  } catch (e) {
    console.error("getFevPayloadSaga Saga ERROR ", e);
    throw e;
  }
}


function* getPefPayloadSaga(action) {
  var token = sessionStorage.getItem("IdToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const GET_PEF_PAYLOAD_URL = `${domainCF}/getPefPayloadBySubjectIdWithRangeArray?subjectId=${action.payload.subjectId}&startDate=${action.payload.startDate}&endDate=${action.payload.endDate}`;

  try {
    const response = yield Transport.genericOperation(GET_PEF_PAYLOAD_URL, headers, {}, "GET");
    console.log("getPefPayload response: ", response);
    if (!response) {
      yield put(patientActions.getPefPayloadFailure());
    } else {
      yield put(patientActions.getPefPayloadSuccess(response.trends8Marrays));
    }
  } catch (e) {
    console.error("getPefPayloadSaga Saga ERROR ", e);
    throw e;
  }
}


export default function* PatientSaga() {
  try {
    yield takeLatest(patientActions.getPatientsList, getPatientListSaga);
    yield takeLatest(patientActions.getPatientVitalsList, getPatientVitalsSaga);
    yield takeLatest(patientActions.getSubjectVitalsList, getSubjectVitalsSaga);
    yield takeLatest(patientActions.getEcgPlot, getEcgPlotSaga);
    yield takeLatest(patientActions.getRfmPlot, getRfmPlotSaga);
    yield takeLatest(patientActions.getLfgPlot, getLfgPlotSaga);
    yield takeLatest(patientActions.getMouthLabLogs, getMouthLabLogsSaga);
    yield takeLatest(patientActions.getAddSubject, getAddSubjectSaga);
    yield takeLatest(patientActions.putStartTimer, putStartTimerSaga);
    yield takeLatest(patientActions.putEndTimer, putEndTimerSaga);
    yield takeLatest(patientActions.putAbortedEndTimer, putAbortedEndTimerSaga);
    yield takeLatest(patientActions.getLatestBaseline, getLatestBaselineSaga);
    yield takeLatest(patientActions.getSbpPayload, getSbpPayloadSaga);
    yield takeLatest(patientActions.getDbpPayload, getDbpPayloadSaga);
    yield takeLatest(patientActions.getSpo2Payload, getSpo2PayloadSaga);
    yield takeLatest(patientActions.getBrPayload, getBrPayloadSaga);
    yield takeLatest(patientActions.getHrPayload, getHrPayloadSaga);
    yield takeLatest(patientActions.getHrvPayload, getHrvPayloadSaga);
    yield takeLatest(patientActions.getTempPayload, getTempPayloadSaga);
    yield takeLatest(patientActions.getPrPayload, getPrPayloadSaga);
    yield takeLatest(patientActions.getFevPayload, getFevPayloadSaga);
    yield takeLatest(patientActions.getPefPayload, getPefPayloadSaga);

  } catch {
    yield;
  }
}
