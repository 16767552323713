import { utilsConstants } from "../Constants/UtilConstants";

const initialState = {
    loader: false,
    patientProfilePageLoader: false,
    patientTimerDrawerLoader: false,
    patientGraphsLoader: false,
    patientAidiLoader: false,
    aidiValidationLoader: false,
    patientVitalsLoader: false,
    patientNotesLoader: false,
    assignMlLoader: false,
    chooseMlLoader: false,
    currPatientGUID: "",
    currPatientSubjectID: "",
    allPatientsList: [],
    patientMainTab: 0,
    spo2BrExpand: false,
    hrHrvExpand: false,
    bpExpand: false,
    tempPrExpand: false,
    fevPefExpand: false,
    gwpExpand: false,
    patientListLoader: false,
    patientProfilePageLoaderAB: false,
    selectedUnitFilters: [],
    selectedFloorFilters: [],
}

export function utilReducer(state = initialState, action) {

    switch (action.type) {
        case utilsConstants.SET_SHOW_LOADER:
            return {
                ...state,
                loader: action.payload
            };

        case utilsConstants.SET_CURRENT_PATIENT_GUID:
            return {
                ...state,
                currPatientGUID: action.payload
            };

        case utilsConstants.SET_CURRENT_PATIENT_SUBJECTID:
            return {
                ...state,
                currPatientSubjectID: action.payload
            };

        case utilsConstants.SET_ALL_PATIENTS_LIST:
            return {
                ...state,
                allPatientsList: action.payload
            };

        case utilsConstants.SET_SHOW_PATIENT_PROFILE_PAGE_LOADER:
            return {
                ...state,
                patientProfilePageLoader: action.payload
            };

        case utilsConstants.SET_SHOW_PATIENT_TIMER_DRAWER_LOADER:
            return {
                ...state,
                patientTimerDrawerLoader: action.payload
            };
        case utilsConstants.SET_SHOW_PATIENT_GRAPHS_LOADER:
            return {
                ...state,
                patientGraphsLoader: action.payload
            };
        case utilsConstants.SET_SHOW_PATIENT_AIDI_LOADER:
            return {
                ...state,
                patientAidiLoader: action.payload
            };
        case utilsConstants.SET_SHOW_AIDI_VALIDATION_LOADER:
            return {
                ...state,
                aidiValidationLoader: action.payload
            };
        case utilsConstants.SET_SHOW_PATIENT_VITALS_LOADER:
            return {
                ...state,
                patientVitalsLoader: action.payload
            };
        case utilsConstants.SET_SHOW_PATIENT_NOTES_LOADER:
            return {
                ...state,
                patientNotesLoader: action.payload
            };
        case utilsConstants.SET_SHOW_ASSIGN_ML_LOADER:
            return {
                ...state,
                assignMlLoader: action.payload
            };
        case utilsConstants.SET_SHOW_CHOOSE_ML_LOADER:
            return {
                ...state,
                chooseMlLoader: action.payload
            };
        case utilsConstants.SET_PATIENT_MAINTAB:
            return {
                ...state,
                patientMainTab: action.payload
            };
        case utilsConstants.SET_SPO2_BR_EXPAND:
            return {
                ...state,
                spo2BrExpand: action.payload
            };
        case utilsConstants.SET_HR_HRV_EXPAND:
            return {
                ...state,
                hrHrvExpand: action.payload
            };
        case utilsConstants.SET_BP_EXPAND:
            return {
                ...state,
                bpExpand: action.payload
            };
        case utilsConstants.SET_TEMP_PR_EXPAND:
            return {
                ...state,
                tempPrExpand: action.payload
            };
        case utilsConstants.SET_FEV_PEF_EXPAND:
            return {
                ...state,
                fevPefExpand: action.payload
            };
        case utilsConstants.SET_G_W_P_EXPAND:
            return {
                ...state,
                gwpExpand: action.payload
            };
        case utilsConstants.SET_PATIENT_LIST_LOADER:
            return {
                ...state,
                patientListLoader: action.payload
            };
        case utilsConstants.SET_PATIENT_PROFILE_LOADER:
            return {
                ...state,
                patientProfilePageLoaderAB: action.payload
            };
        case utilsConstants.SET_SELECTED_FLOOR_FILTERS:
            return {
                ...state,
                selectedFloorFilters: action.payload
            };
        case utilsConstants.SET_SELECTED_UNIT_FILTERS:
            return {
                ...state,
                selectedUnitFilters: action.payload
            };
        default:
            return state;
    }
}