import React, { useState, useContext, useEffect, useRef } from "react";
import styles from "./PatientAIDITable.module.css";
import Grid from "@material-ui/core/Grid";
import PatientAIDITableData from "./PatientAIDITableData";
import { addDays } from "date-fns";
import Backdrop from '@mui/material/Backdrop';
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import subDays from "date-fns/subDays";
import { format } from "date-fns";
import Button from "@mui/material/Button";
import TableChartIcon from '@mui/icons-material/TableChart';
import { DateRangePicker } from "react-date-range";
import { useDispatch, useSelector } from "react-redux";
import { getAidiDateRangeSelector, getAidiRecordTableSelector } from "../../../../../Reducer/selectors/AIDISelector";
import { getAidiGraphPayload, getAidiRecordByRangeTable, setAidiDateRange } from "../../../../../Actions";
import { getCurrentPatientSubjectID } from "../../../../../Reducer/selectors/UtilSelector";
import AIDIGraphWrapper from "../AIDIGraphWrapper";

import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';

const PatientAIDITable = (props) => {

    const dispatch = useDispatch();
    const aidiRecordTable = useSelector(getAidiRecordTableSelector);
    const currentSubjectID = useSelector(getCurrentPatientSubjectID);

    // Sample data, replace it with your actual data

    const hideOnClickOutside = (e) => {
        if (refOne.current && !refOne.current.contains(e.target)) {
            setIsCalOpen(false);
        }
    };

    // hide dropdown on ESC press
    const hideOnEscape = (e) => {
        if (e.key === "Escape") {
            setIsCalOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", hideOnEscape, true);
        document.addEventListener("click", hideOnClickOutside, true);
    }, []);

    const [isCalOpen, setIsCalOpen] = useState(false);

    const [dateRange, setDateRange] = useState([
        {
            startDate: subDays(new Date(), 120),
            endDate: new Date(),
            key: "selection",
        },
    ]);

    // const dateRange = useSelector(getAidiDateRangeSelector);

    const handleCalClick = (event) => {
        event.preventDefault();
        if (isCalOpen) {
            setIsCalOpen(false);
        } else {
            setIsCalOpen(true);
        }
    };

    const fetchAIDITableRecords = (event) => {
        event.preventDefault();
        setIsCalOpen(false);
        const payload = {
            subjectId: currentSubjectID,
            startDate: (dateRange[0].startDate).toISOString().split('T')[0],
            endDate: (dateRange[0].endDate).toISOString().split('T')[0],
        };
        console.log("Request Payload=", payload);
        dispatch(getAidiRecordByRangeTable(payload));
        dispatch(getAidiGraphPayload(payload));
        console.log("Table Data for AIDI Record", aidiRecordTable);
    };

    const useStyles = makeStyles(() => ({
        tooltip: {
            marginTop: 5,
            font: "12px",
            background: "black",
            fontFamily: "GTWalsheimProRegular",
        },
    }));
    const classes = useStyles();

    const handleCalChanges = (item) => {
        // dispatch(setAidiDateRange([item.selection]));
        setDateRange([item.selection]);
    };

    const refOne = useRef(null);

    useEffect(() => {
        const payload = {
            subjectId: currentSubjectID,
            startDate: (dateRange[0].startDate).toISOString().split('T')[0],
            endDate: (dateRange[0].endDate).toISOString().split('T')[0],
        };
        dispatch(getAidiRecordByRangeTable(payload));
        dispatch(getAidiGraphPayload(payload));
    }, []);

    const tableData = (
        <table className={styles.cardStyle}>
            <PatientAIDITableData data={aidiRecordTable} />
        </table>
    );

    return (
        // <div className={styles.containerHeader}>
        //             <div className={styles.headingDiv}>
        //                 <MouthlabIcon color="#ffffff" style={{ height: '25px', width: '25px', fill: "#ffffff", marginRight: "5px", marginLeft: "5px" }}></MouthlabIcon>  Table View
        //             </div>
        <div className={styles.rootDiv}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer - 1 }}
                open={false}
            ></Backdrop>
            <div className={styles.containerDiv}>
                <div className={styles.datePickerDiv}>
                    <Tooltip title="Choose another date range" classes={{ tooltip: classes.tooltip }}>
                        <CalendarMonthIcon onClick={(event) => handleCalClick(event)}></CalendarMonthIcon>
                    </Tooltip>
                    <div className={styles.dateInputDiv}>
                        <input
                            value={`${format(dateRange[0].startDate, "MM/dd/yyyy")}  |  ${format(dateRange[0].endDate, "MM/dd/yyyy")}`}
                            readOnly
                            className={styles.dateInput}
                            onClick={(event) => handleCalClick(event)}
                        />
                    </div>
                    {/* <div>
                        <Button startIcon={<TableChartOutlinedIcon />} onClick={(event) => fetchAIDITableRecords(event)} className={styles.exportButton}>
                            View
                        </Button>
                    </div> */}
                </div>
                {isCalOpen && (
                    <div className={styles.datePickerWrapper} ref={refOne}>
                        <DateRangePicker
                            onChange={(item) => handleCalChanges(item)}
                            editableDateInputs={false}
                            showSelectionPreview={true}
                            moveRangeOnFirstSelection={false}
                            months={2}
                            ranges={dateRange}
                            direction="horizontal"
                            maxDate={addDays(new Date(), 1)}
                            rangeColors={["#4E253A"]}
                        />
                        <div className={styles.applyButtonDiv}>
                            <div className={styles.applyButton} onClick={(e) => fetchAIDITableRecords(e)}> Apply </div>
                        </div>
                    </div>
                )}
            </div>
            <AIDIGraphWrapper></AIDIGraphWrapper>
            <Grid container>
                <div className={styles.containerHeader}>
                    <div className={styles.headingDiv}>
                        <div className={styles.aidiTableIconWrapper}>
                            <TableChartOutlinedIcon color="#4E253A" style={{ height: '18px', width: '18px', fill: "#4E253A", marginRight: '1px' }}></TableChartOutlinedIcon>
                        </div>
                        MouthLab Vitals
                    </div>
                </div>
                <Grid container xs={12} xl={12} lg={12} md={12} sm={12}>
                    <div className={styles.responsive}>
                        <table className={styles.cardStyle}>
                            <thead>
                                <tr className={styles.header}>
                                    <th className={styles.timeStamp}>Time</th>
                                    <th className={styles.deviceId}>Device ID</th>
                                    <th className={styles.AIDI}>AIDI</th>
                                    <th className={styles.averageHR}>Average HR(bpm)</th>
                                    <th className={styles.averageRR}>Average RR (brpm)</th>
                                </tr>
                            </thead>

                        </table>
                        <Grid container xs={12} xl={12} lg={12} md={12} sm={12} style={{ maxHeight: '75vh', overflowY: 'scroll', padding: "5px" }} className={styles.tableContainer}>
                            {aidiRecordTable.length === 0 ? (
                                <div className={styles.noDataWrapper}>
                                    <div className={styles.noData}>
                                        <div>No data available</div>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    {tableData}
                                </>
                            )}
                        </Grid>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default PatientAIDITable;