import React, { useState, useEffect, useContext } from "react";
import styles from "./Devices.module.css";
import DeviceItem from "../DeviceItem/DeviceItem";
import GetSubjectDevices from "../../../../API/GetSubjectDevices/GetSubjectDevices";
import { PatientContext } from "../../../../Store/PatientDataProvider";
import { getSelectedSubjectId } from "../../../../../Reducer/selectors/DashboardSelector";
import { useSelector } from "react-redux";
import { setSelectedSubjectId } from "../../../../../Actions";

export default function DevicesWrapper() {
  const [subjectDetails, setSubjectDetails] = useState([]);
  
  // const [subjectId, setSubjectId] = useState('');
  let patientInfo = useContext(PatientContext);
  
  const noDevice = {
    deviceId: "None",
    deviceType: "--",
    usesPerDay: "--",
  };
  const sId = useSelector(getSelectedSubjectId)
  const [subjectId, setSubjectId] = useState(sId.selectedSubjectId);
  useEffect(() => {    
    setSubjectId(sId.selectedSubjectId)
  },[])

  useEffect(() => {
    GetSubjectDevices(subjectId).then((res) => {
      if(res.deviceId === "") {
        res.deviceId = noDevice.deviceId;
        res.deviceType = noDevice.deviceType;
        res.usesPerDay = noDevice.usesPerDay;
      } 
      setSubjectDetails(res);
    });
  }, [subjectId, patientInfo.isRefresh]);

  if(patientInfo.isRefresh) {
    patientInfo.setIsRefresh(false);
  }

  
  const setSubjectDetailsResponse = (response) => {
    setSubjectDetails(response)
  }
  
  return (
    <div>
      <div className={styles.container}>
        <div className={styles.devicesWrapper}>
          <div className={styles.listContainer}>
            <DeviceItem 
            key={subjectDetails.deviceId} 
            deviceName={subjectDetails.deviceType} 
            deviceId={subjectDetails.deviceId} 
            usage={subjectDetails.usesPerDay} 
            subjectId={subjectId} 
            setSubjectDetailsResponse={setSubjectDetailsResponse}
            noDevice={noDevice}/>
          </div>
          <div className={styles.listContainerFooter}>
            <div className={styles.footerContainer}>
              {/* <button className={styles.addNewDevicesButton} type="button">
                Add Device
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
