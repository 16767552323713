export function getPCCPatientsListSelector({ nursingDashboardReducer }) {
    if (
        nursingDashboardReducer &&
        nursingDashboardReducer.pccPatientsList
    )
        return nursingDashboardReducer.pccPatientsList;
    else return {};
}
export function getPCCPatientsPagingSelector({ nursingDashboardReducer }) {
    if (
        nursingDashboardReducer &&
        nursingDashboardReducer.paging
    )
        return nursingDashboardReducer.paging;
    else return {};
}
export function getPCCUserProfileSelector({ nursingDashboardReducer }) {
    if (
        nursingDashboardReducer &&
        nursingDashboardReducer.pccPatientProfile
    )
        return nursingDashboardReducer.pccPatientProfile.pccPatientProfile;
    else return {};
}

export function getPrevMeasurement({ nursingDashboardReducer }) {
    if (nursingDashboardReducer &&
        nursingDashboardReducer.prevMeasurement
    )
        return nursingDashboardReducer.prevMeasurement;
    else return [];
}

export function getLatestMeasurement({ nursingDashboardReducer }) {
    if (nursingDashboardReducer &&
        nursingDashboardReducer.latestMeasurement
    )
        return nursingDashboardReducer.latestMeasurement;
    else return [];
}

export function getLatestCalMeasurement({ nursingDashboardReducer }) {
    if (nursingDashboardReducer &&
        nursingDashboardReducer.latestCalMeasurement
    )
        return nursingDashboardReducer.latestCalMeasurement;
    else return [];
}

export function getUploadManualEntryStatus({ nursingDashboardReducer }) {
    if (nursingDashboardReducer &&
        nursingDashboardReducer.uploadManualEntry
    )
        return nursingDashboardReducer.uploadManualEntry;
    else return false;
}

export function getCalibrationStatus({ nursingDashboardReducer }) {
    if (nursingDashboardReducer &&
        nursingDashboardReducer.calibrationStatus
    )
        return nursingDashboardReducer.calibrationStatus;
    else return {};
}

export function getPccPatientListPageNumber({ nursingDashboardReducer }) {
    if (nursingDashboardReducer &&
        nursingDashboardReducer.pccPageNumber
    )
        return nursingDashboardReducer.pccPageNumber;
    else return 1;
}

export function getPccPatientListPageSize({ nursingDashboardReducer }) {
    if (nursingDashboardReducer &&
        nursingDashboardReducer.pccPageSize
    )
        return nursingDashboardReducer.pccPageSize;
    else return "20";
}

export function getUserHasML({ nursingDashboardReducer }) {
    if (nursingDashboardReducer &&
        nursingDashboardReducer.isUserAlreadyPaired
    )
        return nursingDashboardReducer.isUserAlreadyPaired;
    else return {};
}

export function getSubjectFromDevice({ nursingDashboardReducer }) {
    if (nursingDashboardReducer &&
        nursingDashboardReducer.subjectId
    )
        return nursingDashboardReducer.subjectId;
    else return false;
}

export function getShowQMDrawer({ nursingDashboardReducer }) {
    if (
        nursingDashboardReducer &&
        nursingDashboardReducer.qmDrawer
    )
        return nursingDashboardReducer.qmDrawer;
    else return false;
}

export function getShowMEDrawer({ nursingDashboardReducer }) {
    if (
        nursingDashboardReducer &&
        nursingDashboardReducer.meDrawer
    )
        return nursingDashboardReducer.meDrawer;
    else return false;
}

export function getShowCMDrawer({ nursingDashboardReducer }) {
    if (
        nursingDashboardReducer &&
        nursingDashboardReducer.cmDrawer
    )
        return nursingDashboardReducer.cmDrawer;
    else return false;
}

export function getFloorEdit({ nursingDashboardReducer }) {
    if (
        nursingDashboardReducer &&
        nursingDashboardReducer.floorEdit
    )
        return nursingDashboardReducer.floorEdit;
    else return false;
}

export function getUnitEdit({ nursingDashboardReducer }) {
    if (
        nursingDashboardReducer &&
        nursingDashboardReducer.unitEdit
    )
        return nursingDashboardReducer.unitEdit;
    else return false;
}

export function getRoomEdit({ nursingDashboardReducer }) {
    if (
        nursingDashboardReducer &&
        nursingDashboardReducer.roomEdit
    )
        return nursingDashboardReducer.roomEdit;
    else return false;
}

export function getBedEdit({ nursingDashboardReducer }) {
    if (
        nursingDashboardReducer &&
        nursingDashboardReducer.bedEdit
    )
        return nursingDashboardReducer.bedEdit;
    else return false;
}

export function getShowSaveCancelForFacility({ nursingDashboardReducer }) {
    if (nursingDashboardReducer &&
        nursingDashboardReducer.showSaveCancelForFacility
    )
        return nursingDashboardReducer.showSaveCancelForFacility;
    else return false;
};

export function getShowFooterButtons({ nursingDashboardReducer }) {
    if (nursingDashboardReducer &&
        nursingDashboardReducer.showFooterButtons
    )
        return nursingDashboardReducer.showFooterButtons;
    else return false;
}