import React from "react";
import styles from "./PatientProfile.module.css";
import Typography from "@mui/material/Typography";
import { RiCloseCircleFill } from "react-icons/ri";
import IconButton from "@mui/material/IconButton";
import ProfileIcon from "../../../../Assets/Images/ProfileIcon.png";
import Avatar from "@material-ui/core/Avatar";
import { ReactComponent as ProfileIconSvg } from "./../../../../Assets/icons/Profile-Icon.svg";
import Box from "@mui/material/Box";

export default function PatientProfile(props) {

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        <div className={styles.iconDivStyles}>
          <ProfileIconSvg size={25} />
        </div>
        <div className={styles.profile}>Profile</div>
        <div className={styles.closeIcon}>
          <IconButton aria-label="delete">
            <RiCloseCircleFill size={22} style={{ color: "#4E253A" }} onClick={props.popupState.close} />
          </IconButton>
        </div>
      </div>
      <div className={styles.card}>
        <div className={styles.cardTitle}>
          <Avatar className={styles.avatar} alt="avatar_patient" src={ProfileIcon}></Avatar>
          <Box sx={{ marginLeft: "12px", marginTop: "auto", marginBottom: "auto" }}>
            <div className={styles.patientName}>{props.patientDetailInfo.firstName + " " + props.patientDetailInfo.lastName}</div>
            <div className={styles.gender}>{props.patientDetailInfo.email ? props.patientDetailInfo.email : "--"}</div>
            <div className={styles.gender}>{props.patientDetailInfo.gender}</div>
          </Box>
        </div>

        <div className={styles.patientDataWrapper}>
          <div className={styles.profileField}>
            <div className={styles.boldText}>Date of Birth</div>
            <div className={styles.regularText}>{props.patientDetailInfo.dob ? props.patientDetailInfo.dob : "--"}</div>
          </div>

          <div className={styles.profileField}>
            <div className={styles.boldText}>Start date</div>
            {props.patientDetailInfo.dateOfFirstMeasurement && (
              <div className={styles.regularText}>{props.patientDetailInfo.dateOfFirstMeasurement.slice(0, 10)}</div>
            )}
          </div>

          <div className={styles.profileField}>
            <div className={styles.boldText}>Elapsed Hours</div>
            <div className={styles.regularText}>{/* {props.patientDetailInfo.elapsedHours} */}
               {props.elapsedHours === "--"
                ? "--"
                : props.elapsedHours.Days > 0
                ? props.elapsedHours.Days + " Days " + props.elapsedHours.Hours + " Hours"
                : props.elapsedHours.Hours + " Hours"}  
            </div>
          </div>

          <div className={styles.profileField}>
            <div className={styles.boldText}>Registered By</div>
            <div className={styles.regularText}>{props.patientDetailInfo.registeredBy ? props.patientDetailInfo.registeredBy : "--"}</div>
          </div>

          <div className={styles.profileField}>
            <div className={`${props.patientDetailInfo.diagnoses.length > 1 ? styles.boldTextManyLines : styles.boldText}`}>Diagnoses</div>
            <div className={`${props.patientDetailInfo.diagnoses.length > 1 ? styles.regularTextManyLines : styles.regularText}`}>
              {props.patientDetailInfo.diagnoses[0] == "" ? "None" : props.patientDetailInfo.diagnoses.join(", ")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
