import React, { useState } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import styles from "./BPWrapper.module.css";
import { styled } from '@mui/material/styles';
import { tooltipClasses } from '@mui/material/Tooltip';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { IconButton } from "@material-ui/core";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import RenderBP from "../CvTrendsUpdated/RenderBP";
import CircleIcon from '@mui/icons-material/Circle';
import { getBpExpand } from "../../../../../../../Reducer/selectors/UtilSelector";
import { setBpExpand } from "../../../../../../../Actions/UtilActions";

export default function BPWrapper() {

    const dispatch = useDispatch();
    const isExpandedView = useSelector(getBpExpand);

    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #dadde9',
        },
    }));

    return (
        <>
            <div className={styles.aidiGraphWrapper}>
                <div className={styles.containerHeader}>
                    <div className={styles.aidiGraphHeadingDiv}>
                        {/* <div className={styles.aidiIconWrapper}>
                            <GraphicEqIcon style={{ color: "#4E253A", height: "18px", width: "18px" }} />
                        </div> */}
                        Blood Pressure(BP) <HtmlTooltip
                            title={
                                <React.Fragment>
                                    <div className={styles.tootlTipHeading}>Blood Pressure(BP)</div>
                                </React.Fragment>
                            }
                        >
                            <InfoOutlinedIcon color="#ffffff" style={{ height: '20px', width: '20px', fill: "#ffffff", marginRight: "5px", marginLeft: "10px" }} />
                        </HtmlTooltip>
                    </div>
                    <div className={styles.expandAidiButton}>
                        <IconButton style={{ padding: "8px" }} KeyboardArrowUpIcon onClick={() => dispatch(setBpExpand(!isExpandedView))}> <div style={{ height: '20px', width: '20px', backgroundColor: "#ffffff", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}>{isExpandedView ? (<KeyboardArrowUpIcon style={{ height: '25px', width: '25px', fill: "#4E253A" }}></KeyboardArrowUpIcon>) : (<KeyboardArrowDownIcon style={{ height: '25px', width: '25px', fill: "#4E253A" }}></KeyboardArrowDownIcon>)}</div></IconButton>
                    </div>
                </div>

                {isExpandedView ? (<div className={styles.graphDiv}>
                    <div style={{ width: "100%", height: "100%" }}>
                        <div className={styles.legendDiv}>
                            <div className={styles.brLegendDiv}>
                                <CircleIcon style={{ height: '15px', width: '15px', color: "#F14C00", marginRight: "5px", marginLeft: "5px" }}></CircleIcon> Avg Diastolic Blood Pressure (mmHg)
                            </div>
                            <div className={styles.hrLegendDiv}>
                                <CircleIcon style={{ height: '15px', width: '15px', color: "#694FC0", marginRight: "5px", marginLeft: "5px" }}></CircleIcon> Avg Systolic Blood Pressure (mmHg)
                            </div>
                        </div>
                        <RenderBP></RenderBP>
                    </div>
                </div>) : (<div style={{ width: "100%" }}></div>)}
            </div>
        </>
    );
}