import React, { useState, useEffect, Fragment } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@mui/material/Button";
import TableHeader from "../TableHeader/TableHeader";
import { AiOutlineUserAdd } from "react-icons/ai";
import GetUserPatients from "../../../../API/GetUserPatients/GetUserPatients";
import { CircularProgress } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import styles from "../../ProjectLocationCards/ProjectLocation.module.css";
import { getPatientsList } from "../../../../../Actions";
import { useDispatch, useSelector } from "react-redux";
import { getPatientsListSelector } from "../../../../../Reducer/selectors/PatientSelector";
import { setShowLoader } from "../../../../../Actions/UtilActions";
import { getPageLoader } from "../../../../../Reducer/selectors/UtilSelector";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "98%",
    padding: `10px 0px 15px 10px`,
  },
  contentStyle: {
    display: "block",
    alignItems: "center",
  },
  heading: {
    fontFamily: `GTWalsheimProRegular`,
    color: "#F44336",
    fontSize: 18,
    textTransform: "uppercase",
    fontWeight: `bold`,
  },
  headingModerate: {
    fontFamily: `GTWalsheimProRegular`,
    color: "#ffb703",
    fontSize: 18,
    textTransform: "uppercase",
    fontWeight: `bold`,
  },
  headingStable: {
    fontFamily: `GTWalsheimProRegular`,
    color: "#000000",
    fontSize: 18,
    textTransform: "uppercase",
    fontWeight: `bold`,
  },
  acStyles: {
    backgroundColor: `#fff`,
    boxShadow: `0px 2px 5px 0px #6c757d`,
    fontFamily: `GTWalsheimProRegular`,
    borderRadius: `10px !important`,
    position: `relative`,
  },
  contentWrapper: {
    marginLeft: `5px`,
    marginRight: "5px",
  },
  addPatient: {
    backgroundColor: `#fff`,
    boxShadow: `0px 2px 5px 0px #6c757d`,
    fontFamily: `GTWalsheimProRegular`,
    borderRadius: `25px !important`,
    position: `relative`,
    margin: `30px`,
    padding: `18px`,
    fontSize: 16,
    textTransform: "uppercase",
  }
}));

export default function PatientAccordion(props) {
  const classes = useStyles();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const patientList = useSelector(getPatientsListSelector);

  console.log("projectId: ", props.projectId);
  console.log("locationId: ", props.locationId);
  const loader = useSelector(getPageLoader);
  useEffect(() => {
    // GetUserPatients(props.email, props.projectId, props.locationId).then((res) => {
    //   console.log("PatientAccordion: res: ", res);
    //   if (res) {
    //     setPatientList(res.map((x) => x));
    //     console.log("^^^^^^^^", patientList);
    //   } 
    // });
    dispatch(setShowLoader(true));
    const payload = {'projectId':props.projectId, 'locationId':props.locationId};
    dispatch(getPatientsList(payload));
  }, []);


  if(loader) {
    return (<div className={styles.circularProgress}><CircularProgress thickness={5.0} /></div>);
  }
  if(!patientList === 0) {
    return (
        <div>
            <div>No patients available. Please register a new patient.</div>
      <Button variant="outlined" startIcon={<AiOutlineUserAdd />} className={classes.addPatient} onClick={() => navigate(`/register`)}>
        Add New Patient
      </Button>
      </div>
    );  
  }
  return (
    <Fragment>
      <div className={classes.root}>
        <Accordion defaultExpanded className={classes.acStyles}>
          <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: `red` }} />} aria-controls="panel2a-content" id="panel2a-header">
            <Typography
              className={`${props.accId === "1" && classes.heading} ${props.accId === "2" && classes.headingModerate} ${
                props.accId === "3" && classes.headingStable
              }`}
            >
              {props.label}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.contentStyle}>
            <div className={classes.contentWrapper}>
              <TableHeader patientList={patientList} pId={props.accId} />
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </Fragment>
  );
}
