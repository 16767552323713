import 'c3/c3.css';
import c3 from "c3";
import 'c3/c3.min.css';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './RenderAIDIGraph.css';
import { getAidiGraphPayloadSelector, getSelectedPatientBRPayload, getSelectedPatientHRPayload } from '../../../../../Reducer/selectors/AIDISelector';
import { format } from 'date-fns';


export default function RenderAIDIGraph() {

    const aidiGraphPayload = useSelector(getAidiGraphPayloadSelector);
    console.log("aidiGraphPayload: ", aidiGraphPayload);

    const daily_aidi = aidiGraphPayload.daily_aidi_dummy ? ["daily_aidi"].concat(aidiGraphPayload.daily_aidi_dummy.slice(1)) : ["daily_aidi",];
    const daily_aidi_x = aidiGraphPayload.daily_aidi_x ? aidiGraphPayload.daily_aidi_x : ["daily_aidi_x",];
    const baseline_num = aidiGraphPayload.baseline_num ? aidiGraphPayload.baseline_num : ["baseline_num",];
    const aidi_actual_values = aidiGraphPayload.daily_aidi ? aidiGraphPayload.daily_aidi : ["daily_aidi",];

    const hrPayload = useSelector(getSelectedPatientHRPayload);
    const daily_hr = hrPayload.daily_avg;

    const brPayload = useSelector(getSelectedPatientBRPayload);
    const daily_br = brPayload.daily_avg;

    // const pallete = ["#cecbd0", "#cfd4d8", "#ddcad9", "#cfcdd8", "#dec7d0", "#d3cbd1"];
    // const pallete = ["#e5e2e7", "#e6ecf0", "#f6e0f1", "#e6e4f0", "#f7dde7", "#eae1e8"];
    // const pallete = ["#2A4A55", "#35A8AF", "#CE2FBE", "#355EAF", "#D81159", "#593E68",];
    const pallete = [ "#cdb4db", "#ffafcc", "#f0c39d", "#758bc5", "#69b6c3","#d272a3",];

    let chart_aidi_bindto = "#chart-aidi";

    let chart_aidi_padding = {
        right: 40,
    };

    let chart_aidi_data = {
        selection: {
            enabled: false,
        },
        xs: {
            daily_aidi: 'daily_aidi_x',
        },
        columns: [daily_aidi_x, daily_aidi],
        type: 'bar',
        // labels: {
        //     format: function (v, id, i, j) {
        //         if (v <= 0.005) {
        //             return 0.0;
        //         } else {
        //             return "B" + parseInt(baseline_num[i + 1]);
        //         }
        //     }
        // },
        colors: {
            daily_aidi: function (d) {
                if (baseline_num[d.index + 1] !== "") {
                    return pallete[(parseInt(baseline_num[d.index + 1]) % 6) - 1];
                } else {
                    return "#4E253A";
                }
            }
        },
    };

    let chart_aidi_bar = {
        width: 10,
        zerobased: false,
        // or
        //width: 100 // this makes bar width 100px
    };

    let chart_aidi_zoom = {
        enabled: false,
        type: 'drag',
        rescale: true,
        //onzoomend: function (d) { correctBRGraphZoom(d); }
    };

    let chart_aidi_axis = {
        x: {
            type: 'timeseries',
            tick: {
                fit: false,
                format: "%e %b",
                centered: true,
            },
        },
        y: {
            max: 1.0,
            min: -0.01,
            padding: { top: 0, bottom: 0 },
            tick: {
                centered: true,
                values: ["0.00", "0.30", "0.45", "1.00"],
            },
        }
    };

    let chart_aidi_legend = {
        show: false
    };

    let chart_aidi_tooltip = {
        grouped: false, // Default true
        format: {
            title: function (d) {
                return format(d, "yyyy-MM-dd");
            },
            value: function (value, ratio, id, index) {
                return "";
            },
            name: function (name, ratio, id, index) {
                return "AIDI: " + aidi_actual_values[index + 1] + " | BR: " + daily_br[index + 1] + " | HR: " + daily_hr[index + 1];
            }
        }
    };

    let chart_aidi_size = {
        height: 220,
    };

    let chart_aidi_regions = [
        // { axis: 'y', start: 0.45, class: "regionHighRisk", label: "regionHighRisk", opacity: 0.04 },
        // { axis: 'y', start: 0.3, end: 0.45, class: 'regionMedRisk', opacity: 0.04 },
        // { axis: 'y', start: 0.0, end: 0.3, class: 'regionLowRisk', opacity: 0.04 },
    ];

    let chart_aidi_grid = {
        y: {
            lines: [
                { value: 0.45 },
                //{ value: 10 },
                { value: 0.3 },
                
            ]
        }
    };

    const regenerateAIDIGraph = () => {
        aidiGraph = c3.generate({
            bindto: chart_aidi_bindto,
            padding: chart_aidi_padding,
            data: chart_aidi_data,
            bar: chart_aidi_bar,
            zoom: chart_aidi_zoom,
            axis: chart_aidi_axis,
            legend: chart_aidi_legend,
            tooltip: chart_aidi_tooltip,
            //color: chart_hr_color,
            size: chart_aidi_size,
            regions: chart_aidi_regions,
            //point: chart_hr_point,
            grid: chart_aidi_grid,
        });
    }

    var aidiGraph = c3.generate({
        bindto: chart_aidi_bindto,
        padding: chart_aidi_padding,
        data: chart_aidi_data,
        bar: chart_aidi_bar,
        zoom: chart_aidi_zoom,
        axis: chart_aidi_axis,
        legend: chart_aidi_legend,
        tooltip: chart_aidi_tooltip,
        //color: chart_hr_color,
        size: chart_aidi_size,
        regions: chart_aidi_regions,
        //point: chart_hr_point,
        grid: chart_aidi_grid,
    });

    useEffect(() => {
        regenerateAIDIGraph();
    }, []);

    return (
        <div>
            <div id='chart-aidi' style={{ marginTop: "20px", marginLeft: "10px", width: "100%", overflow: "visible" }} />
        </div>
    );
}