import React from "react";
import styles from "./Registration.module.css";
import HEADER from "../../../Assets/Images/HEADER-PatientRegistration.png";

export default function RegistrationHeader(props) {

  return (
    <div className={styles.headerWrapper}>
      <img src={HEADER} alt="Aidar Health" className={styles.headerStyles} /><div className={styles.headerMenuBar}>
            <div className={styles.headerBarWrapper}>
                {/* <div className={styles.headerMenuItem}>Privacy Policy</div>
                <div className={styles.headerMenuItem}>All Rights Reserved &#169;AidarHealth</div> */}
            </div>
        </div>
    </div>
  );
}
