import React, {
    forwardRef,
    useImperativeHandle,
    useEffect,
    useRef,
    useState,
} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import SensorsIcon from '@mui/icons-material/Sensors';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import Avatar from "@material-ui/core/Avatar";
import ProfileIcon from "../../../../Assets/Images/ProfileIcon.png";
import styles from "./MEPannel.module.css";
import Backdrop from '@mui/material/Backdrop';
import { getPCCUserProfileSelector, getShowMEDrawer, getPrevMeasurement, getUploadManualEntryStatus } from "../../../../Reducer/selectors/NursingDashboardSelector";
import { getSelectedPCCPatientSelector } from "../../../../Reducer/selectors/DashboardSelector";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Input from '@material-ui/core/Input';
import { setShowMEDrawer, setUploadManualEntryStatus, updateExtraMeasurementParams, uploadManualEntry } from "../../../../Actions";
import { getUserProfile } from "../../../../Reducer/selectors/DashboardSelector.js";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { ReactComponent as TempIcon } from "../../../../Assets/icons/01-Temp.svg";
import { ReactComponent as BPIcon } from "../../../../Assets/icons/02-BP.svg";
import { ReactComponent as RRIcon } from "../../../../Assets/icons/03-RR.svg";
import { ReactComponent as PRIcon } from "../../../../Assets/icons/04-PR.svg";
import { ReactComponent as HRIcon } from "../../../../Assets/icons/05-HR.svg";
import { ReactComponent as SPOIcon } from "../../../../Assets/icons/06-SpO2.svg";
import { ReactComponent as HRVIcon } from "../../../../Assets/icons/07-HRV.svg";
import { ReactComponent as PEFIcon } from "../../../../Assets/icons/08-PEF.svg";
import { ReactComponent as FEVIcon } from "../../../../Assets/icons/09-FEV1.svg";
import { ReactComponent as GlucoseIcon } from "../../../../Assets/icons/Glcouse-Icon.svg";
import { ReactComponent as WeightIcon } from "../../../../Assets/icons/Weight-Icon.svg";
import { CircularProgress } from "@material-ui/core";

const drawerWidth = 600;
const useStyles = makeStyles((theme) => ({
    title: {
        flexGrow: 1,
    },
    hide: {
        display: "none",
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        backgroundColor: "#f4e7ee",
        width: drawerWidth,
    },
    typoStyle: {
        fontSize: 20,
        textTransform: `capitalize;`,
        fontFamily: `GTWalsheimProRegular`,
        width: 100,
    },
    drawerHeader: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        justifyContent: "flex-start",
        backgroundColor: "#E4DFE9",
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginRight: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
}));

const MEPannel = forwardRef((props, ref) => {

    const refOne = useRef(null);
    const classesQV = useStyles();
    const dispatch = useDispatch();
    // const [open, setOpen] = React.useState(false);
    const isMEOpen = useSelector(getShowMEDrawer);
    const [stepNumber, setStepNumber] = useState(1);
    const selectedPCCPatient = useSelector(getSelectedPCCPatientSelector);
    const selectedPCCPatientProfile = useSelector(getPCCUserProfileSelector);
    const [newNotes, setNewNotes] = useState("");
    const [measurementSuccess, setMeasurementSuccess] = useState(false);
    const manualEntryStatus = useSelector(getUploadManualEntryStatus);
    const [showLoader, setShowLoader] = useState(false);
    const [displayStatus, setDisplayStatus] = useState(false);
    const userProfile = useSelector(getUserProfile);
    const [firstName] = useState(userProfile.firstName);
    const [lastName] = useState(userProfile.lastName);
    const userName = firstName + " " + lastName;

    const timeZoneFromDayjs = dayjs.tz.guess();

    const handleQuickDrawerOpen = () => {
        // setOpen(true);
        dispatch(setShowMEDrawer(true));
        setTimestamp(new Date());
    };

    const resetMeasurements = () => {
        setPainLevel();
        setWeight("");
        setGlucoseLevel("");
        setTemp("");
        setSpo2("");
        setBreathingRate("");
        setPulseRate("");
        setHeartRate("");
        setHrv("");
        setSbp("");
        setdbp("");
        setFev1("");
        setPef("");
        setNewNotes("");
    }

    const retakeMeasurements = () => {
        setStepNumber(1);
        resetMeasurements();
        setDisplayStatus(false);
        setTimestamp(new Date());
    }

    const handleQuickDrawerClose = () => {
        // setOpen(false);
        dispatch(setShowMEDrawer(false));
        setStepNumber(1);
        resetMeasurements();
        setDisplayStatus(false);
    };

    const handleSubmitFinalMeasurements = () => {

        const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
        // setOpen(false);
        setShowLoader(true);
        const payload = {
            subjectId: selectedPCCPatientProfile.subjectId,
            temperature: temp === "" ? undefined : "\"" + temp + ", 1.00\"",
            spo2: spo2 === "" ? undefined : "\"" + spo2 + ", 1.00\"",
            breathingRate: breathingRate === "" ? undefined : "\"" + breathingRate + ", 1.00\"",
            pulseRate: pulseRate === "" ? undefined : "\"" + pulseRate + ", 1.00\"",
            heartRate: heartRate === "" ? undefined : "\"" + heartRate + ", 1.00\"",
            hrv: hrv === "" ? undefined : "\"" + hrv + ", 1.00\"",
            sbp: sbp === "" ? undefined : "\"" + sbp + ", 1.00\"",
            dbp: dbp === "" ? undefined : "\"" + dbp + ", 1.00\"",
            fev1: fev1 === "" ? undefined : "\"" + fev1 + ", 1.00\"",
            pef: pef === "" ? undefined : "\"" + pef + ", 1.00\"",
            weight: weight === "" ? undefined : weight,
            glucose: glucoseLevel === "" ? undefined : glucoseLevel,
            painLevel: painLevel === "" ? undefined : painLevel,
            tsRecorded: timestamp,
            timezone: timeZone,
            note: newNotes,
            createdBy: userName,
        }
        console.log("MANUAL ENTRY PAYLOAD: ", payload);
        dispatch(uploadManualEntry(payload));
        closeLoaderIn5Seconds();
    }

    const [painLevel, setPainLevel] = useState();
    const [weight, setWeight] = useState("");
    const [glucoseLevel, setGlucoseLevel] = useState("");
    const [timestamp, setTimestamp] = useState(new Date());
    const [temp, setTemp] = useState("");
    const [spo2, setSpo2] = useState("");
    const [breathingRate, setBreathingRate] = useState("");
    const [pulseRate, setPulseRate] = useState("");
    const [heartRate, setHeartRate] = useState("");
    const [hrv, setHrv] = useState("");
    const [sbp, setSbp] = useState("");
    const [dbp, setdbp] = useState("");
    const [fev1, setFev1] = useState("");
    const [pef, setPef] = useState("");

    const [complete, setComplete] = useState(false);

    useEffect(() => {
        if (painLevel || weight || glucoseLevel || temp || spo2 || breathingRate || pulseRate || heartRate || hrv || sbp || dbp || fev1 || pef) {
            setComplete(true);
        } else {
            setComplete(false);
        }
    }, [painLevel, weight, glucoseLevel, temp, spo2, breathingRate, pulseRate, heartRate, hrv, sbp, dbp, fev1, pef])

    const handleFocus = (event) => event.target.select();

    useImperativeHandle(ref, () => {
        return {
            handleQuickDrawerOpen: handleQuickDrawerOpen,
        };
    });

    const closeLoaderIn5Seconds = () => {
        setTimeout(() => {
            setShowLoader(false);
            setDisplayStatus(true);
        }, 3000);
    };

    useEffect(() => {
        if (manualEntryStatus && displayStatus) {
            console.log("ME status true!");
            closeDrawerIn3Seconds();
        }
    }, [manualEntryStatus, displayStatus]);

    const closeDrawerIn3Seconds = () => {
        setTimeout(() => {
            dispatch(setUploadManualEntryStatus(false));
            dispatch(setShowMEDrawer(false));
            console.log("ME drawer close dispatched!");
            setStepNumber(1);
            resetMeasurements();
            setDisplayStatus(false);
        }, 2000);
    }


    return (
        <div className={classesQV.root} ref={refOne}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer - 1 }}
                open={isMEOpen}
            ></Backdrop>
            <main></main>
            <Drawer
                className={classesQV.drawer}
                anchor="right"
                variant="persistent"
                open={isMEOpen}
                classes={{
                    paper: classesQV.drawerPaper,
                }}
            >

                <div className={styles.qvHeader}>
                    <Avatar className={styles.avatar} alt="avatar_patient" src={ProfileIcon}></Avatar>
                    <div className={styles.avatarTitleDiv}>
                        <div className={styles.avatarTitle}> {selectedPCCPatient.fullName} </div>
                        <div className={styles.avatarTitle1}> {selectedPCCPatient.medicalRecordNumber} </div>
                    </div>
                </div>

                <div className={styles.drawerTitle}>
                    <TouchAppIcon></TouchAppIcon> Manual Measurement
                </div>

                {stepNumber === 1 && (<>
                    <div className={styles.qvDiv}>
                        <Grid
                            container
                            spacing={0}
                            // justify="center"
                            style={{ padding: 10 }}
                        >
                            <Grid item xs={4} xl={4} lg={4} md={4} sm={4}>
                                <div className={styles.cardHighRisk}>
                                    <div className={styles.iconDivStyles}>
                                        <TempIcon className={styles.highRiskIcon} size={35} />
                                    </div>
                                    <div className={styles.countDivStyles}>
                                        <input
                                            type="number"
                                            placeholder="__"
                                            value={temp}
                                            onChange={e => setTemp(e.currentTarget.value)}
                                            className={styles.patientCountHighRiskInput}
                                            onFocus={e => handleFocus(e)} />
                                        <div className={styles.patientCatagoryHighRisk}>Temp (F)</div>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={4} xl={4} lg={4} md={4} sm={4}>
                                <div className={styles.cardHighRisk}>
                                    <div className={styles.iconDivStyles}>
                                        <BPIcon className={styles.highRiskIcon} size={35} />
                                    </div>
                                    <div className={styles.countDivStyles}>
                                        <input
                                            type="number"
                                            placeholder="__"
                                            value={sbp}
                                            onChange={e => setSbp(e.currentTarget.value)}
                                            className={styles.patientCountHighRiskInput}
                                            onFocus={e => handleFocus(e)} />
                                        <div className={styles.patientCatagoryHighRisk}>SBP (mmHg)</div>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={4} xl={4} lg={4} md={4} sm={4}>
                                <div className={styles.cardHighRisk}>
                                    <div className={styles.iconDivStyles}>
                                        <BPIcon className={styles.highRiskIcon} size={40} />
                                    </div>
                                    <div className={styles.countDivStyles}>
                                        <input
                                            type="number"
                                            placeholder="__"
                                            value={dbp}
                                            onChange={e => setdbp(e.currentTarget.value)}
                                            className={styles.patientCountHighRiskInput}
                                            onFocus={e => handleFocus(e)} />
                                        <div className={styles.patientCatagoryHighRisk}>DBP (mmHg)</div>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={4} xl={4} lg={4} md={4} sm={4}>
                                <div className={styles.cardHighRisk}>
                                    <div className={styles.iconDivStyles}>
                                        <HRIcon className={styles.highRiskIcon} size={40} />
                                    </div>
                                    <div className={styles.countDivStyles}>
                                        <input
                                            type="number"
                                            placeholder="__"
                                            value={heartRate}
                                            onChange={e => setHeartRate(e.currentTarget.value)}
                                            className={styles.patientCountHighRiskInput}
                                            onFocus={e => handleFocus(e)} />
                                        <div className={styles.patientCatagoryHighRisk}>HR (bpm)</div>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={4} xl={4} lg={4} md={4} sm={4}>
                                <div className={styles.cardHighRisk}>
                                    <div className={styles.iconDivStyles}>
                                        <PRIcon className={styles.highRiskIcon} size={40} />
                                    </div>
                                    <div className={styles.countDivStyles}>
                                        <input
                                            type="number"
                                            placeholder="__"
                                            value={pulseRate}
                                            onChange={e => setPulseRate(e.currentTarget.value)}
                                            className={styles.patientCountHighRiskInput}
                                            onFocus={e => handleFocus(e)} />
                                        <div className={styles.patientCatagoryHighRisk}>PR (bpm)</div>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={4} xl={4} lg={4} md={4} sm={4}>
                                <div className={styles.cardHighRisk}>
                                    <div className={styles.iconDivStyles}>
                                        <HRVIcon className={styles.highRiskIcon} size={40} />
                                    </div>
                                    <div className={styles.countDivStyles}>
                                        <input
                                            type="number"
                                            placeholder="__"
                                            value={hrv}
                                            onChange={e => setHrv(e.currentTarget.value)}
                                            className={styles.patientCountHighRiskInput}
                                            onFocus={e => handleFocus(e)} />
                                        <div className={styles.patientCatagoryHighRisk}>HRV (ms)</div>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={4} xl={4} lg={4} md={4} sm={4}>
                                <div className={styles.cardHighRisk}>
                                    <div className={styles.iconDivStyles}>
                                        <SPOIcon className={styles.highRiskIcon} size={40} />
                                    </div>
                                    <div className={styles.countDivStyles}>
                                        <input
                                            type="number"
                                            placeholder="__"
                                            value={spo2}
                                            onChange={e => setSpo2(e.currentTarget.value)}
                                            className={styles.patientCountHighRiskInput}
                                            onFocus={e => handleFocus(e)} />
                                        <div className={styles.patientCatagoryHighRisk}>SpO2 (%)</div>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={4} xl={4} lg={4} md={4} sm={4}>
                                <div className={styles.cardHighRisk}>
                                    <div className={styles.iconDivStyles}>
                                        <PEFIcon className={styles.highRiskIcon} size={40} />
                                    </div>
                                    <div className={styles.countDivStyles}>
                                        <input
                                            type="number"
                                            placeholder="__"
                                            value={pef}
                                            onChange={e => setPef(e.currentTarget.value)}
                                            className={styles.patientCountHighRiskInput}
                                            onFocus={e => handleFocus(e)} />
                                        <div className={styles.patientCatagoryHighRisk}>
                                            PEF (L/sec)
                                        </div>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={4} xl={4} lg={4} md={4} sm={4}>
                                <div className={styles.cardHighRisk}>
                                    <div className={styles.iconDivStyles}>
                                        <FEVIcon className={styles.highRiskIcon} size={40} />
                                    </div>
                                    <div className={styles.countDivStyles}>
                                        <input
                                            type="number"
                                            placeholder="__"
                                            value={fev1}
                                            onChange={e => setFev1(e.currentTarget.value)}
                                            className={styles.patientCountHighRiskInput}
                                            onFocus={e => handleFocus(e)} />
                                        <div className={styles.patientCatagoryHighRisk}>FEV1 (L)</div>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={4} xl={4} lg={4} md={4} sm={4}>
                                <div className={styles.cardHighRisk}>
                                    <div className={styles.iconDivStyles}>
                                        <RRIcon className={styles.highRiskIcon} size={40} />
                                    </div>
                                    <div className={styles.countDivStyles}>
                                        <input
                                            type="number"
                                            placeholder="__"
                                            value={breathingRate}
                                            onChange={e => setBreathingRate(e.currentTarget.value)}
                                            className={styles.patientCountHighRiskInput}
                                            onFocus={e => handleFocus(e)} />
                                        <div className={styles.patientCatagoryHighRisk}>
                                            RR (br/min)
                                        </div>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={4} xl={4} lg={4} md={4} sm={4}>
                                <div className={styles.cardHighRisk}>
                                    <div className={styles.iconDivStyles}>
                                        <WeightIcon className={styles.highRiskIcon} />
                                    </div>
                                    <div className={styles.countDivStyles}>
                                        <input
                                            type="number"
                                            placeholder="__"
                                            value={weight}
                                            onChange={e => setWeight(e.currentTarget.value)}
                                            className={styles.patientCountHighRiskInput}
                                            onFocus={e => handleFocus(e)} />
                                        <div className={styles.patientCatagoryHighRisk}>Weight (lbs)</div>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={4} xl={4} lg={4} md={4} sm={4}>
                                <div className={styles.cardHighRisk}>
                                    <div className={styles.iconDivStyles}>
                                        <GlucoseIcon className={styles.highRiskIcon} />
                                    </div>
                                    <div className={styles.countDivStyles}>
                                        <input
                                            type="number"
                                            placeholder="__"
                                            value={glucoseLevel}
                                            onChange={e => setGlucoseLevel(e.currentTarget.value)}
                                            className={styles.patientCountHighRiskInput}
                                            onFocus={e => handleFocus(e)} />
                                        <div className={styles.patientCatagoryHighRisk}>Glu. (mg/dL)</div>
                                    </div>
                                </div>
                            </Grid>

                            <div className={styles.painDiv}>
                                <div className={styles.painDivDesc}>
                                    Rate your pain level on a scale of 0 - 10
                                </div>
                                <div className={styles.painLevelRating}>
                                    <div className={painLevel >= 0 ? styles.selectedRatingdiv : styles.ratingdiv} onClick={() => setPainLevel(0)}>0</div>
                                    <div className={painLevel && painLevel >= 1 ? styles.selectedRatingdiv : styles.ratingdiv} onClick={() => setPainLevel(1)}>1</div>
                                    <div className={painLevel && painLevel >= 2 ? styles.selectedRatingdiv : styles.ratingdiv} onClick={() => setPainLevel(2)}>2</div>
                                    <div className={painLevel && painLevel >= 3 ? styles.selectedRatingdiv : styles.ratingdiv} onClick={() => setPainLevel(3)}>3</div>
                                    <div className={painLevel && painLevel >= 4 ? styles.selectedRatingdiv : styles.ratingdiv} onClick={() => setPainLevel(4)}>4</div>
                                    <div className={painLevel && painLevel >= 5 ? styles.selectedRatingdiv : styles.ratingdiv} onClick={() => setPainLevel(5)}>5</div>
                                    <div className={painLevel && painLevel >= 6 ? styles.selectedRatingdiv : styles.ratingdiv} onClick={() => setPainLevel(6)}>6</div>
                                    <div className={painLevel && painLevel >= 7 ? styles.selectedRatingdiv : styles.ratingdiv} onClick={() => setPainLevel(7)}>7</div>
                                    <div className={painLevel && painLevel >= 8 ? styles.selectedRatingdiv : styles.ratingdiv} onClick={() => setPainLevel(8)}>8</div>
                                    <div className={painLevel && painLevel >= 9 ? styles.selectedRatingdiv : styles.ratingdiv} onClick={() => setPainLevel(9)}>9</div>
                                    <div className={painLevel && painLevel >= 10 ? styles.selectedRatingdiv : styles.ratingdiv} onClick={() => setPainLevel(10)}>10</div>
                                </div>
                            </div>

                            <div className={styles.addNewNoteDiv}>
                                <Input
                                    className={styles.notesEditor}
                                    type="text"
                                    placeholder="Start typing..."
                                    value={newNotes}
                                    multiline={true}
                                    onChange={(event) => setNewNotes(event.target.value)}
                                    disableUnderline="true"
                                />
                            </div>
                            <div className={styles.timeStampStyles}>{timestamp.toString()}</div>
                        </Grid>
                    </div>
                    <div className={styles.loaderDiv}>
                        {showLoader && <CircularProgress style={{ color: `#4E253A` }} ></CircularProgress>}
                        {displayStatus && (manualEntryStatus ? (<div className={styles.loaderMessageSuccess}>Measurement submitted successfully!</div>) : (<div className={styles.loaderMessageFailure}>Measurement submission failed.</div>))}
                    </div>
                    <div className={styles.buttonsDiv}>
                        <div className={styles.retakeButton} onClick={() => handleQuickDrawerClose()}>
                            Cancel
                        </div>
                        {(displayStatus && !manualEntryStatus) ? (<div className={styles.completeButton} onClick={() => retakeMeasurements()}>
                            Retake
                        </div>) : (complete ? <div className={styles.completeButton} onClick={() => handleSubmitFinalMeasurements()}>
                            Submit
                        </div> : <div className={styles.uncompleteButton}>
                            Submit
                        </div>)}
                    </div>
                </>)}

                {stepNumber === 3 && !measurementSuccess && (<>
                    <div className={styles.mlInsDiv}>
                    </div>
                    <div className={styles.cancelButton} onClick={() => handleQuickDrawerClose()}>
                        Cancel
                    </div>
                    <div className={styles.stepTwoWarningDiv}>
                        The measurement upload failed. Please retry manually entering the measurements.
                    </div>
                </>)}
            </Drawer>
        </div >
    );
});
export default MEPannel;