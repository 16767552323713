import React, { useState, useEffect, useContext } from "react";
import styles from "./PatientStatusWrapper.module.css";
import { useParams } from "react-router-dom";
import PatientItem from "./PatientItem/PatientItem";
import GetPatientStatus from "../../../../API/GetPatientStatus/GetPatientStatus";
import { PatientContext } from "../../../../Store/PatientDataProvider";

export default function PatientStatusWrapper() {
  let curPatientId = useParams();

  let patientInfo = useContext(PatientContext);
  let refreshData = patientInfo.isRefresh;

  const [currentPatientStatus, setCurrentPatientStatus] = useState("");
  useEffect(() => {
    GetPatientStatus(curPatientId.patientId).then((res) => {
      setCurrentPatientStatus(res);
      console.log("STATUS", res);
    });
  }, [curPatientId.patientId, refreshData]);
  return (
    <div>
      <div className={styles.container}>
        <div className={styles.devicesWrapper}>
          <div className={styles.listContainer}>
            <PatientItem currentPatientStatus={currentPatientStatus} />
          </div>
          <div className={styles.listContainerFooter}>
            <div className={styles.footerContainer}>
              {/* <button className={styles.addNewDevicesButton} type="button">
                Add Status
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
