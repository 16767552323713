import React, { useContext } from "react";
import styles from "./PatientRegistration.module.css";
import { PatientRegistrationForm } from "./PatientRegistrationForm.jsx";

// Context imports
import { PatientContext } from "../../../../Store/PatientDataProvider";
import { UserContext } from "../../../../Store/UserContext";

export default function PatientRegistration(props) {
  //localStorage.removeItem("PGUID");
  console.log("Props received ", props);

  // Getting Patient ID from the Context API
  const patientInfo = useContext(PatientContext);
  var subjectId = patientInfo.subjectId;

  // Getting form structure from the API Context
  const userContext = useContext(UserContext);
  let userName =
    userContext.userInformation.firstName +
    " " +
    userContext.userInformation.lastName;

    let locations = userContext.userInformation.locations;
    let projects = userContext.userInformation.projects;

  return (
    <div className={styles.wrapperDiv}>
      <PatientRegistrationForm
        userName={userName}
        onHandleBack={props.onHandleBack}
        onHandleNext={props.onHandleNext}
        onPatientIdUpdate={props.onPatientIdUpdate}
      />
    </div>
  );
}
