import axios from "axios";
import domainCF from "../domainCF.js";

export default function GetAvailableDevicesByProjectAndLocation(projectId, locationId) {
  var token = sessionStorage.getItem("IdToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const GET_AVAILABLE_MOUTHLAB = `${domainCF}/api/getAvailableDevicesByProjectAndLocation?projectId=${projectId}&locationId=${locationId}`;

  return axios.get(GET_AVAILABLE_MOUTHLAB, { headers }).then(function (response) {
    console.log("this is from api calling file", response.data);
    return response.data.devices;
  });
}
