import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedProjectIdAndLocationId, getUserProfile } from "../../../../../../Reducer/selectors/DashboardSelector";
import { getMouthLabLogselector } from "../../../../../../Reducer/selectors/PatientSelector";
import { getMouthLabLogs, setProjectIdAndLocationId, setSelectedPageName } from "../../../../../../Actions";
import { globalConstants } from "../../../../../../Constants/GlobalConstants";
import ChooseDevices from "../../../ChooseDevices/ChooseDevices";


export default function MlLogs() {
  const dispatch = useDispatch();
  const profile = useSelector(getUserProfile);
  const mouthLabLogs = useSelector(getMouthLabLogselector);

  useEffect(() => {
    if (profile.projects && profile.projects.length > 0) {
      dispatch(
        setProjectIdAndLocationId({
          projectId: profile.projects[0].id,
          locationId: profile.locations[0].id,
        }));
//      dispatch(getMouthLabLogs({ projectId: profile.projects[0].id, locationId: profile.locations[0].id }));
    }
    dispatch(setSelectedPageName('logs'));
  }, [profile]);

  return (
    <ChooseDevices mouthLabLogs={mouthLabLogs} />
  );
}
