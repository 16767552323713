import React, { useState, useContext, useEffect, useRef } from "react";
import styles from "./ManualViatlsTableWrapper.module.css";
import { useParams } from "react-router-dom";
import { format } from "date-fns";
import TableHeaderManualVitals from "../SecondaryTable/TableHeaderManualVitals";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";
import { DateContext } from "../../../../../Store/DateProvider";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { useDispatch, useSelector } from "react-redux";
import { getSubjectVitalsList } from "../../../../../../Actions";
import { getSubjectVitalsListSelector } from "../../../../../../Reducer/selectors/PatientSelector";
import Grid from "@material-ui/core/Grid";
import Modal from "@material-ui/core/Modal";
import { RiCloseCircleFill } from "react-icons/ri";
import IconButton from "@material-ui/core/IconButton";
import { ExportPatientVitals } from "../VitalsTableWrapper/ExportPatientVitals";
import { getSelectedSubjectId } from "../../../../../../Reducer/selectors/DashboardSelector.js";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@mui/material/Button";
import { getCurrentPatientGUID, getPageLoader } from "../../../../../../Reducer/selectors/UtilSelector";
import { CircularProgress } from "@mui/material";

const useStyles = makeStyles(() => ({
  tooltip: {
    marginTop: 5,
    font: "12px",
    background: "black",
    fontFamily: "GTWalsheimProRegular",
  },
}));

export default function ManualVitalsTableWrapper() {
  // Date context defined to get access to context information
  const dateInfo = useContext(DateContext);
  const classes = useStyles();
  const loader = useSelector(getPageLoader);

  const [dateRange, setDateRange] = useState([
    {
      startDate: dateInfo.fromDate,
      endDate: dateInfo.toDate,
      key: "selection",
    },
  ]);
  const [exportPopup, setExportPopup] = useState(false);
  const openExportPopup = () => {
    setExportPopup(true);
  };
  const closeExportPopup = () => {
    setExportPopup(false);
  };

  var start_date = format(dateRange[0].startDate, "yyyy-MM-dd");
  var end_date = format(addDays(dateRange[0].endDate, 1), "yyyy-MM-dd");

  const [isCalOpen, setIsCalOpen] = useState(false);

  const sId = useSelector(getSelectedSubjectId);
  let curPatientIdFromPrams = useParams();
  let curPatientGUID = useSelector(getCurrentPatientGUID);

  let currentPatientGUID = curPatientGUID ? curPatientGUID : curPatientIdFromPrams.patientId;
  /* console.log("CURRENT USER GUID: ", currentPatientGUID); */

  const refOne = useRef(null);
  const dispatch = useDispatch();
  const subjectVitalsData = useSelector(getSubjectVitalsListSelector);

  const fetchPatientDetials = (event) => {
    event.preventDefault();
    dateInfo.setFromDate(dateRange[0].startDate);
    dateInfo.setToDate(dateRange[0].endDate);
    dispatch(getSubjectVitalsList({ subjectId: currentPatientGUID, startDate: start_date, endDate: end_date }));
  };

  useEffect(() => {
    document.addEventListener("keydown", hideOnEscape, true);
    document.addEventListener("click", hideOnClickOutside, true);

    dispatch(getSubjectVitalsList({ subjectId: currentPatientGUID, startDate: start_date, endDate: end_date }));
  }, [curPatientGUID]);

  const handleCalChanges = (item) => {
    setDateRange([item.selection]);
  };

  // hide dropdown on ESC press
  const hideOnEscape = (e) => {
    if (e.key === "Escape") {
      setIsCalOpen(false);
    }
  };

  // Hide dropdown on outside click
  const hideOnClickOutside = (e) => {
    if (refOne.current && !refOne.current.contains(e.target)) {
      setIsCalOpen(false);
    }
  };

  const handleCalClick = (event) => {
    event.preventDefault();
    if (isCalOpen) {
      setIsCalOpen(false);
    } else {
      setIsCalOpen(true);
    }
  };

  const exportData = subjectVitalsData.map((item) => ({
    time: item.timestamp,
    mlid: item.deviceId,
    temp: item.temperature.value,
    bp: item.sbp.value + "/" + item.dbp.value,
    pr: item.pulseRate.value,
    spo2: item.spo2.value,
    hr: item.heartRate.value,
    hrv: item.hrv.value,
    rr: item.breathingRate.value,
    fev1: item.fev1.value,
    pef: item.pef.value,
  }));

  const headers = [
    { label: "Time", key: "time" },
    { label: "ML Id", key: "mlid" },
    { label: "Temp (F)", key: "temp" },
    { label: "BP (mmHg)", key: "bp" },
    { label: "PR (bpm)", key: "pr" },
    { label: "SpO2 (%)", key: "spo2" },
    { label: "HR (bpm)", key: "hr" },
    { label: "HRV (ms)", key: "hrv" },
    { label: "RR (br/min)", key: "rr" },
    { label: "FEV1 (L)", key: "fev1" },
    { label: "PEF (L/sec)", key: "pef" },
  ];

  return (
    <div>
      <div className={styles.containerDiv}>
        <div className={styles.datePickerDiv}>
          <Tooltip title="Choose another date range" classes={{ tooltip: classes.tooltip }}>
            <CalendarMonthIcon onClick={(event) => handleCalClick(event)}></CalendarMonthIcon>
          </Tooltip>
          <div className={styles.dateInputDiv}>
            <input
              value={`${format(dateRange[0].startDate, "MM/dd/yyyy")}  |  ${format(dateRange[0].endDate, "MM/dd/yyyy")}`}
              readOnly
              className={styles.dateInput}
              onClick={(event) => handleCalClick(event)}
            />
          </div>
          <div>
            <Button startIcon={<TableChartOutlinedIcon />} onClick={(event) => fetchPatientDetials(event)} className={styles.exportButton}>
              View
            </Button>
            {/* <Button startIcon={<FileDownloadOutlinedIcon />} onClick={openExportPopup} className={styles.exportButton}>
              Export
            </Button> */}
          </div>
        </div>
        {isCalOpen && (
          <div className={styles.datePickerWrapper} ref={refOne}>
            <DateRangePicker
              onChange={(item) => handleCalChanges(item)}
              editableDateInputs={false}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={2}
              ranges={dateRange}
              direction="horizontal"
              maxDate={addDays(new Date(), 1)}
              rangeColors={["#4E253A"]}
            />
          </div>
        )}
      </div>
      {subjectVitalsData && (
        <div className={styles.tableContainerStyles}>
          {loader ? (
            <div className={styles.circularProgressDiv} style={{ backgroundColor: `#FFFFFF`, height: `100%` }}>
              <CircularProgress thickness={5.0} style={{ color: `#4E253A` }}></CircularProgress>
            </div>
          ) : (
            <TableHeaderManualVitals patientVitals={subjectVitalsData} />
          )}
        </div>
      )}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={styles.modal}
        open={exportPopup}
        onClose={closeExportPopup}
      >
        <div className={styles.paper}>
          <div className={styles.menuTitle}>
            <div className={styles.titleText}>Export Data?</div>
            <div>
              <IconButton aria-label="delete">
                <RiCloseCircleFill size={25} style={{ color: "#4E253A" }} onClick={closeExportPopup} />
              </IconButton>
            </div>
          </div>

          <div className={styles.patientListDiv}>
            <Grid container>
              <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
                <ExportPatientVitals
                  exportData={exportData}
                  headers={headers}
                  subjectId={sId.selectedSubjectId}
                  startDate={dateRange[0].startDate}
                  endDate={dateRange[0].endDate}
                  closeModal={closeExportPopup}
                />
              </Grid>
            </Grid>
          </div>
        </div>
      </Modal>
    </div>
  );
}
