export function getPatientsListSelector({ patientReducer }) {
    if (
        patientReducer &&
        patientReducer.patientsList
    )
        return patientReducer.patientsList;
    else return {};
}

export function getPatientsListLoader({ patientReducer }) {
    if (
        patientReducer &&
        patientReducer.loader
    )
        return true;
    else return false;
}

export function getPatientVitalsListSelector({ patientReducer }) {
    if (
        patientReducer &&
        patientReducer.patientVitalsList
    )
        return patientReducer.patientVitalsList;
    else return [];

}

export function getBaselineSelector({ patientReducer }) {
    if (
        patientReducer &&
        patientReducer.latestBaseline
    )
        return patientReducer.latestBaseline;
    else return {};
}

export function getSubjectVitalsListSelector({ patientReducer, notesReducer }) {
    if (patientReducer && patientReducer.subjectVitalsList) {
        if (notesReducer && notesReducer.patientNotes) {
            patientReducer.subjectVitalsList.forEach(vital => {
                const findNotes = notesReducer.patientNotes.find(note => note.recordId === vital.recordId);
                if (findNotes) {
                    vital.hasNotes = true;
                }
            });
        }
        return patientReducer.subjectVitalsList;
    } else return [];

}

export function getEcgPlotSelector({ patientReducer }) {
    if (
        patientReducer &&
        patientReducer.ecgPlot
    )
        return patientReducer.ecgPlot;
    else return {};
}

export function getRfmPlotSelector({ patientReducer }) {
    if (
        patientReducer &&
        patientReducer.rfmPlot
    )
        return patientReducer.rfmPlot;
    else return {};
}

export function getLfgPlotSelector({ patientReducer }) {
    if (
        patientReducer &&
        patientReducer.lfgPlot
    )
        return patientReducer.lfgPlot;
    else return {};
}

export function getMouthLabLogselector({ patientReducer }) {
    if (
        patientReducer &&
        patientReducer.mouthLabLogs
    )
        return patientReducer.mouthLabLogs;
}

export function getAddSubjectSelector({ patientReducer }) {
    if (
        patientReducer &&
        patientReducer.addSubject
    )
        return patientReducer.addSubject;
    else return {};
}

export function getTimerStatus({ patientReducer }) {
    if (
        patientReducer &&
        patientReducer.timerStatus
    )
        return patientReducer.timerStatus;
    else return false;
}

export function getTimerCount({ patientReducer }) {
    if (
        patientReducer &&
        patientReducer.timerCount
    )
        return patientReducer.timerCount;
    else return 0;
}

export function getSbpPayloadSelector({ patientReducer }) {
    if (patientReducer &&
        patientReducer.sbpPayload
    )
        return patientReducer.sbpPayload;
    else return [];
};

export function getDbpPayloadSelector({ patientReducer }) {
    if (patientReducer &&
        patientReducer.dbpPayload
    )
        return patientReducer.dbpPayload;
    else return [];
};

export function getSpo2PayloadSelector({ patientReducer }) {
    if (patientReducer &&
        patientReducer.spo2Payload
    )
        return patientReducer.spo2Payload;
    else return [];
};

export function getBrPayloadSelector({ patientReducer }) {
    if (patientReducer &&
        patientReducer.brPayload
    )
        return patientReducer.brPayload;
    else return [];
};

export function getHrPayloadSelector({ patientReducer }) {
    if (patientReducer &&
        patientReducer.hrPayload
    )
        return patientReducer.hrPayload;
    else return [];
};

export function getHrvPayloadSelector({ patientReducer }) {
    if (patientReducer &&
        patientReducer.hrvPayload
    )
        return patientReducer.hrvPayload;
    else return [];
};

export function getTempPayloadSelector({ patientReducer }) {
    if (patientReducer &&
        patientReducer.tempPayload
    )
        return patientReducer.tempPayload;
    else return [];
};

export function getPrPayloadSelector({ patientReducer }) {
    if (patientReducer &&
        patientReducer.prPayload
    )
        return patientReducer.prPayload;
    else return [];
};

export function getFevPayloadSelector({ patientReducer }) {
    if (patientReducer &&
        patientReducer.fevPayload
    )
        return patientReducer.fevPayload;
    else return [];
};

export function getPefPayloadSelector({ patientReducer }) {
    if (patientReducer &&
        patientReducer.pefPayload
    )
        return patientReducer.pefPayload;
    else return [];
};