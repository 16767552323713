import { createAction } from "@reduxjs/toolkit";
import { patientConstants } from "../Constants/PatientConstants";

export const getPatientsList = createAction(
  patientConstants.GET_PATIENTS_LIST,
  (payload) => ({ payload })
);

export const getPatientsListSuccess = createAction(
  patientConstants.GET_PATIENTS_LIST_SUCCESS,
  (payload) => ({ payload })
);

export const getPatientVitalsList = createAction(
  patientConstants.GET_PATIENT_VITALS_LIST,
  (payload) => ({ payload })
)

export const getPatientsVitalsSuccess = createAction(
  patientConstants.GET_PATIENT_VITALS_LIST_SUCCESS,
  (payload) => ({ payload })
)

export const getSubjectVitalsList = createAction(
  patientConstants.GET_SUBJECT_VITALS_LIST,
  (payload) => ({ payload })
)

export const getSubjectVitalsListSuccess = createAction(
  patientConstants.GET_SUBJECT_VITALS_LIST_SUCCESS,
  (payload) => ({ payload })
)

export const getEcgPlot = createAction(
  patientConstants.GET_ECG_PLOT,
  (payload) => ({ payload })
)

export const getEcgPlotSuccess = createAction(
  patientConstants.GET_ECG_PLOT_SUCCESS,
  (payload) => ({ payload })
)

export const getRfmPlot = createAction(
  patientConstants.GET_RFM_PLOT,
  (payload) => ({ payload })
)

export const getRfmPlotSuccess = createAction(
  patientConstants.GET_RFM_PLOT_SUCCESS,
  (payload) => ({ payload })
)

export const getLfgPlot = createAction(
  patientConstants.GET_LFG_PLOT,
  (payload) => ({ payload })
)

export const getLfgPlotSuccess = createAction(
  patientConstants.GET_LFG_PLOT_SUCCESS,
  (payload) => ({ payload })
)

export const getMouthLabLogs = createAction(
  patientConstants.GET_MOUTHLAB_LOGS,
  (payload) => ({ payload })
)

export const getMouthLabLogSuccess = createAction(
  patientConstants.GET_MOUTHLAB_LOGS_SUCCESS,
  (payload) => ({ payload })
)

export const getAddSubject = createAction(
  patientConstants.GET_ADD_SUBJECT,
  (payload) => ({ payload })
)

export const getAddSubjectSuccess = createAction(
  patientConstants.GET_ADD_SUBJECT_SUCCESS,
  (payload) => ({ payload })
)

export const getLatestBaseline = createAction(
  patientConstants.GET_LATEST_BASELINE,
  (payload) => ({ payload })
)

export const getLatestBaselineSuccess = createAction(
  patientConstants.GET_LATEST_BASELINE_SUCCESS,
  (payload) => ({ payload })
)

export const getSubectVitalsListFailure = createAction(patientConstants.GET_SUBJECT_VITALS_LIST_FAILURE);

export const getPatientsVitalsFailure = createAction(patientConstants.GET_PATIENT_VITALS_LIST_FAILURE);

export const getLatestBaselineFailure = createAction(patientConstants.GET_LATEST_BASELINE_FAILURE);

export const getPatientsListFailure = createAction(patientConstants.GET_PATIENTS_LIST_FAILURE);

export const getEcgPlotFailure = createAction(patientConstants.GET_ECG_PLOT_FAILURE);

export const getRfmPlotFailure = createAction(patientConstants.GET_RFM_PLOT_FAILURE);

export const getLfgPlotFailure = createAction(patientConstants.GET_LFG_PLOT_FAILURE);

export const getMouthLabLogsFailure = createAction(patientConstants.GET_MOUTHLAB_LOGS_FAILURE);

export const getAddSubjectFailure = createAction(patientConstants.GET_ADD_SUBJECT_FAILURE);

export const setTimerStatus = createAction(patientConstants.SET_TIMER_STATUS);

export const setTimerCount = createAction(patientConstants.SET_TIMER_COUNT);

export const putStartTimer = createAction(
  patientConstants.PUT_START_TIMER,
  (payload) => ({ payload })
)

export const putStartTimerSuccess = createAction(
  patientConstants.PUT_START_TIMER_SUCCESS,
  (payload) => ({ payload })
)

export const putStartTimerFailure = createAction(patientConstants.PUT_START_TIMER_FAILURE);

export const putEndTimer = createAction(
  patientConstants.PUT_END_TIMER,
  (payload) => ({ payload })
)

export const putEndTimerSuccess = createAction(
  patientConstants.PUT_END_TIMER_SUCCESS,
  (payload) => ({ payload })
)

export const putEndTimerFailure = createAction(patientConstants.PUT_END_TIMER_FAILURE);

export const putAbortedEndTimer = createAction(
  patientConstants.PUT_ABORT_END_TIMER,
  (payload) => ({ payload })
)

export const putAbortedEndTimerSuccess = createAction(
  patientConstants.PUT_ABORT_END_TIMER_SUCCESS,
  (payload) => ({ payload })
)

export const putAbortedEndTimerFailure = createAction(patientConstants.PUT_ABORT_END_TIMER_FAILURE);


export const getSbpPayload = createAction(
  patientConstants.GET_SBP_PAYLOAD,
  (payload) => ({ payload })
)

export const getSbpPayloadSuccess = createAction(
  patientConstants.GET_SBP_PAYLOAD_SUCCESS,
  (payload) => ({ payload })
)

export const getSbpPayloadFailure = createAction(patientConstants.GET_SBP_PAYLOAD_FAILURE);

export const getDbpPayload = createAction(
  patientConstants.GET_DBP_PAYLOAD,
  (payload) => ({ payload })
)

export const getDbpPayloadSuccess = createAction(
  patientConstants.GET_DBP_PAYLOAD_SUCCESS,
  (payload) => ({ payload })
)

export const getDbpPayloadFailure = createAction(patientConstants.GET_DBP_PAYLOAD_FAILURE);


export const getSpo2Payload = createAction(
  patientConstants.GET_SPO2_PAYLOAD,
  (payload) => ({ payload })
)

export const getSpo2PayloadSuccess = createAction(
  patientConstants.GET_SPO2_PAYLOAD_SUCCESS,
  (payload) => ({ payload })
)

export const getSpo2PayloadFailure = createAction(patientConstants.GET_SPO2_PAYLOAD_FAILURE);


export const getBrPayload = createAction(
  patientConstants.GET_BR_PAYLOAD,
  (payload) => ({ payload })
)

export const getBrPayloadSuccess = createAction(
  patientConstants.GET_BR_PAYLOAD_SUCCESS,
  (payload) => ({ payload })
)

export const getBrPayloadFailure = createAction(patientConstants.GET_BR_PAYLOAD_FAILURE);


export const getHrPayload = createAction(
  patientConstants.GET_HR_PAYLOAD,
  (payload) => ({ payload })
)

export const getHrPayloadSuccess = createAction(
  patientConstants.GET_HR_PAYLOAD_SUCCESS,
  (payload) => ({ payload })
)

export const getHrPayloadFailure = createAction(patientConstants.GET_HR_PAYLOAD_FAILURE);


export const getHrvPayload = createAction(
  patientConstants.GET_HRV_PAYLOAD,
  (payload) => ({ payload })
)

export const getHrvPayloadSuccess = createAction(
  patientConstants.GET_HRV_PAYLOAD_SUCCESS,
  (payload) => ({ payload })
)

export const getHrvPayloadFailure = createAction(patientConstants.GET_HRV_PAYLOAD_FAILURE);


export const getTempPayload = createAction(
  patientConstants.GET_TEMP_PAYLOAD,
  (payload) => ({ payload })
)

export const getTempPayloadSuccess = createAction(
  patientConstants.GET_TEMP_PAYLOAD_SUCCESS,
  (payload) => ({ payload })
)

export const getTempPayloadFailure = createAction(patientConstants.GET_TEMP_PAYLOAD_FAILURE);


export const getPrPayload = createAction(
  patientConstants.GET_PR_PAYLOAD,
  (payload) => ({ payload })
)

export const getPrPayloadSuccess = createAction(
  patientConstants.GET_PR_PAYLOAD_SUCCESS,
  (payload) => ({ payload })
)

export const getPrPayloadFailure = createAction(patientConstants.GET_PR_PAYLOAD_FAILURE);


export const getFevPayload = createAction(
  patientConstants.GET_FEV_PAYLOAD,
  (payload) => ({ payload })
)

export const getFevPayloadSuccess = createAction(
  patientConstants.GET_FEV_PAYLOAD_SUCCESS,
  (payload) => ({ payload })
)

export const getFevPayloadFailure = createAction(patientConstants.GET_FEV_PAYLOAD_FAILURE);


export const getPefPayload = createAction(
  patientConstants.GET_PEF_PAYLOAD,
  (payload) => ({ payload })
)

export const getPefPayloadSuccess = createAction(
  patientConstants.GET_PEF_PAYLOAD_SUCCESS,
  (payload) => ({ payload })
)

export const getPefPayloadFailure = createAction(patientConstants.GET_PEF_PAYLOAD_FAILURE);