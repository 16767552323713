export const utilsConstants = {
  SET_SHOW_LOADER: "SET_SHOW_LOADER",
  SET_SHOW_PATIENT_PROFILE_PAGE_LOADER: 'SET_SHOW_PATIENT_PROFILE_PAGE_LOADER',
  SET_SHOW_PATIENT_TIMER_DRAWER_LOADER: 'SET_SHOW_PATIENT_TIMER_DRAWER_LOADER',
  SET_SHOW_PATIENT_GRAPHS_LOADER: 'SET_SHOW_PATIENT_GRAPHS_LOADER',
  SET_SHOW_PATIENT_AIDI_LOADER: 'SET_SHOW_PATIENT_AIDI_LOADER',
  SET_SHOW_AIDI_VALIDATION_LOADER: 'SET_SHOW_AIDI_VALIDATION_LOADER',
  SET_SHOW_PATIENT_VITALS_LOADER: 'SET_SHOW_PATIENT_VITALS_LOADER',
  SET_SHOW_PATIENT_NOTES_LOADER: 'SET_SHOW_PATIENT_NOTES_LOADER',
  SET_SHOW_ASSIGN_ML_LOADER: 'SET_SHOW_ASSIGN_ML_LOADER',
  SET_PATIENT_LIST_LOADER: 'SET_PATIENT_LIST_LOADER',
  SET_PATIENT_PROFILE_LOADER: 'SET_PATIENT_PROFILE_LOADER',
  SET_SHOW_CHOOSE_ML_LOADER: 'SET_SHOW_CHOOSE_ML_LOADER',

  //ML Vitals page current user
  SET_CURRENT_PATIENT_GUID: 'SET_CURRENT_PATIENT_GUID',
  SET_CURRENT_PATIENT_SUBJECTID: 'SET_CURRENT_PATIENT_SUBJECTID',

  // All patient details
  SET_ALL_PATIENTS_LIST: 'SET_ALL_PATIENTS_LIST',
  SET_PATIENT_MAINTAB: 'SET_PATIENT_MAINTAB',

  //Trends
  SET_SPO2_BR_EXPAND: 'SET_SPO2_BR_EXPAND',
  SET_HR_HRV_EXPAND: 'SET_HR_HRV_EXPAND',
  SET_BP_EXPAND: 'SET_BP_EXPAND',
  SET_TEMP_PR_EXPAND: 'SET_TEMP_PR_EXPAND',
  SET_FEV_PEF_EXPAND: 'SET_FEV_PEF_EXPAND',
  SET_G_W_P_EXPAND: 'SET_G_W_P_EXPAND',
  SET_SELECTED_UNIT_FILTERS: 'SET_SELECTED_UNIT_FILTERS',
  SET_SELECTED_FLOOR_FILTERS: 'SET_SELECTED_FLOOR_FILTERS',

};
