import { createAction } from "@reduxjs/toolkit";
import { nursingDashboardConstants } from "../Constants/NursingDashboardConstants";

export const getPCCPatientsList = createAction(
    nursingDashboardConstants.GET_PCC_PATIENTS_LIST,
    (payload) => ({ payload })
);

export const getPCCPatientsListSuccess = createAction(
    nursingDashboardConstants.GET_PCC_PATIENTS_LIST_SUCCESS,
    (payload) => ({ payload })
);

export const getPCCPatientsListFailure = createAction(nursingDashboardConstants.GET_PCC_PATIENTS_LIST_FAILURE);

export const getPCCUserProfile = createAction(
    nursingDashboardConstants.GET_PCC_USER_PROFILE_REQUEST,
    (payload) => ({ payload })
);

export const getPCCUserProfileSuccess = createAction(
    nursingDashboardConstants.GET_PCC_USER_PROFILE_SUCCESS,
    (payload) => ({ payload })
);

export const getPCCUserProfileFailure = createAction(nursingDashboardConstants.GET_PCC_USER_PROFILE_FAILURE);

export const setPrevMeasurement = createAction(
    nursingDashboardConstants.SET_PREV_MEASUREMENT,
    (payload) => ({ payload })
);

export const setLatestMeasurement = createAction(
    nursingDashboardConstants.SET_LATEST_MEASUREMENT,
    (payload) => ({ payload })
);

export const setLatestCalMeasurement = createAction(
    nursingDashboardConstants.SET_LATEST_CAL_MEASUREMENT,
    (payload) => ({ payload })
);

export const updateExtraMeasurementParams = createAction(
    nursingDashboardConstants.UPDATE_EXTRA_MEASUREMENT_PARAMS,
    (payload) => ({ payload })
);

export const updateExtraMeasurementParamsSuccess = createAction(
    nursingDashboardConstants.UPDATE_EXTRA_MEASUREMENT_PARAMS_SUCCESS,
    (payload) => ({ payload })
);

export const updateExtraMeasurementParamsFailure = createAction(nursingDashboardConstants.UPDATE_EXTRA_MEASUREMENT_PARAMS_FAILURE);

export const uploadManualEntry = createAction(
    nursingDashboardConstants.UPLOAD_MANUAL_ENTRY,
    (payload) => ({ payload })
);

export const uploadManualEntrySuccess = createAction(
    nursingDashboardConstants.UPLOAD_MANUAL_ENTRY_SUCCESS,
    (payload) => ({ payload })
);

export const uploadManualEntryFailure = createAction(nursingDashboardConstants.UPLOAD_MANUAL_ENTRY_FAILURE);

export const setUploadManualEntryStatus = createAction(
    nursingDashboardConstants.SET_UPLOAD_MANUAL_ENTRY_STATUS,
    (payload) => ({ payload })
);

export const setCalibrationStatus = createAction(
    nursingDashboardConstants.SET_CALIBRATION_STATUS,
    (payload) => ({ payload })
);

export const setPCCPatientListPageNumber = createAction(
    nursingDashboardConstants.SET_PCC_PAGE_NUMBER,
    (payload) => ({ payload })
);

export const setPCCPatientListPageSize = createAction(
    nursingDashboardConstants.SET_PCC_PAGE_SIZE,
    (payload) => ({ payload })
);

export const searchPccPatient = createAction(
    nursingDashboardConstants.SEARCH_PCC_PATIENT,
    (payload) => ({ payload })
);

export const searchPccPatientSuccess = createAction(
    nursingDashboardConstants.SEARCH_PCC_PATIENT_SUCCESS,
    (payload) => ({ payload })
);

export const searchPccPatientFailure = createAction(nursingDashboardConstants.SEARCH_PCC_PATIENT_FAILURE);

export const getSubjectHasMl = createAction(
    nursingDashboardConstants.GET_SUBJECT_HAS_ML,
    (payload) => ({ payload })
);

export const getSubjectHasMlSuccess = createAction(
    nursingDashboardConstants.GET_SUBJECT_HAS_ML_SUCCESS,
    (payload) => ({ payload })
);

export const getSubjectHasMlFailure = createAction(nursingDashboardConstants.GET_SUBJECT_HAS_ML_FAILURE);

export const getSubjectIdFromDeviceAssignment = createAction(
    nursingDashboardConstants.GET_SUBJECT_FROM_DEVICE,
    (payload) => ({ payload })
);

export const getSubjectIdFromDeviceAssignmentSuccess = createAction(
    nursingDashboardConstants.GET_SUBJECT_FROM_DEVICE_SUCCESS,
    (payload) => ({ payload })
);

export const getSubjectIdFromDeviceAssignmentFailure = createAction(nursingDashboardConstants.GET_SUBJECT_FROM_DEVICE_FAILURE);

export const setSubjectFromDevice = createAction(
    nursingDashboardConstants.SET_SUBJECT_FROM_DEVICE,
    (payload) => ({ payload })
);

export const setShowQMDrawer = createAction(nursingDashboardConstants.SET_SHOW_QM_DRAWER, (payload) => ({ payload }));

export const setShowMEDrawer = createAction(nursingDashboardConstants.SET_SHOW_ME_DRAWER, (payload) => ({ payload }));

export const setShowCMDrawer = createAction(
    nursingDashboardConstants.SET_SHOW_CM_DRAWER,
    (payload) => ({ payload })
);

export const updateFacilityInfo = createAction(
    nursingDashboardConstants.UPDATE_FACILITY_INFO,
    (payload) => ({ payload })
);

export const updateFacilityInfoSuccess = createAction(
    nursingDashboardConstants.UPDATE_FACILITY_INFO_SUCCESS,
    (payload) => ({ payload })
);

export const updateFacilityInfoFailure = createAction(nursingDashboardConstants.UPDATE_FACILITY_INFO_FAILURE);

export const setFloorEdit = createAction(
    nursingDashboardConstants.SET_FLOOR_EDIT,
    (payload) => ({ payload })
);

export const setUnitEdit = createAction(
    nursingDashboardConstants.SET_UNIT_EDIT,
    (payload) => ({ payload })
);

export const setRoomEdit = createAction(
    nursingDashboardConstants.SET_ROOM_EDIT,
    (payload) => ({ payload })
);

export const setBedEdit = createAction(
    nursingDashboardConstants.SET_BED_EDIT,
    (payload) => ({ payload })
);

export const setShowSaveCancelForFacility = createAction(
    nursingDashboardConstants.SET_SHOW_SAVE_CANCEL_FACILITY,
    (payload) => ({ payload })
);

export const setShowFooterButtons = createAction(
    nursingDashboardConstants.SET_SHOW_FOOTER_BUTTONS,
    (payload) => ({ payload })
);


