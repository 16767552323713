import React, {
    forwardRef,
    useImperativeHandle,
    useEffect,
    useRef,
    useState,
} from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import SensorsIcon from '@mui/icons-material/Sensors';
import WarningIcon from '@mui/icons-material/Warning';
import Avatar from "@material-ui/core/Avatar";
import ProfileIcon from "../../../../Assets/Images/ProfileIcon.png";
import styles from "./CalibrationMeasurementPannel.module.css";
import Step1Img from "../../../../Assets/Images/__01-Thumb-Placement.png";
import Step2Img from "../../../../Assets/Images/__02-Breath-Normally.png";
import Step3Img from "../../../../Assets/Images/__03-Blue-Light.png";
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import { getPCCUserProfileSelector, getLatestMeasurement, getPrevMeasurement, getCalibrationStatus, getShowCMDrawer, getLatestCalMeasurement } from "../../../../Reducer/selectors/NursingDashboardSelector";
import { getIsCalOngoing, getSelectedPCCPatientSelector, getUserProfile } from "../../../../Reducer/selectors/DashboardSelector";
import { useSelector, useDispatch } from "react-redux";
import { getPatientVitalsList, setIsCalibrationOngoing, setLatestCalMeasurement, setShowFooterButtons } from "../../../../Actions";
import { getPatientVitalsListSelector } from "../../../../Reducer/selectors/PatientSelector";
import { getSelectedMlIdSelector } from "../../../../Reducer/selectors/DashboardSelector";
import PostUnassignMouthLabFromSubject from "../../../API/PostUnassignMouthLabFromSubject/PostUnassignMouthLabFromSubject";
import { setPrevMeasurement, setLatestMeasurement } from "../../../../Actions";
import Grid from "@material-ui/core/Grid";
import Input from '@material-ui/core/Input';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { updateExtraMeasurementParams } from "../../../../Actions";
import { setShowLoader } from "../../../../Actions/UtilActions";
import { getPageLoader } from "../../../../Reducer/selectors/UtilSelector";
import UnassignMl from "../../HomePage/DeviceLog/MouthLabLogs/MLActions/UnassignMl";
import Modal from "@material-ui/core/Modal";
import { RiCloseCircleFill } from "react-icons/ri";
import IconButton from "@material-ui/core/IconButton";
import { setShowCMDrawer } from "../../../../Actions";

import { ReactComponent as TempIcon } from "../../../../Assets/icons/01-Temp.svg";
import { ReactComponent as BPIcon } from "../../../../Assets/icons/02-BP.svg";
import { ReactComponent as RRIcon } from "../../../../Assets/icons/03-RR.svg";
import { ReactComponent as PRIcon } from "../../../../Assets/icons/04-PR.svg";
import { ReactComponent as HRIcon } from "../../../../Assets/icons/05-HR.svg";
import { ReactComponent as SPOIcon } from "../../../../Assets/icons/06-SpO2.svg";
import { ReactComponent as HRVIcon } from "../../../../Assets/icons/07-HRV.svg";
import { ReactComponent as PEFIcon } from "../../../../Assets/icons/08-PEF.svg";
import { ReactComponent as FEVIcon } from "../../../../Assets/icons/09-FEV1.svg";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import MLImg from '../../../../../src/Assets/Images/Aidar-Hand-Device-Sketch-01.png';

const drawerWidth = 600;
const useStyles = makeStyles((theme) => ({
    title: {
        flexGrow: 1,
    },
    hide: {
        display: "none",
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        backgroundColor: "#fdf8fb",
        width: drawerWidth,
    },
    typoStyle: {
        fontSize: 20,
        textTransform: `capitalize;`,
        fontFamily: `GTWalsheimProRegular`,
        width: 100,
    },
    drawerHeader: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        justifyContent: "flex-start",
        backgroundColor: "#E4DFE9",
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginRight: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
}));

const CalibrationMeasurementPannel = forwardRef((props, ref) => {

    const refOne = useRef(null);
    const classesQV = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const isCMOpen = useSelector(getShowCMDrawer);
    const [stepNumber, setStepNumber] = useState(1);
    const [timerProgress, setTimerProgress] = useState(1);
    const [isTimerOn, setIsTimerOn] = useState(false);
    const mlRecieveWaitTime = 3; // in minutes
    const selectedPCCPatient = useSelector(getSelectedPCCPatientSelector);
    const selectedPCCPatientProfile = useSelector(getPCCUserProfileSelector);
    const [response, setResponse] = useState({
        statusCode: 0,
        message: "",
    });
    const selectedMLId = useSelector(getSelectedMlIdSelector);
    // console.log("Selected ML: ", selectedMLId);

    const prevMeasurement = useSelector(getPrevMeasurement);
    const latestMeasurement = useSelector(getLatestCalMeasurement);
    // console.log("latestMeasurement payload: ", latestMeasurement);
    const currMeas = useSelector(getPatientVitalsListSelector);
    // console.log("currMeas payload: ", currMeas);
    const [newNotes, setNewNotes] = useState("");
    const [painLevel, setPainLevel] = useState();
    const [weight, setWeight] = useState("");
    const [glucoseLevel, setGlucoseLevel] = useState("");
    const [measurementSuccess, setMeasurementSuccess] = useState(false);
    const loader = useSelector(getPageLoader);
    const [showDialog, setShowDialog] = useState(false);
    const [formState, setFormState] = useState(true);
    const calibrationStatus = useSelector(getCalibrationStatus);
    dayjs.extend(utc);
    dayjs.extend(timezone);
    const timeZoneFromDayjs = dayjs.tz.guess();
    const userProfile = useSelector(getUserProfile);
    const [firstName] = useState(userProfile.firstName);
    const [lastName] = useState(userProfile.lastName);
    const userName = firstName + " " + lastName;

    function closeDialogMenu(event, reason) {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            // Set 'open' to false, however you would do that with your particular code.
            setShowDialog(false);
            changeState();
        }
    };

    function unpairAndCloseDrawer() {
        setStepNumber(1);
        setTimerProgress(0);
        dispatch(setLatestCalMeasurement([]));
        // PostUnassignMouthLabFromSubject({ deviceId: selectedMLId }).then((res) => {
        //     setResponse({
        //         statusCode: res.response ? res.response.status : res.error.response.status,
        //         message: res.response ? res.response.data : res.error.response.data,
        //     });
        // });
        setPainLevel();
        setWeight("__");
        setGlucoseLevel("__");
        dispatch(setShowCMDrawer(false));
        dispatch(setShowFooterButtons(true));
    }

    const changeState = () => {
        setFormState(!formState);
    };

    const handleQuickDrawerOpen = () => {
        dispatch(setShowCMDrawer(true));
    };

    const handleQuickDrawerClose = () => {
        setShowDialog(true);
        dispatch(setShowCMDrawer(false));
        dispatch(setIsCalibrationOngoing(false));
    };

    const handleRetakeMaeasurement = () => {
        setStepNumber(1);
        setPainLevel();
        setWeight("");
        setGlucoseLevel("");
        PostUnassignMouthLabFromSubject({ deviceId: selectedMLId }).then((res) => {
            setResponse({
                statusCode: res.response ? res.response.status : res.error.response.status,
                message: res.response ? res.response.data : res.error.response.data,
            });
        });
        dispatch(setShowCMDrawer(false));
        dispatch(setIsCalibrationOngoing(false));
    }

    const handleSubmitFinalMeasurements = () => {
        setStepNumber(1);
        setShowDialog(true);
        const payload = {
            recordId: latestMeasurement[0].recordId,
            subjectId: selectedPCCPatientProfile.subjectId,
            weight: isNaN(weight) ? "" : weight,
            glucose: isNaN(glucoseLevel) ? "" : glucoseLevel,
            painLevel: isNaN(painLevel) ? "" : painLevel,
            tsRecorded: dayjs(timestamp).toISOString(),
            note: newNotes,
        };

        const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

        const newpayload = {
            recordId: latestMeasurement[0].recordId,
            subjectId: selectedPCCPatientProfile.subjectId,
            temperature: temp === "" ? undefined : temp,
            spo2: spo2 === "" ? undefined : spo2,
            breathingRate: breathingRate === "" ? undefined : breathingRate,
            pulseRate: pulseRate === "" ? undefined : pulseRate,
            heartRate: heartRate === "" ? undefined : heartRate,
            hrv: hrv === "" ? undefined : hrv,
            sbp: sbp === "" ? undefined : sbp,
            dbp: dbp === "" ? undefined : dbp,
            fev1: fev1 === "" ? undefined : fev1,
            pef: pef === "" ? undefined : pef,
            weight: isNaN(weight) ? "" : weight,
            glucose: isNaN(glucoseLevel) ? "" : glucoseLevel,
            painLevel: isNaN(painLevel) ? "" : painLevel,
            tsRecorded: timestamp,
            tsRecorded: dayjs(timestamp).toISOString(),
            note: newNotes,
            timezone: timeZone,
            createdBy: userName,
        };

        // console.log("EXTRA MEASUREMENTS PAYLOAD: ", newpayload);
        dispatch(updateExtraMeasurementParams(newpayload));
        setPainLevel();
        setWeight("");
        setGlucoseLevel("");
        dispatch(setShowCMDrawer(false));
        dispatch(setIsCalibrationOngoing(false));
    }

    let timestamp = "";
    let temp = "";
    let spo2 = "";
    let breathingRate = "";
    let pulseRate = "";
    let heartRate = "";
    let hrv = "";
    let sbp = "";
    let dbp = "";
    let fev1 = "";
    let pef = "";
    let recordId = "";

    latestMeasurement.map((allVitals) => {
        timestamp = allVitals.timestamp;
        temp = allVitals.temperature.value;
        spo2 = allVitals.spo2.value;
        breathingRate = allVitals.breathingRate.value;
        pulseRate = allVitals.pulseRate.value;
        heartRate = allVitals.heartRate.value;
        hrv = allVitals.hrv.value;
        sbp = allVitals.sbp.value;
        dbp = allVitals.dbp.value;
        fev1 = allVitals.fev1.value;
        pef = allVitals.pef.value;
        recordId = allVitals.recordId;
    });

    useImperativeHandle(ref, () => {
        return {
            handleQuickDrawerOpen: handleQuickDrawerOpen,
        };
    });

    const handleFocus = (event) => event.target.select();
    const calOngoing = useSelector(getIsCalOngoing);

    useEffect(() => {
        dispatch(setLatestCalMeasurement(currMeas));
    }, [currMeas]);


    const handleSubmit = () => {
        PostUnassignMouthLabFromSubject({ deviceId: selectedMLId }).then((res) => {
        });
        closeDialogMenu();
        unpairAndCloseDrawer();
    };

    return (
        <div className={classesQV.root} ref={refOne}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer - 1 }}
                open={isCMOpen || showDialog}
            ></Backdrop>
            <main></main>
            <Drawer
                className={classesQV.drawer}
                anchor="right"
                variant="persistent"
                open={isCMOpen}
                classes={{
                    paper: classesQV.drawerPaper,
                }}
            >

                <div className={styles.qvHeader}>
                    <Avatar className={styles.avatar} alt="avatar_patient" src={ProfileIcon}></Avatar>
                    <div className={styles.avatarTitleDiv}>
                        <div className={styles.avatarTitle}><span className={classesQV.typoStyle}> {selectedPCCPatient.fullName + " "}({selectedPCCPatient.medicalRecordNumber})</span></div>
                    </div>
                </div>

                <div className={styles.drawerTitle}>
                    <SensorsIcon></SensorsIcon> Calibration Measurement
                </div>

                {stepNumber === 1 && calibrationStatus.success && (loader ? (<div className={styles.circularProgress}><CircularProgress thickness={5.0} /></div>) : (<>
                    <div className={styles.qvDiv}>
                        <Grid
                            container
                            spacing={0}
                            // justify="center"
                            style={{ padding: 10 }}
                        >
                            <Grid item xs={4} xl={4} lg={4} md={4} sm={4}>
                                <div className={styles.cardHighRisk}>
                                    <div className={styles.iconDivStyles}>
                                        <TempIcon className={styles.highRiskIcon} size={35} />
                                    </div>
                                    <div className={styles.countDivStyles}>
                                        <div className={styles.patientCountHighRisk}>{temp}</div>
                                        <div className={styles.patientCatagoryHighRisk}>Temp (F)</div>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={4} xl={4} lg={4} md={4} sm={4}>
                                <div className={styles.cardHighRisk}>
                                    <div className={styles.iconDivStyles}>
                                        <BPIcon className={styles.highRiskIcon} size={35} />
                                    </div>
                                    <div className={styles.countDivStyles}>
                                        <div className={styles.patientCountHighRisk}>
                                            {sbp}
                                        </div>
                                        <div className={styles.patientCatagoryHighRisk}>SBP (mmHg)</div>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={4} xl={4} lg={4} md={4} sm={4}>
                                <div className={styles.cardHighRisk}>
                                    <div className={styles.iconDivStyles}>
                                        <BPIcon className={styles.highRiskIcon} size={40} />
                                    </div>
                                    <div className={styles.countDivStyles}>
                                        <div className={styles.patientCountHighRisk}>
                                            {dbp}
                                        </div>
                                        <div className={styles.patientCatagoryHighRisk}>DBP (mmHg)</div>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={4} xl={4} lg={4} md={4} sm={4}>
                                <div className={styles.cardHighRisk}>
                                    <div className={styles.iconDivStyles}>
                                        <HRIcon className={styles.highRiskIcon} size={40} />
                                    </div>
                                    <div className={styles.countDivStyles}>
                                        <div className={styles.patientCountHighRisk}>
                                            {heartRate}
                                        </div>
                                        <div className={styles.patientCatagoryHighRisk}>HR (bpm)</div>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={4} xl={4} lg={4} md={4} sm={4}>
                                <div className={styles.cardHighRisk}>
                                    <div className={styles.iconDivStyles}>
                                        <PRIcon className={styles.highRiskIcon} size={40} />
                                    </div>
                                    <div className={styles.countDivStyles}>
                                        <div className={styles.patientCountHighRisk}>
                                            {pulseRate}
                                        </div>
                                        <div className={styles.patientCatagoryHighRisk}>PR (bpm)</div>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={4} xl={4} lg={4} md={4} sm={4}>
                                <div className={styles.cardHighRisk}>
                                    <div className={styles.iconDivStyles}>
                                        <HRVIcon className={styles.highRiskIcon} size={40} />
                                    </div>
                                    <div className={styles.countDivStyles}>
                                        <div className={styles.patientCountHighRisk}>{hrv}</div>
                                        <div className={styles.patientCatagoryHighRisk}>HRV (ms)</div>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={4} xl={4} lg={4} md={4} sm={4}>
                                <div className={styles.cardHighRisk}>
                                    <div className={styles.iconDivStyles}>
                                        <SPOIcon className={styles.highRiskIcon} size={40} />
                                    </div>
                                    <div className={styles.countDivStyles}>
                                        <div className={styles.patientCountHighRisk}>{spo2}</div>
                                        <div className={styles.patientCatagoryHighRisk}>SpO2 (%)</div>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={4} xl={4} lg={4} md={4} sm={4}>
                                <div className={styles.cardHighRisk}>
                                    <div className={styles.iconDivStyles}>
                                        <PEFIcon className={styles.highRiskIcon} size={40} />
                                    </div>
                                    <div className={styles.countDivStyles}>
                                        <div className={styles.patientCountHighRisk}>{pef}</div>
                                        <div className={styles.patientCatagoryHighRisk}>
                                            PEF (L/sec)
                                        </div>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={4} xl={4} lg={4} md={4} sm={4}>
                                <div className={styles.cardHighRisk}>
                                    <div className={styles.iconDivStyles}>
                                        <FEVIcon className={styles.highRiskIcon} size={40} />
                                    </div>
                                    <div className={styles.countDivStyles}>
                                        <div className={styles.patientCountHighRisk}>{fev1}</div>
                                        <div className={styles.patientCatagoryHighRisk}>FEV1 (L)</div>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={4} xl={4} lg={4} md={4} sm={4}>
                                <div className={styles.cardHighRisk}>
                                    <div className={styles.iconDivStyles}>
                                        <RRIcon className={styles.highRiskIcon} size={40} />
                                    </div>
                                    <div className={styles.countDivStyles}>
                                        <div className={styles.patientCountHighRisk}>
                                            {breathingRate}
                                        </div>
                                        <div className={styles.patientCatagoryHighRisk}>
                                            RR (br/min)
                                        </div>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={4} xl={4} lg={4} md={4} sm={4}>
                                <div className={styles.cardHighRisk}>
                                    <div className={styles.iconDivStyles}>
                                        <EditOutlinedIcon className={styles.highRiskIcon} />
                                    </div>
                                    <div className={styles.countDivStyles}>
                                        <input
                                            type="text"
                                            placeholder="__"
                                            value={weight}
                                            onChange={e => setWeight(e.currentTarget.value)}
                                            className={styles.patientCountHighRiskInput}
                                            onFocus={e => handleFocus(e)} />
                                        <div className={styles.patientCatagoryHighRisk}>Weight (lbs)</div>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={4} xl={4} lg={4} md={4} sm={4}>
                                <div className={styles.cardHighRisk}>
                                    <div className={styles.iconDivStyles}>
                                        <EditOutlinedIcon className={styles.highRiskIcon} />
                                    </div>
                                    <div className={styles.countDivStyles}>
                                        <input
                                            type="text"
                                            placeholder="__"
                                            value={glucoseLevel}
                                            onChange={e => setGlucoseLevel(e.currentTarget.value)}
                                            className={styles.patientCountHighRiskInput}
                                            onFocus={e => handleFocus(e)} />
                                        <div className={styles.patientCatagoryHighRisk}>Glucose (mg/dL)</div>
                                    </div>
                                </div>
                            </Grid>

                            <div className={styles.painDiv}>
                                <div className={styles.painDivDesc}>
                                    Rate your pain level on a scale of 0 - 10
                                </div>
                                <div className={styles.painLevelRating}>
                                    <div className={painLevel >= 0 ? styles.selectedRatingdiv : styles.ratingdiv} onClick={() => setPainLevel(0)}>0</div>
                                    <div className={painLevel && painLevel >= 1 ? styles.selectedRatingdiv : styles.ratingdiv} onClick={() => setPainLevel(1)}>1</div>
                                    <div className={painLevel && painLevel >= 2 ? styles.selectedRatingdiv : styles.ratingdiv} onClick={() => setPainLevel(2)}>2</div>
                                    <div className={painLevel && painLevel >= 3 ? styles.selectedRatingdiv : styles.ratingdiv} onClick={() => setPainLevel(3)}>3</div>
                                    <div className={painLevel && painLevel >= 4 ? styles.selectedRatingdiv : styles.ratingdiv} onClick={() => setPainLevel(4)}>4</div>
                                    <div className={painLevel && painLevel >= 5 ? styles.selectedRatingdiv : styles.ratingdiv} onClick={() => setPainLevel(5)}>5</div>
                                    <div className={painLevel && painLevel >= 6 ? styles.selectedRatingdiv : styles.ratingdiv} onClick={() => setPainLevel(6)}>6</div>
                                    <div className={painLevel && painLevel >= 7 ? styles.selectedRatingdiv : styles.ratingdiv} onClick={() => setPainLevel(7)}>7</div>
                                    <div className={painLevel && painLevel >= 8 ? styles.selectedRatingdiv : styles.ratingdiv} onClick={() => setPainLevel(8)}>8</div>
                                    <div className={painLevel && painLevel >= 9 ? styles.selectedRatingdiv : styles.ratingdiv} onClick={() => setPainLevel(9)}>9</div>
                                    <div className={painLevel && painLevel >= 10 ? styles.selectedRatingdiv : styles.ratingdiv} onClick={() => setPainLevel(10)}>10</div>
                                </div>
                            </div>

                            <div className={styles.addNewNoteDiv}>
                                <Input
                                    className={styles.notesEditor}
                                    type="text"
                                    placeholder="Start typing..."
                                    value={newNotes}
                                    multiline={true}
                                    onChange={(event) => setNewNotes(event.target.value)}
                                    disableUnderline="true"
                                />
                            </div>
                            <div className={styles.timeStampStyles}> {dayjs.utc(timestamp).tz(timeZoneFromDayjs).format('YYYY-MM-DD HH:mm')} </div>
                        </Grid>
                    </div>
                    <div className={styles.buttonsDiv}>
                        <div className={styles.completeButton} onClick={() => handleSubmitFinalMeasurements()}>
                            Done
                        </div>
                    </div>
                </>))}

                {stepNumber === 1 && !calibrationStatus.success && (loader ? (<div className={styles.circularProgress}><CircularProgress thickness={5.0} /></div>) : (<>
                    <div className={styles.mlInsDiv}>
                    </div>
                    <div className={styles.stepTwoWarningDiv}>
                        Calibration has failed. Please try again.
                    </div>
                    <div className={styles.buttonsDiv}>
                        <div className={styles.retakeButton} onClick={() => handleRetakeMaeasurement()}>
                            Re-calibrate
                        </div>
                        <div className={styles.completeButton} onClick={() => handleQuickDrawerClose()}>
                            Done
                        </div>
                    </div>
                </>))}
            </Drawer>

            {/* MouthLab removal from subject */}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={styles.modal}
                open={showDialog}
                onClose={(event, reason) => closeDialogMenu(event, reason)}
                hideBackdrop={true}
            >
                <div className={styles.paperUnassignML}>
                    <div className={styles.menuTitle}>Unassign MouthLab</div>

                    <div className={styles.userQuestionMessage}> Unassign ML {selectedMLId} from subject {selectedPCCPatient.fullName} ? </div>

                    <div className={styles.mouthLabImgDiv}>
                        <img src={MLImg} loading="lazy" className={styles.mlImg} />
                    </div>

                    <div className={styles.buttonContainer}>
                        <button type="button" onClick={closeDialogMenu} className={styles.cancelButton1}>
                            Cancel
                        </button>
                        <button type="submit" onClick={handleSubmit} className={styles.submitButton}>
                            Confirm
                        </button>
                    </div>
                </div>
            </Modal>
        </div >
    );
});
export default CalibrationMeasurementPannel;