import { createAction } from "@reduxjs/toolkit";
import { dashboardConstants } from "../Constants/DashboardConstants";

export const getUserProfile = createAction(
  dashboardConstants.GET_USER_PROFILE_REQUEST,
  (payload) => ({ payload })
);
export const getUserProfileSuccess = createAction(
  dashboardConstants.GET_USER_PROFILE_SUCCESS,
  (payload) => ({ payload })
);
export const setProjectIdAndLocationId = createAction(
  dashboardConstants.SET_PROJECTID_AND_LOCATIONID,
  (payload) => ({ payload })
);
export const setMLVitalsWrapperId = createAction(
  dashboardConstants.SET_MLVITALS_WRAPPER_ID,
  (payload) => ({ payload })
);

export const setRulesWrapperId = createAction(
  dashboardConstants.SET_RULES_WRAPPER_ID,
  (payload) => ({ payload })
);
export const setSelectedSubjectId = createAction(
  dashboardConstants.SET_SELECTED_SUBJECTID,
  (payload) => ({ payload })
);
export const setSelectedPageName = createAction(
  dashboardConstants.SET_SELECTED_PAGE_NAME,
  (payload) => ({ payload })
);
export const attemptLogout = createAction(dashboardConstants.ATTEMPT_LOGOUT);
export const resetProjectIdAndLocationId = createAction(dashboardConstants.RESET_PROJECTID_LOCATIONID);

export const setShowNotesPanel = createAction(
  dashboardConstants.SET_SHOW_NOTES_PANEL,
  (payload) => ({ payload })
);

export const setShowNotesDrawer = createAction(
  dashboardConstants.SET_SHOW_NOTES_DRAWER,
  (payload) => ({ payload })
);

export const setShowTimerDrawer = createAction(
  dashboardConstants.SET_SHOW_TIMER_DRAWER,
  (payload) => ({ payload })
);

export const setSelectedMlId = createAction(
  dashboardConstants.SET_SELECTED_ML_ID,
  (payload) => ({ payload })
);

export const setSelectedPCCPatient = createAction(
  dashboardConstants.SET_SELECTED_PCC_PATIENT,
  (payload) => ({ payload })
);

export const setIsCalibrationOngoing = createAction(
  dashboardConstants.SET_IS_CAL_ONGOING,
  (payload) => ({ payload })
);

export const setTimerState = createAction(
  dashboardConstants.SET_TIMER_STATE,
  (payload) => ({ payload })
);

export const setIsTimerRunning = createAction(
  dashboardConstants.SET_IS_TIMER_RUNNING,
  (payload) => ({ payload })
);

export const setTimerValue = createAction(
  dashboardConstants.SET_TIMER_VALUE,
  (payload) => ({ payload })
);

export const setTimerID = createAction(
  dashboardConstants.SET_TIMER_ID,
  (payload) => ({ payload })
);

export const setShowTimerNotCompleteDialog = createAction(
  dashboardConstants.SET_SHOW_TIMER_NOT_COMPLETE_DIALOG,
  (payload) => ({ payload })
);

export const setShouldAbortTimer = createAction(
  dashboardConstants.SET_SHOULD_ABORT_TIMER,
  (payload) => ({ payload })
);

export const setAbortTimerRedirectLink = createAction(
  dashboardConstants.SET_ABORT_TIMER_REDIRECT_LINK,
  (payload) => ({ payload })
);

export const setProjectName = createAction(
  dashboardConstants.SET_PROJECT_NAME,
  (payload) => ({ payload })
);

export const setLocationName = createAction(
  dashboardConstants.SET_LOCATION_NAME,
  (payload) => ({ payload })
);