import React, { useState, useContext, useEffect } from "react";
import styles from "./Login.module.css";
import Grid from "@mui/material/Grid";
import { BrowserRouter as Router, NavLink, useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { BsFillEyeSlashFill } from "react-icons/bs";
import { MuiOtpInput } from 'mui-one-time-password-input';
import UserPool from "../Authentication/UserPool";
import { CognitoUser } from "amazon-cognito-identity-js";
import * as actions from '../../Actions';

// Context imports
import { AccountContext } from "../Store/AuthenticationStore/Account";
import { UserContext } from "../Store/UserContext";

//API Imports
import GetDashboardUserInfo from "../API/GetDashboardUserInfo/GetDashboardUserInfo";
import { GrActions } from "react-icons/gr";
import { useDispatch, useSelector } from "react-redux";

import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { getUserRole } from "../../Reducer/selectors/DashboardSelector";
import { ReactComponent as HeaderIcon } from "../../Assets/icons/Aidar-Header.svg";


export default function Login() {
  // State
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [authProcessing, setAuthProcessing] = useState(false);
  const [authErrorMsg, setAuthErrorMsg] = useState("");

  // To store forgot password email
  const [forgotPwdEmail, setForgotPwdEmail] = React.useState('');

  // Forgot Password Screen 1
  const [fgtPwd1, setFgtPwd1] = React.useState(false);

  // Forgot Password Screen 2
  const [fgtPwd2, setFgtPwd2] = React.useState(false);

  // To render login page
  const [isLogin, setIsLogin] = React.useState(true);

  // To store OTP value
  const [otpValue, setOtpValue] = React.useState('');

  // Flag to submit OTP
  const [isOTPFull, setIsOTPFull] = React.useState(false);

  // state to handle the resend confirmation code
  const [isResendCode, setIsResendCode] = useState(false);

  // State to hold new password
  const [newPassword, setNewPassword] = useState('');

  // State to hold new password
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  // state to handle verified up errors
  const [verificationError, setVerificationError] = useState("");

  // state to handle error displaying in signing up process
  const [isVerificationError, setIsVerificationError] = useState(false);

  // to store the status of password change process
  const [isPwdChangeSuccessful, setIsPwdChangeSuccessful] = useState(false);

  // to store the status of resend verification code
  const [isResendError, setIsResendError] = useState(false);

  // to store the error of resend verification code
  const [resendError, setResendError] = useState('');

  //
  const [emailError, setEmailError] = React.useState(false);

  //
  const [passwordError, setPasswordError] = React.useState('');

  // Context Definition
  const { authenticate } = useContext(AccountContext);
  const { setUserInformation } = useContext(UserContext);
  const dispatch = useDispatch();
  const handleSubmit = (event) => {
    event.preventDefault();
    setAuthProcessing(true);
    dispatch(actions.setLocationName(""));
    dispatch(actions.setProjectName(""));
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    if (authProcessing) {
      authenticate(email, password)
        .then((data) => {
          dispatch(actions.getUserProfile(email));
          localStorage.setItem("userId", email);
          fetchUserProfileFromEmail(signal);
          console.log("Logged IN", data, "JWT Token: ", data.idToken.jwtToken);
          console.log("User information ", data.idToken.payload.sub + "first Name :" + data.idToken.payload.email);
          setAuthErrorMsg(null);
          dispatch(actions.setTimerState(0));
        })
        .catch((err) => {
          console.log("Failed to Log in", err, err.message);
          setAuthErrorMsg(err.message);
          setAuthProcessing(false);
        });
    }
    return () => {
      return () => controller.abort();
    };
  }, [authProcessing, setAuthErrorMsg]);

  // useEffect(() => {
  //   if (authProcessing) {
  //     dispatch(actions.getUserProfile(email));
  //   }
  // }, [authProcessing]);

  const fetchUserProfileFromEmail = (signal) => {
    console.log('5....')
    dispatch(actions.getUserProfile(email));

    // GetDashboardUserInfo({ email: email }).then((res) => {
    //   console.log("USER INFO 5", res);
    //   setUserInformation({
    //     email: res.email,
    //     firstName: res.firstName,
    //     lastName: res.lastName,
    //     institutionId: res.institutionId,
    //     projects: res.projects,
    //     locations: res.locations,
    //   });
    // });
  };

  const enableFgtPwd1 = () => {
    setIsLogin(false);
    setFgtPwd1(true);
    setFgtPwd2(false);
    setForgotPwdEmail('');
  }

  const backToLogin = () => {
    setIsLogin(true);
    setFgtPwd1(false);
    setFgtPwd2(false);
  }

  const backToEmail = () => {
    setIsLogin(false);
    setFgtPwd1(true);
    setFgtPwd2(false);
  }

  const enableFgtPwd2 = async () => {
    setIsLogin(false);
    setFgtPwd1(false);
    setFgtPwd2(true);
    setIsResendCode(false);
    setOtpValue('');
    setNewPassword('');
    setConfirmNewPassword('');
    setIsResendError(false);
    setIsPwdChangeSuccessful(false);

    const userData = {
      Username: forgotPwdEmail,
      Pool: UserPool
    }

    const cognitoUser = new CognitoUser(userData);

    await cognitoUser.forgotPassword({
      onSuccess: function (result) {
        console.log('Forgot password inittiated! ', result);
      },
      onFailure: function (result) {
        console.log('Forgot password error: ', result);
      }
    });
  }

  const checkOTPLength = () => {
    if (otpValue.length === 6) {
      setIsOTPFull(true);
    } else {
      setIsOTPFull(false);
    }
  }

  const handleOtpChange = (newValue) => {
    checkOTPLength();
    setOtpValue(newValue);
  }

  const resendConfirmationCode = async () => {
    setResendError('');
    setOtpValue('');
    const userData = {
      Username: forgotPwdEmail,
      Pool: UserPool
    }
    const cognitoUser = new CognitoUser(userData);
    await cognitoUser.forgotPassword({
      onSuccess: function (result) {
        console.log('Forgot password inittiated! ', result);
        setIsVerificationError(false);
        setVerificationError('');
        setIsResendCode(true);
      },
      onFailure: function (result) {
        console.log('Forgot password error: ', result);
        setIsResendError(true);
        setResendError(result.message);
      }
    });
  }

  const submitChangePwd = async (event) => {

    setIsLogin(false);
    setFgtPwd1(false);
    setVerificationError('');
    setIsVerificationError(false);
    setIsPwdChangeSuccessful(false);

    event.preventDefault();

    const userData = {
      Username: forgotPwdEmail,
      Pool: UserPool
    }
    const cognitoUser = new CognitoUser(userData);

    await cognitoUser.confirmPassword(otpValue, newPassword, {
      onSuccess: function (result) {
        console.log('Password changed successfully! ', result);
        setIsVerificationError(false);
        setIsPwdChangeSuccessful(true);
        setTimeout(() => {
          backToLogin();
        }, 5000);
      },
      onFailure: function (result) {
        console.log('Password change error: ', result);
        setIsPwdChangeSuccessful(false);
        setIsVerificationError(true);
        setVerificationError(result.message);
      }
    });
  }

  const isEmailValid = () => {
    const isEmail = /^[a-z0-9.]{1,64}@[a-z0-9.]{1,64}$/i.test(forgotPwdEmail);
    return isEmail;
  }

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));

  const handleEmailValidation = (event) => {
    event.preventDefault();
    const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isEmailValid = regexp.test(forgotPwdEmail);
    if (!isEmailValid && forgotPwdEmail) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  }

  const handleValidation = (event) => {
    const pwdInput = event.target.value.trim();

    const uppercaseRegExp = /(?=.*?[A-Z])/;
    const lowercaseRegExp = /(?=.*?[a-z])/;
    const digitsRegExp = /(?=.*?[0-9])/;
    const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
    const minLengthRegExp = /.{8,}/;

    const pwdLength = pwdInput.length;

    const uppercasePassword = uppercaseRegExp.test(pwdInput);
    const lowercasePassword = lowercaseRegExp.test(pwdInput);
    const digitsPassword = digitsRegExp.test(pwdInput);
    const specialCharPassword = specialCharRegExp.test(pwdInput);
    const minLengthPassword = minLengthRegExp.test(pwdInput);

    let errMsg = "";
    if (!uppercasePassword) {
      errMsg = "Needs at least 1 upper case character";
    } else if (!lowercasePassword) {
      errMsg = "Needs at least 1 lower case character";
    } else if (!digitsPassword) {
      errMsg = "Needs at least 1 numerical digit";
    } else if (!specialCharPassword) {
      errMsg = "Needs at least 1 special character";
    } else if (!minLengthPassword) {
      errMsg = "Needs at least 8 characters in total";
    } else {
      errMsg = "";
    }

    if (pwdLength === 0) {
      errMsg = '';
    }

    setPasswordError(errMsg);
  }

  return (
    <>
      {isLogin &&
        <div>
          <div className={styles.loginWrapper}>
            <Grid item className={styles.loginFormContainer}>
              <div className={styles.loginCard}>
                <div className={styles.loginContainer}>
                  <HeaderIcon className={styles.headerIcon}></HeaderIcon>
                  <div className={styles.openMessage}> Welcome to Aidar Bridge!</div>
                  <form onSubmit={handleSubmit}>
                    <div className={styles.formFieldWrapper}>
                      <div className={styles.labelWrapper}>
                        <label className={styles.labelStyles} htmlFor="email">
                          Email
                        </label>
                      </div>
                      <input className={styles.inputStyle} value={email} onChange={(event) => setEmail(event.target.value)}></input>
                    </div>
                    <div className={styles.formFieldWrapper4}>
                      <div className={styles.labelWrapper}>
                        <label className={styles.labelStyles} htmlFor="password">
                          Password
                        </label>
                      </div>
                      <input
                        className={styles.inputStyle}
                        type={isPasswordVisible ? "text" : "password"}
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                      ></input>
                      <span className={styles.passwordDisplayActions}>
                        <IconButton
                          size="medium"
                          className={styles.pwdLabel}
                          onClick={() => {
                            setIsPasswordVisible(!isPasswordVisible);
                          }}
                        >
                          {!isPasswordVisible ? (
                            <Visibility style={{ color: `#AA346F`, marginTop: `5px` }} />
                          ) : (
                            <VisibilityOff style={{ color: `#AA346F`, marginTop: `5px` }} />
                          )}
                        </IconButton>

                        {!isPasswordVisible ? <div className={styles.pwdLabel}>Show password</div> : <div className={styles.pwdLabel}>Hide password</div>}
                      </span>
                    </div>
                    {authErrorMsg !== '' && (<div className={styles.formFieldWrapper3}>
                      <div className={styles.labelWrapper2}>
                        <label className={styles.errorMessageStyles} htmlFor="error">
                          {authErrorMsg}
                        </label>
                      </div>
                    </div>)}
                    <div className={styles.buttonDiv}>
                      <button className={styles.loginButton} type="submit">
                        Login
                      </button>
                    </div>
                    <div className={styles.footerDiv}>
                      Not a Member?{" "}
                      <span>
                        <NavLink to="/signup" className={styles.navlinkStyle}>
                          Sign Up
                        </NavLink>
                      </span>
                    </div>
                    <div className={styles.footerDiv}>
                      <span>
                        <p className={styles.navlinkStyle2} style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={enableFgtPwd1}>
                          Forgot Password?
                        </p>
                      </span>
                    </div>
                  </form>
                </div>
              </div>
            </Grid>
            <div className={styles.ccfooterDiv}>
              AidarBridge v1.0.0 | All Rights Reserved © Aidar Health
            </div>
          </div>
        </div>
      }
      {fgtPwd1 &&
        <div>
          <div className={styles.loginWrapper}>
            <Grid item className={styles.loginFormContainer}>
              <div className={styles.loginCard}>
                <div className={styles.loginContainer}>
                  <HeaderIcon className={styles.headerIcon}></HeaderIcon>
                  <div className={styles.openMessage}> Reset Password</div>
                  <div className={styles.stepInfoDiv}>Step 1 of 2</div>
                  <div className={styles.openMessage2}> Please enter your email to continue.</div>
                  <form onSubmit={enableFgtPwd2}>
                    <div className={styles.formFieldWrapper2}>
                      <div className={styles.labelWrapper}>
                        <label className={styles.labelStyles} htmlFor="email">
                          Email
                        </label>
                      </div>
                      <input className={styles.inputStyle} value={forgotPwdEmail} onChange={(event) => setForgotPwdEmail(event.target.value)} onKeyUp={(e) => handleEmailValidation(e)}></input>
                    </div>
                    {emailError && (
                      <div className={styles.formFieldWrapper}>
                        <div className={styles.labelWrapper}>
                          <label className={styles.errorMessageStyles} htmlFor="error">
                            Enter a valid email address
                          </label>
                        </div>
                      </div>
                    )}
                    <div className={styles.buttonDiv}>
                      <button className={styles.loginButton} type="submit" disabled={!isEmailValid()}>
                        Continue
                      </button>
                    </div>
                    <div className={styles.buttonDiv}>
                      <span>
                        <p className={styles.navlinkStyle2} style={{ cursor: 'pointer', textDecoration: 'underline', color: '#AA346F' }} onClick={backToLogin}>
                          Back to login
                        </p>
                      </span>
                    </div>
                  </form>
                </div>
              </div>
            </Grid>
            <div className={styles.ccfooterDiv}>
              AidarBridge v1.0.0 | All Rights Reserved © Aidar Health
            </div>
          </div>
        </div>
      }
      {fgtPwd2 &&
        <div>
          <div className={styles.loginWrapper}>

            <Grid item className={styles.loginFormContainer}>
              <div className={styles.loginCard1}>
                <div className={styles.loginContainer}>
                  <div className={styles.openMessage1}>Reset Password</div>
                  <div className={styles.stepInfoDiv1}>Step 2 of 2</div>
                  <form onSubmit={submitChangePwd}>
                    <div className={styles.FormContainer}>
                      <div className={styles.formFieldWrapper1}>
                        <div className={styles.labelWrapper2}>
                          <label className={styles.labelStyles1} htmlFor="npiNumber">
                            Enter the 6-digit code sent to your email *
                          </label>
                        </div>
                        <MuiOtpInput sx={{ color: "white", width: "120% !important", fontWeight: "bold" }} length={6} value={otpValue} onChange={handleOtpChange} />
                      </div>

                      {isResendCode &&
                        (<div className={styles.formFieldWrapper3}>
                          <div className={styles.labelWrapper1}>
                            <label className={styles.successMessageStyle} htmlFor="success">
                              A new code has been sent to your email.
                            </label>
                          </div>
                        </div>)
                      }

                      {isResendError &&
                        (<div className={styles.formFieldWrapper3}>
                          <div className={styles.labelWrapper}>
                            <label className={styles.successMessageStyle} htmlFor="error">
                              {resendError}
                            </label>
                          </div>
                        </div>)
                      }

                      <div className={styles.formFieldWrapper3}>
                        <div className={styles.labelWrapper}>
                          <label className={styles.labelStyles} htmlFor="password">
                            New Password *
                          </label>
                          <HtmlTooltip
                            title={
                              <React.Fragment>
                                <h4 className={styles.infoText}>The pasword requires a minimum of:</h4>
                                <ul>
                                  <li className={styles.infoText}>8 characters total</li>
                                  <li className={styles.infoText}>1 upper case character [A-Z]</li>
                                  <li className={styles.infoText}>1 lower case character [a-z]</li>
                                  <li className={styles.infoText}>1 numerical digit [0-9]</li>
                                  <li className={styles.infoText}>1 special character [!@#$%^&*()]</li>
                                </ul>
                              </React.Fragment>
                            }
                          >
                            <InfoOutlinedIcon className={styles.infoButton}></InfoOutlinedIcon>
                          </HtmlTooltip>
                        </div>
                        <input className={styles.inputStyle} type="password" value={newPassword} onChange={(event) => setNewPassword(event.target.value)} onKeyUp={(e) => handleValidation(e)}></input>
                      </div>
                      {(passwordError !== '') && (
                        <div className={styles.formFieldWrapper3}>
                          <div className={styles.labelWrapper1}>
                            <label className={styles.errorMessageStyles2} htmlFor="error">
                              {passwordError}
                            </label>
                          </div>
                        </div>)}
                      <div className={styles.formFieldWrapper3}>
                        <div className={styles.labelWrapper}>
                          <label className={styles.labelStyles} htmlFor="confirmPassword">
                            Confirm Password *
                          </label>
                        </div>
                        <input className={styles.inputStyle} type="password" value={confirmNewPassword} onChange={(event) => setConfirmNewPassword(event.target.value)}></input>
                      </div>

                      {newPassword !== confirmNewPassword && (
                        <div className={styles.formFieldWrapper3}>
                          <div className={styles.labelWrapper1}>
                            <label className={styles.errorMessageStyles2} htmlFor="error">
                              Passwords do not match
                            </label>
                          </div>
                        </div>
                      )}

                      {isVerificationError &&
                        (<div className={styles.formFieldWrapper3}>
                          <div className={styles.labelWrapper1}>
                            <label className={styles.errorMessageStyles2} htmlFor="error">
                              {verificationError}
                            </label>
                          </div>
                        </div>)
                      }

                      {isPwdChangeSuccessful &&
                        (<div className={styles.formFieldWrapper}>
                          <div className={styles.labelWrapper}>
                            <label className={styles.successMessageStyle} htmlFor="success">
                              Your password has been successfully changed! Please wait to be redirected to login page.
                            </label>
                          </div>
                        </div>)
                      }

                      {!isPwdChangeSuccessful &&
                        <>
                          <div className={styles.buttonDivConfirm1}>
                            <button className={styles.loginButton} type="submit" disabled={(newPassword === "") || (newPassword !== confirmNewPassword)}>
                              Confirm
                            </button>
                          </div>

                          <div className={styles.buttonDivResend}>
                            <span>
                              <p className={styles.navlinkStyle2} style={{ cursor: 'pointer', textDecoration: 'underline', color: '#AA346F' }} onClick={resendConfirmationCode}>
                                Resend Verification Code
                              </p>
                            </span>
                          </div>

                          <div className={styles.buttonDivGoBack}>
                            <span>
                              <p className={styles.navlinkStyle2} style={{ cursor: 'pointer', textDecoration: 'underline', color: '#AA346F' }} onClick={backToEmail}>
                                Go Back
                              </p>
                            </span>
                          </div>
                        </>}

                    </div>
                  </form>
                </div>
              </div>
            </Grid>

            <div className={styles.ccfooterDiv}>
              AidarBridge v1.0.0 | All Rights Reserved © Aidar Health
            </div>
          </div>
        </div>
      }
    </>
  );
}
