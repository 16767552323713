import React, { useState } from "react";
import styles from "./LogoutModal.module.css";
import Grid from "@material-ui/core/Grid";
import Modal from "@material-ui/core/Modal";

// UI component imports
import CountDownTimer from "../../UI/CountDownTimer/CountDownTimer";

export default function LogoutModal(props) {

  const [showDialog, setShowDialog] = useState(true);

  function closeDialogMenu() {
    setShowDialog(false);
  }

  const handleOnLogOff = () => {
    props.onLogoff();
  };

  const handleOnContinue = () => {
    props.onContinue();
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={styles.modal}
        open={showDialog}
        // onClose={closeDialogMenu}

        onClose={(event, reason) => {
          if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            // Set 'open' to false, however you would do that with your particular code.
            closeDialogMenu();
          }
        }}
      >
        <div className={styles.warningPaper}>
          <div className={styles.menuTitle}>Session Timeout</div>
          <div className={styles.subTitle}>
            You are being timed out due to inactivity.
          </div>

          <div className={styles.warningModalContent}>
            <Grid container>
              <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
                <div className={styles.warningMsgContent}>
                  <div className={styles.contentText}>
                    Please chose to stay signed in or logoff.
                  </div>
                  <div className={styles.contentText}>
                    Otherwise you will be logged off in:
                  </div>
                </div>
                <div>
                </div>
              </Grid>
              <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
                {/* <div className={styles.warningMsgContent}>
                <div className={styles.timerDiv}>
                  <div className={styles.counterTime}>
                    <div className={styles.timerNumber}>0</div>
                    <div className={styles.timerNumber}>2</div>
                    <div className={styles.timerSeparator}>:</div>
                    <div className={styles.timerNumber}>0</div>
                    <div className={styles.timerNumber}>0</div>
                  </div>
                </div>
              </div> */}

                <CountDownTimer />
              </Grid>
              <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
                <div className={styles.buttonDiv}>
                  <button
                    className={styles.logoutButton}
                    onClick={handleOnLogOff}
                  >
                    Logout
                  </button>
                  <button
                    className={styles.continueButton}
                    onClick={handleOnContinue}
                  >
                    Continue
                  </button>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </Modal>
    </div>
  );
}
