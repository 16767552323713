import { nursingDashboardConstants } from "../Constants/NursingDashboardConstants";

const initialState = {
  pccPatientsList: [],
  pccPatientProfile: undefined,
  prevMeasurement: [],
  latestMeasurement: [],
  latestCalMeasurement: [],
  extraParamsAdded: false,
  uploadManualEntry: false,
  calibrationStatus: {},
  pccPageNumber: 1,
  pccPageSize: "20",
  paging: {
    hasMore: false
  },
  isUserAlreadyPaired: {},
  subjectId: '',
  qmDrawer: false,
  meDrawer: false,
  cmDrawer: false,
  updateFacilityInfo: false,
  floorEdit: false,
  showSaveCancelForFacility: false,
  showFooterButtons: true,
};

export function nursingDashboardReducer(state = initialState, action) {

  switch (action.type) {
    case nursingDashboardConstants.SET_SHOW_FOOTER_BUTTONS:
      return {
        ...state,
        showFooterButtons: action.payload,
      };

    case nursingDashboardConstants.GET_PCC_PATIENTS_LIST:
      return {
        ...state,
      };

    case nursingDashboardConstants.GET_PCC_PATIENTS_LIST_SUCCESS:
      return {
        ...state,
        pccPatientsList: action.payload && action.payload.pccPatients,
        paging: action.payload && action.payload.paging,
      };

    case nursingDashboardConstants.GET_PCC_PATIENTS_LIST_FAILURE:
      return {
        ...state,
        pccPatientsList: [],
        paging: {},
      };
    case nursingDashboardConstants.SEARCH_PCC_PATIENT:
      return {
        ...state,
      };

    case nursingDashboardConstants.SEARCH_PCC_PATIENT_SUCCESS:
      return {
        ...state,
        pccPatientsList: action.payload && action.payload.pccPatients,
      };

    case nursingDashboardConstants.SEARCH_PCC_PATIENT_FAILURE:
      return {
        ...state,
        pccPatientsList: [],
        paging: {},
      };

    case nursingDashboardConstants.GET_PCC_USER_PROFILE_REQUEST:
      return {
        ...state,
      };

    case nursingDashboardConstants.GET_PCC_USER_PROFILE_SUCCESS:
      return {
        ...state,
        pccPatientProfile: action.payload,
      };

    case nursingDashboardConstants.GET_PCC_USER_PROFILE_FAILURE:
      return {
        ...state,
        pccPatientProfile: undefined,
      };

    case nursingDashboardConstants.SET_PREV_MEASUREMENT:
      return {
        ...state,
        prevMeasurement: action.payload,
      };

    case nursingDashboardConstants.SET_LATEST_MEASUREMENT:
      return {
        ...state,
        latestMeasurement: action.payload,
      };

    case nursingDashboardConstants.SET_LATEST_CAL_MEASUREMENT:
      return {
        ...state,
        latestCalMeasurement: action.payload,
      };

    case nursingDashboardConstants.UPDATE_EXTRA_MEASUREMENT_PARAMS:
      return {
        ...state,
      };

    case nursingDashboardConstants.UPDATE_EXTRA_MEASUREMENT_PARAMS_SUCCESS:
      return {
        ...state,
        extraParamsAdded: true,
      };

    case nursingDashboardConstants.UPDATE_EXTRA_MEASUREMENT_PARAMS_FAILURE:
      return {
        ...state,
        extraParamsAdded: false,
      };

    case nursingDashboardConstants.UPLOAD_MANUAL_ENTRY:
      return {
        ...state,
      };

    case nursingDashboardConstants.UPLOAD_MANUAL_ENTRY_SUCCESS:
      return {
        ...state,
        uploadManualEntry: true,
      };

    case nursingDashboardConstants.UPLOAD_MANUAL_ENTRY_FAILURE:
      return {
        ...state,
        uploadManualEntry: false,
      };

    case nursingDashboardConstants.SET_UPLOAD_MANUAL_ENTRY_STATUS:
      return {
        ...state,
        uploadManualEntry: action.payload,
      };

    case nursingDashboardConstants.SET_CALIBRATION_STATUS:
      return {
        ...state,
        calibrationStatus: action.payload,
      };

    case nursingDashboardConstants.SET_PCC_PAGE_NUMBER:
      return {
        ...state,
        pccPageNumber: action.payload,
      };

    case nursingDashboardConstants.SET_PCC_PAGE_SIZE:
      return {
        ...state,
        pccPageSize: action.payload,
      };
    case nursingDashboardConstants.GET_SUBJECT_HAS_ML:
      return {
        ...state,
      };
    case nursingDashboardConstants.GET_SUBJECT_HAS_ML_SUCCESS:
      return {
        ...state,
        isUserAlreadyPaired: action.payload,
      };
    case nursingDashboardConstants.GET_SUBJECT_HAS_ML_FAILURE:
      return {
        ...state,
      };
    case nursingDashboardConstants.GET_SUBJECT_FROM_DEVICE:
      return {
        ...state,
      };
    case nursingDashboardConstants.GET_SUBJECT_FROM_DEVICE_SUCCESS:
      return {
        ...state,
        subjectId: action.payload,
      };
    case nursingDashboardConstants.GET_SUBJECT_FROM_DEVICE_FAILURE:
      return {
        ...state,
      };
    case nursingDashboardConstants.SET_SUBJECT_FROM_DEVICE:
      return {
        ...state,
        subjectId: action.payload,
      };
    case nursingDashboardConstants.SET_SHOW_QM_DRAWER:
      return {
        ...state,
        qmDrawer: action.payload,
      };
    case nursingDashboardConstants.SET_SHOW_ME_DRAWER:
      return {
        ...state,
        meDrawer: action.payload,
      };
    case nursingDashboardConstants.SET_SHOW_CM_DRAWER:
      return {
        ...state,
        cmDrawer: action.payload
      };
    case nursingDashboardConstants.UPDATE_FACILITY_INFO:
      return {
        ...state,
      };

    case nursingDashboardConstants.UPDATE_FACILITY_INFO_SUCCESS:
      return {
        ...state,
        updateFacilityInfo: true,
      };

    case nursingDashboardConstants.UPDATE_FACILITY_INFO_FAILURE:
      return {
        ...state,
        updateFacilityInfo: false,
      };

    case nursingDashboardConstants.SET_FLOOR_EDIT:
      return {
        ...state,
        floorEdit: action.payload
      };

    case nursingDashboardConstants.SET_SHOW_SAVE_CANCEL_FACILITY:
      return {
        ...state,
        showSaveCancelForFacility: action.payload
      };

    default:
      return state;
  }
}