import React from "react";
import { Field, ErrorMessage } from "formik";
import styles from "./Input.module.css";
import InputErrorMessage from "../InputError/InputErrorMessage";

export default function DateInput(props) {
  const { label, name, required, ...rest } = props;
  return (
    <div>
      <div>
        <label htmlFor={name} className={styles.labelDivStyles}>
        {required ? (
          <div className={styles.requiredStyle}>{label}</div>
          ) : (<div className={styles.labelStyles}>{label}</div>)}
        </label>
        <Field type="date" id={name} name={name} {...rest} className={styles.inputDateStyle} max="2999-12-31"/>
        <ErrorMessage name={name} component={InputErrorMessage} />
      </div>
    </div>
  );
}
