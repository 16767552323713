export const dashboardConstants = {
    GET_USER_PROFILE_REQUEST: 'GET_USER_PROFILE_REQUEST',
    GET_USER_PROFILE_SUCCESS: 'GET_USER_PROFILE_SUCCESS',
    GET_USER_PROFILE_FAILURE: 'GET_USER_PROFILE_FAILURE',
    SET_PROJECTID_AND_LOCATIONID: 'SET_PROJECTID_AND_LOCATIONID',
    ATTEMPT_LOGOUT: 'ATTEMPT_LOGOUT',
    RESET_PROJECTID_LOCATIONID: 'RESET_PROJECTID_LOCATIONID',

    //MLVITALS
    SET_MLVITALS_WRAPPER_ID: 'SET_MLVITALS_WRAPPER_ID',
    SET_RULES_WRAPPER_ID: 'SET_RULES_WRAPPER_ID',
    SET_SELECTED_SUBJECTID: 'SET_SELECTED_SUBJECTID',

    //PageName
    SET_SELECTED_PAGE_NAME: 'SET_SELECTED_PAGE_NAME',

    //ML Vitals page notes panel control
    SET_SHOW_NOTES_PANEL: 'SET_SHOW_NOTES_PANEL',

    //ML Vitals page notes drawer control
    SET_SHOW_NOTES_DRAWER: 'SET_SHOW_NOTES_DRAWER',

    //ML Vitals page timer logs drawer control
    SET_SHOW_TIMER_DRAWER: 'SET_SHOW_TIMER_DRAWER',

    SET_SHOW_QM_DRAWER: 'SET_SHOW_QM_DRAWER',
    SET_SELECTED_ML_ID: "SET_SELECTED_ML_ID",
    SET_SELECTED_PCC_PATIENT: "SET_SELECTED_PCC_PATIENT",
    SET_SHOW_ME_DRAWER: "SET_SHOW_ME_DRAWER",
    SET_SHOW_CM_DRAWER: 'SET_SHOW_CM_DRAWER',
    SET_IS_CAL_ONGOING: 'SET_IS_CAL_ONGOING',
    SET_TIMER_STATE: 'SET_TIMER_STATE',
    SET_IS_TIMER_RUNNING: 'SET_IS_TIMER_RUNNING',
    SET_TIMER_VALUE: 'SET_TIMER_VALUE',
    SET_TIMER_ID: 'SET_TIMER_ID',
    SET_SHOW_TIMER_NOT_COMPLETE_DIALOG: 'SET_SHOW_TIMER_NOT_COMPLETE_DIALOG',
    SET_SHOULD_ABORT_TIMER: 'SET_SHOULD_ABORT_TIMER',
    SET_ABORT_TIMER_REDIRECT_LINK: 'SET_ABORT_TIMER_REDIRECT_LINK',

    SET_PROJECT_NAME: 'SET_PROJECT_NAME',
    SET_LOCATION_NAME: 'SET_LOCATION_NAME',
}