import React, { useState, useContext, useEffect } from "react";
import styles from "./BaselineWrapper.module.css";

// react router dom imports
import { useParams } from "react-router-dom";

// Components Imports
import BaselineContainer from "../BaselineContainer/BaselineContainer";
import EditPatientBaseline from "../EditPatientBaseline/EditPatientBaseline";
import SubjectBaseline from "../SubjectBaseline/SubjectBaseline";

//UI Skeleton Component
import BaselineSkeleton from "../../../../../UI/Skeletons/BaselineSkeleton/BaselineSkeleton";

//APIS import
import GetSubjectBaseline from "../../../../../API/GetSubjectBaseline/GetSubjectBaseline";

//Context Imports
import { PatientContext } from "../../../../../Store/PatientDataProvider";

export default function BaselineWrapper() {
  // Defining  context to get access to patient information
  const patientInfo = useContext(PatientContext);
  // let subjectId = patientInfo.subjectId;

  // fetching subject id from the url params
  let curPatientId = useParams();

  // updating patient Id in the context to the patient is fetched from the url params
  // In case user refreshed the page the context will be set to default value and then will be updated to the new value fetch from the url
  patientInfo.setSubjectId(curPatientId.patientId);
  //console.log("-----------------------------------", subjectId);
  console.log("|||||||||||||||||||||", curPatientId.patientId);

  // State to maintain the subject baseline
  const [subjectBaseline, setSubjectBaseline] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [isBaselinePresent, setIsBaselinePresent] = useState(true);
  const [isNewBaseline, setIsNewBaseline] = useState(false);

  useEffect(() => {
    GetSubjectBaseline(curPatientId.patientId).then((res) => {
      console.log("get baseline status", res.success);
      //   setSubjectBaseline(res.map((x) => x));
      setIsBaselinePresent(res.success);
      setSubjectBaseline(res.baseline);
    });
  }, [isEditing, isNewBaseline]);

  console.log(
    "---------------------------sdfkljgbjklsdf-----",
    subjectBaseline
  );

  console.log("IS BASELINE PRESENT ", isBaselinePresent);
  const handleEditing = () => {
    setIsEditing(true);
    //alert("baseline edit started");
  };

  const handleEditingCancel = () => {
    setIsEditing(false);
  };

  const isNewBaselinePresent = (baseline) => {
    setIsNewBaseline((prevIsNewBaseline) => !prevIsNewBaseline);
    console.log("GSDFDFDSFDSFSDFSDFSDFSFSDFSDFSDFDSF", baseline);
    setSubjectBaseline(baseline);
  };

  return (
    <div>
      {isBaselinePresent ? (
        <div>
          {isEditing && (
            <EditPatientBaseline
              onCancel={handleEditingCancel}
              baseline={subjectBaseline}
            />
          )}
          {!isEditing && (
            <div>
              {subjectBaseline.length <= 0 ? (
                <BaselineSkeleton />
              ) : (
                <BaselineContainer
                  baseline={subjectBaseline}
                  onEditing={handleEditing}
                />
              )}
            </div>
          )}
        </div>
      ) : (
        <div>
          <div className={styles.Container}>
            <div className={styles.card}>
              <div className={styles.titleDiv}>
                No baseline present, set a baseline...
              </div>
            </div>

            <SubjectBaseline
              onCancel={handleEditingCancel}
              onSettingBaseline={isNewBaselinePresent}
            />
          </div>
        </div>
      )}
    </div>
  );
}
