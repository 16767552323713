export const patientConstants = {
    GET_PATIENTS_LIST: 'GET_PATIENTS_LIST',
    GET_PATIENTS_LIST_SUCCESS: 'GET_PATIENTS_LIST_SUCCESS',
    GET_PATIENTS_LIST_FAILURE: 'GET_PATIENTS_LIST_FAILURE',

    //PatientVitals
    GET_PATIENT_VITALS_LIST: 'GET_PATIENT_VITALS_LIST',
    GET_PATIENT_VITALS_LIST_SUCCESS: 'GET_PATIENT_VITALS_LIST_SUCCESS',
    GET_PATIENT_VITALS_LIST_FAILURE: 'GET_PATIENT_VITALS_LIST_FAILURE',

    //SubjectVitals
    GET_SUBJECT_VITALS_LIST: 'GET_SUBJECT_VITALS_LIST',
    GET_SUBJECT_VITALS_LIST_SUCCESS: 'GET_SUBJECT_VITALS_LIST_SUCCESS',
    GET_SUBJECT_VITALS_LIST_FAILURE: 'GET_SUBJECT_VITALS_LIST_FAILURE',

    //Subject ECG Plot
    GET_ECG_PLOT: 'GET_ECG_PLOT',
    GET_ECG_PLOT_SUCCESS: 'GET_ECG_PLOT_SUCCESS',
    GET_ECG_PLOT_FAILURE: 'GET_ECG_PLOT_FAILURE',

    //Subject RFM Plot
    GET_RFM_PLOT: 'GET_RFM_PLOT',
    GET_RFM_PLOT_SUCCESS: 'GET_RFM_PLOT_SUCCESS',
    GET_RFM_PLOT_FAILURE: 'GET_RFM_PLOT_FAILURE',

    //Subject LFG Plot
    GET_LFG_PLOT: 'GET_LFG_PLOT',
    GET_LFG_PLOT_SUCCESS: 'GET_LFG_PLOT_SUCCESS',
    GET_LFG_PLOT_FAILURE: 'GET_LFG_PLOT_FAILURE',

    //Subject MouthLab Logs
    GET_MOUTHLAB_LOGS: 'GET_MOUTHLAB_LOGS',
    GET_MOUTHLAB_LOGS_SUCCESS: 'GET_MOUTHLAB_LOGS_SUCCESS',
    GET_MOUTHLAB_LOGS_FAILURE: 'GET_MOUTHLAB_LOGS_FAILURE',

    //Add Subject
    GET_ADD_SUBJECT: 'GET_ADD_SUBJECT',
    GET_ADD_SUBJECT_SUCCESS: 'GET_ADD_SUBJECT_SUCCESS',
    GET_ADD_SUBJECT_FAILURE: 'GET_ADD_SUBJECT_FAILURE',

    //Timer related
    SET_TIMER_STATUS: 'SET_TIMER_STATUS',
    SET_TIMER_COUNT: 'SET_TIMER_COUNT',
    PUT_START_TIMER: 'PUT_START_TIMER',
    PUT_START_TIMER_SUCCESS: 'PUT_START_TIMER_SUCCESS',
    PUT_START_TIMER_FAILURE: 'PUT_START_TIMER_FAILURE',
    PUT_END_TIMER: 'PUT_END_TIMER',
    PUT_END_TIMER_SUCCESS: 'PUT_END_TIMER_SUCCESS',
    PUT_END_TIMER_FAILURE: 'PUT_END_TIMER_FAILURE',
    PUT_ABORT_END_TIMER: 'PUT_ABORT_END_TIMER',
    PUT_ABORT_END_TIMER_SUCCESS: 'PUT_ABORT_END_TIMER_SUCCESS',
    PUT_ABORT_END_TIMER_FAILURE: 'PUT_ABORT_END_TIMER_FAILURE',

    //PatientBaseline
    GET_LATEST_BASELINE: 'GET_LATEST_BASELINE',
    GET_LATEST_BASELINE_SUCCESS: 'GET_LATEST_BASELINE_SUCCESS',
    GET_LATEST_BASELINE_FAILURE: 'GET_LATEST_BASELINE_FAILURE',

    //TRENDS SBP & DBP
    GET_SBP_PAYLOAD: 'GET_SBP_PAYLOAD',
    GET_SBP_PAYLOAD_SUCCESS: 'GET_SBP_PAYLOAD_SUCCESS',
    GET_SBP_PAYLOAD_FAILURE: 'GET_SBP_PAYLOAD_FAILURE',
    
    GET_DBP_PAYLOAD: 'GET_DBP_PAYLOAD',
    GET_DBP_PAYLOAD_SUCCESS: 'GET_DBP_PAYLOAD_SUCCESS',
    GET_DBP_PAYLOAD_FAILURE: 'GET_DBP_PAYLOAD_FAILURE',

    GET_SPO2_PAYLOAD: 'GET_SPO2_PAYLOAD',
    GET_SPO2_PAYLOAD_SUCCESS: 'GET_SPO2_PAYLOAD_SUCCESS',
    GET_SPO2_PAYLOAD_FAILURE: 'GET_SPO2_PAYLOAD_FAILURE',

    GET_BR_PAYLOAD: 'GET_BR_PAYLOAD',
    GET_BR_PAYLOAD_SUCCESS: 'GET_BR_PAYLOAD_SUCCESS',
    GET_BR_PAYLOAD_FAILURE: 'GET_BR_PAYLOAD_FAILURE',

    GET_HR_PAYLOAD: 'GET_HR_PAYLOAD',
    GET_HR_PAYLOAD_SUCCESS: 'GET_HR_PAYLOAD_SUCCESS',
    GET_HR_PAYLOAD_FAILURE: 'GET_HR_PAYLOAD_FAILURE',

    GET_HRV_PAYLOAD: 'GET_HRV_PAYLOAD',
    GET_HRV_PAYLOAD_SUCCESS: 'GET_HRV_PAYLOAD_SUCCESS',
    GET_HRV_PAYLOAD_FAILURE: 'GET_HRV_PAYLOAD_FAILURE',
    
    GET_TEMP_PAYLOAD: 'GET_TEMP_PAYLOAD',
    GET_TEMP_PAYLOAD_SUCCESS: 'GET_TEMP_PAYLOAD_SUCCESS',
    GET_TEMP_PAYLOAD_FAILURE: 'GET_TEMP_PAYLOAD_FAILURE',
    
    GET_PR_PAYLOAD: 'GET_PR_PAYLOAD',
    GET_PR_PAYLOAD_SUCCESS: 'GET_PR_PAYLOAD_SUCCESS',
    GET_PR_PAYLOAD_FAILURE: 'GET_PR_PAYLOAD_FAILURE',
        
    GET_FEV_PAYLOAD: 'GET_FEV_PAYLOAD',
    GET_FEV_PAYLOAD_SUCCESS: 'GET_FEV_PAYLOAD_SUCCESS',
    GET_FEV_PAYLOAD_FAILURE: 'GET_FEV_PAYLOAD_FAILURE',
        
    GET_PEF_PAYLOAD: 'GET_PEF_PAYLOAD',
    GET_PEF_PAYLOAD_SUCCESS: 'GET_PEF_PAYLOAD_SUCCESS',
    GET_PEF_PAYLOAD_FAILURE: 'GET_PEF_PAYLOAD_FAILURE',
}