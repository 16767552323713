import React, { useState, useContext, useEffect, useRef } from "react";
import { Tabs, Tab, AppBar } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./QuickViewTabs.module.css";
import subDays from "date-fns/subDays";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import QuickViewWrapper from "../QuickViewWrapper/QuickViewWrapper";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import IconButton from "@mui/material/IconButton";
import { PatientContext } from "../../../../Store/PatientDataProvider";
import { useDispatch, useSelector } from "react-redux";
import { getPatientVitalsList, getSubjectVitalsList } from "../../../../../Actions";
import { getPatientsListLoader, getPatientVitalsListSelector, getSubjectVitalsListSelector } from "../../../../../Reducer/selectors/PatientSelector";
import { CircularProgress } from "@mui/material";
import ProfileIcon from "../../../../../Assets/Images/ProfileIcon.png"
import { setCurrentPatientGUID, setCurrentPatientSubjectID } from "../../../../../Actions/UtilActions";
import { getCurrentPatientGUID, getCurrentPatientSubjectID } from "../../../../../Reducer/selectors/UtilSelector";

const useStyles = makeStyles({
  textFont: {
    fontSize: 20,
    textTransform: `capitalize;`,
    fontFamily: `GTWalsheimProRegular`
  },
  typoStyle: {
    fontSize: 20,
    textTransform: `capitalize;`,
    fontFamily: `GTWalsheimProRegular`,
    width: 100,
  },
  measurementDiv: {
    fontSize: 15,
    textTransform: `capitalize;`,
    fontFamily: `GTWalsheimProRegular`,
    marginLeft: `auto`,
    marginRight: `10px`,
  },
});

export default function QuickViewTabs(props) {

  const fromDate = format(subDays(new Date(), 30), "yyyy-MM-dd");
  const toDate = format(new Date(), "yyyy-MM-dd");

  const qvVital = [
    {
      temperature: {
        value: "--",
        baseline: 0,
        risk: "0",
      },
      spo2: {
        value: "--",
        baseline: 0,
        risk: "0",
      },
      breathingRate: {
        value: "--",
        baseline: 0,
        risk: "0",
      },
      pulseRate: {
        value: "--",
        baseline: 0,
        risk: "0",
      },
      heartRate: {
        value: "--",
        baseline: 0,
        risk: "0",
      },
      hrv: {
        value: "--",
        baseline: 0,
        risk: "0",
      },
      sbp: {
        value: "--",
        baseline: 0,
        risk: "0",
      },
      dbp: {
        value: "--",
        baseline: 0,
        risk: "0",
      },
      fvc: {
        value: "--",
        baseline: 0,
        risk: "0",
      },
      fev1: {
        value: "--",
        baseline: 0,
        risk: "0",
      },
      pef: {
        value: "--",
        baseline: 0,
        risk: "0",
      },
      timestamp: "YYYY-MM-DD HH:MM:SS",
      recordId: false,
    },
  ];

  const [selectedTab, setSelectedTab] = React.useState(0);

  const classes = useStyles();

  let patientInfo = useContext(PatientContext);
  const dispatch = useDispatch();
  const patientVitals = useSelector(getPatientVitalsListSelector);
  const loader = useSelector(getPatientsListLoader);
  const currPatientGUID = useSelector(getCurrentPatientGUID);
  const currPatientSubjectID = useSelector(getCurrentPatientSubjectID);

  useEffect(() => {
    dispatch(getPatientVitalsList(props.subjectGUID));
  }, []);

  const handlePrevPatient = (event) => {
    event.preventDefault();

    let prevPatientGUID = props.patientList[0].guid;
    let prevPatientID = props.patientList[0].subjectId;
    let prevPatientMLID = props.patientList[0].deviceId;

    if (props.subjectGUID === prevPatientGUID) {
      return;
    }

    for (const x of props.patientList) {

      if (x.guid === props.subjectGUID) {
        dispatch(getPatientVitalsList(prevPatientGUID));
        dispatch(setCurrentPatientGUID(prevPatientGUID));
        dispatch(setCurrentPatientSubjectID(prevPatientID));
        patientInfo.setSubjectGUID(prevPatientGUID);
        patientInfo.setSubjectId(prevPatientID);
        patientInfo.setCurrentMouthLabId(prevPatientMLID);
      } else {
        prevPatientGUID = x.guid;
        prevPatientID = x.subjectId;
        prevPatientMLID = x.deviceId;
      }
    }

  }

  const handleNextPatient = (event) => {
    event.preventDefault();

    const listLen = props.patientList.length;

    let count = 0;

    for (count = 0; count < listLen; count++) {
      if (props.subjectGUID === props.patientList[count].guid) {
        break;
      }
    }
    dispatch(getPatientVitalsList(props.patientList[count + 1].guid));
    dispatch(setCurrentPatientGUID(props.patientList[count + 1].guid));
    dispatch(setCurrentPatientSubjectID(props.patientList[count + 1].subjectId));
    patientInfo.setSubjectGUID(props.patientList[count + 1].guid);
    patientInfo.setSubjectId(props.patientList[count + 1].subjectId);
    patientInfo.setCurrentMouthLabId(props.patientList[count + 1].deviceId);
  }

  const navigate = useNavigate();

  const handleViewMore = (event) => {
    event.preventDefault();
    navigate(`/patientDetails/${currPatientGUID}`);
  }

  return (
    <>
      <div className={styles.qvHeader}>
        <Avatar className={styles.avatar} alt="avatar_patient" src={ProfileIcon}></Avatar>
        <div className={styles.avatarTitleDiv}>
          <IconButton
            size="medium"
            className={styles.iconButtonLeft}
            onClick={(event) => handlePrevPatient(event)}
            disabled={props.subjectId === props.patientList[0].subjectId}>
            <KeyboardArrowLeftIcon style={ props.subjectId === props.patientList[0].subjectId ? { color: `#E4DFE9` } : { color: `#FFFFFF` } }></KeyboardArrowLeftIcon>
          </IconButton>

          <div className={styles.avatarTitle}><span className={classes.typoStyle}>ADR - {props.subjectId}</span></div>

          <IconButton
            size="medium"
            className={styles.iconButtonRight}
            onClick={(event) => handleNextPatient(event)}
            disabled={props.subjectId === props.patientList[props.patientList.length - 1].subjectId}>
            <KeyboardArrowRightIcon style={ props.subjectId === props.patientList[props.patientList.length - 1].subjectId ? { color: `#E4DFE9` } : { color: `#FFFFFF` } }></KeyboardArrowRightIcon>
          </IconButton>

        </div>
      </div>

      {loader ? (<div className={styles.circularProgressDiv} style={{ backgroundColor: `#F3E8EE`, height: `100%` }}><CircularProgress thickness={5.0} style={{ color: `#4E253A`}}></CircularProgress></div>) : (<QuickViewWrapper vitals={patientVitals} />)}

      <Tabs
        value={selectedTab}
        indicatorColor="#FFF8F9"
        textColor="inherit"
        variant="fullWidth"
        aria-label="full width tabs example"
      >
        <Tab className={styles.qvFooter} style={{ backgroundColor: "#E4DFE9" }} label={<button className={styles.viewMoreButton} onClick={(event) => handleViewMore(event)}>View more</button>}></Tab>
      </Tabs>
    </>
  );
}
